import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import SideNavBar from './sideNavBar';

const useStyles = makeStyles({
  mainContent: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 70px - 50px)',
    background: '#F0F0F0',
    overflow: 'hidden',
  },
  left: {
    width: '16rem',
    boxShadow: '0px 4px 4px 0px #0000001A',
    background: '#FFFFFF',
    zIndex: 1,
    transition: '0.1s ease-in',
  },
  leftAfterShrink: {
    width: '5%',
    boxShadow: '0px 4px 4px 0px #0000001A',
    background: '#FFFFFF',
    transition: '0.1s ease-out',
    zIndex: 1,
  },
  right: {
    width: '84%',
  },
  rightAfterExpand: {
    width: '95%',
  },
});

const AppLayout = (props: any) => {
  const classes = useStyles();
  const [childState, setChildState] = useState(true);

  // Callback function to update state in parent
  const updateStateFromChild = (newState: boolean) => {
    setChildState(newState);
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className={classes.mainContent}>
        {props.isLoggedIn && (
          <div className={childState ? classes.left : classes.leftAfterShrink}>
            <SideNavBar updateParentState={updateStateFromChild} />
          </div>
        )}
        <div className={childState ? classes.right : classes.rightAfterExpand}>
          {props.children}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
}

export default connect(mapStateToProps)(AppLayout);
