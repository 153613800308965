import { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import Card from '@material-ui/core/Card';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { Provider, TaxonomyDetails } from '../../shared/types/mediviTypes';
import { DisplayItem } from './DetailPane';
import DataSourceDatesDisplayItem from './DataSourceDatesDisplayItem';
import AddressDisplayBlock from './AddressDisplayBlock';

type Props = {
  isOpen: DialogProps['open'];
  onClose: DialogProps['onClose'];
  classes: ClassNameMap<
    | 'root'
    | 'header'
    | 'professionalTab'
    | 'tabs'
    | 'cardHeader'
    | 'groupHeader'
    | 'groupData'
    | 'displayItem'
    | 'addressBlock'
    | 'address'
    | 'taxonomyBlock'
    | 'taxonomy'
  >;
  provider: Provider;
};

function formatTaxonomy(taxonomy: TaxonomyDetails): string {
  return `${taxonomy.category} / ${taxonomy.title}`;
}

export default function ProfessionalDetailPane({
  isOpen,
  onClose,
  classes,
  provider,
}: Props): JSX.Element {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen} maxWidth={false}>
      <aside className={classes.root}>
        <h2 className={classes.header}>{provider.name}</h2>
        <section className={classes.tabs}>
          <Card className={classes.professionalTab}>
            <div className={classes.cardHeader}>General Information</div>
            <h3 className={classes.groupHeader}>Overview</h3>
            <section className={classes.groupData}>
              <DisplayItem label="NPI" value={provider.providerID} classes={classes} />
              <AddressDisplayBlock classes={classes} provider={provider} />
            </section>

            <h3 className={classes.groupHeader}>Data Source Dates</h3>
            <section className={classes.groupData}>
              <DataSourceDatesDisplayItem
                classes={classes}
                claimType="Professional"
                date={provider.professionalFYEndDate}
              />
            </section>

            <h3 className={classes.groupHeader}>Provider Type</h3>
            <section className={classes.groupData}>
              {provider.primaryTaxonomy && (
                <div className={classes.taxonomyBlock}>
                  <strong>Primary</strong>
                  <div className={classes.taxonomy}>{formatTaxonomy(provider.primaryTaxonomy)}</div>
                </div>
              )}
              {provider.secondaryTaxonomies && (
                <div className={classes.taxonomyBlock}>
                  <strong>Secondary</strong>
                  {provider.secondaryTaxonomies.map(taxonomy => (
                    <ul key={formatTaxonomy(taxonomy)} className={classes.taxonomy}>
                      {formatTaxonomy(taxonomy)}
                    </ul>
                  ))}
                </div>
              )}
            </section>
          </Card>
        </section>
      </aside>
    </Dialog>
  );
}
