import React, { ReactNode, useState } from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { colors } from '@apps/shared/src/style';
import classNames from 'classnames';
import { contentHeight } from './theme';

const buttonWidth = '40px';

const useStyles = makeStyles({
  sidebar: {
    position: 'relative',
    maxHeight: contentHeight,
    background: '#ffffff',
    zIndex: 0,
  },
  toggleButton: {
    boxShadow: 'none',
    position: 'absolute',
    top: '1em',
    zIndex: 2,
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  toggleButtonOpen: {
    left: `calc(100% - ${buttonWidth} / 2)`,
  },
  toggleButtonClose: {
    left: '1em',
  },
  sidebarContent: {
    width: 'max-content',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1px',
    },
  },
});

type Props = {
  toggleButtonTitle?: string;
  children?: ReactNode;
  className?: string;
  hideButton?: boolean;
};

export default function Sidebar({
  children,
  className,
  hideButton = false,
  toggleButtonTitle = 'Sidebar',
}: Props): JSX.Element {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = (): void => setIsOpen(!isOpen);

  return (
    <aside className={classes.sidebar}>
      <Fab
        title={`${isOpen ? 'Close' : 'Open'} ${toggleButtonTitle}`}
        size="small"
        onClick={toggleSidebar}
        className={classNames(
          classes.toggleButton,
          isOpen ? classes.toggleButtonOpen : classes.toggleButtonClose
        )}
        style={{ display: hideButton ? 'none' : '' }}
        color="primary"
        aria-label="toggle sidebar"
      >
        {isOpen ? <LeftIcon /> : <RightIcon />}
      </Fab>
      <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
        <div className={classNames(classes.sidebarContent, className)}>{children}</div>
      </Slide>
    </aside>
  );
}
