import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { Popover, Button, Tooltip } from '@material-ui/core';
import classnames from 'classnames';

const styles = theme => ({
  header: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '0 0 1em 0',
    display: 'flex',
    alignItens: 'center',
  },
  line: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.5em',
    paddingBottom: '.25em',
    justifyItems: 'center',
  },
  title: {
    marginRight: '1.5em',
  },
  text: {
    marginTop: 'auto',
  },
  container: {
    margin: '1em',
  },
  icon: {
    marginRight: '.5em',
    color: theme.popoverButton.icon.color,
  },
  button: {
    maxWidth: '3em',
    height: '2.5em',
    transition: 'max-width .75s',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      maxWidth: '20em',
    },
  },
  full: {
    maxWidth: '20em',
  },
  fade: {
    maxWidth: '3em',
    transition: 'max-width .75s linear 3s',
  },
});

class PopoverButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, Icon, visible, content, title, tooltip = '' } = this.props;
    const open = Boolean(this.state.anchorEl);

    return visible ? (
      <div className={classnames(classes.button, classes.full)}>
        <Tooltip title={tooltip} placement="top">
          <Button onClick={this.handleClick}>
            <Icon mini="true" className={classes.icon} />
            {title}
          </Button>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {content}
        </Popover>
      </div>
    ) : null;
  }
}

PopoverButton.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  Icon: PropTypes.elementType.isRequired,
  content: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
};
PopoverButton.defaultProps = {
  tooltip: '',
};
export default withStyles(styles)(PopoverButton);
