import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { setEntityType } from '@apps/shared/src/providerSearch/actions';

import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  menuItem: theme.selectMenuItem,
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  entityType: providerSearch.entitytype,
  isLoading: providerSearch.isLoadingProviderList,
});

type StateProps = {
  entityType: string;
  isLoading: boolean;
};

const mapDispatchToProps = {
  setEntityType,
};

type Props = StateProps & typeof mapDispatchToProps;

const getEnityType = (entityType: string) => {
  if (entityType === '1') {
    return 'Professional';
  }
  if (entityType === '2') {
    return 'Facility';
  }
  return 'All';
};

export function EntityTypeFilter({ isLoading, entityType, setEntityType }: Props): JSX.Element {
  const classes = useStyles();

  const [selectedOption, setSelectedOptions] = useState(
    entityType && entityType.length <= 1 ? getEnityType(entityType) : 'All'
  );
  const entityTypeOptions = ['All', 'Professional', 'Facility'];

  const renderMenuItemValue: SelectProps['renderValue'] = selected => selected as string;

  const handleEntityTypeChange: SelectProps['onChange'] = e => {
    const entityType = e.target.value as string;
    setSelectedOptions(entityType);
    setEntityType(entityType);
  };

  useEffect(() => {
    setSelectedOptions(entityType && entityType.length <= 1 ? getEnityType(entityType) : 'All');
  }, [entityType]);

  return (
    <div>
      <Typography className={classes.header}>Professional/Facility</Typography>
      <Select
        labelId="EntityType"
        id="entity-type-search"
        value={selectedOption}
        onChange={handleEntityTypeChange}
        disableUnderline
        disabled={isLoading}
        className={classes.Select}
        renderValue={renderMenuItemValue}
      >
        {entityTypeOptions.map(option => (
          <MenuItem key={option} value={option} className={classes.menuItem}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypeFilter);
