import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import { colors } from '@apps/shared/src/style';
import { Link } from 'react-router-dom';
import LoginModal from './LoginModal';

const styles = {
  appBar: {
    background: 'white',
    color: 'black',
  },
  appBarButtonGroup: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  appBarButton: {
    textTransform: 'uppercase',
    margin: '0 0.5rem',
    border: 'none',
    fontWeight: 600,
  },
  appBarButtonCTA: {
    backgroundColor: colors.yellow,
  },
  tabButtons: {
    width: '11vw',
    marginLeft: '3rem',
  },
  activeTabButton: {
    color: '#F97140',
  },
  logo: {
    width: '6rem',
    padding: '1rem 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

function Header({ classes, userType, setUserType }) {
  const scrollToTop = () => {
    const topOfPage = document.getElementById('top').offsetTop;
    return window.scroll({ top: topOfPage, behavior: 'smooth' });
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <ButtonBase onClick={scrollToTop}>
          <img src="/images/medivi_logo_on_light.svg" className={classes.logo} alt="logo" />
        </ButtonBase>
        <ButtonBase
          onClick={() => setUserType('member')}
          className={`${classes.tabButtons} ${
            userType === 'member' ? classes.activeTabButton : ''
          }`}
        >
          <Typography>FOR MEMBERS</Typography>
        </ButtonBase>
        <ButtonBase
          onClick={() => setUserType('partner')}
          className={`${classes.tabButtons} ${
            userType === 'partner' ? classes.activeTabButton : ''
          }`}
        >
          <Typography>FOR PARTNERS</Typography>
        </ButtonBase>
        <div className={classes.appBarButtonGroup}>
          <Button
            className={classes.appBarButton}
            variant="outlined"
            color="inherit"
            to="/app"
            component={Link}
          >
            Download the App
          </Button>
          <LoginModal />
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  userType: PropTypes.string.isRequired,
  setUserType: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
