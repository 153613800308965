import React, { ChangeEventHandler } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Typography, makeStyles, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { RootState } from '@apps/shared/src/rootStateType';
import {
  addDependentMember,
  setDependentMemberInfo,
  resetDependentMember,
  getDependentMembersDetail,
} from '@apps/shared/src/dependentMember/actions';
import { AddDependentMemberInfo } from '@apps/shared/src/dependentMember/types/dependentMemberTypes';

import AppLayout from '../shared/AppLayout';
import TextInput from '../shared/textInput';
import SuccessDialog from '../shared/successDialog';

const useStyles = makeStyles(theme => ({
  container: {
    width: '95%',
    height: '75vh',
    padding: '1rem 1.5rem',
    overflow: 'auto',
    position: 'relative',
  },

  arrowBack: {
    padding: '0rem 0rem 0.5rem',
    width: '2rem',
    height: '2rem',
    color: '#36383D',
    cursor: 'pointer',
  },

  dependentInformationTitle: {
    color: '#5D5C5C',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },

  diSubtitle: {
    color: '#797C80',
    fontWeight: 400,
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
  },
  fieldContainer: {
    width: '100%',
    marginTop: '1rem',
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '1.125rem',
    },
    '& .MuiInputBase-input': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
  btnDiv: {
    position: 'absolute',
    bottom: 0,
    right: '1.5rem',
    display: 'flex',
    gap: '1.125rem',
  },
  cancelBtn: {
    width: '11.5rem',
    textWrap: 'no-wrap',
    backgroundColor: '#fff',
    color: '#797C80',
    padding: '.6rem 1rem',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    border: '1px solid #797C80',
    borderRadius: '.25rem',
    textAlign: 'center',
  },
  addBtn: {
    width: '11.5rem',
    textWrap: 'no-wrap',
    backgroundColor: '#EF7125',
    color: '#FFFFFF',
    padding: '.6rem 1rem',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    borderRadius: '.25rem',
    textAlign: 'center',
    '&.MuiButton-root:hover': {
      backgroundColor: 'chocolate',
    },
  },
}));

type AddDependentMemberState = RootState['addDependentMember'];
type StateProps = {
  dependentMemberInfo: AddDependentMemberState['dependentMemberInfo'];
  isDependentMemberAdded: boolean;
};

const mapStateToProps = ({ addDependentMember }: RootState): StateProps => ({
  dependentMemberInfo: addDependentMember.dependentMemberInfo,
  isDependentMemberAdded: addDependentMember.isDependendentMemberAdded,
});

type DispatchProps = {
  setDependentMemberInfo: typeof setDependentMemberInfo;
  addDependentMember: typeof addDependentMember;
  resetDependentMember: typeof resetDependentMember;
  getDependentMembersDetail: typeof getDependentMembersDetail;
};

const mapDispatchToProps = {
  setDependentMemberInfo,
  addDependentMember,
  resetDependentMember,
  getDependentMembersDetail,
};

type Props = StateProps & DispatchProps;

const DependentInformation = ({
  dependentMemberInfo,
  setDependentMemberInfo,
  addDependentMember,
  isDependentMemberAdded,
  resetDependentMember,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const handleChange =
    (key: keyof AddDependentMemberInfo): ChangeEventHandler<HTMLInputElement> =>
    (e): void => {
      setDependentMemberInfo(key, e.currentTarget.value.toUpperCase());
    };

  const scroll = (e: React.MouseEvent<HTMLElement>): void => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleDateOfBirthChange = (date: string): void => {
    setDependentMemberInfo('dateOfBirth', date);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addDependentMember(dependentMemberInfo);
  };

  const handleCancel = () => {
    resetDependentMember();
    history.goBack();
  };

  const getLabel = (fieldName: string): string => {
    switch (fieldName) {
      case 'memberID':
        return 'Member ID';
      case 'groupID':
        return 'Group ID';
      case 'lastName':
        return 'Last Name';
      case 'firstName':
        return 'First Name';
      default:
        return fieldName;
    }
  };
  // if (isDependentMemberAdded) {
  //   // resetDependentMember();
  //   // getDependentMembersDetail();
  //   return <Redirect to="/my-account" />;
  // }

  const verifyDateOfBirth = (value: string) => {
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const enteredDate = new Date(value);
    const isValid = enteredDate >= eighteenYearsAgo;
    return isValid;
  };

  const verifyZipCode = (value: string) => {
    const isValid = value.length === 5;
    return isValid;
  };

  const disableSubmit: boolean =
    !dependentMemberInfo ||
    !dependentMemberInfo.firstName ||
    !dependentMemberInfo.lastName ||
    !dependentMemberInfo.groupID ||
    !dependentMemberInfo.memberID ||
    !dependentMemberInfo.dateOfBirth ||
    !verifyDateOfBirth(dependentMemberInfo.dateOfBirth) ||
    !verifyZipCode(dependentMemberInfo.zip) ||
    !dependentMemberInfo.zip;

  const handleAddPlanSuccess = (): void => {
    history.push('/my-account');
  };

  return (
    <AppLayout>
      <div className={classes.container}>
        <KeyboardBackspaceIcon onClick={handleCancel} className={classes.arrowBack} />
        <Typography className={classes.dependentInformationTitle}>Dependent Information</Typography>
        <p className={classes.diSubtitle}>Fill in the fields with dependent information.</p>
        <Grid container direction="column" spacing={2}>
          <Grid container item spacing={3}>
            {['memberID', 'groupID', 'firstName', 'lastName'].map((fieldName: string) => (
              <Grid key={fieldName} item xs={4} sm={4}>
                <TextInput
                  name={fieldName}
                  label={getLabel(fieldName)}
                  onChangeEvent={handleChange(fieldName as keyof AddDependentMemberInfo)}
                  value={dependentMemberInfo[fieldName as keyof AddDependentMemberInfo]}
                  validators={['required']}
                  errorMessages={['This field is required.']}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            ))}
            <Grid item xs={4} sm={4}>
              <TextInput
                label="Date of Birth"
                type="date"
                name="dateOfBirth"
                value={dependentMemberInfo.dateOfBirth}
                onClick={scroll}
                onChange={handleDateOfBirthChange}
                validators={['required', 'checkAgeUnder18']}
                errorMessages={[
                  'This field is required.',
                  '*Dependent’s age should be less than 18 years.',
                ]}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextInput
                name="zip"
                label="Zip Code"
                type="text"
                onChangeEvent={handleChange('zip')}
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 5,
                }}
                value={dependentMemberInfo.zip}
                validators={['required', 'isNumber', 'validateZipCode']}
                errorMessages={[
                  'This field is required.',
                  'Only numbers are allowed.',
                  'Must be 5 digits.',
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.btnDiv}>
          <Button className={classes.cancelBtn} onClick={handleCancel}>
            CANCEL
          </Button>
          <Button
            className={classes.addBtn}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            ADD
          </Button>
        </div>
      </div>
      {isDependentMemberAdded && (
        <SuccessDialog
          open={isDependentMemberAdded}
          icon="images/success.gif"
          title="Dependent Member Added!"
          message="Dependent Member added successfully. Check Accounts section for the details."
          buttonText="Continue"
          handleCloseDialog={handleAddPlanSuccess}
          handleSuccess={handleAddPlanSuccess}
        />
      )}
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DependentInformation);
