import axios from 'axios';
import { ADD_SNACKBAR } from '../shared/snackbarReducer';

export const UPDATE_VALUE = 'UPDATE_VALUE';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const UPDATE_DEPENDENT_NUMBERS = 'UPDATE_DEPENDENT_NUMBERS';
export const UPDATE_MEDICARE_RATIOS = 'UPDATE_MEDICARE_RATIOS';
export const UPDATE_PRICING_SELECTION = 'UPDATE_PRICING_SELECTION';
export const VIEW_REPORT = 'VIEW_REPORT';

const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_PENDING = `${DOWNLOAD_REPORT}_PENDING`;
export const DOWNLOAD_REPORT_REJECTED = `${DOWNLOAD_REPORT}_REJECTED`;
export const DOWNLOAD_REPORT_FULFILLED = `${DOWNLOAD_REPORT}_FULFILLED`;

export function updateTotalingNumber(name, value) {
  return dispatch => {
    dispatch(updateValue(name, value));
    dispatch(updateTotal(name));
    dispatch(updateDependentNumbers(name));
  };
}
export function updateDependentValue(name, value) {
  return dispatch => {
    dispatch(updateValue(name, value));
    dispatch(updateDependentNumbers(name));
  };
}
export function updateValue(name, value) {
  return { type: UPDATE_VALUE, payload: { name, value } };
}
export function updateTotal(name) {
  return { type: UPDATE_TOTAL, payload: { name } };
}
export function updateDependentNumbers(name) {
  return { type: UPDATE_DEPENDENT_NUMBERS, payload: { name } };
}
export function updatePricingSelection(value) {
  return { type: UPDATE_PRICING_SELECTION, payload: { value } };
}
export function updateRadio(name, checked) {
  return updateValue(name, checked);
}
export function updateMedicareRatios(ratios) {
  return {
    type: UPDATE_MEDICARE_RATIOS,
    payload: {
      utilization: ratios.utilization,
      inpatientMedicareRatio: ratios.iphRatio,
      outpatientMedicareRatio: ratios.ophRatio,
      professionalMedicareRatio: ratios.proRatio,
    },
  };
}
export function downloadResult(name) {
  return (dispatch, getState) => {
    dispatch({
      type: DOWNLOAD_REPORT,
      payload: axios.post(`/api/comparison-${name}`, getState().analysis, {
        responseType: 'blob',
        timeout: 30000,
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
      meta: name,
    }).catch(error => {
      // use async FileReader to get the error text of the blob
      const reader = new FileReader();
      reader.onload = () => {
        dispatch({ type: ADD_SNACKBAR, payload: reader.result });
      };
      reader.readAsText(error.response.data);
    });
  };
}
export function viewResult(name) {
  return { type: VIEW_REPORT, payload: { name } };
}
