import React, { useState } from 'react';

import axios from 'axios';

import AboveTheFold from './AboveTheFold';
import TestimonialPage from './TestimonialPage';
import RegisterPage from './RegisterPage';
import EmailSuccessModal from './EmailSuccessModal';
import MediviMobile from './MediviMobile';

export function MediVIForPartners(userType: any): JSX.Element {
  const [email, setEmail] = useState('');
  const [wasEmailSent, setWasEmailSent] = useState(false);

  const updateEmail = (e: React.FormEvent<HTMLInputElement>): void => {
    setEmail(e.currentTarget.value.replace(/[^\x00-\x7F]/g, '')); // eslint-disable-line no-control-regex
  };

  const validEmail = (email: string): boolean => {
    const re =
      /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x7f])+)\])$/;
    return re.test(String(email).toLowerCase());
  };

  const requestAccess = (): void => {
    if (validEmail(email)) {
      axios
        .post(`/api/send-email`, { email })
        .then(() => {
          setWasEmailSent(true);
        })
        .catch(() => {
          console.error('POST unsuccessful'); // eslint-disable-line no-console
        });
    }
  };

  const closeEmailSuccessModal = (): void => {
    setEmail('');
    setWasEmailSent(false);
  };

  const isButtonActive = email === '' || validEmail(email);

  return (
    <>
      <AboveTheFold
        email={email}
        updateEmail={updateEmail}
        requestAccess={requestAccess}
        activeButton={isButtonActive}
      />
      <MediviMobile userType={userType.userType} />
      <TestimonialPage />
      <RegisterPage
        email={email}
        updateEmail={updateEmail}
        requestAccess={requestAccess}
        activeButton={isButtonActive}
      />
      <EmailSuccessModal
        email={email}
        requestEmailSent={wasEmailSent}
        closeEmailSuccessModal={closeEmailSuccessModal}
      />
    </>
  );
}

export default MediVIForPartners;
