import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { colors } from '@apps/shared/src/style/colors';
import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles({
  container: {
    padding: '1.5rem',
    height: 'calc(100vh - 120px - 1rem)',
    overflowY: 'scroll',
  },
  heading: {
    margin: 0,
    fontWeight: 500,
  },
  icon: {
    marginRight: '0.25rem',
    color: '#F57040',
  },
  number: {
    color: '#366BED',
  },
  inlineIcon: {
    verticalAlign: 'middle',
    paddingRight: '0.25rem',
    color: '#F57040',
  },
  text: {
    fontSize: '.95rem',
    color: colors.greyText,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    fontWeight: 500,
    marginBottom: '0rem',
  },
  textStyle: {
    fontSize: '.95rem',
    color: colors.greyText,
    paddingLeft: '0.5rem',
    fontWeight: 500,
    marginBottom: '0rem',
  },

  title: {
    fontSize: '1.1rem',
    color: colors.black,
    margin: '0.5rem 0',
    fontWeight: 500,
  },
  subTitle: {
    fontSize: '1rem',
    color: colors.greyText,
    paddingTop: '0.5rem',
    fontWeight: 600,
  },
  greyTextStyle: {
    color: '#888787',
    paddingInlineStart: '25px',
  },
  link: {
    color: '#366BED',
    textDecoration: 'underline',
  },
});

function Privacy(): JSX.Element {
  const classes = useStyles();
  return (
    <AppLayout>
      <div className={classes.container}>
        <h3 className={classes.heading}>Privacy Policy</h3>
        <Typography className={classes.text}>
          6 Degrees Health Dx, LLC (6 Degrees Health) values its users’ privacy. This Privacy Policy
          (“Policy”) will help you understand how we collect and use personal information from those
          who visit our website or make use of our online facilities and services, and what we will
          and will not do with the information we collect. Our Policy, which is subject to change
          from time to time, has been designed and created to ensure those affiliated with 6 Degrees
          Health Dx, LLC of our commitment and realization of our obligation not only to meet, but
          to exceed, most existing privacy standards. We reserve the right to make changes to this
          Policy at any given time. If you want to make sure that you are up to date with the latest
          changes, we advise you to frequently visit this page. If at any point in time 6 Degrees
          Health Dx, LLC decides to make use of any personally identifiable information on file, in
          a manner vastly different from that which was stated when this information was initially
          collected, the user or users shall be promptly notified by email. Users at that time shall
          have the option as to whether to permit the use of their information in this separate
          manner. This Policy applies to 6 Degrees Health Dx, LLC, and it governs any and all data
          collection and usage by us. Through the use of 6degreeshealth.com, you are therefore
          consenting to the data collection procedures expressed in this Policy. Please note that
          this Policy does not govern the collection and use of information by companies that 6
          Degrees Health Dx, LLC does not control, nor by individuals not employed or managed by us.
          If you visit a website that we mention or link to, be sure to review its privacy policy
          before providing the site with information. It is highly recommended and suggested that
          you review the privacy policies and statements of any website you choose to use or
          frequent to better understand the way in which websites garner, make use of, and share the
          information collected.
        </Typography>

        <Typography className={classes.text}>
          Specifically, this Policy will inform you of the following:
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          1. What personally identifiable information is collected from you through our website;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. Why we collect personal identifiable information and the legal basis for such
          collection;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          3. How we use the collected information and with whom it may be shared;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          4. What choices are available to you regarding the use of your data; and
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          5. The security procedures in place to protect the misuse of your information.
        </Typography>

        <Typography className={classes.title}>Information We Collect</Typography>
        <Typography className={classes.text}>
          It is always up to you whether to disclose personally identifiable information to us,
          although if you elect not to do so, we reserve the right to restrict information that you
          will be able to access.
        </Typography>
        <Typography className={classes.text}>
          6 Degrees Health may collect personally identifiable information (“Personal Information”
          or “PII”) via webforms on the Site. This Personal Information may include information used
          to identify you, such as:
        </Typography>

        <Typography variant="body1" paragraph className={classes.textStyle}>
          1.First and Last Name;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. Mailing Address;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          3. E-mail Address;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          4. Phone Number;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          5. Employer; or
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          6. Medical Information
        </Typography>

        <Typography className={classes.text}>
          6 Degrees Health does not collect any Personal Information unless you voluntarily provide
          the Personal Information to 6 Degrees Health. However, you may be required to provide
          certain personal information to 6 Degrees Health when you elect to use certain products or
          services available on the Site. Personal Information which may be required to use the Site
          may include:
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          1. Registering for an account on the Site with an e-mail address; or
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. Submitting a webform on the Site with Personal Information in order for 6 Degrees
          Health to contact you about services provided by the Company.
        </Typography>

        <Typography className={classes.text}>
          6 Degrees Health will use Personal Information for communicating with you in relation to
          services or products you have requested from the Company. The Company also may gather
          additional Personal Information or non-personal information to use for other purposes in
          the future
        </Typography>

        <Typography className={classes.title}>Use Of Personal Information</Typography>
        <Typography className={classes.text}>
          6 Degrees Health collects and uses Personal Information to operate its website(s), apps,
          and deliver the products and services you have requested.
        </Typography>
        <Typography className={classes.text}>
          6 Degrees Health may also use Personal Information to inform you of other products or
          services available from 6 Degrees Health and its affiliates.
        </Typography>

        <Typography className={classes.title}>Sharing Information With Third Parties</Typography>
        <Typography className={classes.text}>
          6 Degrees Health may disclose Personal Information, without notice, if required to do so
          by law or in the good faith belief that such action is necessary to conform with the law
          or comply with legal process served on the Company or the Site.
        </Typography>

        <Typography className={classes.title}>Automatically Collected Information</Typography>
        <Typography className={classes.text}>
          Information about a customer or user’s computer hardware and software may be automatically
          collected by 6 Degrees Health when the customer or user visits the Site. This information
          can include:
        </Typography>

        <Typography variant="body1" paragraph className={classes.textStyle}>
          1. An IP address;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. Browser type;
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          3. Domain names; or
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          4. Access times and referring website addresses.
        </Typography>

        <Typography className={classes.text}>
          This information is used for the operation of the Site, to maintain quality of the
          service, and to provide general statistics regarding use of the Site.
        </Typography>

        <Typography className={classes.title}>Security Of Personal Information</Typography>
        <Typography className={classes.text}>
          6 Degrees Health secures Personal Information against unauthorized access, use, or
          disclosure. 6 Degrees Health uses the following methods to secure your Personal
          Information:
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          1. Secure Sockets Layer (SSL) Protocol
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. HIPPA compliant encryption When 6 Degrees Health transmits Personal Information to
          other websites, the Personal Information is protected through the use of standard
          encryption methods, such as the Secure Sockets Layer (SSL) protocol.
        </Typography>

        <Typography className={classes.text}>
          6 Degrees Health strives to take appropriate security measures to protect against
          unauthorized access to or alteration of your Personal Information. Unfortunately, no data
          transmission over the Internet or any wireless network is guaranteed to be 100% secure. As
          a result, while the Company strives to protect and secure all Personal Information, you
          acknowledge that:
        </Typography>

        <Typography variant="body1" paragraph className={classes.textStyle}>
          1. There are security and privacy limitations inherent to the Internet which are beyond
          the Company’s control; and
        </Typography>
        <Typography variant="body1" paragraph className={classes.textStyle}>
          2. Security, integrity, and privacy of any and all information and data exchanged between
          you and 6 Degrees Health through this Site cannot be guaranteed.
        </Typography>

        <Typography className={classes.title}>E-Mail Communications</Typography>
        <Typography className={classes.text}>
          From time to time, 6 Degrees Health may contact you by email for the purpose of providing
          announcements, promotional offers, alerts, confirmations, surveys, and/or other general
          communication.
        </Typography>
        <Typography className={classes.text}>
          If you would like to stop receiving marketing or promotional communications via email from
          6 Degrees Health, you may opt out of such communications by replying “Stop” to any message
          you received from the Company.
        </Typography>

        <Typography className={classes.title}>Disclosure of Information</Typography>
        <Typography className={classes.text}>
          6 Degrees Health DX, LLC may not use or disclose the information provided by you except
          under the following circumstances:
        </Typography>
        <ul className={classes.greyTextStyle}>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              as necessary to provide services ;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              in other ways described in this Policy or to which you have otherwise consented;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              in the aggregate with other information in such a way so that your identity cannot
              reasonably be determined;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              as required by law, or in response to a subpoena or search warrant;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              to outside auditors who have agreed to keep the information confidential;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              as necessary to enforce the Terms of Service;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              as necessary to maintain, safeguard and preserve all the rights and property of 6
              Degrees Health Dx, LLC.
            </Typography>
          </li>
        </ul>

        <Typography className={classes.title}>Non-Marketing Purposes</Typography>
        <Typography className={classes.text}>
          6 Degrees Health DX, LLC greatly respects your privacy. We do maintain and reserve the
          right to contact you if needed for non-marketing purposes (such as bug alerts, security
          breaches, account issues, and/or changes in 6 Degrees Health DX, LLC products and
          services). In certain circumstances, we may use our website, newspapers, or other public
          means to post a notice.
        </Typography>

        <Typography className={classes.title}>Children under the age of 13</Typography>
        <Typography className={classes.text}>
          6 Degrees Health DX, LLC’s website is not directed to, and does not knowingly collect
          personally identifiable information from, children under the age of thirteen (13). If it
          is determined that such information has been inadvertently collected on anyone under the
          age of thirteen (13), we shall immediately take the necessary steps to ensure that such
          information is deleted from our system’s database, or in the alternative, that verifiable
          parental consent is obtained for the use and storage of such information. Anyone under the
          age of thirteen (13) must seek and obtain parent or guardian permission to use this
          website.
        </Typography>

        <Typography className={classes.title}>Links to Other Websites</Typography>
        <Typography className={classes.text}>
          Our website does contain links to affiliate and other websites. 6 Degrees Health Dx, LLC
          does not claim nor accept responsibility for any privacy policies, practices and/or
          procedures of other such websites. Therefore, we encourage all users and visitors to be
          aware when they leave our website and to read the privacy statements of every website that
          collects personally identifiable information. This Privacy Policy Agreement applies only
          and solely to the information collected by our website.
        </Typography>

        <Typography className={classes.title}>Notice to European Union Users</Typography>
        <Typography className={classes.text}>
          6 Degrees Health Dx, LLC’s operations are located primarily in the United States. If you
          provide information to us, the information will be transferred out of the European Union
          (EU) and sent to the United States. (The adequacy decision on the EU-US Privacy became
          operational on August 1, 2016. This framework protects the fundamental rights of anyone in
          the EU whose personal data is transferred to the United States for commercial purposes. It
          allows the free transfer of data to companies that are certified in the US under the
          Privacy Shield.) By providing personal information to us, you are consenting to its
          storage and use as described in this Policy.
        </Typography>

        <Typography className={classes.title}>Your Rights as a Data Subject</Typography>
        <Typography className={classes.text}>
          Under the regulations of the General Data Protection Regulation (“GDPR”) of the EU, you
          have certain rights as a Data Subject. These rights are as follows:
        </Typography>
        <ul className={classes.greyTextStyle}>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right to be informed:</strong> this means we must inform you of how we
              intend to use your personal data and we do this through the terms of this Policy.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right of access:</strong> this means you have the right to request access
              to the data we hold about you and we must respond to those requests within one month.
              You can do this by sending an email to
              <br />
              <Link
                href="mailto:appsupport@6degreeshealth.com"
                className={classes.link}
                aria-label="contact MediVI technical support"
              >
                appsupport@6degreeshealth.com
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right to rectification: </strong> this means that if you believe some of
              the data we hold is incorrect, you have the right to have it. You can do this by
              logging into your account with us, or by sending us an email with your request.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right to erasure:</strong> this means you can request that the information
              we hold be deleted, and we will comply unless we have a compelling reason not to, in
              which case you will be informed of the same. You can do this by sending an email to
              <br />
              <Link
                href="mailto:appsupport@6degreeshealth.com"
                className={classes.link}
                aria-label="contact MediVI technical support"
              >
                appsupport@6degreeshealth.com
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right to restrict processing:</strong> this means you can change your
              communication preferences or opt out of certain communications. You can do this by
              sending an email to{' '}
              <Link
                href="mailto:appsupport@6degreeshealth.com"
                className={classes.link}
                aria-label="contact MediVI technical support"
              >
                appsupport@6degreeshealth.com
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right of data portability:</strong> this means you can obtain and use the
              data we hold for your own purposes. If you wish to request a copy of your information,
              contact us at
              <br />
              <Link
                href="mailto:appsupport@6degreeshealth.com"
                className={classes.link}
                aria-label="contact MediVI technical support"
              >
                appsupport@6degreeshealth.com
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph className={classes.textStyle}>
              <strong>The right to object:</strong> this means you can file a formal objection with
              us regarding our use of your information with regard to third parties, or its
              processing where our legal basis is our legitimate interest in it. To do this, please
              send an email to
              <br />
              <Link
                href="mailto:appsupport@6degreeshealth.com"
                className={classes.link}
                aria-label="contact MediVI technical support"
              >
                appsupport@6degreeshealth.com
              </Link>
            </Typography>
          </li>
        </ul>
        <Typography className={classes.text}>
          In addition to the rights above, please rest assured that we will always aim to encrypt
          and anonymise your personal information whenever possible. We also have protocols in place
          in the unlikely event that we suffer a data breach and we will contact you if your
          personal information is ever at risk. For more details regarding our security protections
          see the section below or visit our website at 6degreeshealth.com.
        </Typography>

        <Typography className={classes.title}>Acceptance of Terms</Typography>
        <Typography className={classes.text}>
          By using this website, you are hereby accepting the terms and conditions stipulated within
          the Privacy Policy Agreement. If you are not in agreement with our terms and conditions,
          then you should refrain from further use of our sites. In addition, your continued use of
          our website following the posting of any updates or changes to our terms and conditions
          shall mean that you agree and acceptance of such changes.
        </Typography>

        <Typography className={classes.title}>How to Contact Us</Typography>
        <Typography className={classes.text}>
          If you have any questions or concerns regarding the Privacy Policy Agreement related to
          our website, please feel free to contact us at the following email, telephone number, or
          mailing address.
          <br />
          {/* <br /> */}
          {/* <MailOutlineIcon fontSize="inherit" className={classes.inlineIcon} />
          <Link
            href="mailto:appsupport@6degreeshealth.com"
            className={classes.link}
            aria-label="contact MediVI technical support"
          >
            appsupport@6degreeshealth.com
          </Link> */}
        </Typography>
        <Typography className={classes.subTitle}>Mailing Address:</Typography>
        <Typography className={classes.text}>
          6 Degrees Health DX, LLC 3439 NE Sandy Blvd Suite 385, Portland, Oregon 97124
        </Typography>
        <Typography className={classes.text}>
          The data controller responsible for your personal information for the purposes of GDPR
          compliance is: 6 Degrees Health
          <br />
          <Link
            href="mailto:appsupport@6degreeshealth.com"
            className={classes.link}
            aria-label="contact MediVI technical support"
          >
            appsupport@6degreeshealth.com
          </Link>
        </Typography>

        <span className={classes.number}>+1 888-208-3815</span>

        <Typography className={classes.text}>
          3439 NE Sandy Blvd., Suite 384, Portland, OR 97232
        </Typography>
        <Typography className={classes.text}>
          6 Degrees Health is committed to maintaining the highest standards of compliance and
          ethics. We take our responsibilities seriously and are dedicated to ensuring consistent
          compliance with our standards. If you have any questions or concerns about our compliance
          policies or have any other compliance-related issues please leave a voicemail, with
          includes your name and preferred contact information (email or phone). Our compliance
          reporting policies and procedures prohibit our response to anonymous submissions due to
          the possible lack of authenticity.
          {/* <br /> */}
          {/* <PhoneIcon className={classes.icon} fontSize="inherit" /> */}
          {/* <a
            aria-label="phone number"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.number}
          >
            +1 888-208-3815
          </a> */}
        </Typography>

        <Typography className={classes.title}>GDPR Disclosure:</Typography>
        <Typography className={classes.text}>
          If you answered “yes” to the question Does your website comply with the General Data
          Protection Regulation
        </Typography>
        <Typography className={classes.text}>
          (“GDPR”)? then the Privacy Policy above includes language that is meant to account for
          such compliance. Nevertheless, in order to be fully compliant with GDPR regulations your
          company must fulfill other requirements such as: (i) doing an assessment of data
          processing activities to improve security; (ii) have a data processing agreement with any
          third party vendors; (iii) appoint a data protection officer for the company to monitor
          GDPR compliance; (iv) designate a representative based in the EU under certain
          circumstances; and (v) have a protocol in place to handle a potential data breach. For
          more details on how to make sure your company is fully compliant with GDPR, please visit
          the official website at{' '}
          <Link
            href="https://gdpr.eu/"
            className={classes.link}
            aria-label="contact MediVI technical support"
            target="_blank"
          >
            https://gdpr.eu
          </Link>
          . FormSwift and its subsidiaries are in no way responsible for determining whether or not
          your company is in fact compliant with GDPR and takes no responsibility for the use you
          make of this Privacy Policy or for any potential liability your company may face in
          relation to any GDPR compliance issues.
        </Typography>

        <Typography className={classes.title}>COPPA Compliance Disclosure:</Typography>
        <Typography className={classes.text}>
          This Privacy Policy presumes that your website is not directed at children under the age
          of 13 and does not knowingly collect personal identifiable information from them or allow
          others to do the same through your site. If this is not true for your website or online
          service and you do collect such information (or allow others to do so), please be aware
          that you must be compliant with all COPPA regulations and guidelines in order to avoid
          violations which could lead to law enforcement actions, including civil penalties.
        </Typography>
        <Typography className={classes.text}>
          In order to be fully compliant with COPPA your website or online service must fulfill
          other requirements such as: (i) posting a privacy policy which describes not only your
          practices, but also the practices of any others collecting personal information on your
          site or service — for example, plug-ins or ad networks; (ii) include a prominent link to
          your privacy policy anywhere you collect personal information from children; (iii) include
          a description of parental rights (e.g. that you won’t require a child to disclose more
          information than is reasonably necessary, that they can review their child’s personal
          information, direct you to delete it, and refuse to allow any further collection or use of
          the child’s information, and the procedures to exercise their rights); (iv) give parents
          “direct notice” of your information practices before collecting information from their
          children; and (v) obtain the parents’ “verifiable consent” before collecting, using or
          disclosing personal information from a child. For more information on the definition of
          these terms and how to make sure your website or online service is fully compliant with
          COPPA please visit
          <br />
          <Link
            href="https://www.ftc.gov/tips-advice/businesscenter/guidance/childrens-online-privacy-protection-rule-six-step-compliance"
            className={classes.link}
            aria-label="contact MediVI technical support"
            target="_blank"
          >
            https://www.ftc.gov/tips-advice/businesscenter/guidance/childrens-online-privacy-protection-rule-six-step-compliance
          </Link>
          .
        </Typography>
        <Typography className={classes.text}>
          FormSwift and its subsidiaries are in no way responsible for determining whether or not
          your company is in fact compliant with COPPA and takes no responsibility for the use you
          make of this Privacy Policy or for any potential liability your company may face in
          relation to any COPPA compliance issues.
        </Typography>
      </div>
    </AppLayout>
  );
}

export default Privacy;
