import React, { useState } from 'react';
import axios from 'axios';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar } from '@material-ui/core';

import Illustrator from '../shared/Illustrator';
import InputBox from '../shared/InputBox';
import EmailSuccessModal from './EmailSuccessModal';
import { PrimaryButton } from '../shared/button';
import BottomCredits from '../shared/BottomCredits';

const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  illustrator: {
    width: '50%',
  },
  loginBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
    alignItems: 'center',
  },
  signinBox: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    flex: 1,
  },
  signinText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    letterSpacing: '0em',
    marginBottom: '1rem',
    textAlign: 'left',
    color: '#37383D',
  },
  inputBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '1rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.75rem',
      boxShadow: 'rgba(33, 35, 38, 0.2) 0px 10px 10px -10px',
    },
  },
  signinBtn: {
    width: '100%',
    backgroundColor: '#F07037',
    color: '#EFECE7',
    height: '3rem',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    borderRadius: '0.75rem',
    boxShadow: 'none',
    marginTop: '1.5rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F07037',
      color: '#EFECE7',
    },
  },
}));

const RequestAccess = () => {
  const classes = styles();
  const [email, setEmail] = useState('');
  const [wasEmailSent, setWasEmailSent] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = e => {
    setEmail(e.currentTarget.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(e.currentTarget.value));
  };

  const requestAccess = () => {
    if (isValid && email !== '') {
      axios
        .post(`/api/send-email`, { email })
        .then(() => {
          setWasEmailSent(true);
        })
        .catch(() => {
          console.error('POST unsuccessful'); // eslint-disable-line no-console
        });
    } else {
      setSnackbarOpen(true); // Open the snackbar if email is invalid
    }
  };

  const closeEmailSuccessModal = () => {
    setEmail('');
    setWasEmailSent(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close snackbar when user dismisses it
  };

  ValidatorForm.addValidationRule('noWhitespacePadding', value => value.trim() === value);

  return (
    <div className={classes.container}>
      <div className={classes.illustrator}>
        <Illustrator />
      </div>
      <div className={classes.loginBox}>
        <div className={classes.signinBox}>
          <Typography className={classes.signinText}>Request Access</Typography>
          <ValidatorForm onSubmit={requestAccess} instantValidate={false}>
            <InputBox
              name="email"
              label="Email"
              id="email"
              onChangeEvent={handleChange}
              value={email}
              className={classes.inputBox}
              validators={['required', 'noWhitespacePadding', 'isEmail']}
              errorMessages={[
                'This field is required',
                'No leading or trailing whitespace',
                'Must be valid email',
              ]}
            />

            <PrimaryButton onClick={requestAccess} className={classes.signinBtn} type="submit">
              SUBMIT
            </PrimaryButton>
          </ValidatorForm>
        </div>

        <BottomCredits />
      </div>

      <EmailSuccessModal
        email={email}
        requestEmailSent={wasEmailSent}
        closeEmailSuccessModal={closeEmailSuccessModal}
      />

      {/* Snackbar for invalid email */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message="Enter a valid email"
        autoHideDuration={3000}
      />
    </div>
  );
};

export default RequestAccess;
