import React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import questions from '@apps/shared/src/providerSearch/faq/faqQuestions';
import FAQQuestion from '@apps/shared/src/providerSearch/faq/FAQQuestion';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'fit-content',
    padding: '1rem 1rem 2rem 1rem',
  },
  privacy: {
    lineHeight: '1.3',
    padding: '1rem 0',
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: 'underline',
  },
}));

export default function FAQ(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {questions.map(faq => (
        <FAQQuestion key={faq.question} faq={faq} />
      ))}
      <Typography className={classes.privacy}>
        Learn more about the{' '}
        <Link
          href="https://medivi.6degreeshealth.com/privacy"
          className={classes.link}
          aria-label="MediVI Privacy Policy"
        >
          MediVI Privacy Policy
        </Link>
      </Typography>
    </div>
  );
}
