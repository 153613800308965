import UserCard from '@apps/shared/src/auth/UserCard';
import { clearRedirect, createProfile, login } from '@apps/shared/src/auth/userActions';
import { colors } from '@apps/shared/src/style';
import { checkCompromisedPassword } from '@apps/shared/src/auth/passwordUtils';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TermsOfService from './TermsOfService';

const styles = {
  inputWrapper: {
    width: '100%',
    marginTop: '10px',
  },
};

class CreateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: 'temporary@placeholder.com',
      password: '',
      rePassword: '',
      checkToggled: false,
      isValid: false,
      nameDirty: false,
      passDirty: false,
      rePassDirty: false,
      errors: {
        fullName: '',
        password: '',
        rePassword: '',
        agreeCheck: '',
      },
      agreeCheck: false,
      showTOS: false,
      hasMinChar: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isCompromised: false, // Add state for compromised password
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearRedirect()); // Clear any redirect before leaving
  }

  checkPassword = async password => {
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinChar = false;
    let isCompromised = false; // Add state for compromised password

    if (password.length >= 12) {
      hasMinChar = true;
    }
    if (/[A-Z]/.test(password)) {
      hasUppercase = true;
    }
    if (/[a-z]/.test(password)) {
      hasLowercase = true;
    }
    if (/[0-9]/.test(password)) {
      hasNumber = true;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      hasSpecialChar = true;
    }

    // Check if the password is compromised
    try {
      isCompromised = await checkCompromisedPassword(password);
    } catch (error) {
      isCompromised = false;
    }

    this.setState({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      hasMinChar,
      isCompromised,
    });
  };

  handleChange = event => {
    const {
      target: { value },
    } = event;
    this.checkPassword(value);
    this.setState({ password: value });
  };

  setValue = (name, validate) => e => {
    if (validate) this.setState({ [name]: e.target.value }, this.validate);
    else this.setState({ [name]: e.target.value });
  };

  toggleValue = name => () => {
    if (name === 'agreeCheck') this.setState({ checkToggled: true });
    this.setState(
      prevState => ({
        [name]: !prevState[name],
      }),
      this.validate
    );
  };

  setAgree = value => () => {
    this.setState(
      {
        agreeCheck: value,
        showTOS: false,
        checkToggled: true,
      },
      this.validate
    );
  };

  validate = () => {
    const errors = {
      fullName: '',
      password: '',
      rePassword: '',
      agreeCheck: '',
    };
    const {
      fullName,
      password,
      rePassword,
      agreeCheck,
      checkToggled,
      passDirty,
      rePassDirty,
      nameDirty,
    } = this.state;

    if (fullName.trim() !== fullName) errors.fullName = 'No leading or trailing whitespace';
    else if (nameDirty && fullName.trim().length < 3) errors.fullName = 'Minimum 3 characters';

    if (!agreeCheck && checkToggled) errors.agreeCheck = 'Must agree to terms of service';

    if (password.trim() !== password) errors.password = 'No leading or trailing whitespace';
    if (passDirty) {
      if (password.length < 12) errors.password = 'Minimum 12 characters';
      else if (password.length > 128) errors.password = 'Maximum 128 characters';
    }

    if (rePassDirty && rePassword?.length > 0 && password !== rePassword)
      errors.rePassword = 'Passwords must match';

    this.setState({ errors });

    const valCheck =
      this.state.password.trim().length > 0 &&
      this.state.rePassword.trim().length > 0 &&
      this.state.fullName.trim().length > 0 &&
      this.state.agreeCheck;
    const errCount = Object.values(errors).filter(x => x !== '').length > 0;
    const defaultEmailCheck = this.props.registerEmail !== undefined;
    return this.setState({ isValid: !errCount && valCheck && defaultEmailCheck });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.isValid) {
      this.createProfile();
    }
  };

  login = () => {
    this.props.dispatch(
      login({
        email: this.props.registerEmail,
        password: this.state.password,
        application: 'claims',
        destinationURL: '/repricing',
      })
    );
  };

  createProfile = () => {
    this.props.dispatch(createProfile(this.state.fullName, this.state.password, this.props.app));
  };

  render() {
    const { classes } = this.props;

    const formOrTOS = this.state.showTOS ? (
      <TermsOfService onClose={this.toggleValue('showTOS')} onAgree={this.setAgree} />
    ) : (
      <form onSubmit={this.handleSubmit} style={{ width: '100%', margin: 'auto' }}>
        <div style={{ maxWidth: '250px', margin: 'auto', textAlign: 'left' }}>
          <FormControl className={classes.inputWrapper} error={!!this.state.errors.fullName}>
            <InputLabel htmlFor="fullName">Full Name</InputLabel>
            <Input
              id="fullName"
              value={this.state.fullName}
              onChange={this.setValue('fullName')}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ nameDirty: true })}
              label="Full Name"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="fullNameErrorText"
            />
            <FormHelperText id="fullNameErrorText">{this.state.errors.fullName}</FormHelperText>
          </FormControl>

          <FormControl
            className={classes.inputWrapper}
            error={!!this.state.errors.email}
            style={{ paddingBottom: '20px' }}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" value={this.props.registerEmail} label="Email" readOnly fullWidth />
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              value={this.state.password}
              onChange={this.handleChange}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ passDirty: true })}
              type="password"
              fullWidth
              aria-describedby="passwordErrorText"
            />
            <FormHelperText id="passwordErrorText">{this.state.errors.password}</FormHelperText>
            {this.state.password && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {[
                  { label: '12 Characters', state: this.state.hasMinChar },
                  { label: 'Uppercase', state: this.state.hasUppercase },
                  { label: 'Lowercase', state: this.state.hasLowercase },
                  { label: 'Number', state: this.state.hasNumber },
                  { label: 'Special Character', state: this.state.hasSpecialChar },
                ].map(({ label, state }) => (
                  <span key={label}>
                    <span style={{ color: state ? 'green' : 'red' }}>
                      {state ? '\u2714' : '\u2718'} <span style={{ color: 'black' }}>{label}</span>
                    </span>
                  </span>
                ))}
                {this.state.isCompromised && (
                  <span style={{ color: 'red' }}>
                    {'\u2718'}{' '}
                    <span style={{ color: 'black' }}>
                      Password is compromised. Please choose another.
                    </span>
                  </span>
                )}
              </div>
            )}
          </FormControl>

          <FormControl className={classes.inputWrapper} error={!!this.state.errors.rePassword}>
            <InputLabel htmlFor="rePassword">Re-enter Password</InputLabel>
            <Input
              id="rePassword"
              value={this.state.rePassword}
              onChange={this.setValue('rePassword', true)}
              onBlur={() => this.validate()}
              onFocus={() => this.setState({ rePassDirty: true })}
              type="password"
              fullWidth
              style={{ maxWidth: '250px' }}
              aria-describedby="rePasswordErrorText"
            />
            <FormHelperText id="rePasswordErrorText">{this.state.errors.rePassword}</FormHelperText>
          </FormControl>

          <div style={{ marginTop: '12px' }}>
            <FormControlLabel
              style={{ marginRight: '4px' }}
              control={
                <Checkbox
                  style={{ color: colors.greyDark }}
                  value="agreeCheck"
                  checked={this.state.agreeCheck}
                  onChange={this.toggleValue('agreeCheck')}
                />
              }
              label="Agree to"
            />
            <Button
              style={{ color: colors.blue, padding: '0' }}
              onClick={this.toggleValue('showTOS')}
              aria-describedby="agreeCheckErrorText"
            >
              Terms of Service
            </Button>
            <FormHelperText id="agreeCheckErrorText" style={{ marginTop: '0' }}>
              {this.state.errors.agreeCheck}
            </FormHelperText>
          </div>
        </div>
        <Button
          style={{ margin: '40px auto 0', maxWidth: '350px' }}
          variant="contained"
          type="submit"
          color="primary"
          disabled={!this.state.isValid}
          fullWidth
        >
          CREATE ACCOUNT
        </Button>
      </form>
    );

    return this.props.redirectTo !== '' ? (
      <Redirect to={this.props.redirectTo} />
    ) : (
      <UserCard showLogo={!this.state.showTOS}>{formOrTOS}</UserCard>
    );
  }
}

CreateProfile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  registerEmail: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
};
CreateProfile.defaultProps = {
  redirectTo: '',
};

function mapStateToProps(state) {
  return {
    redirectTo: state.user.redirectTo,
    registerEmail: state.user.currentUser?.email,
    app: state.admin.app,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CreateProfile));
