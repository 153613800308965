type SplitProviderReturn = { exclusionType: string; provider: string };

export function splitProviderRow(providerRow: string): SplitProviderReturn {
  const splitRow: SplitProviderReturn = {
    exclusionType: '',
    provider: '',
  };
  const firstDashIndex = providerRow.indexOf('-');
  if (firstDashIndex >= 0) {
    splitRow.exclusionType = providerRow.substring(0, firstDashIndex).trim();
    splitRow.provider = providerRow.substring(firstDashIndex + 1).trim();
  }
  return splitRow;
}

export function groupExcludedProviders(excludedProviders: string[]): Record<string, string[]> {
  const providerGroups: Record<string, string[]> = {};
  if (Array.isArray(excludedProviders))
    excludedProviders.forEach(providerRow => {
      const { exclusionType, provider } = splitProviderRow(providerRow);
      if (providerGroups[exclusionType]) {
        providerGroups[exclusionType].push(provider);
      } else if (exclusionType !== '') {
        providerGroups[exclusionType] = [provider];
      }
    });
  return providerGroups;
}
