import { AxiosPromise } from 'axios';
import { Notification } from './notification';

enum M {
  GET_NOTIFICATION = 'GET_NOTIFICATION',
  GET_NOTIFICATION_PENDING = 'GET_NOTIFICATION_PENDING',
  GET_NOTIFICATION_REJECTED = 'GET_NOTIFICATION_REJECTED',
  GET_NOTIFICATION_FULFILLED = 'GET_NOTIFICATION_FULFILLED',
  GET_NOTIFICATION_RTK_PENDING = 'notification/getNotification/pending',
  GET_NOTIFICATION_RTK_REJECTED = 'notification/getNotification/rejected',
  GET_NOTIFICATION_RTK_FULFILLED = 'notification/getNotification/fulfilled',
  UPDATE_NEW_NOTIFICATION = 'UPDATE_NEW_NOTIFICATION',
  SET_NOTIFICATION_FILTER = 'SET_NOTIFICATION_FILTER',
  MARK_ALL_AS_READ = 'MARK_ALL_AS_READ',
  MARK_AS_READ = 'MARK_AS_READ',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
}

export default M;

export type GetNotification = {
  type: typeof M.GET_NOTIFICATION;
  payload: AxiosPromise;
};

export type GetNotificationPending = {
  type: typeof M.GET_NOTIFICATION_PENDING | typeof M.GET_NOTIFICATION_RTK_PENDING;
};

export type GetNotificationRejected = {
  type: typeof M.GET_NOTIFICATION_REJECTED | typeof M.GET_NOTIFICATION_RTK_REJECTED;
};

export type GetNotificationFulfilled = {
  type: typeof M.GET_NOTIFICATION_FULFILLED | typeof M.GET_NOTIFICATION_RTK_FULFILLED;
  payload: {
    data: Notification[];
  };
};

export type UpdateNewNotification = {
  type: typeof M.UPDATE_NEW_NOTIFICATION;
  payload: any;
};

export type SetNotificationFilter = {
  type: typeof M.SET_NOTIFICATION_FILTER;
  payload: string;
};

export type MarkAllAsRead = {
  type: typeof M.MARK_ALL_AS_READ;
};

export type MarkAsRead = {
  type: typeof M.MARK_AS_READ;
  payload: string;
};

export type DeleteNotification = {
  type: typeof M.DELETE_NOTIFICATION;
  payload: string;
};

export type NotificationActionTypes =
  | GetNotification
  | GetNotificationPending
  | GetNotificationRejected
  | GetNotificationFulfilled
  | UpdateNewNotification
  | SetNotificationFilter
  | MarkAllAsRead
  | MarkAsRead
  | DeleteNotification;
