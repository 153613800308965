import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { setGenderFilter } from '@apps/shared/src/providerSearch/actions';

import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  menuItem: theme.selectMenuItem,
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  providergendercode: providerSearch.providergendercode,
  isLoading: providerSearch.isLoadingProviderList,
});

type StateProps = {
  providergendercode: string;
  isLoading: boolean;
};

const mapDispatchToProps = {
  setGenderFilter,
};

type Props = StateProps & typeof mapDispatchToProps;

const getGenderValue = (gender: string) => {
  if (gender === 'M') {
    return 'Male';
  }
  if (gender === 'F') {
    return 'Female';
  }
  return 'All';
};

export function GenderFilter({
  isLoading,
  providergendercode,
  setGenderFilter,
}: Props): JSX.Element {
  const classes = useStyles();

  const [selectedOption, setSelectedOptions] = useState(
    providergendercode && providergendercode.length <= 1
      ? getGenderValue(providergendercode)
      : 'All'
  );
  const genderOptions = ['All', 'Male', 'Female'];

  const renderMenuItemValue: SelectProps['renderValue'] = selected => selected as string;

  useEffect(() => {
    setSelectedOptions(
      providergendercode && providergendercode.length <= 1
        ? getGenderValue(providergendercode)
        : 'All'
    );
  }, [providergendercode]);

  const handleGenderChange: SelectProps['onChange'] = e => {
    const gender = e.target.value as string;
    setSelectedOptions(gender);
    setGenderFilter(gender);
  };

  return (
    <div>
      <Typography className={classes.header}>Gender</Typography>
      <Select
        labelId="Gender"
        id="gender-search"
        value={selectedOption}
        onChange={handleGenderChange}
        disableUnderline
        disabled={isLoading}
        className={classes.Select}
        renderValue={renderMenuItemValue}
      >
        {genderOptions.map(option => (
          <MenuItem key={option} value={option} className={classes.menuItem}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GenderFilter);
