import React, { ChangeEventHandler } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, TextField, Typography, makeStyles, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { addPlan, setPlanInfo, resetAddPlan } from '@apps/shared/src/addPlan/actions';
import { PlanInfo } from '@apps/shared/src/addPlan/types/addPlanTypes';
import TextInput from '../shared/textInput';
import { RootState } from '../store';
import SuccessDialog from '../shared/successDialog';
import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles(theme => ({
  container: {
    width: '95%',
    height: '75vh',
    padding: '1rem 1.5rem',
    overflow: 'auto',
    position: 'relative',
  },

  arrowBack: {
    padding: '0rem 0rem 0.5rem',
    width: '2rem',
    height: '2rem',
    color: '#36383D',
    cursor: 'pointer',
  },

  dependentInformationTitle: {
    color: '#5D5C5C',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },

  diSubtitle: {
    color: '#797C80',
    fontWeight: 400,
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
  },
  fieldsDiv: {
    display: 'flex',
    gap: '2.75rem',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: '100%',
    marginTop: '1rem',
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '1.125rem',
    },
    '& .MuiInputBase-input': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
  btnDiv: {
    position: 'absolute',
    bottom: 0,
    right: '1.5rem',
    display: 'flex',
    gap: '1.125rem',
  },
  cancelBtn: {
    width: '11.5rem',
    textWrap: 'no-wrap',
    backgroundColor: '#fff',
    color: '#797C80',
    padding: '.6rem 1rem',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    border: '1px solid #797C80',
    borderRadius: '.25rem',
    textAlign: 'center',
  },
  addBtn: {
    width: '11.5rem',
    textWrap: 'no-wrap',
    backgroundColor: '#EF7125',
    color: '#FFFFFF',
    padding: '.6rem 1rem',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    borderRadius: '.25rem',
    textAlign: 'center',
    '&.MuiButton-root:hover': {
      backgroundColor: 'chocolate',
    },
  },
}));

type AddPlanState = RootState['addPlan'];
type StateProps = {
  planInfo: AddPlanState['planInfo'];
  isAddingPlan: boolean;
  selectedZipCode: string;
  addPlanSuccess: boolean;
};

const mapStateToProps = ({ addPlan, providerSearch }: RootState): StateProps => ({
  planInfo: addPlan.planInfo,
  isAddingPlan: addPlan.isAddingPlan,
  selectedZipCode: providerSearch.selectedZipCode,
  addPlanSuccess: addPlan.addPlanSuccess,
});

type DispatchProps = {
  setPlanInfo: typeof setPlanInfo;
  addPlan: typeof addPlan;
  resetAddPlan: typeof resetAddPlan;
};

const mapDispatchToProps = {
  setPlanInfo,
  addPlan,
  resetAddPlan,
};

type Props = StateProps & DispatchProps;

const AddPlan = ({
  planInfo,
  setPlanInfo,
  addPlan,
  selectedZipCode,
  isAddingPlan,
  resetAddPlan,
  addPlanSuccess,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const scroll = (e: React.MouseEvent<HTMLElement>): void => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleChange =
    (key: keyof PlanInfo): ChangeEventHandler<HTMLInputElement> =>
    (e): void => {
      setPlanInfo(key, e.currentTarget.value.toUpperCase());
    };

  const handleDateOfBirthChange = (date: string): void => {
    setPlanInfo('dateOfBirth', date);
  };

  const handleCancel = () => {
    resetAddPlan();
    history.goBack();
  };

  const handleSubmit = (): void => {
    addPlan(planInfo, selectedZipCode, '/my-account');
  };

  const handleAddPlanSuccess = (): void => {
    resetAddPlan();
    history.push('/my-account');
  };

  const verifyZipCode = (value: string) => {
    const isValid = value.length === 5;
    return isValid;
  };

  const disableSubmit: boolean =
    !isAddingPlan &&
    (!planInfo ||
      !planInfo.firstName ||
      !planInfo.lastName ||
      !planInfo.groupID ||
      !planInfo.memberID ||
      !planInfo.dateOfBirth ||
      !verifyZipCode(planInfo.zip) ||
      !planInfo.zip);

  return (
    <AppLayout>
      <div className={classes.container}>
        <KeyboardBackspaceIcon className={classes.arrowBack} onClick={handleCancel} />
        <Typography className={classes.dependentInformationTitle}>Add Plan</Typography>
        <p className={classes.diSubtitle}>Fill in the fields to add plan.</p>
        <Grid container direction="column" spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextInput
                name="memberID"
                label="Member ID"
                onClick={scroll}
                onChangeEvent={handleChange('memberID')}
                value={planInfo.memberID}
                validators={['required']}
                errorMessages={['This field is required.']}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                name="groupID"
                label="Group ID"
                onClick={scroll}
                onChangeEvent={handleChange('groupID')}
                value={planInfo.groupID}
                validators={['required']}
                errorMessages={['This field is required.']}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                name="firstName"
                label="First Name"
                onClick={scroll}
                onChangeEvent={handleChange('firstName')}
                value={planInfo.firstName}
                validators={['required']}
                errorMessages={['This field is required.']}
                inputProps={{ maxLength: 35 }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                name="lastName"
                label="Last Name"
                onClick={scroll}
                onChangeEvent={handleChange('lastName')}
                value={planInfo.lastName}
                validators={['required']}
                errorMessages={['This field is required.']}
                inputProps={{ maxLength: 60 }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                label="Date of Birth"
                type="date"
                name="dateOfBirth"
                onClick={scroll}
                value={planInfo.dateOfBirth}
                onChange={handleDateOfBirthChange}
                validators={['required']}
                errorMessages={['This field is required.']}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                name="zip"
                label="Zip Code"
                type="text"
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 5,
                }}
                value={planInfo.zip}
                onChangeEvent={handleChange('zip')}
                validators={['required', 'isNumber', 'validateZipCode']}
                errorMessages={[
                  'This field is required.',
                  'Only numbers are allowed.',
                  'Must be 5 digits.',
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.btnDiv}>
          <Button className={classes.cancelBtn} variant="contained" onClick={handleCancel}>
            CANCEL
          </Button>
          <Button
            className={classes.addBtn}
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            ADD PLAN
          </Button>
        </div>
      </div>
      {addPlanSuccess && (
        <SuccessDialog
          open={addPlanSuccess}
          icon="images/success.png"
          title="Plan Added!"
          message="Plan added successfully. Check Accounts section for the details."
          buttonText="Continue"
          handleCloseDialog={handleAddPlanSuccess}
          handleSuccess={handleAddPlanSuccess}
        />
      )}
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlan);
