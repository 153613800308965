import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// import LeftFeature from './LeftFeature';
// import RightFeature from './RightFeature';
import FeatureContent from './FeatureContent';
import { features } from './content';

const styles = {
  Heading: {
    margin: '4rem 0 0 10rem',
    fontWeight: 600,
  },
  allFeatures: {
    margin: '2vw 10vw 0 10vw',
    display: 'flex',
    gap: '1rem',
  },
};

function Features(props) {
  const { classes } = props;
  const allFeatures = features.map((f, index) => {
    // alternate left and right views
    // if (index % 2 === 0) {
    //   return <LeftFeature key={f.title} content={f} />;
    // }
    // return <RightFeature key={f.title} content={f} />;
    return <FeatureContent key={f.title} content={f} />;
  });

  return (
    <>
      <Typography variant="h3" className={classes.Heading}>
        MEDIVI FEATURES
      </Typography>
      <div className={classes.allFeatures} id="features">
        {allFeatures}
      </div>
    </>
  );
}

Features.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Features);
