import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import ZoomControls from '../shared/ZoomControls';
import PanControl from '../shared/PanControl';
import ChartCanvas from '../shared/ChartCanvas';
import ExcludedProviders from './ExcludedProviders';
import ChartSummary from './ChartSummary';
import {
  makeTooltipTitleFormatter,
  makeCallbackLabelFormatter,
  makeCallbackFooterFormatter,
} from '../shared/formats';
import {
  providerDataType,
  chartOptionsType,
  chartMetaType,
  chartDataType,
  hiddenDataType,
} from '../shared/types/propTypes';

const styles = {
  root: {
    minWidth: 0, // Allows canvas to resize responsively
    padding: '0 3em',
    left: 'auto',
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: 'calc(100vh - 70px - 50px - 6rem)',
  },
  canvasGroup: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: `calc(100% - 6em)`, // 6em is the height of underchart and overchart
    justifyContent: 'center',
  },
  underChart: {
    margin: '0',
    textAlign: 'left',
    height: '3em',
  },
  overchart: {
    display: 'flex',
    margin: '0 0 0 2em',
    top: '0',
    width: '100%',
    flexWrap: 'wrap',
    height: '3em',
  },
  right: {
    textAlign: 'right',
    display: 'flex',
    height: '2.5em',
    alignItems: 'center',
    marginLeft: 'auto',
  },
};

export function ChartPane({
  providerData,
  selectedProcedure,
  useRatios,
  classes,
  data,
  options,
  spotsRight,
  spotsLeft,
  canShowLabels,
  handlePan,
  updateShowLabels,
  reportChartPosition,
  handleChartClick,
  handleResetZoom,
  handleZoomChart,
  handleToggleHiddenData,
  hiddenData,
  viewStart,
  viewEnd,
  atMax,
  isChartLoading,
  meta,
}) {
  return (
    <section className={classes.root}>
      <div className={classes.overchart}>
        <div className={classes.right}>
          <ZoomControls
            showControl={data.labels.length > 0}
            resetZoom={handleResetZoom}
            zoomChart={handleZoomChart}
            providerCount={providerData.providers.length}
            viewCount={viewEnd - viewStart + 1}
            atMax={atMax}
          />
        </div>
      </div>
      <div className={classes.canvasGroup}>
        <PanControl direction="left" handlePan={() => handlePan(-1, 0.2)} spots={spotsLeft} />
        <ChartCanvas
          type="bar"
          data={data}
          options={options}
          onClick={handleChartClick}
          reportChartPosition={reportChartPosition}
          updateShowLabels={updateShowLabels}
          formatTooltipTitle={makeTooltipTitleFormatter(meta)}
          formatCallbackLabel={makeCallbackLabelFormatter(useRatios)}
          formatCallbackFooter={makeCallbackFooterFormatter(meta)}
          useRatios={useRatios}
          selectedProcedure={selectedProcedure}
          stateCanShowLabels={canShowLabels}
          hiddenData={hiddenData}
          handleToggleHiddenData={handleToggleHiddenData}
          isChartLoading={isChartLoading}
          viewStart={viewStart}
          viewEnd={viewEnd}
        />
        <PanControl direction="right" handlePan={() => handlePan(1, 0.2)} spots={spotsRight} />
      </div>
      <div className={classes.underChart}>
        <ChartSummary providerData={providerData} selectedProcedure={selectedProcedure} />
        <ExcludedProviders excludedProviders={providerData.excludedProviders} />
      </div>
    </section>
  );
}

ChartPane.propTypes = {
  atMax: PropTypes.bool,
  canShowLabels: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: chartDataType.isRequired,
  handleChartClick: PropTypes.func.isRequired,
  handlePan: PropTypes.func.isRequired,
  handleResetZoom: PropTypes.func.isRequired,
  handleToggleHiddenData: PropTypes.func.isRequired,
  handleZoomChart: PropTypes.func.isRequired,
  hiddenData: hiddenDataType,
  isChartLoading: PropTypes.bool.isRequired,
  meta: chartMetaType.isRequired,
  options: chartOptionsType.isRequired,
  providerData: providerDataType,
  reportChartPosition: PropTypes.func.isRequired,
  selectedProcedure: PropTypes.string.isRequired,
  spotsLeft: PropTypes.number.isRequired,
  spotsRight: PropTypes.number.isRequired,
  updateShowLabels: PropTypes.func.isRequired,
  useRatios: PropTypes.bool.isRequired,
  viewEnd: PropTypes.number,
  viewStart: PropTypes.number,
};
ChartPane.defaultProps = {
  viewStart: 0,
  viewEnd: 0,
  providerData: {},
  atMax: false,
  canShowLabels: true,
  hiddenData: [],
};

export default withStyles(styles)(ChartPane);
