import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import { IncompleteTheme } from '../shared/types/theme';
import { Video } from '../shared/types/mediviTypes';

const videoWidth = 450;
const aspectRatio = 16 / 9;
const videoHeight = videoWidth / aspectRatio;

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  videoWrapper: {
    width: videoWidth,
    margin: '1rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  videoTitle: theme.documentation.trainingVideo.title,
  videoDescription: theme.documentation.trainingVideo.description,
  iframe: theme.documentation.trainingVideo.iframe,
}));

export default function VideoCard({ src, title, description }: Video): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.videoWrapper} key={src}>
      <iframe
        className={classes.iframe}
        src={src}
        width={videoWidth}
        height={videoHeight}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title={title}
      />
      <Typography className={classes.videoTitle} variant="h5">
        {title}
      </Typography>
      <Typography className={classes.videoDescription} variant="body1">
        {description}
      </Typography>
    </div>
  );
}
