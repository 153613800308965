import { PlanState } from '../../planSelect/types/plan';

export type RootState = {
  providerSearch: ProviderSearchState;
  planSelect: PlanState;
};

export type Provider = {
  name: string;
  npi: string;
  address1?: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
  label1: Accessibility;
  label2?: Accessibility;
  partnerPlans?: string[];
  primaryTaxonomy: string;
  secondaryTaxonomies?: string[];
  distance: number;
  providergendercode: string;
  entitytype: string;
};

export type TaxonomyDetailsMap = Record<string, TaxonomyDetails>;

export type TaxonomyDetails = {
  title: string;
  category: string;
  cmsGrouping?: string;
  cmsClassification?: string;
  cmsSpecialization?: string;
};

export type ProviderListFilterValues = {
  fullList: Provider[];
  radius: SearchRadius;
  searchInput: string;
  selectedAccessibilities: Accessibility[];
  taxonomyDetailsMap: TaxonomyDetailsMap;
  selectedProviderType: string;
  selectedPlanID: string;
  providerTypesMap: ProviderTypesMap;
  providergendercode: string;
  entitytype: string;
};

export type ProviderTypesMap = Record<string, string[]>;

export type ProviderTypeCounts = {
  categories: Record<string, number>;
  titles: Record<string, number>;
};

export type ProviderTypeScrollPosition = {
  category: string;
  isTitle: boolean;
};

export type ProviderData = {
  providers: Provider[] | null;
  taxonomyDetails: TaxonomyDetailsMap;
};

export type ProviderSearchState = {
  selectedZipCode: string;
  zipCodeDetails: string;
  isZipUnrecognized: boolean;
  radius: SearchRadius;
  searchInput: string;
  isLoadingProviderList: boolean;
  fullList: Provider[];
  filteredList: Provider[];
  taxonomyDetailsMap: TaxonomyDetailsMap;
  providerTypesMap: ProviderTypesMap;
  selectedProviderType: string;
  sortCriteria: SortCriteria;
  sortDirection: SortDirection;
  selectedAccessibilities: AccessibilityFilter[];
  selectedPlanID: string;
  isFilterTrayOpen: boolean;
  scrollPosition: ProviderTypeScrollPosition;
  displayPlanMessage: boolean;
  planMessage: string;
  providergendercode: string;
  entitytype: string;
};

export enum Accessibility {
  Contracted = 'Contracted',
  Recommended = 'Recommended',
  Unknown = 'Unknown',
  NotRecommended = 'Not Recommended',
  Caution = 'Caution',
}

export enum AccessibilityFilter {
  Contracted = 'Contracted',
  Unknown = 'Unknown',
  Caution = 'Caution',
  NotRecommended = 'Not Recommended',
}

export const ProviderTitle = {
  Green: 'Contracted Provider',
  Grey: 'No significant Access Issues known.',
  Yellow: 'Provider may Balance Bill or Deny Access until SCA in place. Contact 6DH.',

  Red: 'Provider has a history of denying access',
};

export type SearchRadius = string;
export type SortCriteria = 'accessibility' | 'name' | 'distance';
export type SortDirection = 'asc' | 'desc';

export type LocalStorageProviders = {
  zip: string;
  dateLastFetched: string;
  list: Provider[];
};

export type ZipCodeValidation = {
  county?: string;
  state?: string;
  message?: string;
};

export type FilterOption = 'distance' | 'providerType' | 'accessibility' | 'sort' | 'plan';
export type FilterDetails = {
  buttonText: string;
  component: JSX.Element;
  visible?: boolean;
  truncate?: boolean;
};
