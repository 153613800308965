import React, { MouseEventHandler } from 'react';

import { Theme } from '@material-ui/core';
import useTheme from '@material-ui/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { colors } from '@apps/shared/src/style';

import { Option } from './ExclusiveButtons';

export type { Option } from './ExclusiveButtons';

const useStyles = makeStyles({
  tabs: {
    // backgroundColor: colors.grey12,
    borderRadius: '.35rem',
    width: 'fit-content',
  },
  tabsButton: {
    margin: '0.25rem 0',
    padding: '.35rem 1rem',
    fontFamily: 'Avenir, Helvetica, sans-serif',

    '&.MuiButton-containedPrimary': {
      backgroundColor: '#366BED !important',
    },
    '&.MuiButton-disableElevation': {
      backgroundColor: '#B6B6B6',
      color: 'white',
    },
  },
  divider: {
    display: 'inline-block',
    height: '1.2rem',
    width: '1px',
    marginLeft: '-1px', // prevents buttons from shifting when you click around
    backgroundColor: (props: { dividerColor: string }): string => props.dividerColor,
    verticalAlign: 'middle',
  },
});

type Props = {
  selectedValue?: Option['value'];
  options: Option[];
  onChange: (value: Option['value']) => void;
  selectedColor?: ButtonProps['color'];
  unselectedColor?: ButtonProps['color'];
};

export default function Tabs({
  selectedValue,
  options,
  onChange,
  selectedColor = 'primary',
  unselectedColor = 'default',
}: Props): JSX.Element {
  const theme: Theme = useTheme();
  const dividerColor =
    unselectedColor === 'primary' || unselectedColor === 'secondary'
      ? theme?.palette[unselectedColor].main
      : theme?.palette.text.primary;

  const classes = useStyles({ dividerColor });

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    const { value } = event.currentTarget;
    if (selectedValue !== value) onChange(value);
  };

  return (
    <div className={classes.tabs}>
      {options.map((option, index) => {
        const isSelected = selectedValue === option.value;
        const isDividerDisplayed =
          !isSelected && index !== 0 && options[index - 1]?.value !== selectedValue;

        return [
          // isDividerDisplayed && <div className={classes.divider} key={`divider-${option.value}`} />,
          // <Tooltip title={option.title || ''} key={option.value}>
          <Button
            key={option.value}
            className={classes.tabsButton}
            color={isSelected ? selectedColor : unselectedColor}
            variant={isSelected ? 'contained' : undefined}
            value={option.value}
            onClick={onButtonClick}
            disableElevation
          >
            {option.content}
          </Button>,
          // </Tooltip>,
        ];
      })}
    </div>
  );
}
