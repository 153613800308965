export type Question = {
  question: string;
  answer: string;
};

const questions: Question[] = [
  {
    question: `What is the purpose of MediVI's Provider Search?`,
    answer: `Your health plan has eliminated Preferred Provider Networks (PPO) for medical providers and physicians,
    allowing you to seek care without the constraints of a network. The MediVI app will help you to make
    informed decisions when choosing a provider to meet your healthcare needs.`,
  },
  {
    question: `How do I find a provider in my area?`,
    answer: `Begin by entering a valid 5-digit zip code to serve as the starting location for your provider search.
    You may then narrow the list of providers by searching via text or utilizing any of the filters located above the list.`,
  },
  {
    question: `What does the green "Contracted" badge represent?`,
    answer: `The contracted badge means that those providers have a contract in place to provide service to our members.
    These providers will always be badged as recommended because of the low likelihood of having an access issue or receiving
    a balance bill.`,
  },
  {
    question: `What does the green "Recommended" badge represent?`,
    answer: `Providers with a positive transaction history with 6 Degrees Health or those that have placed a direct
    contract with us will have a green badge. We encourage you to utilize these providers as we feel confident there is
    a low potential of an access issue or additional billing beyond your responsibility under the plan (often referred
    to as a "balance bill").`,
  },
  {
    question: `What does the yellow "Not Recommended" badge represent?`,
    answer: `If the provider has a yellow badge, 6 Degrees Health’s extensive data has shown that the provider could
    potentially deny access or send a balance bill after the visit has occurred. Providers labeled with a yellow badge
    should be avoided for elective procedures if possible. If for any non-emergency reason you need to go to one of these
    providers, please contact your third-party administrator in advance so that they may coordinate with 6 Degrees
    Health to try to resolve any potential issues. In an emergency, you should never jeopardize anyone's
    health in an attempt to avoid a yellow badge provider.`,
  },
  {
    question: `What does it mean if a provider does not have a colored badge?`,
    answer: `If the provider does not have a colored badge, 6 Degrees Health does not have adequate data to
    identify the provider as green or yellow.`,
  },
  {
    question: `What if my Provider is not in the MediVI Provider Search?`,
    answer: `6 Degrees Health is continuously updating the MediVI Provider Search. If you cannot find your Provider in MediVI,
    this does not mean you cannot seek care with them.`,
  },
  {
    question: `Do the color badges represent the best-quality providers?`,
    answer: `Badges do not indicate the quality of care, only the provider’s willingness to work with your
    healthcare plan structure.`,
  },
  {
    question: `What if the hospital or physician doesn't accept my health plan?`,
    answer: `Please tell your provider that your coverage is through a self-funded employer-sponsored health plan. It is
    an open-access plan that does not restrict access based on networks, and all providers are reimbursed at the same
    levels. The provider should collect any applicable copay and submit a claim through the third-party administrator
    with the information on your ID card. If the provider still has questions, please have them call your third-party
    administrator. The phone number is also on the front of your health insurance ID card. Make sure you present
    your ID card at every visit or service.`,
  },
  {
    question: `What should I do if a provider requests payment up front?`,
    answer: `You should not need to pay anything other than your copay up front. The provider should call your third-party
    administrator if they are requesting any money beyond your copay.`,
  },
  {
    question: `Who should I contact for questions about my health insurance benefits or my medical
    coverage?`,
    answer: `Please contact your third-party administrator. They have a dedicated customer service team
    ready to assist you with any questions regarding your medical coverage or plan options.`,
  },
];

export default questions;
