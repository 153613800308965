import React from 'react';
import { connect } from 'react-redux';

import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import { Notification } from '@apps/shared/src/notification/types/notification';
import { RootState } from '../store';

type StateProps = {
  notifications: Notification[];
  enableNotification: boolean;
};

type ParentProps = {
  onClick: () => void;
};

const mapStateToProps = ({ notification, user }: RootState): StateProps => ({
  notifications: notification.notifications || [],
  enableNotification: user?.currentUser?.enableNotification || false,
});

type Props = StateProps & ParentProps;

const NotificationBell = ({ onClick, notifications, enableNotification }: Props) => {
  const unreadCount = notifications.filter(notification => !notification.isRead).length;

  return (
    <IconButton style={{ marginTop: '.2rem' }} onClick={onClick}>
      <Badge badgeContent={enableNotification ? unreadCount : null} color="primary">
        <NotificationsIcon style={{ color: 'white' }} />
      </Badge>
    </IconButton>
  );
};

export default connect(mapStateToProps, null)(NotificationBell);
