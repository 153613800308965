import ps, { ProviderSearchActionTypes } from './types/providerSearchActions';
import p, { PlanActionTypes } from '../planSelect/types/planActions';
import a, { AddPlanActionTypes, isAddPlanResponse } from '../addPlan/types/addPlanActions';
import { Provider, ProviderSearchState, ProviderTypeScrollPosition } from './types/providerSearch';
import {
  sortProviders,
  swapSortDirection,
  filterAndSortProviderList,
  filterProviderList,
  buildProviderTypesMap,
  allAccessibilities,
  toggleAccessibility,
  removeUnknownAccessibilitiesFromProviders,
} from './api/providers';
import { getProviderStorage, setProviderStorage } from './api/providerStorage';

export const defaultProviderSearchState: ProviderSearchState = {
  selectedZipCode: '',
  zipCodeDetails: '',
  isZipUnrecognized: false,
  radius: '25',
  searchInput: '',
  isLoadingProviderList: false,
  fullList: [],
  filteredList: [],
  taxonomyDetailsMap: {},
  providerTypesMap: {},
  selectedProviderType: '',
  sortCriteria: 'accessibility',
  sortDirection: 'asc',
  selectedAccessibilities: allAccessibilities,
  selectedPlanID: '',
  isFilterTrayOpen: false,
  scrollPosition: {} as ProviderTypeScrollPosition,
  displayPlanMessage: false,
  planMessage: '',
  providergendercode: '',
  entitytype: '',
};

export function generateInitialProviderSearchState(): ProviderSearchState {
  const { zip, fullList, taxonomyDetailsMap, providerTypesMap } = getProviderStorage();
  // const selectedPlanID = ProviderSearchState.selectedPlanID;
  const displayPlanMessage =
    window.localStorage.getItem('selectedPlanDisplayPlanMessage') === 'true';
  const planMessage = window.localStorage.getItem('selectedPlanMessage') || '';

  return {
    ...defaultProviderSearchState,
    // selectedPlanID,
    displayPlanMessage,
    planMessage,
    selectedZipCode: zip,
    fullList,
    filteredList: fullList,
    taxonomyDetailsMap,
    providerTypesMap,
  };
}

export default function providerSearchReducer(
  state: ProviderSearchState = generateInitialProviderSearchState(),
  action: ProviderSearchActionTypes | PlanActionTypes | AddPlanActionTypes
): ProviderSearchState {
  state.selectedPlanID = localStorage.getItem('selectedPlanID') || ''; // eslint-disable-line no-param-reassign
  switch (action.type) {
    case ps.RESET_PROVIDER_SEARCH: {
      return generateInitialProviderSearchState();
    }
    case ps.SET_SEARCH_INPUT: {
      const searchInput = action.payload;
      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        searchInput,
        filteredList,
      };
    }
    case ps.SET_ZIP_CODE: {
      if (action.payload.length < 5) {
        return {
          ...state,
          zipCodeDetails: '',
          selectedZipCode: action.payload,
        };
      }
      return {
        ...state,
        selectedZipCode: action.payload,
      };
    }
    case ps.SET_SEARCH_RADIUS: {
      const radius = action.payload;
      const filteredList = filterAndSortProviderList(
        state.fullList,
        radius,
        state.searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        radius,
        filteredList,
      };
    }
    case ps.SET_PROVIDER_TYPE: {
      const selectedProviderType = action.payload;
      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        selectedProviderType,
        filteredList,
      };
    }
    case ps.SET_IS_FILTER_TRAY_OPEN: {
      const isFilterTrayOpen = action.payload;
      return {
        ...state,
        isFilterTrayOpen,
      };
    }
    case ps.SET_SCROLL_POSITION: {
      const scrollPosition = action.payload;
      return {
        ...state,
        scrollPosition,
      };
    }

    case ps.GET_PROVIDERS_PENDING:
    case ps.GET_PROVIDERS_RTK_PENDING: {
      return {
        ...state,
        isLoadingProviderList: true,
      };
    }
    case ps.GET_PROVIDERS_FULFILLED:
    case ps.GET_PROVIDERS_RTK_FULFILLED: {
      const selectedZipCode = action.meta.arg;
      const { providers, taxonomyDetails: taxonomyDetailsMap } = action.payload.data;
      const providerTypesMap = buildProviderTypesMap(taxonomyDetailsMap);
      const dateLastFetched = new Date().toISOString();

      let fullList: Provider[] = [];
      if (providers && providers.length > 0) {
        const sanitizedList = removeUnknownAccessibilitiesFromProviders(providers);
        fullList = sortProviders(sanitizedList, state.sortCriteria, state.sortDirection);
      }

      setProviderStorage({
        zip: selectedZipCode,
        dateLastFetched,
        fullList,
        taxonomyDetailsMap,
        providerTypesMap,
      });

      const filteredList = filterProviderList({
        fullList,
        radius: state.radius,
        searchInput: state.searchInput,
        providergendercode: state.providergendercode,
        entitytype: state.entitytype,
        selectedAccessibilities: state.selectedAccessibilities,
        taxonomyDetailsMap,
        selectedProviderType: state.selectedProviderType,
        selectedPlanID: state.selectedPlanID,
      });

      return {
        ...state,
        fullList,
        filteredList,
        taxonomyDetailsMap,
        providerTypesMap,
        selectedZipCode,
        isLoadingProviderList: false,
      };
    }
    case ps.GET_PROVIDERS_REJECTED:
    case ps.GET_PROVIDERS_RTK_REJECTED: {
      return {
        ...state,
        fullList: [],
        filteredList: [],
        taxonomyDetailsMap: {},
        providerTypesMap: {},
        isLoadingProviderList: false,
      };
    }
    case ps.TOGGLE_ACCESSIBILITY: {
      const selectedAccessibilities = toggleAccessibility(
        state.selectedAccessibilities,
        action.payload
      );
      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        selectedAccessibilities,
        filteredList,
      };
    }
    case ps.SET_ACCESSIBILITIES: {
      const selectedAccessibilities = action.payload;
      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        selectedAccessibilities,
        filteredList,
      };
    }
    case ps.SET_SORT_CRITERIA: {
      return {
        ...state,
        sortCriteria: action.payload,
        filteredList: sortProviders(state.filteredList, action.payload, state.sortDirection),
      };
    }
    case ps.SET_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.payload,
      };
    }
    case ps.SET_PROVIDER_GENDER_CODE: {
      let providergendercode = '';
      if (action.payload === 'Male') {
        providergendercode = 'M';
      } else if (action.payload === 'Female') {
        providergendercode = 'F';
      }

      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        providergendercode,
        state.entitytype
      );

      return {
        ...state,
        providergendercode,
        filteredList,
      };
    }
    case ps.SET_ENTITY_TYPE: {
      let entitytype = '';
      if (action.payload === 'Professional') {
        entitytype = '1';
      } else if (action.payload === 'Facility') {
        entitytype = '2';
      }

      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        state.selectedPlanID,
        state.providergendercode,
        entitytype
      );

      return {
        ...state,
        entitytype,
        filteredList,
      };
    }
    case ps.TOGGLE_SORT_DIRECTION: {
      const sortDirection = swapSortDirection(state.sortDirection);
      return {
        ...state,
        sortDirection,
        filteredList: sortProviders(state.filteredList, state.sortCriteria, sortDirection),
      };
    }
    case ps.TOGGLE_CHANGE_PLAN: {
      const id = window.localStorage.getItem('selectedPlanID') || '';
      const flag = window.localStorage.getItem('selectedPlanDisplayPlanMessage') === 'true';
      const message = window.localStorage.getItem('selectedPlanMessage') || '';

      return {
        ...state,
        selectedPlanID: id,
        displayPlanMessage: flag,
        planMessage: message,
      };
    }
    case ps.VALIDATE_ZIP_CODE_RTK_PENDING: {
      return { ...state, zipCodeDetails: '' };
    }
    case ps.VALIDATE_ZIP_CODE_RTK_REJECTED: {
      return {
        ...state,
        zipCodeDetails: 'Could not connect to server. Please check your network connection.',
        isZipUnrecognized: true,
      };
    }
    case ps.VALIDATE_ZIP_CODE_RTK_FULFILLED: {
      const { county, state: zipCodeState, message } = action.payload.data;

      const isZipUnrecognized = message !== undefined && message !== '';
      const zipCodeDetails = isZipUnrecognized ? message : `${county}, ${zipCodeState}`;

      return {
        ...state,
        zipCodeDetails,
        isZipUnrecognized,
      };
    }
    case p.SET_PLAN_STATE: {
      const selectedPlanID = action.payload.id;
      const filteredList = filterAndSortProviderList(
        state.fullList,
        state.radius,
        state.searchInput,
        state.selectedAccessibilities,
        state.sortCriteria,
        state.sortDirection,
        state.taxonomyDetailsMap,
        state.selectedProviderType,
        selectedPlanID,
        state.providergendercode,
        state.entitytype
      );
      return {
        ...state,
        filteredList,
        selectedPlanID,
      };
    }
    case a.ADD_PLAN_PENDING: {
      return { ...state, isLoadingProviderList: true };
    }
    case a.ADD_PLAN_REJECTED: {
      return { ...state, isLoadingProviderList: false };
    }
    case a.ADD_PLAN_FULFILLED: {
      const newState = {
        ...state,
        isLoadingProviderList: false,
      };
      if (isAddPlanResponse(action.payload.data)) {
        newState.fullList = action.payload.data.providers;
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}
