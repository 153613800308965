import PropTypes from 'prop-types';

export const procedureType = PropTypes.shape({
  billedAvg: PropTypes.number,
  billedStdDev: PropTypes.number,
  costAvg: PropTypes.number,
  costStdDev: PropTypes.number,
  medicareAvg: PropTypes.number,
  medicareStdDev: PropTypes.number,
  lengthOfStayAvg: PropTypes.number,
  lengthOfStayStdDev: PropTypes.number,
  numClaims: PropTypes.number,
  included: PropTypes.bool,
});

export const providerType = {
  filter: PropTypes.shape({
    geo: PropTypes.shape({
      city: PropTypes.string.isRequired,
      county: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }),
  }),
  matches: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export const excludedProvidersType = PropTypes.arrayOf(PropTypes.string);

export const providerDataType = PropTypes.shape({
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      quality: PropTypes.number,
      procedures: PropTypes.arrayOf(procedureType),
      simpleAggregate: PropTypes.procedureType,
      weightedAggregate: PropTypes.procedureType,
    })
  ),
  excludedProviders: excludedProvidersType,
  simpleAggregate: PropTypes.shape({}),
  weightedAggregate: PropTypes.shape({}),
});

export const currentUserType = PropTypes.shape({
  fullName: PropTypes.string,
  email: PropTypes.string,
});

export const autocompleteOptionType = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
});

export const autocompleteOptionsType = PropTypes.arrayOf(autocompleteOptionType);

export const providerDetailsType = PropTypes.shape({
  info: PropTypes.shape({}),
  costreport: PropTypes.shape({}),
  quality: PropTypes.shape({}),
  inpatientBilledToMedicare: PropTypes.number,
  outpatientBilledToMedicare: PropTypes.number,
});

export const chartOptionsType = PropTypes.shape({});

export const chartMetaType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

export const chartDataType = PropTypes.shape({
  datasets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const hiddenDataType = PropTypes.arrayOf(PropTypes.shape({}));
