import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import UserCard from '@apps/shared/src/auth/UserCard';

const styles = theme => ({
  content: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: theme.props.contentHeight,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
});

export function WelcomeMember({ classes }) {
  return (
    <div className={classes.content}>
      <UserCard title="Welcome!" showLogo>
        <Typography variant="h5">Welcome to MediVI</Typography>
        <Typography variant="h6">Please return to the MediVI mobile app to log in</Typography>
      </UserCard>
    </div>
  );
}

WelcomeMember.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(WelcomeMember);
