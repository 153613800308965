import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    borderTop: theme.footer.root.border,
    backgroundColor: theme.footer.root.backgroundColor,
    height: theme.props.footerHeight,
    boxSizing: 'border-box',
    color: theme.footer.root.color,
  },
  text: {
    fontSize: '.85rem',
  },
  link: {
    textDecoration: 'none',
    color: theme.footer.link.color,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const thisYear = new Date().getFullYear();

  return (
    <footer className={classes.root}>
      <span className={classes.text}>
        &copy; {thisYear}&nbsp;
        <a
          href="https://www.6degreeshealth.com/"
          target="blank"
          alt="6 Degrees Health"
          className={classNames(classes.text, classes.link)}
          title="6 Degrees Health: The Future of Healthcare Reimbursements"
        >
          6 Degrees Health
        </a>
        &nbsp;All Rights Reserved
      </span>
    </footer>
  );
}
