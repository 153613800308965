import axios from 'axios';
import * as t from './reportActionTypes';
import {
  ValidCaseStatusStage,
  ValidMonthlyRepricingYear,
  ValidMonthlyRepricingMonth,
  ValidReportName,
} from './types';

export function getPowerBIEmbed(report: string): t.ReportActionTypes {
  return {
    type: t.GET_POWERBI_EMBED,
    payload: axios.get(`/api/reports/${report}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function selectReport(report: ValidReportName): t.ReportActionTypes {
  return {
    type: t.SELECT_REPORT,
    payload: report,
  };
}

export function selectPage(pageDisplayName: string): t.ReportActionTypes {
  return {
    type: t.SELECT_PAGE,
    payload: pageDisplayName,
  };
}

export function selectMonthlyRepricingYear(year: ValidMonthlyRepricingYear): t.ReportActionTypes {
  return {
    type: t.SELECT_MONTHLY_REPRICING_YEAR,
    payload: year,
  };
}

export function selectMonthlyRepricingMonth(
  month: ValidMonthlyRepricingMonth
): t.ReportActionTypes {
  return {
    type: t.SELECT_MONTHLY_REPRICING_MONTH,
    payload: month,
  };
}

export function setNavOpenOption(
  option: ValidCaseStatusStage | ValidMonthlyRepricingYear | undefined
): t.ReportActionTypes {
  return {
    type: t.SET_NAV_OPEN_OPTION,
    payload: option,
  };
}
