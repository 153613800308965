import React, { useCallback, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  uploadFilesWrapper: {
    width: '50%',
  },
  label: {
    padding: '0.5rem 0',
  },
  filesDragAndDrop: {
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    fontSize: '24px',
    color: '#55555',
    border: '1px dashed #366BEDCC',
    borderRadius: '12px',
    height: '30vh',
    cursor: 'pointer',
    marginBottom: '.5rem',
  },
  uploadStyle: {
    marginTop: '-5px',
    color: '#366BED',
  },
  iconStyle: {
    color: '#366BED',
  },
  fileNameWrapper: {},
  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    marginLeft: '10px',
    cursor: 'pointer',
    padding: '.5rem',
  },
  icon: {
    color: '#797C80',
  },
  files: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#0FB529',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginLeft: '.5rem',
    fontWeight: 400,
    textDecoration: 'underline',
  },
});

type Props = {
  upLoadedFile: File[];
  onFilesAccepted: (files: File[]) => void;
  onRemoveFile: (index: number) => void;
};

export default function DragDrop({ upLoadedFile, onFilesAccepted, onRemoveFile }: Props) {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Handle the dropped files here
      onFilesAccepted(acceptedFiles);
    },
    [onFilesAccepted]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'application/pdf': ['.pdf'],
    },
    multiple: true,
  });

  return (
    <div className={classes.uploadFilesWrapper}>
      <Typography className={classes.label}>Upload Documents</Typography>
      <div {...getRootProps()} className={classes.filesDragAndDrop}>
        <input {...getInputProps()} />
        <CloudUpload className={classes.iconStyle} />
        <Typography className={classes.uploadStyle}>Upload</Typography>
      </div>
      <div className={classes.fileNameWrapper}>
        {upLoadedFile &&
          upLoadedFile.map((file, index) => (
            <div key={file} className={classes.fileNameContainer}>
              <img src="./images/circle-tick.png" alt="circle tick" />
              <div className={classes.files}>
                <Typography className={classes.fileName} variant="body1">
                  {file.name}
                </Typography>
                <IconButton onClick={() => onRemoveFile(index)} className={classes.closeIcon}>
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
