import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import SummaryIcon from '@material-ui/icons/Subject';
import { formatMoneyNoDecimal, formatNumber } from '../shared/formats';
import PopoverButton from '../shared/PopoverButton';
import { providerDataType } from '../shared/types/propTypes';

const styles = theme => ({
  header: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '0 0 1em 0',
    display: 'flex',
    alignItens: 'center',
  },
  line: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.5em',
    paddingBottom: '.25em',
    justifyItems: 'center',
  },
  title: {
    marginRight: '1.5em',
  },
  text: {
    marginTop: 'auto',
  },
  approximate: {
    color: theme.chartSummary.approximate.color,
    fontSize: 'small',
  },
  container: {
    margin: '1em',
  },
  excludedIcon: {
    marginRight: '.5em',
    color: theme.chartSummary.excludedIcon.color,
  },
  excludedButton: {
    maxWidth: '3em',
    transition: 'max-width .75s',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      maxWidth: '20em',
    },
  },
});

function ChartSummary({ providerData, selectedProcedure, classes }) {
  const summary =
    selectedProcedure === 'weighted average' && providerData.weightedAggregate
      ? providerData.weightedAggregate
      : providerData.simpleAggregate;
  const content = summary ? (
    <section className={classes.container}>
      <h2 className={classes.header}>
        <SummaryIcon className={classes.excludedIcon} />
        Chart Averages
      </h2>
      <div className={classes.line}>
        <span className={classes.title}>Billed Charge</span>
        <span className={classes.text}> {formatMoneyNoDecimal(summary.billedAvg)}</span>
      </div>
      <div className={classes.line}>
        <span className={classes.title}>Cost</span>
        <span className={classes.text}> {formatMoneyNoDecimal(summary.costAvg)}</span>
      </div>
      <div className={classes.line}>
        <span className={classes.title}>Medicare Reimbursement</span>
        <span className={classes.text}> {formatMoneyNoDecimal(summary.medicareAvg)}</span>
      </div>
      <div className={classes.line}>
        <span className={classes.title}>Ratio of Billed to Medicare</span>
        <span className={classes.text}>
          {' '}
          {formatNumber(summary.billedAvg / summary.medicareAvg, '0.00')}
        </span>
      </div>
      <div className={classes.line}>
        <span className={classes.title}>Length of Stay</span>
        <span className={classes.text}> {formatNumber(summary.lengthOfStayAvg, '0.00')}</span>
      </div>
      <div className={classes.line}>
        <span className={classes.title}>
          Number of Claims <span className={classes.approximate}>(rounded)</span>
        </span>
        <span className={classes.text}> {formatNumber(summary.numClaims, '0,0')}</span>
      </div>
    </section>
  ) : (
    ''
  );
  return (
    <PopoverButton
      title="Chart Averages"
      visible={Boolean(summary)}
      content={content}
      Icon={SummaryIcon}
    />
  );
}

ChartSummary.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  providerData: providerDataType,
  selectedProcedure: PropTypes.string,
};
ChartSummary.defaultProps = {
  providerData: {},
  selectedProcedure: '',
};

export default withStyles(styles)(ChartSummary);
