import m, { GetMemberActionTypes } from './types/memberActions';
import { MemberInfoState } from './types/memberTypes';

export const defaultMemberState: MemberInfoState = {
  memberDetailsLoaded: false,
  memberInfo: {
    sixdegaliasid: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    dateOfBirth: '',
    memberId: '',
    patientid: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    policyNumber: '',
    sixDegPatientID: '',
    planname: '',
    recordType: '',
    preferredMethodOfContact: '',
    additionalComment: '',
    groupid: '',
    sfplanid: '',
    newPatient: false,
  },
};

const getMemberReducer = (
  state: MemberInfoState = defaultMemberState,
  action: GetMemberActionTypes
): MemberInfoState => {
  switch (action.type) {
    case m.GET_MEMBER: {
      return {
        ...state,
      };
    }
    case m.GET_MEMBER_PENDING: {
      return {
        ...state,
      };
    }
    case m.GET_MEMBER_FULFILLED: {
      const memberInfo = action.payload.data;
      if (action.payload) {
        return {
          ...state,
          memberInfo,
          memberDetailsLoaded: true,
        };
      }

      return {
        ...state,
        memberDetailsLoaded: false,
      };
    }

    case m.GET_MEMBER_REJECTED: {
      return {
        ...state,
      };
    }

    case m.RESET_MEMBER_INFO: {
      return {
        ...defaultMemberState,
      };
    }

    default:
      return state;
  }
};

export default getMemberReducer;
