import { AxiosPromise } from 'axios';
import {
  EmbedReport,
  Page,
  ValidCaseStatusStage,
  ValidReportName,
  ValidMonthlyRepricingYear,
  ValidMonthlyRepricingMonth,
} from './types';

export const GET_POWERBI_EMBED = 'GET_POWERBI_EMBED';
export const GET_POWERBI_EMBED_PENDING = `GET_POWERBI_EMBED_PENDING`;
export const GET_POWERBI_EMBED_FULFILLED = `GET_POWERBI_EMBED_FULFILLED`;
export const GET_POWERBI_EMBED_REJECTED = `GET_POWERBI_EMBED_REJECTED`;

export const SELECT_REPORT = 'SELECT_REPORT';
export const SELECT_PAGE = 'SELECT_PAGE';
export const SET_NAV_OPEN_OPTION = 'SET_NAV_OPEN_OPTION';

export const SELECT_MONTHLY_REPRICING_YEAR = 'SELECT_MONTHLY_REPRICING_YEAR';
export const SELECT_MONTHLY_REPRICING_MONTH = 'SELECT_MONTHLY_REPRICING_MONTH';

export type PowerBIEmbedResponse = {
  data: {
    report: EmbedReport;
    pages: {
      value: Page[];
    };
    lastPublished?: {
      year: ValidMonthlyRepricingYear;
      month: ValidMonthlyRepricingMonth;
    };
  };
};

type GetPowerBIEmbed = {
  type: typeof GET_POWERBI_EMBED;
  payload: AxiosPromise<PowerBIEmbedResponse>;
};
type GetPowerBIEmbedPending = {
  type: typeof GET_POWERBI_EMBED_PENDING;
};
type GetPowerBIEmbedFulfilled = {
  type: typeof GET_POWERBI_EMBED_FULFILLED;
  payload: PowerBIEmbedResponse;
};
type GetPowerBIEmbedRejected = {
  type: typeof GET_POWERBI_EMBED_REJECTED;
};

export type GetPowerBIEmbedAction =
  | GetPowerBIEmbed
  | GetPowerBIEmbedPending
  | GetPowerBIEmbedFulfilled
  | GetPowerBIEmbedRejected;

type SelectReport = {
  type: typeof SELECT_REPORT;
  payload: ValidReportName;
};

type SelectPage = {
  type: typeof SELECT_PAGE;
  payload: string;
};

type SetNavOpenOption = {
  type: typeof SET_NAV_OPEN_OPTION;
  payload: ValidMonthlyRepricingYear | ValidCaseStatusStage | undefined;
};

type SelectMonthlyRepricingYear = {
  type: typeof SELECT_MONTHLY_REPRICING_YEAR;
  payload: ValidMonthlyRepricingYear;
};
type SelectMonthlyRepricingMonth = {
  type: typeof SELECT_MONTHLY_REPRICING_MONTH;
  payload: ValidMonthlyRepricingMonth;
};

export type ReportActionTypes =
  | GetPowerBIEmbedAction
  | SelectReport
  | SelectPage
  | SetNavOpenOption
  | SelectMonthlyRepricingYear
  | SelectMonthlyRepricingMonth;
