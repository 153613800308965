import m, {
  DependentMemberActionsTypes,
  DependentMembersActionTypes,
} from './types/dependentMemberActions';
import { DependentMemberInfo, AddDependentMemberState } from './types/dependentMemberTypes';

export const defaultDependentMembersState: DependentMemberInfo[] = [];
export const defaultAddDependentMemberState: AddDependentMemberState = {
  isDependentMemberDeleted: false,
  isDependendentMemberAdded: false,
  dependentMemberInfo: {
    memberID: '',
    groupID: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    zip: '',
  },
  redirectTo: '',
};
export const getDependentMembersReducer = (
  state: DependentMemberInfo[] = defaultDependentMembersState,
  action: DependentMembersActionTypes | DependentMemberActionsTypes
): DependentMemberInfo[] => {
  switch (action.type) {
    case m.GET_DEPENDENT_MEMBERS: {
      // Assuming you might want to clear the existing list when fetching new members
      return [];
    }
    case m.GET_DEPENDENT_MEMBERS_PENDING: {
      return state; // You might want to indicate loading in your state
    }
    case m.GET_DEPENDENT_MEMBERS_FULFILLED: {
      const newDependentMembers = action.payload.data.dependentMembers || [];
      return [...newDependentMembers];
    }

    case m.GET_DEPENDENT_MEMBERS_REJECTED: {
      return state; // You might want to handle error state here
    }

    default:
      return state;
  }
};

export const addDependentMemberReducer = (
  state: AddDependentMemberState = defaultAddDependentMemberState,
  action: DependentMemberActionsTypes
): AddDependentMemberState => {
  switch (action.type) {
    case m.ADD_DEPENDENT_MEMBER_PENDING: {
      return {
        ...state,
      };
    }
    case m.ADD_DEPENDENT_MEMBER_FULFILLED: {
      const newState = { ...state, isDependendentMemberAdded: true };
      return newState;
    }
    case m.ADD_DEPENDENT_MEMBER_REJECTED: {
      return {
        ...state,
      };
    }
    case m.SET_DEPENDENT_MEMBER_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        dependentMemberInfo: { ...state.dependentMemberInfo, [key]: value },
      };
    }
    case m.RESET_ADD_DEPENDENT_MEMBER: {
      return defaultAddDependentMemberState;
    }

    case m.DELETE_DEPENDENT_MEMBER:
    case m.DELETE_DEPENDENT_MEMBER_FULFILLED: {
      return {
        ...state,
        isDependentMemberDeleted: true,
      };
    }
    default:
      return state;
  }
};
