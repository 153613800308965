import { AxiosError, AxiosPromise } from 'axios';
import { MemberInfo } from '../../getMember/types/memberTypes';
import { AddCaseState, ProviderDescription, ProviderInfo } from './addCaseTypes';

enum a {
  ADD_CASE = 'ADD_CASE',
  ADD_CASE_PENDING = 'ADD_CASE_PENDING',
  ADD_CASE_REJECTED = 'ADD_CASE_REJECTED',
  ADD_CASE_FULFILLED = 'ADD_CASE_FULFILLED',
  SET_CASE_TYPE = 'SET_CASE_TYPE',
  SET_CASE_NAVIGATION = 'SET_CASE_NAVIGATION',
  SET_CASE_MEMBER_INFO = 'SET_CASE_MEMBER_INFO',
  SET_PROVIDER_OPTION = 'SET_PROVIDER_OPTION',
  UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT',
  UPLOAD_ATTACHMENT_REJECTED = 'UPLOAD_ATTACHMENT_REJECTED',
  SET_ADD_CASE_RESPONE_INFO = 'SET_ADD_CASE_RESPONE_INFO',
  SET_PROVIDER_INFO = 'SET_PROVIDER_INFO',
  SET_PROVIDER_DESCRIPTION_INFO = 'SET_PROVIDER_DESCRIPTION_INFO',
  RESET_ADD_CASE = 'RESET_ADD_CASE',
  UPDATE_CASE_MEMBER_INFO = 'UPDATE_CASE_MEMBER_INFO',
  SET_IS_DEPENDENT_MEMBER = 'SET_IS_DEPENDENT_MEMBER',
}
export default a;

type AddCaseResponse = {
  errors: [];
  id: string;
  success: boolean;
};

export type AddCase = {
  type: typeof a.ADD_CASE;
  meta: { redirectTo?: string };
  payload: AxiosPromise<AddCaseResponse>;
};

export type AddCasePending = {
  type: typeof a.ADD_CASE_PENDING;
  meta: { redirectTo?: string };
};

export type AddCaseFulfilled = {
  type: typeof a.ADD_CASE_FULFILLED;
  meta: { redirectTo?: string };
  payload: { data: AddCaseResponse };
};

export type AddCaseRejected = {
  type: typeof a.ADD_CASE_REJECTED;
  meta: { redirectTo?: string };
  payload: AxiosError;
};

export type SetCaseType = {
  type: typeof a.SET_CASE_TYPE;
  payload: string;
};

export type SetIsDependentMember = {
  type: typeof a.SET_IS_DEPENDENT_MEMBER;
  payload: boolean;
};

export type SetAddCaseResponseInfo = {
  type: typeof a.SET_ADD_CASE_RESPONE_INFO;
  meta: {
    key: keyof AddCaseState;
    value: AddCaseState[keyof AddCaseState];
  };
};

export type ResetAddCase = {
  type: typeof a.RESET_ADD_CASE;
};

export type SetProviderOption = {
  type: typeof a.SET_PROVIDER_OPTION;
  payload: boolean;
};

export type SetHomeNavigation = {
  type: typeof a.SET_CASE_NAVIGATION;
  payload: boolean;
};

export type UpdateCaseMemberInfo = {
  type: typeof a.UPDATE_CASE_MEMBER_INFO;
  payload: MemberInfo;
};

export type SetCaseMemberInfo = {
  type: typeof a.SET_CASE_MEMBER_INFO;
  meta: {
    key: keyof MemberInfo;
    value: MemberInfo[keyof MemberInfo];
  };
};

export type uploadAttachment = {
  type: typeof a.UPLOAD_ATTACHMENT;
  meta: { redirectTo?: string };
  payload: AxiosPromise;
};

export type uploadAttachmentRejected = {
  type: typeof a.UPLOAD_ATTACHMENT_REJECTED;
  meta: { redirectTo?: string };
  payload: AxiosPromise;
};

export type SetProviderInfo = {
  type: typeof a.SET_PROVIDER_INFO;
  meta: {
    key: keyof ProviderInfo;
    value: ProviderInfo[keyof ProviderInfo];
  };
};

export type SetProviderDescriptionInfo = {
  type: typeof a.SET_PROVIDER_DESCRIPTION_INFO;
  meta: {
    key: keyof ProviderDescription;
    value: ProviderDescription[keyof ProviderDescription];
  };
};

export type ProviderActionTypes = SetProviderInfo | SetProviderDescriptionInfo;

export type AddCaseActionTypes =
  | AddCase
  | AddCasePending
  | AddCaseRejected
  | AddCaseFulfilled
  | SetCaseType
  | SetHomeNavigation
  | SetProviderOption
  | SetCaseMemberInfo
  | UpdateCaseMemberInfo
  | uploadAttachment
  | uploadAttachmentRejected
  | SetAddCaseResponseInfo
  | SetIsDependentMember
  | ResetAddCase;
