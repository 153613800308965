import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import UserCard from '@apps/shared/src/auth/UserCard';

function EmailSuccessModal({ email, requestEmailSent, closeEmailSuccessModal }) {
  return (
    <Modal open={requestEmailSent} onClose={closeEmailSuccessModal}>
      <UserCard showLogo>
        <Typography variant="body1" style={{ marginBottom: '1em' }}>
          An email requesting MediVI access has been sent to 6 Degrees Health.
        </Typography>
        <Typography variant="body1">
          A 6 Degrees Health representative will be in touch shortly.
        </Typography>
      </UserCard>
    </Modal>
  );
}

EmailSuccessModal.propTypes = {
  closeEmailSuccessModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  requestEmailSent: PropTypes.bool.isRequired,
};

export default EmailSuccessModal;
