import React, { MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';
import { closeCredentialsInUseDialog } from './actions';
import { RootState } from '../rootStateType';

type StateProps = {
  existingEmail?: string;
};

function mapStateToProps({ addPlan }: RootState): StateProps {
  return { existingEmail: addPlan.existingEmail };
}

const mapDispatchToProps = {
  closeCredentialsInUseDialog,
};

type DispatchProps = typeof mapDispatchToProps;

type ParentProps = {
  loginLink?: string;
  resetPasswordLink?: string;
};

type Props = StateProps & DispatchProps & ParentProps;

export function CredentialsInUseDialog({
  existingEmail,
  closeCredentialsInUseDialog,
  loginLink,
  resetPasswordLink,
}: Props): JSX.Element {
  const handleLogInClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (loginLink) window.location.assign(loginLink);
  };

  return (
    <Dialog open={existingEmail !== undefined} onClose={closeCredentialsInUseDialog}>
      <DialogTitle>Member credentials already in use</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The member info you have entered is associated with the email address {existingEmail}.
        </DialogContentText>
        {loginLink && (
          <DialogContentText>
            If you&apos;ve already created an account, please <Link href={loginLink}>log in</Link>.
          </DialogContentText>
        )}
        {resetPasswordLink && (
          <DialogContentText>
            If you forgot your password, <Link href={resetPasswordLink}>reset your password</Link>.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={closeCredentialsInUseDialog}
          style={{ color: 'white' }}
        >
          CANCEL
        </Button>
        {loginLink && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogInClick}
            style={{ color: 'white' }}
          >
            LOG IN
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsInUseDialog);
