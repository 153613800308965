import a, { AddCaseActionTypes, ProviderActionTypes } from './types/addCaseActions';
import { AddCaseState, ProviderInfo, UploadAttachmentState } from './types/addCaseTypes';
import { defaultMemberState } from '../getMember/reducer';

export const defaultProviderInfoState: ProviderInfo = {
  typeOfAccessIssue: '',
  patientSeenFor: '',
  reasonForAccessIssue: '',
  scheduledDateOfService: '1111-01-01T00:00:00Z',
  additionalComment: '',
  description: {
    providerOfficeName: '',
    providerPhone: '',
    providerStreet: '',
    providerCity: '',
    providerState: '',
    providerZip: '',
    additionalComments: '',
  },
};

export const defaultAddCaseState: AddCaseState = {
  isCreated: false,
  addingCase: false,
  isDependentMember: false,
  caseId: '',
  caseType: '',
  memberInfo: {
    ...defaultMemberState.memberInfo,
  },
  providerInfo: {
    ...defaultProviderInfoState,
  },
  appointmentFor: '',
  isHomeNavigation: false,
  providerOption: false,
};

export const defaultUploadAttachmentForm: UploadAttachmentState = {
  title: 'testing-v-01',
  versionData: '',
  contentLocation: '',
  caseID: '',
};

const addCaseReducer = (
  state: AddCaseState = defaultAddCaseState,
  action: AddCaseActionTypes | ProviderActionTypes
): AddCaseState => {
  switch (action.type) {
    case a.SET_CASE_TYPE: {
      return {
        ...state,
        caseType: action.payload,
      };
    }
    case a.SET_IS_DEPENDENT_MEMBER: {
      return {
        ...state,
        isDependentMember: action.payload,
      };
    }
    case a.RESET_ADD_CASE: {
      return {
        ...defaultAddCaseState,
      };
    }
    case a.SET_ADD_CASE_RESPONE_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        [key]: value,
      };
    }
    case a.SET_CASE_NAVIGATION: {
      return {
        ...state,
        isHomeNavigation: action.payload,
      };
    }
    case a.SET_PROVIDER_OPTION: {
      return {
        ...state,
        providerOption: action.payload,
      };
    }
    case a.SET_CASE_MEMBER_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        memberInfo: { ...state.memberInfo, [key]: value },
      };
    }
    case a.UPDATE_CASE_MEMBER_INFO: {
      return {
        ...state,
        memberInfo: action.payload,
      };
    }
    case a.SET_PROVIDER_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        providerInfo: { ...state.providerInfo, [key]: value },
      };
    }
    case a.SET_PROVIDER_DESCRIPTION_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        providerInfo: {
          ...state.providerInfo,
          description: { ...state.providerInfo.description, [key]: value },
        },
      };
    }
    case a.ADD_CASE: {
      return {
        ...state,
      };
    }
    case a.ADD_CASE_PENDING: {
      return {
        ...state,
        addingCase: true,
      };
    }
    case a.ADD_CASE_FULFILLED: {
      const { data } = action.payload;
      const newState = {
        ...defaultAddCaseState,
        caseId: data.id,
        isCreated: data.success,
        addingCase: false,
      };
      return newState;
    }
    case a.ADD_CASE_REJECTED: {
      return {
        ...state,
        addingCase: false,
      };
    }
    case a.UPLOAD_ATTACHMENT: {
      return {
        ...state,
      };
    }
    case a.UPLOAD_ATTACHMENT_REJECTED: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default addCaseReducer;
