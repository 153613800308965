import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, Typography, makeStyles } from '@material-ui/core';

import { Plan } from '@apps/shared/src/planSelect/types/plan';
import { DependentMemberInfo } from '@apps/shared/src/dependentMember/types/dependentMemberTypes';
import { MemberInfo } from '@apps/shared/src/getMember/types/memberTypes';
import { deleteUser } from '@apps/shared/src/auth/userActions';
import { resetAddPlan } from '@apps/shared/src/addPlan/actions';
import { setPlanState } from '@apps/shared/src/planSelect/actions';
import { getMemberDetail } from '@apps/shared/src/getMember/actions';
import {
  getDependentMembersDetail,
  deleteDependentMember,
  resetDependentMember,
} from '@apps/shared/src/dependentMember/actions';

import AppLayout from '../shared/AppLayout';
import { RootState } from '../store';
import { getPlans } from '../providerSearch/actions';
import SelectInput from '../shared/muiSelect';
import ModalLayout from '../shared/ModalLayout';

const useStyles = makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 70px - 50px)',
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    overflow: 'scroll',
  },
  accountSettingsTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    color: '#36383D',
  },
  commonDivStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0rem',
  },
  commonTitleStyle: {
    color: '#5D5C5C',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  commonBtnStyle: {
    width: '11.5rem',
    textWrap: 'no-wrap',
    backgroundColor: '#EF7125',
    color: '#FFFFFF',
    padding: '.6rem 1rem',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    borderRadius: '.25rem',
    textAlign: 'center',
    '&.MuiButton-root:hover': {
      backgroundColor: 'chocolate',
    },
  },
  profileCard: {
    marginBottom: '0.5rem',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: '0.5rem',
    padding: '1rem',
    paddingRight: '0rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameCard: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  nameSFdiv: {
    width: '5rem',
    height: '5rem',
    backgroundColor: 'rgba(240, 112, 55, 0.11)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameSF: {
    fontSize: '2.25rem',
    color: '#F97140',
    fontWeight: 500,
  },
  name: {
    color: '#36383D',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
    margin: '0.25rem',
  },
  memberID: {
    color: '#797C80',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.125rem',
    margin: '0.25rem',
  },
  deleteBtn: {
    width: '11.5rem',
    margin: 0,
    color: '#F41F1F',
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    cursor: 'pointer',
    textAlign: 'center',
    border: 'none',
    background: 'none',
    height: '3rem',
  },
  planCard: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: '0.5rem',
    paddingRight: '1.5rem',
  },
  planName: {
    display: 'flex',
    // padding: '1.5rem',
    alignItems: 'center',
    lineHeight: '1.5rem',
    marginRight: '1.5rem',
    '& .MuiSelect-select': {
      color: '#797C80',
      fontWeight: 400,
      fontSize: '1.125rem',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiSelect-selectMenu:focus': {
      border: 'none',
    },
  },
}));

type StateProps = {
  currentUser: any;
  isCaseMember: boolean;
  memberInfo: MemberInfo;
  getDependentMember: any;
  dependentMemberDeleteStatus: boolean;
  isDependendentMemberAdded: boolean;
  plans: Plan[];
  selectedPlan: Plan;
  addPlanSuccess: boolean;
  accessLevel: string;
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.user.currentUser,
  isCaseMember: state.user.isCaseMember,
  accessLevel: state.user.accessLevel,
  memberInfo: state.getMember.memberInfo,
  getDependentMember: state.getDependentMember,
  dependentMemberDeleteStatus: state.addDependentMember.isDependentMemberDeleted,
  isDependentMemberAdded: state.addDependentMember.isDependendentMemberAdded,
  plans: state.planSelect.plans,
  selectedPlan: state.planSelect.selectedPlan,
  addPlanSuccess: state.addPlan.addPlanSuccess,
});

type DispatchProps = {
  deleteUser: (userID: any, email: any, redirectPath: string) => void;
  deleteDependentMember: (id: string) => void;
  getDependentMembersDetail: () => void;
  resetDependentMember: () => void;
  getMemberDetail: () => void;
  getPlans: () => void;
  setPlanState: (plan: Plan) => void;
  resetAddPlan: () => void;
};

const mapDispatchToProps = {
  deleteUser,
  getDependentMembersDetail,
  deleteDependentMember,
  resetDependentMember,
  getMemberDetail,
  getPlans,
  setPlanState,
  resetAddPlan,
};

type Props = StateProps & DispatchProps;

const getInitials = (fullName: string): string => {
  return fullName
    .split(' ')
    .map(name => name.charAt(0))
    .join('');
};

const AccountSettings = ({
  currentUser,
  isCaseMember,
  accessLevel,
  memberInfo,
  getDependentMember,
  getDependentMembersDetail,
  deleteDependentMember,
  deleteUser,
  dependentMemberDeleteStatus,
  isDependendentMemberAdded,
  resetDependentMember,
  getMemberDetail,
  getPlans,
  plans,
  selectedPlan,
  setPlanState,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const userInitials = getInitials(currentUser.fullName);
  // const [reload, setReload] = React.useState(false);
  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = React.useState(false);
  const [isDeleteMemberModalOpen, setDeleteMemberModalOpen] = React.useState(false);
  const [deleteMemberId, setDeleteMemberId] = React.useState('');

  const handleDeleteMemberIconClick = (id: string) => {
    setDeleteMemberModalOpen(true);
    setDeleteMemberId(id);
  };
  useEffect(() => {
    getPlans();
    if (isCaseMember && accessLevel !== 'Limited Access') {
      resetDependentMember();
      getMemberDetail();

      getDependentMembersDetail();
    }
  }, [dependentMemberDeleteStatus, isDependendentMemberAdded]);

  const handleDeleteAccountClick = () => {
    setDeleteAccountModalOpen(true);
  };

  const handleChangePasswordClick = () => {
    history.push('/change-password');
  };
  const handleAddClick = () => {
    history.push('/dependent-information');
  };

  const handleDeleteMember = () => {
    deleteDependentMember(deleteMemberId);
    handleModalClose();
  };

  const handleModalClose = () => {
    setDeleteAccountModalOpen(false);
    setDeleteMemberModalOpen(false);
    setDeleteMemberId('');
  };

  const handleDeleteAccount = () => {
    deleteUser(currentUser.userID, currentUser.email, '/register');
    handleModalClose();
  };

  const handlePlanSelect = (planName: string): void => {
    const plan = plans.filter((plan: Plan) => {
      return plan.name === planName;
    });
    if (plan.length > 0) {
      setPlanState(plan[0]);
    }
  };

  const handleAddPlan = (): void => {
    history.push('/add-plan');
  };

  const selectedPlanName =
    (selectedPlan && selectedPlan.name) || (plans.length > 0 && plans[0].name) || '';

  return (
    <AppLayout>
      <div className={classes.container}>
        <Typography className={classes.accountSettingsTitle}>Account Settings</Typography>
        <div className={classes.commonDivStyle}>
          <Typography className={classes.commonTitleStyle}>My Profile</Typography>
          <Button className={classes.commonBtnStyle} onClick={handleChangePasswordClick}>
            CHANGE PASSWORD
          </Button>
        </div>
        <div className={classes.profileCard}>
          <div className={classes.nameCard}>
            <Avatar className={classes.nameSFdiv}>
              <Typography className={classes.nameSF}>{userInitials}</Typography>
            </Avatar>
            <div>
              <h2 className={classes.name}>{currentUser.fullName}</h2>
              {isCaseMember && (
                <p className={classes.memberID}>
                  Member ID <span style={{ fontWeight: 500 }}>{memberInfo.memberId}</span>
                </p>
              )}
            </div>
          </div>
          <button className={classes.deleteBtn} type="button" onClick={handleDeleteAccountClick}>
            Delete Account
          </button>
        </div>
        {plans.length >= 1 && (
          <>
            <div className={classes.commonDivStyle}>
              <Typography className={classes.commonTitleStyle}>My Plan</Typography>
              {memberInfo.memberId !== '' && (
                <Button className={classes.commonBtnStyle} onClick={handleAddPlan}>
                  ADD PLAN
                </Button>
              )}
            </div>
            <div className={classes.planCard}>
              <SelectInput
                options={plans.map((plan: Plan) => `${plan.name}`)}
                value={selectedPlanName}
                selectBoxStyle={classes.planName}
                onSelectChange={handlePlanSelect}
              />
            </div>
          </>
        )}
        {isCaseMember && accessLevel !== 'Limited Access' && (
          <>
            <div className={classes.commonDivStyle}>
              <Typography className={classes.commonTitleStyle}>Dependent Members</Typography>
              <Button className={classes.commonBtnStyle} onClick={handleAddClick}>
                ADD DEPENDENT
              </Button>
            </div>
            {getDependentMember.map((dependentMember: DependentMemberInfo) => (
              <div key={dependentMember.sixDegPatientID} className={classes.profileCard}>
                <div className={classes.nameCard}>
                  <Avatar className={classes.nameSFdiv}>
                    <Typography className={classes.nameSF}>
                      {getInitials(`${dependentMember.firstName} ${dependentMember.lastName}`)}
                    </Typography>
                  </Avatar>
                  <div>
                    <h2 className={classes.name}>
                      {dependentMember.firstName} {dependentMember.lastName}
                    </h2>
                    <p className={classes.memberID}>
                      Member ID{' '}
                      <span style={{ fontWeight: 500 }}>
                        {dependentMember.memberId || dependentMember.patientid}
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  className={classes.deleteBtn}
                  type="button"
                  onClick={() => handleDeleteMemberIconClick(dependentMember.sixDegPatientID)}
                >
                  Delete Member
                </button>
              </div>
            ))}
          </>
        )}
      </div>
      <ModalLayout
        showModal={isDeleteAccountModalOpen}
        title="Are You Sure?"
        message={
          <>
            <div>Do you really want to delete your account?</div>
            <div>This action cannot be undone</div>
          </>
        }
        handleModalClose={handleModalClose}
        handleSuccess={handleDeleteAccount}
        closeTitle="CANCEL"
        actionTitle="DELETE"
      />

      <ModalLayout
        showModal={isDeleteMemberModalOpen}
        title="Are You Sure?"
        message={
          <>
            <div>Do you really want to delete this dependent profile?</div>
            <div>This action cannot be undone</div>
          </>
        }
        handleModalClose={handleModalClose}
        handleSuccess={handleDeleteMember}
        closeTitle="CANCEL"
        actionTitle="DELETE"
      />
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
