import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import DataSourceDatesDisplayItem from './DataSourceDatesDisplayItem';
import { DisplayItem } from './DetailPane';

type Props = {
  classes: ClassNameMap<'groupHeader' | 'groupData'>;
  inpatientFYEndDate?: string;
  outpatientFYEndDate?: string;
  costReportFY?: string;
};

export default function DetailPaneDataSourceDates({
  classes,
  inpatientFYEndDate,
  outpatientFYEndDate,
  costReportFY,
}: Props): JSX.Element {
  return (
    <>
      <h3 className={classes.groupHeader}>Data Source Dates</h3>
      <section className={classes.groupData}>
        <DataSourceDatesDisplayItem
          classes={classes}
          claimType="Inpatient"
          date={inpatientFYEndDate}
        />
        <DataSourceDatesDisplayItem
          classes={classes}
          claimType="Outpatient"
          date={outpatientFYEndDate}
        />
        <DisplayItem
          classes={classes}
          label="Cost Report"
          value={costReportFY ? `FY ${costReportFY}` : '-'}
        />
      </section>
    </>
  );
}
