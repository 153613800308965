import * as a from './analysisActions';

export const dataSource = {
  complete: 'complete',
  facilityAndProfessional: 'facilityAndProfessional',
  totalOnly: 'totalOnly',
  estimateFromPlanResponsibility: 'estimateFromPlanResponsibility',
  estimateFromBilled: 'estimateFromBilled',
  estimateFromMedicalSpend: 'estimateFromMedicalSpend',
};

export const pricingSelection = {
  current: 'current',
  old: 'old',
  custom: 'custom',
};

const change = {
  billed: 'billed',
  medicalSpend: 'medicalSpend',
  planResponsibility: 'planResponsibility',
};

const planResChanged = new RegExp(`${change.planResponsibility}|DataSource|percent|discount`, 'i');
const billedChanged = new RegExp(`${change.billed}|DataSource|percent|discount`, 'i');
const medSpendChanged = new RegExp(`${change.medicalSpend}|DataSource|percent|discount`, 'i');

const fullReplacementPEPMPrice = 24;
const facilityPEPMPrice = 16;
const professionalPEPMPrice = 8;
const professionalOnlyPEPMPrice = 16;
const facilityOONPercentOfSavingsPrice = 15;
const facilityOONPEPM = 4;
const minEmployees = 60;

const prices = {
  old: {
    fullReplacementPEPMPrice: 18,
    facilityPEPMPrice: 14,
    professionalPEPMPrice: 8,
    professionalOnlyPEPMPrice: 8,
    facilityOONPercentOfSavingsPrice: 15,
    fullReplacementMinPrice: 1110,
    facilityMinPrice: 850,
    professionalOnlyMinPrice: 850,
    minEmployees: 65,
  },
  current: {
    fullReplacementPEPMPrice,
    facilityPEPMPrice,
    professionalPEPMPrice,
    professionalOnlyPEPMPrice,
    facilityOONPercentOfSavingsPrice,
    facilityOONPEPM,
    fullReplacementMinPrice: minEmployees * fullReplacementPEPMPrice,
    facilityMinPrice: minEmployees * facilityPEPMPrice,
    professionalOnlyMinPrice: minEmployees * professionalOnlyPEPMPrice,
    minEmployees,
  },
};

export const defaultState = {
  planName: 'Test Plan',
  numberOfEmployees: 1000,
  startDate: '1/1/2017',
  endDate: '1/1/2018',
  inpatientBilled: 10000000,
  outpatientBilled: 4000000,
  facilityBilled: 14000000,
  professionalBilled: 3000000,
  totalBilled: 17000000,
  utilization: [],

  currentInpatientPlanResponsibility: 3995000,
  currentOutpatientPlanResponsibility: 1530000,
  currentProfessionalPlanResponsibility: 1445000,
  currentFacilityPlanResponsibility: 5525000,
  currentTotalPlanResponsibility: 6970000,

  currentInpatientMedicalSpend: 4700000,
  currentOutpatientMedicalSpend: 1800000,
  currentProfessionalMedicalSpend: 1700000,
  currentFacilityMedicalSpend: 6500000,
  currentTotalMedicalSpend: 8200000,
  currentNetworkDiscount: 50,
  currentPercentOfMedical: 85,
  currentVendorFeeFacilityPEPM: 8,
  currentVendorFeeProfessionalPEPM: 4,
  currentVendorFeePercentBilled: 0,
  currentVendorFeePercentSavings: 0,
  vendorPercentDisabled: true,

  inpatientMedicareRatio: 6,
  outpatientMedicareRatio: 5,
  professionalMedicareRatio: 3,
  sixdFacilityMedicareMultiple: 1.5,
  sixdProfessionalMedicareMultiple: 1.2,
  billedDataSource: dataSource.complete,
  planResponsibilityDataSource: dataSource.complete,
  medicalSpendDataSource: dataSource.complete,
  pricingSelection: pricingSelection.current,
  ...prices.current,
  utilizationLocationEstimate: 'claims',
  allowedNotAvailable: false,
  currentVendorFeeIsEstimate: false,
  generatingPDF: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case a.UPDATE_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
        ...getVendorFeeUpdates(action.payload.name, action.payload.value, state),
      };
    case a.UPDATE_TOTAL:
      return { ...state, ...getTotal(action.payload.name, state) };
    case a.UPDATE_DEPENDENT_NUMBERS:
      return updateDependentDataSources(action.payload.name, state);
    case a.UPDATE_MEDICARE_RATIOS:
      return { ...state, ...action.payload };
    case a.DOWNLOAD_REPORT_PENDING:
      return { ...state, generatingPDF: action.meta };
    case a.DOWNLOAD_REPORT_REJECTED:
      return { ...state, generatingPDF: '' };
    case a.DOWNLOAD_REPORT_FULFILLED:
      downloadReport(action.meta, state.planName, action.payload.data);
      return { ...state, generatingPDF: '' };
    case a.VIEW_REPORT:
      viewReport(action.payload.name, state);
      return state;
    case a.UPDATE_PRICING_SELECTION:
      return updatePricingSelection(state, action.payload.value);
    default:
      return state;
  }
};

export function updatePricingSelection(state, value) {
  return { ...state, pricingSelection: value, ...prices[value] };
}

export function updateDependentDataSources(name, prevState) {
  let updatedMedicalSpend = false;
  let nextState = { ...prevState };
  if (
    name.match(planResChanged) &&
    nextState.medicalSpendDataSource === dataSource.estimateFromPlanResponsibility
  ) {
    nextState = {
      ...nextState,
      ...getMedicalSpendFromPlanResponsibility(
        nextState.currentPercentOfMedical,
        nextState.currentTotalPlanResponsibility,
        nextState.currentFacilityPlanResponsibility,
        nextState.currentInpatientPlanResponsibility
      ),
    };
    updatedMedicalSpend = true;
  }
  if (
    name.match(billedChanged) &&
    nextState.medicalSpendDataSource === dataSource.estimateFromBilled
  ) {
    nextState = {
      ...nextState,
      ...getMedicalSpendFromBilled(
        nextState.currentNetworkDiscount,
        nextState.totalBilled,
        nextState.facilityBilled,
        nextState.inpatientBilled
      ),
    };
    updatedMedicalSpend = true;
  }
  if (updatedMedicalSpend || name.match(medSpendChanged)) {
    if (nextState.billedDataSource === dataSource.estimateFromMedicalSpend) {
      nextState = {
        ...nextState,
        ...getBilledFromMedicalSpend(
          nextState.currentNetworkDiscount,
          nextState.currentTotalMedicalSpend,
          nextState.currentFacilityMedicalSpend,
          nextState.currentInpatientMedicalSpend
        ),
      };
    }
    if (nextState.planResponsibilityDataSource === dataSource.estimateFromMedicalSpend) {
      nextState = {
        ...nextState,
        ...getPlanResponsibilityFromMedicalSpend(
          nextState.currentPercentOfMedical,
          nextState.currentTotalMedicalSpend,
          nextState.currentFacilityMedicalSpend,
          nextState.currentInpatientMedicalSpend
        ),
      };
    }
  }
  return nextState;
}
export function getBilledFromMedicalSpend(networkDiscount, total, facility, inpatient) {
  const percent = 1 / (1 - networkDiscount / 100.0); // inverse of paid percent
  const d = calculatePercents(total, facility, inpatient, percent);
  return {
    inpatientBilled: d.inpatient,
    outpatientBilled: d.outpatient,
    facilityBilled: d.facility,
    professionalBilled: d.professional,
    totalBilled: d.total,
  };
}
export function getMedicalSpendFromBilled(networkDiscount, total, facility, inpatient) {
  const percent = 1 - networkDiscount / 100.0;
  const d = calculatePercents(total, facility, inpatient, percent);
  return {
    currentInpatientMedicalSpend: d.inpatient,
    currentOutpatientMedicalSpend: d.outpatient,
    currentFacilityMedicalSpend: d.facility,
    currentProfessionalMedicalSpend: d.professional,
    currentTotalMedicalSpend: d.total,
  };
}
export function getMedicalSpendFromPlanResponsibility(
  currentPercentOfMedical,
  total,
  facility,
  inpatient
) {
  const percent = 1 / (currentPercentOfMedical / 100.0); // inverse of paid percent
  const d = calculatePercents(total, facility, inpatient, percent);
  return {
    currentInpatientMedicalSpend: d.inpatient,
    currentOutpatientMedicalSpend: d.outpatient,
    currentFacilityMedicalSpend: d.facility,
    currentProfessionalMedicalSpend: d.professional,
    currentTotalMedicalSpend: d.total,
  };
}
export function getPlanResponsibilityFromMedicalSpend(
  currentPercentOfMedical,
  total,
  facility,
  inpatient
) {
  const d = calculatePercents(total, facility, inpatient, currentPercentOfMedical / 100.0);
  return {
    currentInpatientPlanResponsibility: d.inpatient,
    currentOutpatientPlanResponsibility: d.outpatient,
    currentFacilityPlanResponsibility: d.facility,
    currentProfessionalPlanResponsibility: d.professional,
    currentTotalPlanResponsibility: d.total,
  };
}
export function calculatePercents(total, facility, inpatient, percent) {
  const totalResult = Math.round(parseFloat(total * percent));
  const facilityResult = Math.round(parseFloat(facility * percent));
  const inpatientResult = Math.round(parseFloat(inpatient * percent));
  return {
    inpatient: inpatientResult,
    outpatient: facilityResult - inpatientResult,
    facility: facilityResult,
    professional: totalResult - facilityResult,
    total: totalResult,
  };
}
function getVendorFeeUpdates(name, value, state) {
  const newState = { ...state, [name]: value };
  if (name.indexOf('currentVendorFee') >= 0) {
    const vendorPercentDisabled =
      newState.currentVendorFeeFacilityPEPM !== 0 &&
      newState.currentVendorFeeProfessionalPEPM !== 0;
    return {
      vendorPercentDisabled,
      currentVendorFeePercentBilled: vendorPercentDisabled
        ? 0
        : newState.currentVendorFeePercentBilled,
      currentVendorFeePercentSavings: vendorPercentDisabled
        ? 0
        : newState.currentVendorFeePercentSavings,
    };
  }
  return {};
}
export function getTotal(name, state) {
  switch (name) {
    case 'inpatientBilled':
    case 'outpatientBilled':
      return {
        facilityBilled: state.inpatientBilled + state.outpatientBilled,
        totalBilled: state.inpatientBilled + state.outpatientBilled + state.professionalBilled,
      };
    case 'professionalBilled':
      return {
        totalBilled: state.inpatientBilled + state.outpatientBilled + state.professionalBilled,
      };
    case 'facilityBilled': {
      const f = facilitySum(state.facilityBilled, state.professionalBilled);
      return { totalBilled: f.total, inpatientBilled: f.inpatient, outpatientBilled: f.outpatient };
    }
    case 'totalBilled': {
      const t = totalSum(state.totalBilled);
      return {
        inpatientBilled: t.inpatient,
        outpatientBilled: t.outpatient,
        facilityBilled: t.facility,
        professionalBilled: t.professional,
      };
    }
    case 'currentInpatientMedicalSpend':
    case 'currentOutpatientMedicalSpend':
      return {
        currentFacilityMedicalSpend:
          state.currentInpatientMedicalSpend + state.currentOutpatientMedicalSpend,
        currentTotalMedicalSpend:
          state.currentInpatientMedicalSpend +
          state.currentOutpatientMedicalSpend +
          state.currentProfessionalMedicalSpend,
      };
    case 'currentProfessionalMedicalSpend':
      return {
        currentTotalMedicalSpend:
          state.currentInpatientMedicalSpend +
          state.currentOutpatientMedicalSpend +
          state.currentProfessionalMedicalSpend,
      };
    case 'currentFacilityMedicalSpend': {
      const f2 = facilitySum(
        state.currentFacilityMedicalSpend,
        state.currentProfessionalMedicalSpend
      );
      return {
        currentTotalMedicalSpend: f2.total,
        currentInpatientMedicalSpend: f2.inpatient,
        currentOutpatientMedicalSpend: f2.outpatient,
      };
    }
    case 'currentTotalMedicalSpend': {
      const t2 = totalSum(state.currentTotalMedicalSpend);
      return {
        currentInpatientMedicalSpend: t2.inpatient,
        currentOutpatientMedicalSpend: t2.outpatient,
        currentFacilityMedicalSpend: t2.facility,
        currentProfessionalMedicalSpend: t2.professional,
      };
    }
    case 'currentInpatientPlanResponsibility':
    case 'currentOutpatientPlanResponsibility':
      return {
        currentFacilityPlanResponsibility:
          state.currentInpatientPlanResponsibility + state.currentOutpatientPlanResponsibility,
        currentTotalPlanResponsibility:
          state.currentInpatientPlanResponsibility +
          state.currentOutpatientPlanResponsibility +
          state.currentProfessionalPlanResponsibility,
      };
    case 'currentProfessionalPlanResponsibility':
      return {
        currentTotalPlanResponsibility:
          state.currentInpatientPlanResponsibility +
          state.currentOutpatientPlanResponsibility +
          state.currentProfessionalPlanResponsibility,
      };
    case 'currentFacilityPlanResponsibility': {
      const f1 = facilitySum(
        state.currentFacilityPlanResponsibility,
        state.currentProfessionalPlanResponsibility
      );
      return {
        currentTotalPlanResponsibility: f1.total,
        currentInpatientPlanResponsibility: f1.inpatient,
        currentOutpatientPlanResponsibility: f1.outpatient,
      };
    }
    case 'currentTotalPlanResponsibility': {
      const t1 = totalSum(state.currentTotalPlanResponsibility);
      return {
        currentInpatientPlanResponsibility: t1.inpatient,
        currentOutpatientPlanResponsibility: t1.outpatient,
        currentFacilityPlanResponsibility: t1.facility,
        currentProfessionalPlanResponsibility: t1.professional,
      };
    }
    default:
      return {};
  }
}
export function facilitySum(facility, professional) {
  const outpatient = parseInt(facility * 0.59, 10); // 59% outpatient
  return {
    total: parseInt(facility + professional, 10),
    inpatient: facility - outpatient, // 41% inpatient
    outpatient,
  };
}
export function totalSum(total) {
  const inpatient = parseInt(total * 0.24, 10); // 24% inpatient
  const outpatient = parseInt(total * 0.342, 10); // 34.2% outpatient
  return {
    inpatient,
    outpatient,
    facility: inpatient + outpatient,
    professional: total - inpatient - outpatient,
  };
}
export function downloadReport(reportName, planName, result) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(result);
  link.download = `${planName} - 6 Degrees Health ${reportName}.pdf`;
  link.click();
}
export function viewReport(name, state) {
  const form = document.createElement('form');
  form.style.visibility = 'hidden';
  form.method = 'POST';
  form.action = `/api/comparison-${name}?format=html`;
  const input = document.createElement('input');
  input.name = 'JSON';
  input.value = JSON.stringify(state);
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}
