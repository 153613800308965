import React, { ChangeEventHandler, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { previousPage, setUserInfo } from '@apps/shared/src/register/actions';
import { RootState } from '@apps/shared/src/rootStateType';
import { UserInfo } from '@apps/shared/src/register/types/register';
import InputBox from '../shared/InputBox';
import { IncompleteTheme } from '../shared/types/theme';

const styles = makeStyles((theme: IncompleteTheme) => ({
  signinBox: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
  },
  signinText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    letterSpacing: '0em',
    marginBottom: '1rem',
    textAlign: 'left',
    color: '#37383D',
  },

  label: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '0',
  },
}));

type RegisterState = RootState['register'];
type StateProps = {
  userInfo: RegisterState['userInfo'];
};

const mapStateToProps = ({ register }: RootState): StateProps => ({
  userInfo: register.userInfo,
});

type DispatchProps = {
  setUserInfo: typeof setUserInfo;
  previousPage: typeof previousPage;
};

const mapDispatchToProps = { setUserInfo, previousPage };

type parentProps = {
  onNext: () => void;
  onTextInputEmptyChange: (isEmpty: boolean) => void;
  setInputEmpty: (isEmpty: boolean) => void;
};

type Props = StateProps & DispatchProps & parentProps;

const EmailPage = ({
  userInfo,
  setUserInfo,
  onNext,
  onTextInputEmptyChange,
  previousPage,
  setInputEmpty,
}: Props): JSX.Element => {
  const classes = styles();
  const history = useHistory();

  useEffect(() => {
    onTextInputEmptyChange(userInfo.email.trim() === '');
  }, [onTextInputEmptyChange, userInfo.email]);

  const scroll = (e: React.MouseEvent<HTMLElement>): void => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleChange =
    (key: keyof UserInfo): ChangeEventHandler<HTMLInputElement> =>
    (e): void => {
      const newvalue = e.currentTarget.value.toLowerCase();
      setUserInfo(key, newvalue);
      onTextInputEmptyChange(newvalue.trim() === '');
    };

  return (
    <div className={classes.signinBox}>
      <Typography className={classes.signinText}>Register</Typography>

      <div>
        <InputBox
          name="email"
          label="Email"
          type="email"
          onClick={scroll}
          onChangeEvent={handleChange('email')}
          value={userInfo.email}
          validators={['required', 'isEmail']}
          errorMessages={['This field is required', 'Must be valid email']}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPage);
