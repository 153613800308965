import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import ExcludedIcon from '@material-ui/icons/VisibilityOff';
import { splitCamelCase } from '@apps/shared/src/utilities';
import { groupExcludedProviders } from './benchmarkUtilities';
import InfoTooltip from '../shared/InfoTooltip';
import PopoverButton from '../shared/PopoverButton';
import { excludedProvidersType } from '../shared/types/propTypes';

const styles = theme => ({
  excludedPopover: {
    margin: '1em',
  },
  header: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
  },
  excludedIcon: {
    marginRight: '.5em',
    color: theme.excludedProviders.excludedIcon.color,
  },
  excludedButton: {
    maxWidth: '3em',
    transition: 'max-width .75s',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      maxWidth: '20em',
    },
  },
  ul: {
    listStyle: 'none',
    margin: '0',
    padding: '0 1em',
    color: theme.excludedProviders.provider.color,
  },
  providerBlock: {
    marginTop: '1em',
  },
  title: {
    marginBottom: '0.5em',
  },
});

const exclusionGroupDetails = {
  lowData: {
    toolTip:
      'Providers with limited reported claim data are excluded to ensure the anonymity of patients',
    titlePrefix: 'To protect personal health information,',
  },
  noData: {
    toolTip:
      'A Provider with few Medicare reimbursable claims is not reportable, and thereby excluded',
    titlePrefix: 'Due to lack of data,',
  },
  excluded: {
    toolTip:
      'Providers whose financial model is substantially different from traditional providers are excluded as they make side-by-side analysis difficult. Such facilities can include HMOs, Acute Care Centers, Native American facilities, etc.',
    titlePrefix: 'Due to facility type,',
  },
};

class ExcludedProviders extends React.Component {
  render() {
    const { excludedProviders, classes } = this.props;
    const qty = num => (num <= 1 ? '' : `${num} `);
    const pluralize = (num, verb = false) => {
      const singular = num === 1;
      const plural = singular ? '' : 's';
      if (!verb) return plural;
      if (singular) return `${plural} has`;
      return `${plural} have`;
    };
    const title = `Excluded Provider${pluralize(excludedProviders.length)} (${
      excludedProviders.length
    })`;

    const providerGroups = groupExcludedProviders(excludedProviders);

    return (
      <PopoverButton
        title={title}
        visible={Boolean(excludedProviders && excludedProviders.length > 0)}
        content={
          <section className={classes.excludedPopover}>
            <h2 className={classes.header}>
              <ExcludedIcon className={classes.excludedIcon} />
              {title}
            </h2>
            {Object.keys(providerGroups).map(exclusionType => {
              const providers = providerGroups[exclusionType];
              const providerCount = providers.length;
              const toolTip = exclusionGroupDetails[exclusionType]?.toolTip || '';
              const titlePrefix =
                exclusionGroupDetails[exclusionType]?.titlePrefix ||
                `${splitCamelCase(exclusionType)}:`;

              return (
                <div key={exclusionType} className={classes.providerBlock}>
                  <InfoTooltip tooltip={toolTip}>
                    <div className={classes.title}>
                      {titlePrefix} the following {qty(providerCount)} provider
                      {pluralize(providerCount, true)} been excluded:
                    </div>
                  </InfoTooltip>
                  <ul className={classes.ul}>
                    {providers.map(provider => (
                      <li key={provider}>{provider}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </section>
        }
        Icon={ExcludedIcon}
      />
    );
  }
}

ExcludedProviders.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  excludedProviders: excludedProvidersType,
};
ExcludedProviders.defaultProps = {
  excludedProviders: [],
};

export default withStyles(styles)(ExcludedProviders);
