import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import utils from './utils';
import PowerBI from './PowerBI';
import NavPanel from './navigation/NavPanel';
import { contentHeight } from '../shared/theme';
import AppLayout from '../shared/AppLayout';
import PageSpecificHeader from '../shared/PageSpecificHeader';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '70vh',
  },
});

export default function Reports(): JSX.Element {
  const classes = useStyles();

  return (
    <AppLayout>
      <PageSpecificHeader
        title="Reports"
        description="View filtered reports by selecting the case type"
      />
      <div className={classes.container}>
        <NavPanel />
        <PowerBI />
      </div>
    </AppLayout>
  );
}
