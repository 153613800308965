import a, { AddPlanActions, isAddPlanResponse } from '../addPlan/types/addPlanActions';
import { Plan, PlanState } from './types/plan';
import p, { PlanActionTypes } from './types/planActions';

export function makeDefaultPlanState(): PlanState {
  return {
    plans: [],
    isPlansLoading: false,
    hasLoadedPlans: false,
    selectedPlan: undefined,
  };
}

function initializePlanState(): PlanState {
  const name = localStorage.getItem('selectedPlanName');
  const id = localStorage.getItem('selectedPlanID');
  const displayMediviMessage = localStorage.getItem('selectedPlanDisplayPlanMessage') === 'true';
  const mediviMessage = localStorage.getItem('selectedPlanMessage') || '';
  const defaultPlanState = makeDefaultPlanState();

  if (!name || !id) {
    scrubSelectedPlanFromStorage();
    return { ...defaultPlanState };
  }

  return {
    ...defaultPlanState,
    selectedPlan: { name, id, displayMediviMessage, mediviMessage },
  };
}

export function saveSelectedPlanToStorage(plan?: Plan): void {
  if (!plan) {
    scrubSelectedPlanFromStorage();
    return;
  }
  localStorage.setItem('selectedPlanName', plan.name);
  localStorage.setItem('selectedPlanID', plan.id);
  localStorage.setItem('selectedPlanDisplayPlanMessage', `${plan.displayMediviMessage}`);
  localStorage.setItem('selectedPlanMessage', plan.mediviMessage);
}

function scrubSelectedPlanFromStorage(): void {
  localStorage.removeItem('selectedPlanName');
  localStorage.removeItem('selectedPlanID');
  localStorage.removeItem('selectedPlanDisplayPlanMessage');
  localStorage.removeItem('selectedPlanMessage');
}

function getSelectedPlan(plans: Plan[], currentSelectedPlan?: Plan): Plan | undefined {
  switch (plans.length) {
    case 0:
      return undefined;
    case 1:
      return plans[0];
    default:
      if (currentSelectedPlan) return plans.find(p => p.id === currentSelectedPlan.id);
      return undefined;
  }
}

const planReducer = (
  state: PlanState = initializePlanState(),
  action: PlanActionTypes | AddPlanActions
): PlanState => {
  switch (action.type) {
    case p.GET_PLANS_PENDING:
    case p.GET_PLANS_RTK_PENDING: {
      return { ...state, isPlansLoading: true };
    }
    case p.GET_PLANS_REJECTED:
    case p.GET_PLANS_RTK_REJECTED: {
      return { ...state, isPlansLoading: false };
    }
    case p.GET_PLANS_FULFILLED:
    case p.GET_PLANS_RTK_FULFILLED: {
      const plans = action.payload.data;
      let { selectedPlan } = state;
      selectedPlan = getSelectedPlan(plans, selectedPlan);
      saveSelectedPlanToStorage(selectedPlan);
      return {
        ...state,
        plans,
        isPlansLoading: false,
        hasLoadedPlans: true,
        selectedPlan,
      };
    }

    case p.SET_PLAN_STATE: {
      saveSelectedPlanToStorage(action.payload);
      return { ...state, selectedPlan: action.payload };
    }

    case p.RESET_PLAN_PAGE: {
      return { ...initializePlanState() };
    }

    case a.ADD_PLAN_PENDING: {
      return { ...state, isPlansLoading: true };
    }
    case a.ADD_PLAN_REJECTED: {
      return { ...state, isPlansLoading: false };
    }
    case a.ADD_PLAN_FULFILLED: {
      const newState = {
        ...state,
        isPlansLoading: false,
        hasLoadedPlans: true,
      };

      if (isAddPlanResponse(action.payload.data)) {
        const { plans } = action.payload.data;
        let { selectedPlan } = state;
        selectedPlan = getSelectedPlan(plans, selectedPlan);
        saveSelectedPlanToStorage(selectedPlan);
        newState.plans = plans;
        newState.selectedPlan = selectedPlan;
      }

      return newState;
    }

    default:
      return state;
  }
};

export default planReducer;
