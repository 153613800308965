import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import LoadingIcon from '@apps/shared/src/components/LoadingIcon';

import { autocompleteOptionsType } from '../shared/types/propTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    overflowY: 'scroll',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  type: {
    color: theme.autocompleteSelect.color,
    paddingLeft: '1em',
  },
  textBox: {
    '& .MuiOutlinedInput-input': {
      padding: '.6rem',
    },
  },
  dropdown: {
    maxWidth: '100vw',
    overflowY: 'auto',
  },
  spinner: {
    position: 'absolute',
    top: 0,
    right: '-0.25em',
    ...theme.loadingSpinner,
  },
  inputAdornment: {
    color: theme.autocompleteSelect.icon.color,
  },
});

class AutocompleteSelect extends React.Component {
  popperNode;

  itemToString = item => {
    return item ? item.name : '';
  };

  handleKeyDown = e => {
    if (e.which === 27 && this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    const {
      isOpen,
      options,
      selections,
      value,
      onSelect,
      onChange,
      onClick,
      onBlur,
      placeholder,
      classes,
      isLoading,
    } = this.props;
    const minWidth = this.popperNode && this.popperNode.clientWidth + 24; // input + search icon width
    const maxHeight = `calc(100vh - ${
      this.popperNode
        ? this.popperNode.getBoundingClientRect().top + this.popperNode.clientHeight
        : 0
    }px)`;
    return (
      <div className={classes.root}>
        <Downshift inputValue={value} onSelect={onSelect} itemToString={this.itemToString}>
          {({ getInputProps, getItemProps, inputValue, highlightedIndex }) => {
            return (
              <div className={classes.container}>
                <TextField
                  InputProps={{
                    ...getInputProps({
                      onChange,
                      onBlur,
                      onClick,
                      placeholder,
                      onKeyDown: this.handleKeyDown,
                      value: inputValue,
                      inputRef: node => {
                        this.popperNode = node;
                      },
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <LoadingIcon
                            isLoading={isLoading && isOpen}
                            className={classes.spinner}
                            size="2em"
                          >
                            <SearchIcon />
                          </LoadingIcon>
                        </InputAdornment>
                      ),
                    }),
                  }}
                  variant="outlined"
                  fullWidth
                  className={classes.textBox}
                />
                <Popper
                  open={isOpen}
                  anchorEl={this.popperNode}
                  style={{ minWidth }}
                  placement="bottom-start"
                  modifiers={{
                    flip: { enabled: false },
                    preventOverflow: { enabled: true, boundariesElement: 'window' },
                  }}
                >
                  <Paper square className={classes.dropdown} style={{ maxHeight }} elevation={2}>
                    {Array.isArray(options) &&
                      options
                        .filter(i => !selections.find(j => j.name === i.name && j.type === i.type))
                        .map((option, index) => (
                          <MenuItem
                            key={JSON.stringify(option)}
                            {...getItemProps({
                              item: option,
                              component: 'div',
                              dense: true,
                              selected: highlightedIndex === index, // allows for keyboard navigation
                              className: classes.menuItem,
                            })}
                          >
                            <Typography variant="body2" noWrap>
                              {option.name}
                            </Typography>
                            <Typography variant="body2" className={classes.type}>
                              {`${option.type} (${option.matches})`}
                            </Typography>
                          </MenuItem>
                        ))}
                  </Paper>
                </Popper>
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}

AutocompleteSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: autocompleteOptionsType,
  placeholder: PropTypes.string,
  selections: autocompleteOptionsType,
  value: PropTypes.string.isRequired,
};
AutocompleteSelect.defaultProps = {
  isLoading: false,
  placeholder: '',
  options: [],
  selections: [],
};

export default withStyles(styles)(AutocompleteSelect);
