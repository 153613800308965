import { models } from 'powerbi-client';
import * as t from './reportActionTypes';
import {
  LastPublished,
  MonthlyRepricingState,
  NavState,
  PageDisplayNameMap,
  ReportState,
  ValidMonthlyRepricingYear,
} from './types';
import {
  caseStatusPages,
  determineReportYears,
  makePageMap,
  setMonthlyRepricingFilters,
} from './utils';

function defaultReportState(): ReportState {
  return {
    config: {
      type: 'report',
      tokenType: models.TokenType.Embed,
      settings: {
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToPage,
        },
        panes: {
          pageNavigation: {
            visible: false,
          },
        },
      },
    },
    selectedReportName: 'case-status',
    selectedReportPage: 'Balance Bill Closed',
    isEmbedFetched: false,
    nav: emptyNavState(),
  };
}

export function defaultMonthlyRepricingState(lastPublished: LastPublished): MonthlyRepricingState {
  return {
    lastPublished,
    reportYears: determineReportYears(lastPublished.year),
    selectedYear: lastPublished.year,
    selectedMonth: lastPublished.month,
  };
}

export function emptyNavState(): NavState {
  return {
    options: [],
  };
}

function defaultMonthlyRepricingNavState(
  lastPublishedYear: ValidMonthlyRepricingYear,
  pageDisplayNames: PageDisplayNameMap
): NavState {
  return {
    pageDisplayNames,
    options: determineReportYears(lastPublishedYear),
    openOption: lastPublishedYear,
  };
}

function defaultCaseStatusNavState(pageDisplayNames: PageDisplayNameMap): NavState {
  return { pageDisplayNames, options: ['active', 'closed'], openOption: 'closed' };
}

export default function reportReducer(
  state: ReportState = defaultReportState(),
  action: t.ReportActionTypes
): ReportState {
  switch (action.type) {
    case t.GET_POWERBI_EMBED_REJECTED:
    case t.GET_POWERBI_EMBED_PENDING:
      return {
        ...state,
        config: {
          ...state.config,
          accessToken: undefined,
          embedUrl: undefined,
          pageName: undefined,
        },
      };
    case t.GET_POWERBI_EMBED_FULFILLED: {
      const { report, pages, lastPublished } = action.payload.data;
      if (report === undefined) {
        return state;
      }

      let monthlyRepricing, nav, defaultReportPage;
      let filters: models.IFilter[] = [];
      const pageDisplayNames = makePageMap(pages);

      if (lastPublished) {
        monthlyRepricing = defaultMonthlyRepricingState(lastPublished);
        filters = setMonthlyRepricingFilters(lastPublished.year, lastPublished.month);
        nav = defaultMonthlyRepricingNavState(lastPublished.year, pageDisplayNames);
        defaultReportPage = 'Monthly';
      } else {
        nav = defaultCaseStatusNavState(pageDisplayNames);
        defaultReportPage = caseStatusPages.balanceBillClosed;
      }

      return {
        ...state,
        config: {
          ...state.config,
          accessToken: report.accessToken,
          embedUrl: report.embedUrl,
          filters,
          pageName: pageDisplayNames[defaultReportPage],
        },
        monthlyRepricing,
        isEmbedFetched: true,
        nav,
        selectedReportPage: defaultReportPage,
      };
    }
    case t.SELECT_REPORT: {
      return {
        ...state,
        selectedReportName: action.payload,
        selectedReportPage: '',
        isEmbedFetched: false,
        config: { ...state.config, pageName: undefined },
        monthlyRepricing: undefined,
        nav: emptyNavState(),
      };
    }
    case t.SELECT_PAGE: {
      const { payload } = action;
      const { pageDisplayNames } = state.nav;
      const pageName = pageDisplayNames && pageDisplayNames[payload];
      return {
        ...state,
        config: { ...state.config, pageName },
        selectedReportPage: payload,
      };
    }
    case t.SELECT_MONTHLY_REPRICING_YEAR: {
      const selectedYear = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          filters: setMonthlyRepricingFilters(selectedYear, undefined),
        },
        monthlyRepricing: { ...state.monthlyRepricing, selectedYear, selectedMonth: undefined },
      };
    }
    case t.SELECT_MONTHLY_REPRICING_MONTH: {
      const selectedMonth = action.payload;
      return {
        ...state,
        config: {
          ...state.config,
          filters: setMonthlyRepricingFilters(
            state.nav.openOption as ValidMonthlyRepricingYear,
            selectedMonth
          ),
        },
        monthlyRepricing: {
          ...state.monthlyRepricing,
          selectedMonth,
          selectedYear: state.nav.openOption as ValidMonthlyRepricingYear,
        },
      };
    }
    case t.SET_NAV_OPEN_OPTION: {
      return { ...state, nav: { ...state.nav, openOption: action.payload } };
    }
    default:
      return state;
  }
}
