import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';

const buttonStyles = {
  borderRadius: '.5rem',
  padding: '.5rem 1rem',
  margin: '0.5rem',
};

const iconStyles = {
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles({
  dialogActions: {
    justifyContent: 'center',
  },
  dialogContentText: {
    textAlign: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '1rem',
  },
  cancelBtn: {
    ...buttonStyles,
    width: '45%',
  },
  successBtn: {
    ...buttonStyles,
    width: '100%',
    padding: '0.6rem 0',
    background: '#F97140',
    color: 'white',
  },
  successIcon: {
    ...iconStyles,
    width: '40%',
    height: '40%',
  },
  modalIcon: {
    ...iconStyles,
    justifyContent: 'center',
    alignItems: 'center',
    animation: '$rotate 2s infinite linear',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

type Props = {
  message: string;
  open: boolean;
};

export default function LoaderDialog({ message, open }: Props): JSX.Element {
  const classes = useStyles();

  const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      padding: '2rem 1rem',
    },
  });

  return (
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.modalIcon}>
          <img src="./images/case-submission-loader.png" alt="Case Submission Loader" />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
          {message}
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
}
