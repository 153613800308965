import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button';

import DocumentationNav from './DocumentationNav';
import { roles as mediviRoles } from './globals';

const useStyles = makeStyles(theme => ({
  navButton: theme.header.navButton,
  active: theme.header.navButtonActive,
}));

export default function NavMenu({ currentUser }) {
  const classes = useStyles();

  const roles = (currentUser && currentUser.roles) || [];
  const hasAdminRole = roles.indexOf(mediviRoles.admin) > -1;
  const hasAnalysisRole = roles.indexOf(mediviRoles.analysis) > -1;
  // const hasResearchRole = roles.indexOf(mediviRoles.code) > -1;
  const hasReadRole = roles.indexOf(mediviRoles.read) > -1;
  const hasBenchmarkRole = roles.indexOf(mediviRoles.benchmarks) > -1;
  const hasMobileReadRole = roles.indexOf(mediviRoles.mobileRead) > -1;
  const hasReportRole = roles.indexOf(mediviRoles.reports) > -1;
  const hasInviteRole = roles.indexOf(mediviRoles.invite) > -1;
  // const hasMediviTesterRole = roles.indexOf(mediviRoles.tester) > -1;

  return (
    <>
      {hasBenchmarkRole && (
        <Button
          component={NavLink}
          to="/benchmarks"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Benchmarks
        </Button>
      )}
      {hasMobileReadRole && (
        <Button
          component={NavLink}
          to="/provider-search"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Provider Search
        </Button>
      )}
      {/* {hasResearchRole && (
        <Button
          component={NavLink}
          to="/code"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Code Research
        </Button>
      )} */}
      {(hasAdminRole || hasInviteRole) && (
        <Button
          component={NavLink}
          to="/users"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Users
        </Button>
      )}
      {hasAnalysisRole && (
        <Button
          component={NavLink}
          to="/analysis"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Market Analysis
        </Button>
      )}
      {hasReportRole && (
        <Button
          component={NavLink}
          to="/reports"
          activeClassName={classes.active}
          className={classes.navButton}
        >
          Reports
        </Button>
      )}
      {(hasReadRole || hasMobileReadRole) && (
        <DocumentationNav
          hasBenchmarksAccess={hasReadRole}
          hasProviderSearchAccess={hasMobileReadRole}
        />
      )}
    </>
  );
}

NavMenu.propTypes = {
  currentUser: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
