import React from 'react';

import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { memberIntro } from './content';
import useAboveTheFoldStyles from './useAboveTheFoldStyles';
import MediviMobile from './MediviMobile';

function MediVIForMembers(userType: any): JSX.Element {
  const classes = useAboveTheFoldStyles('member');
  // eslint-disable-next-line global-require
  const laptop = require('./assets/PSLaptop.png') as string;

  return (
    <div className={classes.heroImg}>
      <section className={classes.allContent}>
        <img
          className={classes.laptop}
          src={laptop}
          alt="Laptop with screenshot of ProviderSearch"
        />
        <div className={classes.allText}>
          <Typography variant="h4" className={classnames(classes.heading, classes.typeOnDark)}>
            {memberIntro.headline}
          </Typography>
          <div className={classes.subContent}>
            <Typography className={classnames(classes.subHeading, classes.typeOnDark)}>
              {memberIntro.subHeading}
            </Typography>
          </div>
          <Button
            component={Link}
            to="/register"
            variant="contained"
            color="secondary"
            size="large"
            className={classes.registerButton}
            disableElevation
          >
            Register now
          </Button>
        </div>
      </section>
      <MediviMobile userType={userType.userType} />
    </div>
  );
}

export default MediVIForMembers;
