import r, { VerifyUserCredentialsFulfilled } from '../register/types/registerActions';
import a, { AddPlanActionTypes, isCredentialsInUseResponse } from './types/addPlanActions';
import { AddPlanState } from './types/addPlanTypes';

export const defaultAddPlanState: AddPlanState = {
  isAddingPlan: false,
  addPlanSuccess: false,
  planInfo: {
    memberID: '',
    groupID: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    zip: '',
  },
  existingEmail: undefined,
};

const addPlanReducer = (
  state: AddPlanState = defaultAddPlanState,
  action: AddPlanActionTypes | VerifyUserCredentialsFulfilled
): AddPlanState => {
  switch (action.type) {
    case a.ADD_PLAN_PENDING: {
      return {
        ...state,
        isAddingPlan: true,
        existingEmail: undefined,
      };
    }
    case a.ADD_PLAN_FULFILLED: {
      const newState = { ...state, isAddingPlan: false, addPlanSuccess: true };
      if (isCredentialsInUseResponse(action.payload.data)) {
        newState.existingEmail = action.payload.data.existingEmail;
      }
      return newState;
    }
    case a.ADD_PLAN_REJECTED: {
      return {
        ...state,
        isAddingPlan: false,
        existingEmail: undefined,
      };
    }
    case a.SET_PLAN_INFO: {
      const { key, value } = action.meta;
      return {
        ...state,
        planInfo: { ...state.planInfo, [key]: value },
        existingEmail: undefined,
      };
    }
    case a.RESET_ADD_PLAN: {
      return defaultAddPlanState;
    }

    case a.CLOSE_CREDENTIALS_IN_USE_DIALOG: {
      return { ...state, existingEmail: undefined };
    }

    case r.VERIFY_USER_CREDENTIALS_FULFILLED: {
      if (isCredentialsInUseResponse(action.payload.data)) {
        return { ...state, existingEmail: action.payload.data.existingEmail };
      }
      return state;
    }

    default:
      return state;
  }
};

export default addPlanReducer;
