import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import useProviderTypeCounts from '@apps/shared/src/providerSearch/api/useProviderTypeCounts';
import {
  ProviderTypesMap,
  ProviderTypeScrollPosition,
  ProviderTypeCounts,
} from '@apps/shared/src/providerSearch/types/providerSearch';
import { setProviderType, setScrollPosition } from '@apps/shared/src/providerSearch/actions';
import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  header2: theme.sidebar.headerText2,
  title: {
    marginBottom: '0.25rem',
  },
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
  menuItem: {
    ...theme.selectMenuItem,
    display: 'list-item',
    fontWeight: 'bold',
  },
  menuItemText: {
    display: 'block',
  },
  menuItemSubText: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 'lighter',
    paddingLeft: '0.5rem',
  },
}));

function getProviderCountText(numProviders: number): string {
  return `${numProviders} ${numProviders === 1 ? ' Provider' : ' Providers'}`;
}

function getSpecialtyCountText(
  category: string,
  providerTypesMap: ProviderTypesMap,
  counts: ProviderTypeCounts
): string {
  let numSpecialties = 0;
  const allTitles = providerTypesMap[category];
  for (const title of allTitles) {
    if (counts.titles[title] > 0) {
      numSpecialties++;
    }
  }
  return ` in ${numSpecialties} ${numSpecialties === 1 ? ' Specialty' : ' Specialties'}`;
}

function findProviderCategoryFromTitle(
  providerTypesMap: ProviderTypesMap,
  keyTitle: string
): string {
  for (const category in providerTypesMap) {
    if (providerTypesMap.hasOwnProperty(category))
      for (const title of providerTypesMap[category]) {
        if (title === keyTitle) return category;
      }
  }
  return '';
}

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  providerTypesMap: providerSearch.providerTypesMap,
  scrollPosition: providerSearch.scrollPosition,
  selectedProviderType: providerSearch.selectedProviderType,
  isLoading: providerSearch.isLoadingProviderList,
});

type StateProps = {
  providerTypesMap: ProviderTypesMap;
  scrollPosition: ProviderTypeScrollPosition;
  selectedProviderType: string;
  isLoading: boolean;
};

const mapDispatchToProps = {
  setProviderType,
  setScrollPosition,
};

type Props = StateProps & typeof mapDispatchToProps;

const defaultProviderCategory = 'All Types';
const defaultProviderTitle = 'All Specialties';

export function ProviderTypeFilter({
  providerTypesMap,
  setProviderType,
  setScrollPosition,
  scrollPosition,
  selectedProviderType,
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();
  const counts = useProviderTypeCounts();
  const [providerCategory, setProviderCategory] = useState(defaultProviderCategory);
  const [providerTitle, setProviderTitle] = useState(defaultProviderTitle);

  useEffect(() => {
    if (selectedProviderType === '') {
      setProviderCategory(defaultProviderCategory);
      setProviderTitle(defaultProviderTitle);
      return;
    }
    if (scrollPosition.isTitle) {
      setProviderCategory(findProviderCategoryFromTitle(providerTypesMap, selectedProviderType));
      setProviderTitle(selectedProviderType);
      return;
    }
    setProviderCategory(selectedProviderType);
    setProviderTitle(defaultProviderTitle);
  }, [selectedProviderType, scrollPosition, providerTypesMap]);

  const categories = Object.keys(providerTypesMap).filter(
    category => counts.categories[category] > 0
  );
  const titles = providerTypesMap[providerCategory]?.filter(title => counts.titles[title] > 0);

  const handleProviderCategoryChange: SelectProps['onChange'] = e => {
    const category = e.target.value as string;
    if (category === defaultProviderCategory) {
      setProviderType('');
      setScrollPosition({ category: '', isTitle: false });
      return;
    }
    setProviderType(category);
    setScrollPosition({ category, isTitle: false });
  };

  const handleProviderTitleChange: SelectProps['onChange'] = e => {
    const title = e.target.value as string;
    if (title === defaultProviderTitle) {
      setProviderType(providerCategory);
      setScrollPosition({ category: providerCategory, isTitle: false });
      return;
    }
    setProviderType(title);
    setScrollPosition({ category: title, isTitle: true });
  };

  const renderMenuItemValue: SelectProps['renderValue'] = selected => selected as string;
  const providerCategoryItems = categories.map(category => (
    <MenuItem key={category} value={category} className={classes.menuItem}>
      <span className={classes.menuItemText}>{category}</span>
      <span className={classes.menuItemSubText}>
        {`${getProviderCountText(counts.categories[category])} ${getSpecialtyCountText(
          category,
          providerTypesMap,
          counts
        )}`}
      </span>
    </MenuItem>
  ));
  const providerTitleItems = titles?.map(title => (
    <MenuItem key={title} value={title} className={classes.menuItem}>
      <span className={classes.menuItemText}>{title}</span>
      <span className={classes.menuItemSubText}>{getProviderCountText(counts.titles[title])}</span>
    </MenuItem>
  ));

  return (
    <div>
      <Typography className={classes.header}>Provider Type</Typography>
      <Select
        labelId="provider-category"
        id="provider-type-search"
        value={providerCategory}
        onChange={handleProviderCategoryChange}
        disableUnderline
        disabled={isLoading}
        renderValue={renderMenuItemValue}
        className={classes.Select}
      >
        <MenuItem value={defaultProviderCategory} className={classes.menuItem}>
          {defaultProviderCategory}
        </MenuItem>
        {providerCategoryItems}
      </Select>
      {providerCategory !== defaultProviderCategory && (
        <>
          <Typography className={classes.header2}>Provider Specialty</Typography>
          <Select
            labelId="provider-title"
            id="provider-specialty-search"
            value={providerTitle}
            onChange={handleProviderTitleChange}
            disableUnderline
            disabled={isLoading}
            renderValue={renderMenuItemValue}
          >
            <MenuItem value={defaultProviderTitle} className={classes.menuItem}>
              {defaultProviderTitle}
            </MenuItem>
            {providerTitleItems}
          </Select>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderTypeFilter);
