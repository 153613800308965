import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { UserState } from '@apps/shared/src/auth/types';
import { colors, hexToRgba } from '@apps/shared/src/style';
import Header from './Header';
import Footer from './Footer';
import MediVIForPartners from './MediVIForPartners';
import MediVIForMembers from './MediVIForMembers';
import { roles as mediviRoles } from '../shared/globals';
import { RootState } from '../store';

function getRedirectLocation(userRoles?: string[]): string {
  return '/provider-search';
}

function mapStateToProps({ user }: RootState): StateProps {
  return {
    isLoggedIn: user.isLoggedIn,
    currentUser: user.currentUser,
  };
}

type StateProps = {
  isLoggedIn: UserState['isLoggedIn'];
  currentUser: UserState['currentUser'];
};

export function LandingPage({ isLoggedIn, currentUser }: StateProps): JSX.Element {
  const [userType, setUserType] = useState('member');

  const content =
    userType === 'partner' ? (
      <MediVIForPartners userType={userType} />
    ) : (
      <MediVIForMembers userType={userType} />
    );

  return isLoggedIn ? (
    <Redirect
      to={
        currentUser?.roles && currentUser.roles.length > 0
          ? getRedirectLocation(currentUser?.roles)
          : '/login'
      }
    />
  ) : (
    <>
      <Header userType={userType} setUserType={setUserType} />
      <div
        style={{
          marginTop: '5rem',
          width: '100vw',
        }}
      >
        {content}
      </div>
      <Footer setUserType={setUserType} userType={userType} />
    </>
  );
}

export default connect(mapStateToProps)(LandingPage);
