import { AxiosPromise } from 'axios';
import { SetPlanState } from '../../planSelect/types/planActions';
import {
  ProviderData,
  SearchRadius,
  SortCriteria,
  ZipCodeValidation,
  ProviderTypeScrollPosition,
  Accessibility,
  SortDirection,
} from './providerSearch';

enum p {
  SET_ZIP_CODE = 'SET_ZIP_CODE',
  SET_SEARCH_RADIUS = 'SET_SEARCH_RADIUS',
  SET_SEARCH_INPUT = 'SET_SEARCH_INPUT',
  SET_PROVIDER_TYPE = 'SET_PROVIDER_TYPE',
  SET_IS_FILTER_TRAY_OPEN = 'SET_IS_FILTER_TRAY_OPEN',
  SET_SCROLL_POSITION = 'SET_SCROLL_POSITION',
  GET_PROVIDERS = 'GET_PROVIDERS',
  GET_PROVIDERS_PENDING = 'GET_PROVIDERS_PENDING',
  GET_PROVIDERS_FULFILLED = 'GET_PROVIDERS_FULFILLED',
  GET_PROVIDERS_REJECTED = 'GET_PROVIDERS_REJECTED',
  GET_PROVIDERS_RTK_PENDING = 'providerSearch/getProviders/pending',
  GET_PROVIDERS_RTK_FULFILLED = 'providerSearch/getProviders/fulfilled',
  GET_PROVIDERS_RTK_REJECTED = 'providerSearch/getProviders/rejected',
  TOGGLE_ACCESSIBILITY = 'TOGGLE_ACCESSIBILITY',
  SET_ACCESSIBILITIES = 'SET_ACCESSIBILITIES',
  SET_SORT_CRITERIA = 'SET_SORT_CRITERIA',
  SET_SORT_DIRECTION = 'SET_SORT_DIRECTION',
  SET_PROVIDER_GENDER_CODE = 'SET_PROVIDER_GENDER_CODE',
  SET_ENTITY_TYPE = 'SET_ENTITY_TYPE',
  TOGGLE_SORT_DIRECTION = 'TOGGLE_SORT_DIRECTION',
  TOGGLE_CHANGE_PLAN = 'TOGGLE_CHANGE_PLAN',
  VALIDATE_ZIP_CODE = 'VALIDATE_ZIP_CODE',
  VALIDATE_ZIP_CODE_PENDING = 'VALIDATE_ZIP_CODE_PENDING',
  VALIDATE_ZIP_CODE_FULFILLED = 'VALIDATE_ZIP_CODE_FULFILLED',
  VALIDATE_ZIP_CODE_REJECTED = 'VALIDATE_ZIP_CODE_REJECTED',
  VALIDATE_ZIP_CODE_RTK_PENDING = 'providerSearch/validateZipCode/pending',
  VALIDATE_ZIP_CODE_RTK_FULFILLED = 'providerSearch/validateZipCode/fulfilled',
  VALIDATE_ZIP_CODE_RTK_REJECTED = 'providerSearch/validateZipCode/rejected',
  RESET_PROVIDER_SEARCH = 'RESET_PROVIDER_SEARCH',
}

interface SetSearchInput {
  type: typeof p.SET_SEARCH_INPUT;
  payload: string;
}

interface ResetProviderSearch {
  type: typeof p.RESET_PROVIDER_SEARCH;
}

interface SetZipCode {
  type: typeof p.SET_ZIP_CODE;
  payload: string;
}

interface SetSearchRadius {
  type: typeof p.SET_SEARCH_RADIUS;
  payload: SearchRadius;
}

interface SetProviderType {
  type: typeof p.SET_PROVIDER_TYPE;
  payload: string;
}

interface SetIsFilterTrayOpen {
  type: typeof p.SET_IS_FILTER_TRAY_OPEN;
  payload: boolean;
}

interface SetScrollPosition {
  type: typeof p.SET_SCROLL_POSITION;
  payload: ProviderTypeScrollPosition;
}

interface GetProviders {
  type: typeof p.GET_PROVIDERS;
  meta: {
    arg: string;
  };
  payload: AxiosPromise<{
    data: ProviderData;
  }>;
}

interface GetProvidersPending {
  type: typeof p.GET_PROVIDERS_PENDING | p.GET_PROVIDERS_RTK_PENDING;
}

interface GetProvidersFulfilled {
  type: typeof p.GET_PROVIDERS_FULFILLED | p.GET_PROVIDERS_RTK_FULFILLED;
  meta: {
    arg: string;
  };
  payload: {
    data: ProviderData;
  };
}
interface GetProvidersRejected {
  type: typeof p.GET_PROVIDERS_REJECTED | p.GET_PROVIDERS_RTK_REJECTED;
  payload: {
    message: string;
  };
}

interface ToggleAccessibility {
  type: typeof p.TOGGLE_ACCESSIBILITY;
  payload: Accessibility;
}

interface SetAccessibilities {
  type: typeof p.SET_ACCESSIBILITIES;
  payload: Accessibility[];
}

interface SetSortCriteria {
  type: typeof p.SET_SORT_CRITERIA;
  payload: SortCriteria;
}

interface SetSortDirection {
  type: typeof p.SET_SORT_DIRECTION;
  payload: SortDirection;
}

interface SetProviderGenderCode {
  type: typeof p.SET_PROVIDER_GENDER_CODE;
  payload: string;
}

interface SetEntityType {
  type: typeof p.SET_ENTITY_TYPE;
  payload: string;
}

interface ToggleSortDirection {
  type: typeof p.TOGGLE_SORT_DIRECTION;
}

interface ToggleChangePlan {
  type: typeof p.TOGGLE_CHANGE_PLAN;
}

interface ValidateZipCode {
  type: typeof p.VALIDATE_ZIP_CODE;
  meta: { arg: string };
  payload: AxiosPromise<{
    data: ZipCodeValidation;
  }>;
}

interface ValidateZipCodePending {
  type: typeof p.VALIDATE_ZIP_CODE_PENDING | p.VALIDATE_ZIP_CODE_RTK_PENDING;
}
interface ValidateZipCodeFulfilled {
  type: typeof p.VALIDATE_ZIP_CODE_FULFILLED | p.VALIDATE_ZIP_CODE_RTK_FULFILLED;
  meta: { arg: string };
  payload: {
    data: ZipCodeValidation;
  };
}
interface ValidateZipCodeRejected {
  type: typeof p.VALIDATE_ZIP_CODE_REJECTED | p.VALIDATE_ZIP_CODE_RTK_REJECTED;
  error: boolean;
  payload: {
    message: string;
  };
}

type ValidateZipCodeAction =
  | ValidateZipCode
  | ValidateZipCodePending
  | ValidateZipCodeFulfilled
  | ValidateZipCodeRejected;

type GetProvidersAction =
  | GetProviders
  | GetProvidersPending
  | GetProvidersFulfilled
  | GetProvidersRejected;

type SetFilterAction =
  | SetSearchInput
  | SetSearchRadius
  | SetZipCode
  | SetProviderType
  | ToggleAccessibility
  | SetAccessibilities
  | SetSortCriteria
  | SetSortDirection
  | SetProviderGenderCode
  | SetEntityType
  | ToggleSortDirection
  | ValidateZipCodeAction
  | SetIsFilterTrayOpen
  | SetScrollPosition
  | ToggleChangePlan
  | ResetProviderSearch;

export type ProviderSearchActionTypes = GetProvidersAction | SetFilterAction | SetPlanState;

export default p;

export function isValidateZipCodeResponse(data: unknown): data is ZipCodeValidation {
  return (
    (data as ZipCodeValidation).county !== undefined ||
    (data as ZipCodeValidation).message !== undefined ||
    (data as ZipCodeValidation).state !== undefined
  );
}
