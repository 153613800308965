import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Typography } from '@material-ui/core';

import { previousPage, setPageCount } from '@apps/shared/src/register/actions';
import BottomCredits from './BottomCredits';
import { PrimaryButton, SecondaryButton } from './button';
import Illustrator from './Illustrator';
import { RootState } from '../store';

type StateProps = {
  currentPage: RootState['register']['currentPage'];
};

const mapStateToProps = ({ register }: RootState): StateProps => {
  return {
    currentPage: register.currentPage,
  };
};

type DispatchProps = {
  setPageCount: typeof setPageCount;
  previousPage: typeof previousPage;
};

const mapDispatchToProps = {
  setPageCount,
  previousPage,
};

type PagesParentProps = {
  buttonsDisabled: boolean;
  pages: React.ReactNode[];
  nextPageText: string;
  isAnyTextInputEmpty: boolean;
  setInputEmpty: (isEmpty: boolean) => void;
  onNext: () => void;
};

type PagesProps = PagesParentProps & StateProps & DispatchProps;

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  illustrator: {
    width: '50%',
  },
  loginBox: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
    alignItems: 'center',
  },
  hidden: {
    visibility: 'hidden',
  },
  errorText: {
    minHeight: '1rem',
    marginBottom: '25px',
    fontSize: '0.8rem',
  },
  buttonContainer: {
    display: 'flex',
    margin: '1rem 0',
    justifyContent: 'space-between',
  },
  buttonAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
  },
  backBtn: {
    backgroundColor: '#F9F9F9',
    color: '#888787',
    border: '1px solid #888787',
    width: '47%',
    minHeight: '3rem',
    margin: '1rem 0 0',
    fontSize: '0.8rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    borderRadius: '0.75rem',
    textTransform: 'uppercase',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F9F9F9',
      color: '#888787',
    },
  },
  signinBtn: {
    width: '47% !important',
    margin: '1rem 0 0',
  },
  dontMessage: {
    marginTop: '1.5rem',
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  signBtn: {
    fontSize: '1rem',
    fontWeight: 600,
    borderRadius: 0,
    padding: '0 0 4px 4px',
    color: '#F07037',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export function Pages({
  pages,
  currentPage,
  buttonsDisabled,
  nextPageText,
  setPageCount,
  previousPage,
  onNext,
  isAnyTextInputEmpty,
  setInputEmpty,
}: PagesProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const pageCount = pages.length;
  const pageNum = Math.floor(Math.min(Math.max(currentPage, 1), pageCount));
  const page = pages[pageNum - 1];

  useEffect(() => {
    setPageCount(pages.length);
  }, [setPageCount, pages.length]);

  const handlePrevious = (e: React.FormEvent<Element>): void => {
    previousPage();
    setInputEmpty(true);
  };

  const handleSubmit = (e: React.FormEvent<Element>): void => {
    onNext();
  };

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit}>
        <div className={classes.container}>
          <div className={classes.illustrator}>
            <Illustrator />
          </div>
          <div className={classes.loginBox}>
            {page}
            <div className={classes.buttonAlignment}>
              <Button
                className={pageNum === 1 ? classes.hidden : classes.backBtn}
                variant="contained"
                onClick={handlePrevious}
                disabled={buttonsDisabled}
              >
                BACK
              </Button>
              <div className={classes.signinBtn}>
                <SecondaryButton title={nextPageText} type="submit" disabled={buttonsDisabled} />
              </div>
            </div>

            <Typography className={classes.dontMessage}>
              Already have an account?
              <IconButton className={classes.signBtn} onClick={() => history.push('/login')}>
                Sign In
              </IconButton>
            </Typography>
            <BottomCredits />
          </div>
        </div>
      </ValidatorForm>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
