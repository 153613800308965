import React, { ChangeEventHandler, useState } from 'react';
import { connect } from 'react-redux';

import { doesStringInclude } from '@apps/shared/src/utilities';
import { User } from '@apps/shared/src/auth/types';
import { AdminState } from '@apps/shared/src/components/AdminUserList/adminTypes';
import { addRole, removeRole } from '@apps/shared/src/components/AdminUserList/adminActions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';

import SearchInputBox from '../shared/SearchInputBox';
import VirtualizedTable, { ColumnData, Data } from './VirtualizedTable';

const emailWidth = 300;
const nameWidth = 200;
const roleWidth = 150;

const useStyles = makeStyles({
  container: {
    padding: '1rem',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    marginTop: '1.5rem',
  },
  search: {
    width: '20rem',
    margin: '0 1rem 1rem 0',
    display: 'inline-block',
  },
  checkbox: {
    margin: '0 auto',
    width: '1.25rem',
    height: '1.25rem',
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#F07037',
    },
  },
  email: {
    wordBreak: 'break-word',
  },
});

type ParentProps = {
  users?: User[];
  appRoles?: string[];
  app?: string;
  superAdmin: boolean;
};

function formatRole(regex: RegExp, role: string): string {
  const cleanedRole = role.replace(regex, '');
  //   return cleanedRole.charAt(0).toUpperCase() + cleanedRole.slice(1);
  return cleanedRole.toUpperCase();
}

function sortRoles(a: string, b: string): number {
  const groupRegex = /\w*-\w*-\w*/g;
  const isAGrouped = groupRegex.test(a);
  const isBGrouped = groupRegex.test(b);
  if (isAGrouped === isBGrouped) return a.localeCompare(b);
  if (isAGrouped) return 1;
  return -1;
}

function makeColumns(regex: RegExp, appRoles: AdminState['appRoles']): ColumnData[] {
  return [
    {
      dataKey: 'name',
      label: 'Name',
      width: nameWidth,
      style: { fontWeight: 600 },
    },
    {
      dataKey: 'email',
      label: 'Email',
      width: emailWidth,
      style: { fontWeight: 600 },
    },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...appRoles!.sort(sortRoles).map(role => ({
      dataKey: role,
      label: formatRole(regex, role),
      width: roleWidth,
      style: {
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
      },
    })),
  ];
}

function filterUsers(users: User[], searchKey: string): User[] {
  if (searchKey === '') return users;

  return users.filter(
    user => doesStringInclude(user.fullName, searchKey) || doesStringInclude(user.email, searchKey)
  );
}

const VirtualizedUserList: React.FC<Props> = (props): JSX.Element => {
  const { users, app, appRoles, superAdmin, addRole, removeRole } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  const roleCount = appRoles?.length || 0;

  const filteredUsers = (users && filterUsers(users, searchText)) || [];
  const regex = new RegExp(`^${app}-`, 'i');
  const columns = (appRoles && makeColumns(regex, appRoles)) || [];

  const roleChange = (role: string, user: User): void => {
    const { userID, roles } = user;
    if (roles.includes(role)) {
      removeRole({ userID, role });
    } else {
      addRole({ userID, role });
    }
  };

  const transformData = (user: Partial<User>): Data => {
    const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = event => {
      roleChange(event.currentTarget.value, user as User);
    };

    const shouldDisable = (role: string): boolean => {
      const adminCheck = role === 'claims-admin' || role === 'medivi-admin';
      return !superAdmin && adminCheck;
    };

    const userRoles: Data = {};
    if (appRoles && appRoles.length > 0) {
      for (const role of appRoles) {
        userRoles[role] = (
          <Checkbox
            disabled={shouldDisable(role)}
            value={role}
            onChange={onCheckboxChange}
            checked={user.roles?.includes(role)}
            className={classes.checkbox}
          />
        );
      }
    }

    return {
      name: user.fullName,
      email: <div className={classes.email}>{user.email}</div>,
      ...userRoles,
    };
  };

  const onSearch = (searchKey: string): void => setSearchText(searchKey);

  const onClearSearch = (): void => setSearchText('');

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.search}>
          <SearchInputBox
            searchText={searchText}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            placeholder="Search..."
          />
        </div>
        {/* <Typography variant="caption">{filteredUsers.length} users found</Typography> */}
      </div>

      <VirtualizedTable
        columns={columns}
        data={filteredUsers}
        dataTransformer={transformData}
        roleCount={roleCount}
      />
    </div>
  );
};

// Container

type DispatchProps = {
  addRole: typeof addRole;
  removeRole: typeof removeRole;
};

const mapDispatchToProps = {
  removeRole,
  addRole,
};

type Props = ParentProps & DispatchProps;

export default connect(null, mapDispatchToProps)(VirtualizedUserList);
