import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import { colors } from '@apps/shared/src/style';

const styles = theme => ({
  footerBackground: {
    // background: colors.grey82,
    background: '#ECF0F2',
    minHeight: '20vh',
    borderBottom: `5px solid ${colors.orange}`,
    color: 'black',
    padding: '5vh 10vw',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  navLinks: {
    display: 'flex',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.3rem',
    },
  },
  aTagLink: {
    textDecoration: 'none',
    color: '#454545',
  },
  subHeading: {
    color: 'black',
    lineHeight: '1',
    fontWeight: 600,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem',
    listStyle: 'none',
    padding: '0',
    marginRight: '5vw',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
    '@media (min-width: 959px) and ( max-width: 1135px)': {
      fontSize: '.9rem',
    },
  },
  firstListItem: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '5vw',
    },
  },
  listItem: {
    marginBottom: '15px',
  },
  copyrightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: '7vh',
      alignItems: 'flex-start',
    },
  },
  copyrightSectionText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#454545',
    marginTop: '.9rem',
    textAlign: 'right',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.25rem',
    },
    '@media (min-width: 959px) and ( max-width: 1135px)': {
      fontSize: '.6rem',
      marginTop: '1.05rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  logo: {
    width: '210px',
    marginBottom: '15%',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 0 5% 0',
    },
    [theme.breakpoints.up('xl')]: {
      width: '225px',
    },
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.6rem',
  },
  icon: {
    width: '1rem',
  },
});

function Footer({ classes, setUserType, userType }) {
  const thisYear = new Date().getFullYear();

  useEffect(() => {
    const scrollToFeatures = () => {
      if (userType !== 'partner') {
        setUserType('partner');
      }
      const featuresElement = document.getElementById('features');
      if (featuresElement) {
        const topOfElem = featuresElement.offsetTop - 200;
        window.scrollTo({ top: topOfElem, behavior: 'smooth' });
      }
    };

    const handleFeaturesClick = () => {
      scrollToFeatures();
    };

    const featuresButton = document.getElementById('featuresButton');
    if (featuresButton) {
      featuresButton.addEventListener('click', handleFeaturesClick);
    }

    return () => {
      if (featuresButton) {
        featuresButton.removeEventListener('click', handleFeaturesClick);
      }
    };
  }, [userType, setUserType]);

  return (
    <footer className={classes.footerBackground}>
      <div className={classes.container}>
        <div className={classes.navLinks}>
          <div className={classes.firstListItem}>
            <Typography
              variant="subtitle1"
              className={classnames(classes.subHeading, classes.link)}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <img src="./images/medivi_logo_on_light.svg" alt="Medivi" />
            </Typography>
            <ul className={classes.list}>
              <ButtonBase
                id="featuresButton"
                className={classnames(classes.listItem, classes.link)}
              >
                <li>FEATURES</li>
              </ButtonBase>
            </ul>
            <div className={classnames(classes.flexBox)}>
              <ButtonBase
                id="featuresButton"
                className={classnames(classes.listItem, classes.link, classes.icon)}
                component="a"
                href="https://x.com/i/flow/login?redirect_after_login=%2F6degreeshealth"
                target="_blank"
              >
                <img src="./images/twitter.png" alt="twitter" style={{ width: '1.2rem' }} />
              </ButtonBase>
              <ButtonBase
                id="featuresButton"
                className={classnames(classes.listItem, classes.link, classes.icon)}
                component="a"
                href="https://www.linkedin.com/company/6-degrees-health-inc"
                target="_blank"
              >
                <img src="./images/LNIcon.png" alt="LN Icon" style={{ width: '1.4rem' }} />
              </ButtonBase>
              <ButtonBase
                id="featuresButton"
                className={classnames(classes.listItem, classes.link, classes.icon)}
                component="a"
                href="https://www.youtube.com/channel/UC0r95FvST-g2VOw_x_CM_ig"
                target="_blank"
              >
                <img src="./images/YTIcon.png" alt="Youtube Icon" style={{ width: '1.8rem' }} />
              </ButtonBase>
            </div>
          </div>
          <div>
            <Typography variant="subtitle1" className={classes.subHeading}>
              <a
                href="https://6degreeshealth.com"
                className={classnames(classes.aTagLink, classes.link, classes.subHeading)}
              >
                6 DEGREES HEALTH
              </a>
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <a
                  href="https://6degreeshealth.com/about-us/"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  ABOUT
                </a>
              </li>
              <li className={classes.listItem}>
                <a
                  href="https://6degreeshealth.com/cost-containment"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  COST CONTAINMENT
                </a>
              </li>
              <li>
                <a
                  href="https://6degreeshealth.com/transplant-specialty"
                  className={classnames(classes.aTagLink, classes.link)}
                >
                  TRANSPLANT & SPECIALTY
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.copyrightSection}>
          <div className={classes.copyrightSection}>
            <Typography className={classes.copyrightSectionText} style={{ fontWeight: 600 }}>
              POWERED BY:
            </Typography>
            <a href="https://6degreeshealth.com">
              <img
                src="/images/6degreeshealth.png"
                alt="6 Degrees Health Logo"
                className={classes.logo}
              />
            </a>
          </div>
          <Typography paragraph className={classes.copyrightSectionText}>
            COPYRIGHT © {thisYear} 6 DEGREES HEALTH DX, LLC. ALL RIGHTS RESERVED.
          </Typography>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setUserType: PropTypes.func,
  userType: PropTypes.string,
};

export default withStyles(styles)(Footer);
