import React, { ChangeEventHandler } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

type Props = {
  searchText: string;
  onSearch: (value: string) => void;
  onClearSearch: () => void;
  label?: string;
  placeholder?: string;
  tooltipTitle?: string;
};

const styles = makeStyles(theme => ({
  searchIcon: {
    color: '#F07037',
    fontSize: '22px',
    paddingLeft: '0',
  },
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      height: '40px',
      padding: '0 10px 0 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E5EBF5',
    },
    '& .MuiIconButton-root': {
      paddingRight: '0',
    },
    '& .MuiInputBase-root': {
      borderRadius: '6px',
      backgroundColor: '#fff',
    },
  },
}));

export default function SearchInput({
  searchText,
  onSearch,
  onClearSearch,
  placeholder = 'Search...',
  label = '',
  tooltipTitle = '',
}: Props): JSX.Element {
  const classes = styles();
  const onChange: ChangeEventHandler<HTMLInputElement> = event =>
    onSearch(event.currentTarget.value);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon className={classes.searchIcon} />
      </InputAdornment>
    ),
    endAdornment: searchText && (
      <InputAdornment position="end">
        <IconButton onClick={onClearSearch}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Tooltip title={tooltipTitle}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={placeholder}
        // type="search"
        value={searchText}
        label={label}
        onChange={onChange}
        InputProps={inputProps}
        className={classes.root}
        fullWidth
      />
    </Tooltip>
  );
}
