import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUsInfo } from './ContactUsQuestions';
import AppLayout from '../shared/AppLayout';
import PageSpecificHeader from '../shared/PageSpecificHeader';

const useStyles = makeStyles(() => ({
  rootContainer: {
    padding: '0 2rem 0 0',
  },
  heading: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    textAlign: 'left',
    color: '#36383D',
    padding: '1rem 0 0 1rem',
    marginBottom: '1rem',
  },
  question: {
    fontSize: '1.125rem',
    fontWeight: 550,
    lineHeight: '1.5625rem',
    letterSpacing: '0em',
    color: '#36383D',
    opacity: '80%',
    padding: '.5rem 0 0 1rem',
    marginBottom: '0.5rem',
  },
  answer: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.03125rem',
    color: '#888787',
    padding: '0 0 0.5rem 1rem',
  },
  link: {
    width: 'fit-content',
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'left',
    color: '#366BED',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 1rem',
    marginBottom: '1rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const handleMail = () => {
    const email = 'appsupport@6degreeshealth.com';

    const mailtoLink = `mailto:${email}`;

    window.location.href = mailtoLink;
  };

  return (
    <AppLayout>
      <div className={classes.rootContainer}>
        <PageSpecificHeader title="Contact Us" />
        {ContactUsInfo.map((contact, index) => (
          <div key={contact.id}>
            <Typography className={classes.question}>{contact.question}</Typography>
            {index === 1 && contact.link ? (
              <>
                <Typography className={classes.answer}>{contact.answer}</Typography>
                <div
                  className={classes.link}
                  onClick={handleMail}
                  onKeyDown={e => {
                    if (e.key === 'Space') {
                      handleMail();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img src="/images/Message.svg" alt="message Icon" className={classes.icon} />
                  {contact.link}
                </div>
              </>
            ) : (
              <>
                {contact.link ? (
                  <>
                    <a
                      className={classes.link}
                      href="https://www.6degreeshealth.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {contact.link}
                    </a>
                    <Typography className={classes.answer}>{contact.answer}</Typography>
                  </>
                ) : (
                  <Typography className={classes.answer}>{contact.answer}</Typography>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </AppLayout>
  );
};

export default ContactUs;
