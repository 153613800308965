import { SortDirection } from '@apps/shared/src/providerSearch/types/providerSearch';
import {
  SearchRadius,
  SortCriteria,
  ProviderTypeScrollPosition,
  Accessibility,
} from './types/providerSearch';
import p, { ProviderSearchActionTypes } from './types/providerSearchActions';

export function setSearchInput(input: string): ProviderSearchActionTypes {
  return {
    type: p.SET_SEARCH_INPUT,
    payload: input,
  };
}

export function setSearchRadius(radius: SearchRadius): ProviderSearchActionTypes {
  return {
    type: p.SET_SEARCH_RADIUS,
    payload: radius,
  };
}

export function setZipCode(zip: string): ProviderSearchActionTypes {
  return {
    type: p.SET_ZIP_CODE,
    payload: zip,
  };
}

export function setProviderType(providerType: string): ProviderSearchActionTypes {
  return {
    type: p.SET_PROVIDER_TYPE,
    payload: providerType,
  };
}

export function setScrollPosition(
  scrollPosition: ProviderTypeScrollPosition
): ProviderSearchActionTypes {
  return {
    type: p.SET_SCROLL_POSITION,
    payload: scrollPosition,
  };
}

export function setIsFilterTrayOpen(isFilterTrayOpen: boolean): ProviderSearchActionTypes {
  return {
    type: p.SET_IS_FILTER_TRAY_OPEN,
    payload: isFilterTrayOpen,
  };
}

export function toggleAccessibility(accessibility: Accessibility): ProviderSearchActionTypes {
  return {
    type: p.TOGGLE_ACCESSIBILITY,
    payload: accessibility,
  };
}

export function setAccessibilities(accessibilities: Accessibility[]): ProviderSearchActionTypes {
  return {
    type: p.SET_ACCESSIBILITIES,
    payload: accessibilities,
  };
}

export function setSortCriteria(sortCriteria: SortCriteria): ProviderSearchActionTypes {
  return {
    type: p.SET_SORT_CRITERIA,
    payload: sortCriteria,
  };
}

export function setSortDirection(sortDirection: SortDirection): ProviderSearchActionTypes {
  return {
    type: p.SET_SORT_DIRECTION,
    payload: sortDirection,
  };
}

export function setGenderFilter(gender: string): ProviderSearchActionTypes {
  return {
    type: p.SET_PROVIDER_GENDER_CODE,
    payload: gender,
  };
}

export function setEntityType(entityType: string): ProviderSearchActionTypes {
  return {
    type: p.SET_ENTITY_TYPE,
    payload: entityType,
  };
}

export function toggleSortDirection(): ProviderSearchActionTypes {
  return {
    type: p.TOGGLE_SORT_DIRECTION,
  };
}

export function toggleChangePlan(): ProviderSearchActionTypes {
  return {
    type: p.TOGGLE_CHANGE_PLAN,
  };
}
export function resetProviderSearch(): ProviderSearchActionTypes {
  return {
    type: p.RESET_PROVIDER_SEARCH,
  };
}
