import { useSelector } from 'react-redux';

import { RootState } from '../types/root';
import { Provider, Accessibility } from '../types/providerSearch';
import { allAccessibilities, filterProviderList } from './providers';

export function countProvidersByAccessibility(
  providers: Provider[]
): Record<Accessibility, number> {
  const counts = {} as Record<Accessibility, number>;
  allAccessibilities.forEach(a => {
    counts[a] = 0;
  });

  for (const p of providers) {
    counts[p.label1]++;
    if (p.label2) counts[p.label2]++;
  }

  return counts;
}

export default function useProviderAccessibilityCounts(): Record<Accessibility, number> {
  const values = useSelector((state: RootState) => state.providerSearch);

  const neutralFilteredList = filterProviderList({
    fullList: values.fullList,
    radius: values.radius,
    searchInput: values.searchInput,
    selectedAccessibilities: allAccessibilities,
    taxonomyDetailsMap: values.taxonomyDetailsMap,
    selectedProviderType: values.selectedProviderType,
    selectedPlanID: values.selectedPlanID,
  });

  return countProvidersByAccessibility(neutralFilteredList);
}
