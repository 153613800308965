import React, { useState } from 'react';
import { connect } from 'react-redux';
import { verifyUserEmail, verifyUserCredentials, addUser } from '@apps/shared/src/register/actions';
import CredentialsInUseDialog from '@apps/shared/src/addPlan/CredentialsInUseDialog';
import Pages from '../shared/Pages';
import MemberPage from './MemberPage';
import EmailPage from './EmailPage';
import AccountPage from './AccountPage';
import { RootState } from '../store';

type RegisterState = RootState['register'];
type AddPlanState = RootState['addPlan'];
type StateProps = {
  isVerifying: RegisterState['isVerifying'];
  hasAccount: RegisterState['hasAccount'];
  userInfo: RegisterState['userInfo'];
  currentPage: RegisterState['currentPage'];
  planInfo: AddPlanState['planInfo'];
  authBaseURL: RootState['user']['authBaseURL'];
};

const mapStateToProps = ({ register, addPlan, user }: RootState): StateProps => {
  return {
    isVerifying: register.isVerifying,
    hasAccount: register.hasAccount,
    userInfo: register.userInfo,
    currentPage: register.currentPage,
    planInfo: addPlan.planInfo,
    authBaseURL: user.authBaseURL,
  };
};

type DispatchProps = {
  verifyUserCredentials: typeof verifyUserCredentials;
  verifyUserEmail: typeof verifyUserEmail;
  addUser: typeof addUser;
};

const mapDispatchToProps = {
  verifyUserCredentials,
  verifyUserEmail,
  addUser,
};

type Props = StateProps & DispatchProps;

export function Register({
  planInfo,
  userInfo,
  hasAccount,
  isVerifying,
  currentPage,
  verifyUserCredentials,
  verifyUserEmail,
  addUser,
  authBaseURL,
}: Props): JSX.Element {
  const [textInputEmpty, setTextInputEmpty] = useState(true);

  const onNext = (): void => {
    switch (currentPage) {
      case 1:
        verifyUserCredentials(planInfo);
        break;
      case 2:
        verifyUserEmail(userInfo.email);
        break;
      default:
        addUser(planInfo, userInfo, hasAccount ? '/provider-search' : '/check-email');
    }
  };

  const pages = [
    <MemberPage key="MemberPage" onTextInputEmptyChange={setTextInputEmpty} />,
    <EmailPage key="EmailPage" onTextInputEmptyChange={setTextInputEmpty} />,
    <AccountPage key="AccountPage" onTextInputEmptyChange={setTextInputEmpty} />,
  ];

  let buttonText;
  if (currentPage < pages.length) {
    buttonText = 'NEXT';
  } else if (hasAccount) {
    buttonText = 'NEXT';
  } else {
    buttonText = 'REGISTER';
  }
  return (
    <>
      <Pages
        pages={pages}
        onNext={onNext}
        setInputEmpty={setTextInputEmpty}
        isAnyTextInputEmpty={textInputEmpty}
        nextPageText={buttonText}
        buttonsDisabled={isVerifying}
      />
      <CredentialsInUseDialog loginLink="/login" resetPasswordLink="/password-reset" />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
