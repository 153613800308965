import React, { ReactNode, SyntheticEvent } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { IncompleteTheme } from './types/theme';

const primaryStyles = makeStyles((theme: IncompleteTheme) => ({
  primaryBtn: {
    width: '100%',
    backgroundColor: '#F07037',
    color: '#EFECE7',
    minHeight: '3.2em',
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    borderRadius: '0.75rem',
    boxShadow: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F07037',
      color: '#EFECE7',
    },
  },
}));

type PrimaryButtonProps = {
  children: ReactNode;
  className?: string;
  type?: string;
  disabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
};

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  onClick,
  className,
  disabled = false,
}) => {
  const classes = primaryStyles();
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      className={`${classes.primaryBtn} ${className}`}
    >
      {children}
    </Button>
  );
};

const secondaryStyles = makeStyles({
  btn: {
    minHeight: '3rem',
    borderRadius: '0.75rem',
    color: 'white',
    fontSize: '14px',
    lineHeight: '20px',
    '&.MuiButton-contained': {
      boxShadow: 'none',
    },
  },
  transparentBtn: {
    border: '1px solid #F97140',
    background: '#F9F9F9',
    display: 'flex',
    gap: '1rem',
    '&:focus': {
      background: '#F9F9F9',
    },
    '&:active': {
      background: '#F9F9F9',
    },
  },
});

type SecondaryButtonProps = {
  title: React.ReactNode | string;
  onClick: Function;
  disabled?: boolean;
  type: string;
  transparent?: boolean;
};

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  type,
  title,
  onClick,
  disabled = false,
  transparent = false,
}) => {
  const classes = secondaryStyles();

  return (
    <Button
      className={`${classes.btn} ${transparent ? classes.transparentBtn : ''}`}
      color="primary"
      variant="contained"
      fullWidth
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {title}
    </Button>
  );
};
