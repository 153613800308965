import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import { getCaseById } from '@apps/shared/src/caseSelect/actions';
import { Case } from '@apps/shared/src/caseSelect/types/myCase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      width: '100%',
      marginTop: '2rem',
    },
    Modal: {
      width: '100%',
      marginTop: '5%',
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'scroll',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #dadada',
      borderRadius: '0.5rem',
      boxShadow: theme.shadows[5],
      padding: '2rem',
    },
    titleDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: 'clamp(1rem, 0.7rem + 0.625vw, 1.1rem)',
      lineHeight: '1.1rem',
      fontWeight: 600,
      color: '#36383D',
    },
    closeBtn: {
      color: '#36383D',
      fontWeight: 600,
      cursor: 'pointer',
    },
    dataDiv: {
      margin: '.5rem 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    key: {
      width: '50%',
      textAlign: 'left',
      fontSize: 'clamp(0.75rem, 0rem + 1.5625vw, 1rem)',
      color: '#797C80',
      fontWeight: 400,
      lineHeight: '1.466rem',
    },
    value: {
      width: '50%',
      textAlign: 'left',
      fontSize: 'clamp(0.75rem, 0rem + 1.5625vw, 1rem)',
      color: '#36383D',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    mailBtn: {
      width: '100%',
      backgroundColor: '#F97140',
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '0.25rem',
      height: '3.2rem',
      padding: '0 1.8rem',
      cursor: 'default',
      '&.MuiButton-root:hover': {
        backgroundColor: '#EF7125',
      },
    },
    mailBtnPara: {
      fontSize: 'clamp(1rem, 0.7rem + 0.625vw, 1.1rem)',
      fontWeight: 500,
      lineHeight: '1.1rem',
      margin: '0',
    },
    expandDownIcon: {
      width: '1.5rem',
      height: '1.5rem',
      color: '#fff',
      fontSize: 'clamp(1rem, 0.7rem + 0.625vw, 1.1rem)',
      fontWeight: 800,
      cursor: 'pointer',
    },
    claimStatus: {
      padding: '.2rem 1.5rem',
      color: '#366BED',
      background: '#E0E9FF',
      borderRadius: '1rem',
      width: 'fit-content',
      textTransform: 'uppercase',
    },
    closedClaim: {
      padding: '.2rem 1.5rem',
      color: '#797C80',
      background: '#E8E8E8',
      borderRadius: '1rem',
      width: 'fit-content',
      textTransform: 'uppercase',
    },
  })
);

type ParentProps = {
  selectedCase: string;
  setCaseDetailModalOpen: React.Dispatch<React.SetStateAction<any>>;
  caseDetailModalOpen: boolean;
};

const CaseDetailModal = ({
  selectedCase,
  setCaseDetailModalOpen,
  caseDetailModalOpen,
}: ParentProps) => {
  const classes = useStyles();
  const [caseData, setCaseData] = useState<Case | null>(null);

  const handleClose = () => {
    setCaseDetailModalOpen(false);
  };

  const handleEmailClick = () => {
    const email = caseData?.PSS__r?.Email || '';
    const caseNumber = caseData?.CaseNumber;
    const recordType = caseData?.RecordType.Name;

    const subject = `${recordType} - ${caseNumber}`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoLink;
  };

  // used to format the date globally
  const formatDate = (inputDate: any) => {
    if (inputDate !== '') {
      const dateObject = new Date(inputDate);
      const datePart = dateObject.toISOString().split('T')[0];
      const dateComponents = datePart.split('-');
      if (dateComponents.length === 3) {
        const year = dateComponents[0];
        const month = dateComponents[1];
        const day = dateComponents[2];
        return `${month}/${day}/${year}`;
      }
    }
    return '';
  };

  let statusLabel;

  if (caseData?.Status === 'Intake') {
    statusLabel = 'Opened';
  } else if (caseData?.Status === 'Closed') {
    statusLabel = 'Closed';
  } else {
    statusLabel = 'Processing';
  }

  useEffect(() => {
    if (selectedCase) {
      getCaseById(selectedCase)
        .then(data => {
          setCaseData(data[0]);
        })
        .catch(error => {
          console.error('Error fetching case data:', error);
        });
    }
  }, [selectedCase]);

  return (
    <div>
      <Modal
        className={classes.Modal}
        open={caseDetailModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {!caseData ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <CircularProgress variant="determinate" value={75} className={classes.spinner} />
          </div>
        ) : (
          <div className={classes.paper}>
            <div className={classes.titleDiv}>
              <Typography className={classes.title}>OVERVIEW</Typography>
              <CloseIcon onClick={handleClose} className={classes.closeBtn} />
            </div>
            {caseData?.Hospital__r?.Name && (
              <div className={classes.dataDiv}>
                <Typography className={classes.key}>Providers Name:</Typography>
                <Typography className={classes.value}>
                  {caseData?.Hospital__r?.Name || '-'}
                </Typography>
              </div>
            )}
            {caseData?.Hospital__r?.ShippingStreet &&
              caseData?.Hospital__r?.ShippingCity &&
              caseData?.Hospital__r?.ShippingPostalCode &&
              caseData?.Hospital__r?.ShippingStateCode && (
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Providers Address:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Hospital__r?.ShippingStreet || '-'},&nbsp;
                    {caseData?.Hospital__r?.ShippingCity || '-'},&nbsp;
                    {caseData?.Hospital__r?.ShippingPostalCode || '-'},&nbsp;
                    {caseData?.Hospital__r?.ShippingStateCode || '-'},&nbsp;
                  </Typography>
                </div>
              )}
            {(caseData?.RecordType.Name === 'Balance Bill' ||
              caseData?.RecordType.Name === '6DH Employee Balance Bill') && (
              <>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Total Billed Amount:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Total_Billed_Charges__c
                      ? `$${caseData?.Total_Billed_Charges__c}`
                      : '-'}
                  </Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Balance Billed Amount:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Balance_Bill_Amount_Calc__c
                      ? `$${caseData?.Balance_Bill_Amount_Calc__c}`
                      : '-'}
                  </Typography>
                </div>
              </>
            )}
            <hr />
            <div className={classes.titleDiv}>
              <Typography className={classes.title}>CASE DETAILS</Typography>
            </div>
            <div className={classes.dataDiv}>
              <Typography className={classes.key}>Case Status:</Typography>
              <Typography
                className={
                  caseData?.Status === 'closed' ? classes.closedClaim : classes.claimStatus
                }
              >
                {statusLabel}
              </Typography>
            </div>
            <div className={classes.dataDiv}>
              <Typography className={classes.key}>Case Number:</Typography>
              <Typography className={classes.value}>{caseData?.CaseNumber || '-'}</Typography>
            </div>
            <div className={classes.dataDiv}>
              <Typography className={classes.key}>Created Date:</Typography>
              <Typography className={classes.value}>
                {formatDate(caseData?.CreatedDate! || '-')}
              </Typography>
            </div>
            <div className={classes.dataDiv}>
              <Typography className={classes.key}>Provider Name:</Typography>
              <Typography className={classes.value}>
                {caseData?.Hospital__r?.Name || '-'}
              </Typography>
            </div>
            <div className={classes.dataDiv}>
              <Typography className={classes.key}>Plan:</Typography>
              <Typography className={classes.value}>
                {caseData?.Plan_Name__r?.Name || '-'}
              </Typography>
            </div>
            {(caseData?.RecordType.Name === 'Balance Bill' ||
              caseData?.RecordType.Name === '6DH Employee Balance Bill') && (
              <>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Date Of Service:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Claim_DOS_From__c && caseData?.Claim_DOS_To__c
                      ? `${formatDate(caseData?.Claim_DOS_From__c)} - ${formatDate(
                          caseData?.Claim_DOS_To__c
                        )}`
                      : '-'}
                  </Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Number Of Claims:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Number_of_Claims_in_Case__c || '-'}
                  </Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Total Billed Amount:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Total_Billed_Charges__c
                      ? `$${caseData?.Total_Billed_Charges__c}`
                      : '-'}
                  </Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.key}>Balance Bill Amount:</Typography>
                  <Typography className={classes.value}>
                    {caseData?.Balance_Bill_Amount_Calc__c
                      ? `$${caseData?.Balance_Bill_Amount_Calc__c}`
                      : '-'}
                  </Typography>
                </div>
              </>
            )}
            <hr />
            <Button className={classes.mailBtn}>
              <Typography className={classes.mailBtnPara}>Questions about this case?</Typography>
              <MailIcon className={classes.expandDownIcon} onClick={handleEmailClick} />
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CaseDetailModal;
