import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import { formatConditionalNumber } from '../../shared/formats';

import InfoTooltip from '../../shared/InfoTooltip';
import { DisplayItem } from './DetailPane';

const ratioLabels: Record<string, string> = {
  inpatient:
    'This Inpatient Billed-to-Medicare Ratio is generated for the entire facility by looking at the weighted average of all DRG codes',
  outpatient:
    'This Outpatient Billed-to-Medicare Ratio is generated for the entire facility by looking at the weighted average of all Diagnosis Codes reported to Medicare which is the most reliable resource for comparing facilities as a whole',
};

type Props = {
  classes: ClassNameMap<'groupHeader' | 'groupData'>;
  claimType: string;
  ratio: number;
};

export default function BilledToMedicareDisplayItem({
  claimType,
  ratio,
  classes,
}: Props): JSX.Element {
  const tooltip = ratioLabels[claimType.toLowerCase()];

  return (
    <DisplayItem
      label={tooltip ? <InfoTooltip tooltip={tooltip}>{claimType}</InfoTooltip> : claimType}
      value={formatConditionalNumber(ratio)}
      classes={classes}
    />
  );
}
