import React, { useState } from 'react';
import { AutoSizer, TableCellProps, MultiGrid } from 'react-virtualized';
import makeStyles from '@material-ui/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import classNames from 'classnames';

const emailWidth = 300;
const nameWidth = 200;
const roleWidth = 150;

const useVirtualizedTableStyles = makeStyles({
  table: {
    '& .ReactVirtualized__Table__headerColumn': {
      margin: '0 !important',
    },
    '& .ReactVirtualized__Table__headerRow': {
      paddingLeft: '2px',
    },
    '& .ReactVirtualized__Table__row': {
      marginTop: 0,
      borderLeft: '2px solid transparent',
      '&:hover': {
        borderLeft: '2px solid #366BED',
        backgroundColor: '#EFF3FE',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  contentWrapper: {
    /* width */
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb ': {
      background: 'grey',
      borderRadius: '10px',
    },
  },
  tableContainer: {
    width: 'auto',
    margin: 0,
    padding: 0,
    border: '1px solid #E0E0E0',
    borderWidth: '1px 1px 0px 1px',
    borderRadius: '0.5rem 0.5rem 0 0',
    overflow: 'hidden',
    '& div[role=row]': {
      display: 'flex',
    },
  },
  tableAlign: (props: { roleCount: number }) => ({
    height: 'calc(75vh - 155px)',
    // width: `calc(${nameWidth + emailWidth}px + ${props.roleCount * roleWidth}px)`,
    borderRadius: '0.5rem 0.5rem 0 0',
    boxShadow: 'none',
  }),
  column: {
    margin: '0 !important',
  },
  selectBox: {
    height: '10%',
    width: 'auto',
    borderTop: '1px solid #ECECEC',
    backgroundColor: '#fff',
    border: '1px solid #E0E0E0',
    borderRadius: '0 0 .5rem .5rem',
    overflow: 'hidden',
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'white !important',
      width: 'auto',
      '&:focus': {
        border: 'none',
      },
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '.5rem',
    },
  },
});

const useMuiTableStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    fontSize: '0.8rem',
    height: '0.8rem',
    lineHeight: '1rem',
    textTransform: 'none',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: '#fff',
  },
});

export type Data = Record<string, React.ReactNode>;

export type ColumnData = {
  dataKey: string;
  label: string;
  isNumeric?: boolean;
  style?: React.CSSProperties;
  width: number;
};

export type CellProps = Pick<TableCellProps, 'cellData' | 'columnIndex'> & {
  columns: ColumnData[];
  rowHeight: number;
  isHeader: boolean;
};

export type HeaderCellProps = {
  label: string;
  columnIndex: number;
  headerHeight: number;
  columns: ColumnData[];
};

export type CellRenderer = ({ cellData, columns, rowHeight, isHeader }: CellProps) => JSX.Element;

export type HeaderCellRenderer = ({
  label,
  columnIndex,
  headerHeight,
  columns,
}: HeaderCellProps) => JSX.Element;

function MuiCell({ cellData, columnIndex, columns, rowHeight, isHeader }: CellProps): JSX.Element {
  const classes = useMuiTableStyles();
  const isNumberOrString = typeof cellData === 'number' || typeof cellData === 'string';
  const columnWidth = columns[columnIndex].width;
  const isFixedColumn = columnIndex < 2; // Determine if the column is a fixed column

  return (
    <TableCell
      className={classNames(classes.cell, {
        [classes.stickyCell]: isFixedColumn,
        [classes.header]: isHeader,
      })}
      style={{
        ...columns[columnIndex].style,
        maxWidth: columnWidth,
        overflowWrap: 'break-word',
        justifyContent: isFixedColumn ? 'left' : 'center',
      }}
      height={rowHeight}
      component="div"
      variant={isHeader ? 'head' : 'body'}
      title={isNumberOrString ? `${cellData}` : ''}
      align={columns[columnIndex].isNumeric ? 'right' : 'left'}
    >
      {cellData}
    </TableCell>
  );
}

type VirtualizedTableProps = {
  columns: ColumnData[];
  data: Data[];
  rowHeight?: number;
  headerHeight?: number;
  dataTransformer?: (data: Data) => Data;
  cellRenderer?: CellRenderer;
  roleCount: number;
};

export default function VirtualizedTable({
  columns,
  rowHeight = 48,
  headerHeight = 48,
  data,
  dataTransformer,
  roleCount,
  cellRenderer = MuiCell,
}: VirtualizedTableProps): JSX.Element {
  const classes = useVirtualizedTableStyles({ roleCount });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToColumn, setScrollToColumn] = useState(0);

  const Cell = cellRenderer;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    if (rowIndex === 0) {
      return columns[columnIndex].label;
    }

    const rowData = dataTransformer
      ? dataTransformer(paginatedData[rowIndex - 1])
      : paginatedData[rowIndex - 1];

    return rowData[columns[columnIndex].dataKey];
  };

  const startIndex = page * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, data.length);
  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <>
      <div className={classes.tableContainer}>
        <Paper className={classes.tableAlign}>
          <AutoSizer>
            {({ height, width }): JSX.Element => (
              <>
                <MultiGrid
                  className={classes.table}
                  fixedColumnCount={2}
                  fixedRowCount={1}
                  enableFixedColumnScroll
                  enableFixedRowScroll
                  hideTopRightGridScrollbar
                  hideBottomLeftGridScrollbar
                  classNameBottomRightGrid={classes.contentWrapper}
                  columnCount={columns.length}
                  columnWidth={({ index }) => columns[index].width + 40}
                  height={height || 100}
                  rowCount={paginatedData.length + 1}
                  rowHeight={rowHeight}
                  width={width || 100}
                  scrollToColumn={scrollToColumn}
                  cellRenderer={({ columnIndex, rowIndex, style }) => (
                    <div style={style}>
                      <Cell
                        cellData={getCellData(rowIndex, columnIndex)}
                        columnIndex={columnIndex}
                        rowHeight={rowHeight}
                        columns={columns}
                        isHeader={rowIndex === 0}
                      />
                    </div>
                  )}
                />
              </>
            )}
          </AutoSizer>
        </Paper>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.selectBox}
      />
    </>
  );
}
