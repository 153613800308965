import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  header: {
    padding: '1rem 1rem 1.5rem 1rem',
    paddingLeft: '1.5rem',
  },
  title: {
    color: '#36383D',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '24px',
  },
  description: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    marginTop: '.5rem',
  },
});

const PageSpecificHeader = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
};

export default PageSpecificHeader;
