import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  btn: {
    minHeight: '3rem',
    borderRadius: '0.75rem',
    color: 'white',
    fontSize: '14px',
    lineHeight: '20px',
    '&.MuiButton-contained': {
      boxShadow: 'none',
    },
  },
  transparentBtn: {
    border: '1px solid #F97140',
    background: '#F9F9F9',
    display: 'flex',
    gap: '1rem',
    '&:focus': {
      background: '#F9F9F9',
    },
    '&:active': {
      background: '#F9F9F9',
    },
  },
});

type ButtonProps = {
  title: React.ReactNode | string;
  onClick: Function;
  disabled?: boolean;
  type: string;
  transparent?: boolean;
};

export default function PrimaryButton({
  type,
  title,
  onClick,
  disabled = false,
  transparent = false,
}: ButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.btn} ${transparent ? classes.transparentBtn : ''}`}
      color="primary"
      variant="contained"
      fullWidth
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {title}
    </Button>
  );
}
