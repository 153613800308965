import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { selectPage, setNavOpenOption } from '../reportActions';

const useStyles = makeStyles({
  subHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    color: '#797C80',
    '& .MuiTypography-body1': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '20px',
      marginTop: '1rem',
    },
  },
  menuItem: {
    backgroundColor: '#fff',
    color: 'red',
    height: '60px',
    width: '100%',
  },
  selectBox: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      backgroundColor: '#fff !important',
      border: '1px solid #E5EBF5',
      width: '100%',
    },
    '&.MuiInput-underline': {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
  },
});

type ParentProps = {
  stage: string;
  caseValue: string;
  accessIssuePage: string;
  balanceBillPage: string;
  selectedReportPage: string;
  selectPage: typeof selectPage;
  onCaseTypeChange: (newState: string) => void;
};

export default function CaseStatusNavStage({
  stage,
  caseValue,
  selectedReportPage,
  accessIssuePage,
  balanceBillPage,
  selectPage,
  onCaseTypeChange,
}: ParentProps): JSX.Element {
  const classes = useStyles();

  const [caseType, setCaseType] = useState('');

  const handleCaseTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as string;
    setCaseType(newValue);
    onCaseTypeChange(newValue);
  };

  const handleSelectPageTitle = (pageTitle: string) => (): void => {
    selectPage(pageTitle);
  };

  useEffect(() => {
    if (caseValue === '') {
      setCaseType('');
    }
  }, [caseValue]);

  return (
    <>
      <div>
        <div className={classes.subHeading}>
          <Typography>Type of case</Typography>
        </div>
        <Select
          id="demo-simple-select-outlined"
          value={caseType}
          onChange={handleCaseTypeChange}
          className={classes.selectBox}
          displayEmpty
        >
          <MenuItem value="" className={classes.menuItem} disabled>
            Select case type
          </MenuItem>
          <MenuItem
            value="AccessIssue"
            selected={selectedReportPage === accessIssuePage}
            onClick={handleSelectPageTitle(accessIssuePage)}
          >
            Access Issue
          </MenuItem>
          <MenuItem
            value="BalanceBill"
            selected={selectedReportPage === balanceBillPage}
            onClick={handleSelectPageTitle(balanceBillPage)}
          >
            Balance Bill
          </MenuItem>
        </Select>
      </div>
    </>
  );
}
