import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import { nextSnackbar } from './snackbarReducer';

class SnackbarManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: Boolean(props.message),
    };
  }

  UNSAFE_componentWillReceiveProps({ message }) {
    if (!this.state.open && message) {
      this.setState({ open: true });
    }
  }

  handleClose = () => this.setState({ open: false });
  handleExited = () => this.props.dispatch(nextSnackbar());

  render() {
    return (
      <Snackbar
        message={this.props.message}
        open={this.state.open}
        onClose={this.handleClose}
        onExited={this.handleExited}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    );
  }
}

SnackbarManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]).isRequired,
};

function mapStateToProps({ snackbars: state }) {
  return {
    message: state.current,
  };
}

export default connect(mapStateToProps)(SnackbarManager);
