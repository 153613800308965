import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles(() => ({
  videoContainer: {
    padding: '.5rem 1rem 1rem 1rem',
    marginLeft: '.5rem',
    overflowY: 'scroll',
    height: 'calc(100vh - 150px)',
  },
  header: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    textAlign: 'left',
    color: '#36383D',
    padding: '.6rem 0',
  },
  content: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.75rem',
    textAlign: 'left',
    color: '#EF7125',
    padding: '.6rem 0',
  },
  video: {
    width: '40rem',
    height: '25rem',
    borderRadius: '0.25rem',
    color: '#36383D',
    border: '1px solid #F0F0F0',
  },
  title: {
    width: '38rem',
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '1.5625rem',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#36383D',
  },
  description: {
    width: '39rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.03rem',
    textAlign: 'center',
    color: '#888787',
    padding: '.6rem 0',
  },
}));

const InstructionalVideo = () => {
  const classes = useStyles();
  return (
    <AppLayout>
      <div className={classes.videoContainer}>
        <Typography className={classes.header}>Instructional Videos</Typography>
        <Typography className={classes.content}>Provider Search Training</Typography>
        {/* eslint-disable react/self-closing-comp */}
        <iframe
          className={classes.video}
          title="Provider search Highlights"
          src="https://player.vimeo.com/video/996195279?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
          width="640"
          height="360"
          allow="autoplay; fullscreen"
          // allowfullScreen
        ></iframe>
        <Typography className={classes.title}>Desktop Tutorial</Typography>
        <Typography className={classes.description}>
          An introduction to the MediVI Provider Search Desktop Tool.
        </Typography>
      </div>
    </AppLayout>
  );
};

export default InstructionalVideo;
