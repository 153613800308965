import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { updateCaseMessage, getAllNotifications } from '@apps/shared/src/notification/actions';
import { DependentMemberInfo } from '@apps/shared/src/dependentMember/types/dependentMemberTypes';
import { RootState } from '../store';

const mapDispatchToProps = {
  updateCaseMessage,
  getAllNotifications,
};

type StateProps = {
  sixDegPatientID: string;
  dependentSixDegPatientID: string[];
};

const mapStateToProps = ({ getMember, getDependentMember }: RootState): StateProps => {
  const { sixDegPatientID } = getMember.memberInfo;
  const dependentSixDegPatientID = getDependentMember.map(
    (member: DependentMemberInfo) => member.sixDegPatientID
  );

  return {
    sixDegPatientID,
    dependentSixDegPatientID,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type WebSocketSubscriptionProps = PropsFromRedux;

const serializeMessage = (message: any) => {
  return {
    id: Number(message.Notification_Id__c) || 0,
    description: message.Description__c || '',
    subject: message.Subject__c || '',
    createdDate: new Date(message.CreatedDate),
    caseId: message.CaseNumber__c || '',
    status: message.Status__c || '',
    memberID: message.Member_ID__c || '',
    isRead: false,
    isDeleted: false,
    statusInfo: message.Status_Info__c || '',
    recordType: message.Record_Type__c || '',
  };
};

const WebSocketSubscription: React.FC<WebSocketSubscriptionProps> = ({
  sixDegPatientID,
  dependentSixDegPatientID,
  updateCaseMessage,
  getAllNotifications,
}) => {
  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  useEffect(() => {
    const socket = new WebSocket('wss://apps-4cy4q6gf6q-uc.a.run.app/sf/notifications');

    socket.onopen = () => {
      console.log('Connected to the WebSocket server');
      const sixDegPatientIDs = [sixDegPatientID, ...dependentSixDegPatientID];
      const initMessage = JSON.stringify({ sixDegPatientIDs });
      socket.send(initMessage);
    };

    socket.onmessage = event => {
      const message = JSON.parse(event.data);
      if (message.payload) {
        const serializedMessage = serializeMessage(message.payload);
        updateCaseMessage({ ...serializedMessage, isRead: false, isDeleted: false });
      }
    };

    socket.onerror = error => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      socket.close();
    };
  }, [sixDegPatientID, dependentSixDegPatientID, updateCaseMessage]);

  return null;
};

export default connector(WebSocketSubscription);
