import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios, { AxiosError } from 'axios';
import {
  uploadAttachment,
  setAddCaseResponseInfo,
  resetAddCase,
  setCaseMemberInfo,
} from '@apps/shared/src/addCase/actions';

import { MemberInfo } from '@apps/shared/src/getMember/types/memberTypes';
import { getAxiosRequestConfig } from '@apps/shared/src/utilities/http';
import { AddCaseState } from '@apps/shared/src/addCase/types/addCaseTypes';
import a from '@apps/shared/src/addCase/types/addCaseActions';

import { RootState } from '../store';
import TextInput from '../shared/textInput';
import DragDrop from './dragDrop';
import LoaderDialog from '../shared/loaderDialog';
import SuccessDialog from '../shared/successDialog';
import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles({
  appContainer: {
    color: '#797C80',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    position: 'relative',
    height: 'calc(100vh - 120px - 2.5rem)',
  },
  arrowIcon: {
    height: '2rem',
    width: '2rem',
    cursor: 'pointer',
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'start',
    gap: '1rem',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1px',
    },
  },
  textBox: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    width: '50%',
    '&.MuiOutlinedInput-root': {
      borderRadius: '.5rem',
    },
    '& .MuiInputBase-root': {
      height: '30vh',
    },
    '& .MuiInputBase-inputMultiline': {
      height: 'inherit !important',
    },
  },
  buttonStyle: {
    width: '32%',
    position: 'absolute',
    bottom: 0,
    right: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
  },
  backBtn: {
    gridColumn: '1 / -1',
    width: '50%',
    borderRadius: '.5rem',
    background: '#fff',
    color: '#888787',
    border: '1px solid #888787',
    padding: '.6rem 1rem',
    cursor: 'pointer',
    boxShadow: 'None',
  },
  successBtn: {
    gridColumn: '1 / -1',
    width: '50%',
    borderRadius: '.5rem',
    background: 'primary',
    color: 'white',
    fontWeight: 600,
    outline: 'None',
    padding: '.6rem',
    cursor: 'pointer',
    boxShadow: 'None',
  },
  loadingSpinner: {
    margin: '3rem auto 0 auto',
  },
  link: {
    color: '#366BED',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0)', // Initial transparent background
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0, // Initial opacity set to 0
    transition: 'background 0.3s ease, opacity 0.3s ease', // Transition properties
  },
  overlayVisible: {
    background: 'rgba(255, 255, 255, 0.8)', // Visible background color
    opacity: 1, // Visible opacity
    zIndex: 999,
  },
});

type StateProps = {
  newCaseInfo: any;
  caseId: string;
  isCaseCreated: boolean;
  caseType: string;
  addingCase: boolean;
};

type DispatchProps = {
  setAddCaseResponseInfo: (
    key: keyof AddCaseState,
    value: AddCaseState[keyof AddCaseState]
  ) => void;
  uploadAttachment: (redirectTo: string, caseID: string) => void;
  resetAddCase: () => void;
  setCaseMemberInfo: (key: keyof MemberInfo, value: MemberInfo[keyof MemberInfo]) => void;
};

const mapDispatchToProps = {
  setAddCaseResponseInfo,
  uploadAttachment,
  resetAddCase,
  setCaseMemberInfo,
};

const mapStateToProps = (state: RootState) => ({
  newCaseInfo: state.addCase.memberInfo,
  caseId: state.addCase.caseId,
  isCaseCreated: state.addCase.isCreated,
  caseType: state.addCase.caseType,
  addingCase: state.addCase.addingCase,
});

type Props = StateProps & DispatchProps;

const UploadFiles = ({
  newCaseInfo,
  caseType,
  isCaseCreated,
  setAddCaseResponseInfo,
  uploadAttachment,
  addingCase,
  resetAddCase,
  setCaseMemberInfo,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [comments, setComments] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (caseType === '') {
      history.push(`/my-cases`);
    }
    // eslint-disable-next-line
  }, []);

  const handleFilesAccepted = useCallback((acceptedFiles: File[]) => {
    setUploadedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleEmailClick = () => {
    const email = 'PSS@6degreeshealth.com';

    const mailtoLink = `mailto:${email}`;

    window.location.href = mailtoLink;
  };

  const handleSubmit = useCallback(async () => {
    const caseDetail = { caseId: '', isCreated: '' };
    dispatch({
      type: a.ADD_CASE_PENDING,
    });

    try {
      setLoading(true);

      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL || ''}/api/createCase`,
        {
          ...newCaseInfo,
          recordType: caseType === 'Balance Bill' ? 'BalanceBill' : 'AccessIssue',
        },
        getAxiosRequestConfig()
      );

      const newCaseId = result.data.id;
      caseDetail.caseId = newCaseId;

      if (uploadedFiles.length > 0) {
        try {
          const formData = new FormData();
          formData.append('caseID', newCaseId);
          formData.append('contentLocation', '');
          formData.append('title', newCaseId);

          uploadedFiles.forEach((file, index) => {
            formData.append('files', file, file.name); // Append each file individually
          });

          const uploadResult = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL || ''}/api/case/uploadAttachment`,
            formData,
            getAxiosRequestConfig()
          );

          const uploadSuccess = uploadResult.data.success;
          caseDetail.isCreated = uploadSuccess;
        } catch (error) {
          if ((error as AxiosError).response) {
            const axiosError = error as AxiosError;
            const errorResponse = axiosError.response?.data;
            // addMessage(errorResponse);
          }
        }
      }

      setAddCaseResponseInfo('isCreated', caseDetail.isCreated);
      setAddCaseResponseInfo('caseId', caseDetail.caseId);
      dispatch({
        type: a.ADD_CASE_FULFILLED,
        payload: { ...result, caseId: newCaseId },
      });

      setLoading(false);
    } catch (error) {
      if ((error as AxiosError).response) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError.response?.data;
        // addMessage(errorResponse);
      }
      setLoading(false);
    }
  }, [
    dispatch,
    setAddCaseResponseInfo,
    setLoading,
    // addMessage,
    newCaseInfo,
    caseType,
    uploadedFiles,
  ]);

  const handleChange = (value: string, key: keyof MemberInfo) => {
    setComments(value);
    setCaseMemberInfo(key, value);
  };

  const handleSuccess = (): void => {
    resetAddCase();
    history.push('/my-cases');
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(index, 1);
    setUploadedFiles(newFiles);
  };
  const disableSubmitBtn = uploadedFiles.length === 0;
  return (
    <>
      <div className={`${classes.overlay} ${loading ? classes.overlayVisible : ''}`}>
        <LoaderDialog
          open={loading}
          message="We're working on your request. Please hang tight, and we'll notify you once your case is submitted."
        />
        {isCaseCreated && (
          <SuccessDialog
            icon="images/success.svg"
            open={isCaseCreated}
            title="Submitted"
            message="Thanks for submitting the case form. We will review your case and get back to you soon. New cases will appear within 1 business day."
            buttonText="Continue"
            handleSuccess={handleSuccess}
          />
        )}
      </div>
      <AppLayout>
        <div className={classes.appContainer}>
          <ArrowBackIcon className={classes.arrowIcon} onClick={handleBack} />
          <Typography>
            ** Please upload a clear, readable copy of the entire page(s) of the statement or bill
            you received.
            <br /> This can be a photo of the bill or a scan. <br />
            ** If you are experiencing difficulties uploading the required document(s), please email
            the document(s) to <br />
            <Link onClick={handleEmailClick} to="/upload-file">
              <span className={classes.link}>PSS@6degreeshealth.com.</span>
            </Link>
          </Typography>
          <div className={classes.formWrapper}>
            <DragDrop
              upLoadedFile={uploadedFiles}
              onFilesAccepted={handleFilesAccepted}
              onRemoveFile={handleRemoveFile}
            />
            <div className={classes.textBox}>
              <TextInput
                placeholder=""
                id="outlined-multiline-static"
                label="Additional Comments (If any)"
                value={comments}
                onChange={val => handleChange(val, 'additionalComment')}
                multiline
                minRow={11}
                maxRow={11}
              />
            </div>
          </div>
          <div className={classes.buttonStyle}>
            <Button type="button" className={classes.backBtn} onClick={handleBack}>
              BACK
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.successBtn}
              onClick={handleSubmit}
              disabled={disableSubmitBtn}
            >
              {loading ? 'Submitting...' : 'SUBMIT'}
            </Button>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles);
