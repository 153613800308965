import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ProviderTypeScrollPosition, RootState } from '../types/providerSearch';
import { setProviderType, setScrollPosition } from '../actions';
import { colors } from '../../style/colors';

const useStyles = makeStyles({
  taxonomyTextContainer: {
    display: 'flex',
    margin: '0.3rem 0',
  },
  button: {
    padding: 0,
    textDecoration: `underline #F97140`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.orangeDark,
      textDecoration: `underline #F97140`,
    },
  },
  categoryButton: {
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  titleButton: {
    flex: '1 0 auto',
    maxWidth: 'max-content',
  },
  disabledButton: {
    textDecoration: 'none',
  },
  text: {
    color: colors.orange,
    fontSize: '.85rem',
    textTransform: 'none',
  },
  category: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type ParentProps = {
  category: string;
  title: string;
};

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  scrollPosition: providerSearch.scrollPosition,
  selectedProviderType: providerSearch.selectedProviderType,
});

type StateProps = {
  scrollPosition: ProviderTypeScrollPosition;
  selectedProviderType: string;
};

const mapDispatchToProps = {
  setProviderType,
  setScrollPosition,
};

type Props = ParentProps & StateProps & typeof mapDispatchToProps;

export function CategoryAndTitle({
  category,
  title,
  setProviderType,
  setScrollPosition,
  selectedProviderType,
}: Props): JSX.Element {
  const classes = useStyles();

  if (!category || !title) return <div />;

  const handleClick =
    (isTitle = false) =>
    (): void => {
      if (isTitle === false) setProviderType(category);
      else setProviderType(title);
      setScrollPosition({ category, isTitle });
    };

  return (
    <div className={classes.taxonomyTextContainer}>
      <Button
        onClick={handleClick()}
        className={classNames(classes.button, classes.categoryButton)}
        classes={{ disabled: classes.disabledButton }}
        disabled={category === selectedProviderType}
      >
        <Typography className={classNames(classes.category, classes.text)}>{category}</Typography>
      </Button>
      <Typography className={classes.text}>&nbsp;/&nbsp;</Typography>
      <Button
        onClick={handleClick(true)}
        className={classNames(classes.button, classes.titleButton)}
        classes={{ disabled: classes.disabledButton }}
        disabled={title === selectedProviderType}
      >
        <Typography className={classes.text}>{title}</Typography>
      </Button>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAndTitle);
