import { setPlanState } from '@apps/shared/src/planSelect/actions';
import { Plan } from '@apps/shared/src/planSelect/types/plan';
import { toggleChangePlan } from '@apps/shared/src/providerSearch/actions';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { IncompleteTheme } from '../../shared/types/theme';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  menuItem: theme.selectMenuItem,
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
}));

const mapStateToProps = ({ providerSearch, planSelect }: RootState): StateProps => ({
  isLoading: providerSearch.isLoadingProviderList,
  selectedPlanID: providerSearch.selectedPlanID,
  plans: planSelect.plans,
});

type StateProps = {
  isLoading: boolean;
  selectedPlanID?: string;
  plans: Plan[];
};

const mapDispatchToProps = {
  setPlanState,
  toggleChangePlan,
};

type Props = StateProps & typeof mapDispatchToProps;

const defaultPlanSelectLabel = 'No plan selected';
const defaultPlanSelectValue = '-1';

export function PlanSelectFilter({
  isLoading,
  selectedPlanID,
  plans,
  setPlanState,
  toggleChangePlan,
}: Props): JSX.Element {
  const classes = useStyles();

  const handlePlanChange: SelectProps['onChange'] = e => {
    const plan = plans.find(plan => plan.id === e.target.value);
    if (plan) {
      setPlanState(plan);
      toggleChangePlan();
    }
  };

  if (plans.length < 2) return <></>;
  return (
    <div>
      <Typography className={classes.header}>Select a Plan</Typography>
      <FormControl>
        <Select
          value={selectedPlanID || defaultPlanSelectValue}
          onChange={handlePlanChange}
          labelId="plan-select"
          disabled={isLoading}
          disableUnderline
          className={classes.Select}
        >
          {!selectedPlanID && (
            <MenuItem value={defaultPlanSelectValue} className={classes.menuItem}>
              {defaultPlanSelectLabel}
            </MenuItem>
          )}
          {plans.map(plan => (
            <MenuItem value={plan.id} key={plan.id} className={classes.menuItem}>
              {plan.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelectFilter);
