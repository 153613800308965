import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.5rem',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  modalBtns: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  cancelModalBtns: {
    width: '47%',
    backgroundColor: '#fff',
    color: '#888787',
    border: '1px solid #888787',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    fontWeight: 700,
    '&.MuiButton-root:hover': {
      backgroundColor: '#fff',
    },
  },
  BtnDelete: {
    width: '47%',
    backgroundColor: '#F97140',
    color: '#fff',
    borderRadius: '0.25rem',
    fontWeight: 700,
    cursor: 'pointer',
    '&.MuiButton-root:hover': {
      backgroundColor: '#EF7125',
    },
  },
}));

type ModalProps = {
  handleModalClose: () => void;
  handleSuccess: () => void;
  showModal: boolean;
  title: string;
  message: React.ReactElement;
  closeTitle: string;
  actionTitle: string;
};

const ModalLayout: React.FC<ModalProps> = ({
  showModal,
  handleSuccess,
  handleModalClose,
  title,
  message,
  closeTitle,
  actionTitle,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title" style={{ color: '#36383D' }}>
            {title}
          </h2>
          <Typography id="simple-modal-description" style={{ color: '#888787' }}>
            {message}
          </Typography>
          <div className={classes.modalBtns}>
            <Button className={classes.cancelModalBtns} onClick={handleModalClose}>
              {closeTitle}
            </Button>
            <Button className={classes.BtnDelete} onClick={handleSuccess}>
              {actionTitle}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalLayout;
