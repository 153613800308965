import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { plusPad } from '../../utilities/stringUtilities';
import { colors } from '../../style';

const useStyles = makeStyles({
  text: {
    color: '#888787',
    lineHeight: 1.3,
  },
  link: {
    color: 'inherit',
  },
});

export function formatCityStateZip(city?: string, state?: string, zip?: string): string {
  if (!city || !state) return '';
  return `${city}, ${state} ${zip || ''}`.trim();
}

function formatAddress(address1: string, address2?: string): string {
  if (!address2) return address1.trim();
  return `${address1.trim()} ${address2.trim()}`;
}

export function getGoogleMapsLink(
  name = '',
  address = '',
  city = '',
  state = '',
  zip = ''
): string {
  const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
  const a = plusPad(name);
  const b = plusPad(address);
  const c = plusPad(city);
  const d = plusPad(`${state} ${zip}`);
  const formattedAddress = [a, b, c, d].filter(s => s).join(',+');
  return formattedAddress ? `${baseUrl}${formattedAddress}`.toLowerCase() : '';
}

type Props = {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};

export default function ProviderAddress({
  name,
  address1,
  address2,
  city,
  state,
  zip,
}: Props): JSX.Element {
  const classes = useStyles();
  const address = formatAddress(address1, address2);
  const href = getGoogleMapsLink(name, address, city, state, zip);

  const linkContent = (
    <Typography variant="body2" component="span" className={classes.text}>
      {address},&nbsp;{formatCityStateZip(city, state, zip)}
    </Typography>
  );

  if (!href) return linkContent;
  return (
    <a
      href={href}
      className={classes.link}
      aria-label="provider address"
      target="_blank"
      rel="noopener noreferrer"
    >
      {linkContent}
    </a>
  );
}
