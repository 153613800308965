import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import { Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import PasswordExpireForm from '@apps/shared/src/auth/PasswordExpireForm';

import UserCard from '@apps/shared/src/auth/UserCard';
import { IncompleteTheme } from '../shared/types/theme';
import { RootState } from '../store';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  login: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: theme.props.contentHeight,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  card: {
    maxWidth: '30rem',
    margin: 'auto',
  },
}));

const mapStateToProps = ({ user }: RootState): StateProps => ({
  isPasswordExpired: user.currentUser?.isPasswordExpired || false,
});

type StateProps = {
  isPasswordExpired: boolean;
};

type Props = StateProps;

export function PasswordNotifyModal({ isPasswordExpired }: Props): JSX.Element {
  const [passwordFormOpen, setpasswordFormOpen] = useState(true);

  const history = useHistory();
  const classes = useStyles();
  const handleLoginClick = (): void => {
    setpasswordFormOpen(!passwordFormOpen);
    history.push('/change-password');
  };

  return (
    <>
      {isPasswordExpired && (
        <Modal open={passwordFormOpen} className={classes.login}>
          <UserCard showLogo classes={classes}>
            <PasswordExpireForm handleModalClose={handleLoginClick} />
          </UserCard>
        </Modal>
      )}
    </>
  );
}

export default connect(mapStateToProps)(PasswordNotifyModal);
