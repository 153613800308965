import update from 'immutability-helper';

import { hexToRgba, colors, chartColors } from '@apps/shared/src/style';
import * as b from './benchmarkActions';
import {
  generateProviderLabel,
  getProviderName,
  formatMoneyShort,
  formatRatios,
  filterQualityTicks,
  filterLeftTicks,
} from '../shared/formats';
import { maxProviders } from '../shared/theme';

export function generateInitialState() {
  return {
    claimType: '',
    procedureQuery: '',
    providerQuery: '',
    providerSelectIsOpen: false,
    procedureSelectIsOpen: false,
    providerSelectPending: 0,
    procedureSelectPending: 0,
    detailsIsOpen: false,
    procedureOptions: [],
    providerOptions: [],
    chartLoading: 0,
    selectedProcedure: 'weighted average',
    useRatios: false,
    sortOrder: 'quality',
    details: { info: {}, costreport: {}, quality: {} },
    hiddenData: [],
    canShowLabels: false,
    showLabels: true,
    optionsOpen: true,
    dataSelectionOpen: true,
    outpatientCodeType: 'Diagnosis',
    professionalCodeType: 'HCPCS',
    ...emptyChartData(),
  };
}

function emptyChartOptions(type) {
  return {
    title: {
      display: true,
      text: `${type || 'MediVI'} Market Benchmarks`,
      fontSize: 16,
      fontColor: colors.black,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
            canDisplay: false,
            callback: getProviderName,
            beginAtZero: true,
            autoSkip: false,
          },
        },
      ],
      yAxes: [
        {
          id: 'left',
          ticks: {
            beginAtZero: true,
            callback: formatMoneyShort,
          },
          afterBuildTicks: filterLeftTicks,
        },
        {
          id: 'quality',
          position: 'right',
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
          },
          afterBuildTicks: filterQualityTicks,
          scaleLabel: {
            labelString: 'Quality',
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
    pan: {
      enabled: true,
      drag: true,
      mode: 'x',
      speed: 1.25,
    },
    zoom: {
      enabled: true,
      drag: false,
      mode: 'x',
      sensitivity: 0.02,
    },
    maintainAspectRatio: false,
    responsive: true,
    hover: {
      onHover: (e, i) => {
        e.target.style.cursor = i[0] ? 'pointer' : 'default';
      },
    },
  };
}

function emptyChartData(type = 'Inpatient') {
  return {
    chart: { bars: [], lines: [], meta: [] },
    data: { labels: [], datasets: [] },
    providerSelections: [],
    procedureSelections: [],
    providerData: { providers: [], excludedProviders: [] },
    viewStart: 0,
    viewEnd: 0,
    spotsLeft: 0,
    spotsRight: 0,
    options: emptyChartOptions(type),
  };
}

function benchmarkReducer(state = generateInitialState(), action) {
  const { type, payload, meta } = action;
  let spotsLeft, spotsRight, viewStart, viewEnd, atMax;
  switch (type) {
    case b.SET_CLAIM_TYPE: {
      if (meta.type === state.claimType) return state;

      const newChartElements = saveAndSwapChartElements(meta.type, {
        claimType: state.claimType,
        providerSelections: state.providerSelections,
        procedureSelections: state.procedureSelections,
        selectedProcedure: state.selectedProcedure,
        useRatios: state.useRatios,
        sortOrder: state.sortOrder,
      });

      return {
        ...state,
        sortOrder: determineNewSortOrder(meta.type, state.sortOrder),
        ...newChartElements,
        providerOptions: [],
        procedureOptions: [],
      };
    }

    case b.ADD_PROVIDER_FILTER:
      return {
        ...state,
        providerSelections: [...state.providerSelections, meta.filter],
        providerSelectIsOpen: false,
      };
    case b.ADD_PROCEDURE_FILTER:
      return {
        ...state,
        procedureSelections: [...state.procedureSelections, meta.filter],
        procedureSelectIsOpen: false,
      };
    case b.REMOVE_PROVIDER_FILTER:
      return {
        ...state,
        providerSelections: [
          ...state.providerSelections.slice(0, meta.index),
          ...state.providerSelections.slice(meta.index + 1),
        ],
        procedureOptions: [],
      };
    case b.REMOVE_PROCEDURE_FILTER:
      return {
        ...state,
        procedureSelections: [
          ...state.procedureSelections.slice(0, meta.index),
          ...state.procedureSelections.slice(meta.index + 1),
        ],
        providerOptions: [],
      };

    case b.UPDATE_SELECTED_PROCEDURE: {
      const updatedSelectedProcedureChart = transformChart(
        state.providerData,
        meta.selectedProcedure,
        state.useRatios,
        state.sortOrder
      );
      const selProcData = getData(updatedSelectedProcedureChart, state.hiddenData);
      return {
        ...state,
        chart: updatedSelectedProcedureChart,
        data: selProcData,
        selectedProcedure: meta.selectedProcedure,
        options: {
          ...updateOptionsLabels(
            state.options,
            selProcData.labels,
            0,
            selProcData.labels.length - 1,
            state.canShowLabels,
            state.showLabels
          ),
          title: {
            ...state.options.title,
            text: getTitle({
              claimType: state.claimType,
              selectedProcedure: meta.selectedProcedure,
              procedureSelections: state.procedureSelections,
              providerSelections: state.providerSelections,
              providerData: state.providerData,
              professionalCodeType: state.professionalCodeType,
              outpatientCodeType: state.outpatientCodeType,
            }),
          },
        },
      };
    }

    case b.TOGGLE_USE_RATIOS: {
      const newSort = getSortOrderAfterRatioChange(
        state.sortOrder,
        state.claimType,
        state.useRatios
      );
      const updatedRatioChart = transformChart(
        state.providerData,
        state.selectedProcedure,
        !state.useRatios,
        newSort
      );
      const indexToUpdate = state.options.scales.yAxes.findIndex(y => y.id === 'left');
      const newCallback = !state.useRatios ? formatRatios : formatMoneyShort;
      const newData = getData(updatedRatioChart, state.hiddenData);
      return update(state, {
        options: {
          scales: {
            yAxes: { [indexToUpdate]: { ticks: { callback: { $set: newCallback } } } },
            xAxes: {
              0: {
                ticks: {
                  max: { $set: newData.labels[state.data.labels.length - 1] },
                  min: { $set: newData.labels[0] },
                },
              },
            },
          },
        },
        useRatios: { $set: !state.useRatios },
        sortOrder: { $set: newSort },
        chart: { $set: updatedRatioChart },
        data: { $set: newData },
      });
    }
    case b.CLOSE_PROCEDURE_AUTOCOMPLETE:
      return { ...state, procedureSelectIsOpen: false, procedureQuery: '', procedureOptions: [] };
    case b.CLOSE_PROVIDER_AUTOCOMPLETE:
      return { ...state, providerSelectIsOpen: false, providerQuery: '' };
    case b.OPEN_PROCEDURE_AUTOCOMPLETE:
      return { ...state, procedureSelectIsOpen: true, providerOptions: [] };
    case b.OPEN_PROVIDER_AUTOCOMPLETE:
      return { ...state, providerSelectIsOpen: true, procedureOptions: [] };
    case b.CLOSE_DETAILS_DIALOG:
      return { ...state, detailsIsOpen: false };

    case b.SEARCH_PROVIDER_CANCEL:
      return {
        ...state,
        providerQuery: meta.query,
        providerOptions: [],
        providerSelectIsOpen: false,
      };
    case b.SEARCH_PROVIDER_PENDING:
      return {
        ...state,
        providerQuery: meta.query,
        providerSelectIsOpen: true,
        providerSelectPending: state.providerSelectPending + 1,
      };
    case b.SEARCH_PROVIDER_FULFILLED: {
      if (state.providerQuery === meta.query)
        return {
          ...state,
          providerOptions: payload.data,
          providerSelectPending: state.providerSelectPending - 1,
        };
      if (state.providerSelectPending > 0)
        return { ...state, providerSelectPending: state.providerSelectPending - 1 };
      return state;
    }

    case b.SEARCH_PROVIDER_REJECTED: {
      if (state.providerQuery === meta.query)
        return {
          ...state,
          providerOptions: [],
          providerSelectPending: state.providerSelectPending - 1,
        };
      if (state.providerSelectPending > 0)
        return { ...state, providerSelectPending: state.providerSelectPending - 1 };
      return state;
    }

    case b.SEARCH_PROCEDURE_CANCEL:
      return {
        ...state,
        procedureQuery: meta.query,
        procedureOptions: [],
        procedureSelectIsOpen: false,
      };
    case b.SEARCH_PROCEDURE_PENDING:
      return {
        ...state,
        procedureQuery: meta.query,
        procedureSelectIsOpen: true,
        procedureSelectPending: state.procedureSelectPending + 1,
      };
    case b.SEARCH_PROCEDURE_FULFILLED: {
      if (state.procedureQuery === meta.query)
        return {
          ...state,
          procedureOptions: payload.data,
          procedureSelectPending: state.procedureSelectPending - 1,
        };
      if (state.procedureSelectPending > 0)
        return { ...state, procedureSelectPending: state.procedureSelectPending - 1 };
      return state;
    }
    case b.SEARCH_PROCEDURE_REJECTED: {
      if (state.procedureQuery === meta.query)
        return {
          ...state,
          procedureOptions: [],
          procedureSelectPending: state.procedureSelectPending - 1,
        };
      if (state.procedureSelectPending > 0)
        return { ...state, procedureSelectPending: state.procedureSelectPending - 1 };
      return state;
    }

    case b.GET_CHART_INPATIENT_PENDING:
    case b.GET_CHART_OUTPATIENT_PENDING:
    case b.GET_CHART_PROFESSIONAL_PENDING:
      return { ...state, chartLoading: state.chartLoading + 1 };

    case b.GET_CHART_INPATIENT_FULFILLED:
    case b.GET_CHART_OUTPATIENT_FULFILLED:
    case b.GET_CHART_PROFESSIONAL_FULFILLED: {
      const providerData =
        payload && payload.data !== '' ? payload.data : { providers: [], excludedProviders: [] };
      const updatedChart = transformChart(
        providerData,
        state.selectedProcedure,
        state.useRatios,
        state.sortOrder
      );
      return updateChartInState(updatedChart, {
        ...{ ...state, chartLoading: state.chartLoading - 1 },
        providerData,
      });
    }
    case b.GET_CHART_INPATIENT_REJECTED:
    case b.GET_CHART_OUTPATIENT_REJECTED:
    case b.GET_CHART_PROFESSIONAL_REJECTED:
      return {
        ...state,
        providerData: { providers: [], excludedProviders: [] },
        chart: { bars: [], lines: [], meta: [] },
        chartLoading: state.chartLoading - 1,
      };

    case b.SCRUB_CHART:
      return { ...state, ...emptyChartData(state.claimType) };

    case b.GET_PROVIDER_DETAIL_FULFILLED:
      return { ...state, detailsIsOpen: true, details: payload.data };

    case b.CAN_SHOW_LABELS:
      return {
        ...state,
        canShowLabels: payload.canShowLabels,
        options: updateOptionsLabels(
          state.options,
          state.data.labels,
          payload.minIndex,
          payload.maxIndex,
          payload.canShowLabels,
          state.showLabels
        ),
      };
    case b.SHOW_LABELS:
      return {
        ...state,
        showLabels: payload === 'true',
        options: updateOptionsLabels(
          state.options,
          state.data.labels,
          state.viewStart,
          state.viewEnd,
          state.canShowLabels,
          payload === 'true'
        ),
      };

    case b.ZOOM_CHART: {
      const z = calculateZoom(state, payload);
      return {
        ...state,
        ...z,
        options: updateOptionsPosition(state.options, state.data.labels, z.viewStart, z.viewEnd),
      };
    }
    case b.RESET_CHART_ZOOM:
      atMax = state.data.labels.length > maxProviders;
      viewStart = atMax ? state.viewStart : 0;
      viewEnd = atMax ? maxProviders - 1 + viewStart : state.data.labels.length - 1;
      spotsLeft = atMax ? viewStart : 0;
      spotsRight = atMax ? state.data.labels.length - maxProviders : 0;
      return {
        ...state,
        viewStart,
        viewEnd,
        spotsLeft,
        spotsRight,
        options: updateOptionsPosition(state.options, state.data.labels, viewStart, viewEnd),
      };

    case b.PAN_CHART:
      return { ...state, ...processPan(state, payload) };

    case b.UPDATE_CHART_POSITION: {
      const { minIndex } = payload;
      let { maxIndex } = payload;
      if (maxIndex < 0) maxIndex = 0;
      if (maxIndex - minIndex > maxProviders) maxIndex = minIndex + maxProviders - 1;
      const shiftLeft = minIndex - state.viewStart;
      const shiftRight = maxIndex - state.viewEnd;
      const newMax = getMaxValue(state, minIndex, maxIndex);
      const roundTo = state.useRatios ? 1 : 100000;
      const roundedMax =
        newMax === 0
          ? 10000 // set a reasonable default
          : Math.ceil(((newMax * 1.05) / roundTo) * roundTo);
      return update(state, {
        viewStart: { $set: minIndex },
        viewEnd: { $set: maxIndex },
        spotsLeft: { $set: state.spotsLeft + shiftLeft },
        spotsRight: { $set: state.spotsRight - shiftRight },
        options: {
          scales: {
            yAxes: { 0: { ticks: { max: { $set: roundedMax } } } },
            xAxes: {
              0: {
                ticks: {
                  min: { $set: state.data.labels[minIndex] },
                  max: { $set: state.data.labels[maxIndex] },
                },
              },
            },
          },
        },
      });
    }

    case b.CHANGE_SORT_ORDER:
      return updateChartInState(
        transformChart(state.providerData, state.selectedProcedure, state.useRatios, payload),
        { ...state, sortOrder: payload }
      );

    case b.TOGGLE_OPTIONS_OPEN:
      return { ...state, optionsOpen: !state.optionsOpen };

    case b.TOGGLE_DATA_SELECTION_OPEN:
      return { ...state, dataSelectionOpen: !state.dataSelectionOpen };

    case b.OUTPATIENT_CODE_TYPE:
      return {
        ...state,
        outpatientCodeType: payload,
        procedureSelections: [],
        procedureOptions: [],
      };

    case b.PROFESSIONAL_CODE_TYPE:
      return {
        ...state,
        professionalCodeType: payload,
        procedureSelections: [],
        procedureOptions: [],
      };

    case b.TOGGLE_HIDDEN_DATA:
      return { ...state, hiddenData: updateHiddenData(state.hiddenData, payload) };

    default:
      return state;
  }
}

function updateHiddenData(arr, label) {
  if (arr.indexOf(label) === -1) arr.push(label);
  else arr.splice(arr.findIndex(el => el === label));
  return arr;
}

function sortProviderData(providerData, sortOrder, selectedProcedure) {
  let condition;
  if (sortOrder === 'quality') condition = prov => prov[sortOrder];
  else {
    const aggregate = selectedProcedure === 'average' ? 'simpleAggregate' : 'weightedAggregate';
    if (sortOrder.slice(-5) === 'Ratio')
      condition = prov =>
        prov[aggregate].billedAvg / prov[aggregate][`${sortOrder.slice(0, -5)}Avg`];
    else condition = p => p[aggregate][sortOrder];
  }
  providerData.providers.sort((a, b) => {
    if (condition(a) > condition(b)) return -1;
    if (condition(a) === condition(b)) return 0;
    return 1;
  });
  return providerData;
}

function transformChart(providerData, selectedProcedure, useRatios, sortOrder) {
  if (!providerData || !providerData.providers) return { bars: [], lines: [], meta: [] };
  const transform = useRatios ? transformChartRatios : transformChartRaw;
  const isProfessional =
    providerData.providers &&
    providerData.providers[0] &&
    providerData.providers[0].id.length !== 6;
  const sortedProviderData = sortProviderData(providerData, sortOrder, selectedProcedure);
  if (!selectedProcedure || selectedProcedure === 'average') {
    return transform(
      sortedProviderData,
      provider => provider.simpleAggregate || {},
      isProfessional
    );
  }
  return transform(
    sortedProviderData,
    provider => provider.weightedAggregate || {},
    isProfessional
  );
}

function updateOptionsLabels(options, labels, minIndex, maxIndex, canShowLabels, showLabels) {
  return {
    ...options,
    scales: {
      ...options.scales,
      xAxes: [
        ...options.scales.xAxes.map((el, i) => {
          let x = null;
          if (i === 0)
            x = {
              ...el,
              ticks: {
                ...el.ticks,
                min: labels[minIndex],
                max: labels[maxIndex],
                display: showLabels && canShowLabels,
                canDisplay: canShowLabels,
              },
            };
          return x;
        }),
      ],
    },
  };
}

function updateOptionsPosition(options, labels, minIndex, maxIndex) {
  return {
    ...options,
    scales: {
      ...options.scales,
      xAxes: [
        ...options.scales.xAxes.map((el, i) => {
          let y = null;
          if (i === 0)
            y = {
              ...el,
              ticks: {
                ...el.ticks,
                min: labels[minIndex],
                max: labels[maxIndex],
              },
            };
          return y;
        }),
      ],
    },
  };
}

export function transformChartRaw(providerData, getProcedure, isProfessional) {
  const billed = [];
  const cost = [];
  const medicare = [];
  const quality = [];
  const meta = [];
  providerData.providers.forEach(provider => {
    const procedure = getProcedure(provider);
    billed.push(procedure.billedAvg);
    cost.push(procedure.costAvg);
    medicare.push(procedure.medicareAvg);
    quality.push(provider.quality);
    const data = [{ label: 'Claims', value: procedure.numClaims || 0 }];
    if (procedure.lengthOfStay) {
      data.push({ label: 'Length of Stay', value: procedure.lengthOfStay });
    }
    meta.push({ id: provider.id, name: provider.name, data });
  });

  return isProfessional
    ? {
        bars: [
          { label: 'Medicare Average', values: medicare, axis: 'left' },
          { label: 'Billed Average', values: billed, axis: 'left' },
        ],
        lines: [],
        meta,
      }
    : {
        bars: [
          { label: 'Cost Average', values: cost, axis: 'left', hidden: true },
          { label: 'Medicare Average', values: medicare, axis: 'left' },
          { label: 'Billed Average', values: billed, axis: 'left' },
        ],
        lines: [{ label: 'Quality', values: quality, axis: 'quality' }],
        meta,
      };
}

export function transformChartRatios(providerData, getProcedure, isProfessional) {
  const billedToMedicare = [];
  const billedToCost = [];
  const quality = [];
  const meta = [];
  providerData.providers.forEach(provider => {
    const procedure = getProcedure(provider);
    billedToMedicare.push(procedure.billedAvg / procedure.medicareAvg);
    billedToCost.push(procedure.billedAvg / procedure.costAvg);
    quality.push(provider.quality);
    const data = [{ label: 'Claims', value: procedure.numClaims || 0 }];
    if (procedure.lengthOfStay) {
      data.push({ label: 'Length of Stay', value: procedure.lengthOfStay });
    }
    meta.push({ id: provider.id, name: provider.name, data });
  });

  return isProfessional
    ? {
        bars: [{ label: 'Billed as Medicare Multiple', values: billedToMedicare, axis: 'left' }],
        lines: [],
        meta,
      }
    : {
        bars: [
          { label: 'Billed as Cost Multiple', values: billedToCost, axis: 'left' },
          { label: 'Billed as Medicare Multiple', values: billedToMedicare, axis: 'left' },
        ],
        lines: [{ label: 'Quality', values: quality, axis: 'quality' }],
        meta,
      };
}

function calculateZoom(state, payload) {
  let visibleRange = state.viewEnd - state.viewStart;
  if (visibleRange < 1) visibleRange = 1;
  const range = state.chart.meta.length;
  const viewOffset = Math.ceil(visibleRange * payload.percent) * payload.polarity;
  let viewStart = state.viewStart + viewOffset;
  let viewEnd = state.viewEnd - viewOffset;
  if (state.viewStart === state.viewEnd && payload.polarity === 1) {
    ({ viewStart, viewEnd } = state);
  } // already at max zoom. do nothing
  if (viewStart > viewEnd) {
    viewStart = viewEnd;
  } // not enought room to zoom in, show only one
  if (viewEnd - viewStart > range) {
    viewStart = 0;
    viewEnd = range - 1;
  } // not enough total room to zoom out, reset to 100%
  if (viewStart < 0) {
    viewEnd -= viewStart;
    viewStart = 0;
  } // no room to the left, shift zoom right
  if (viewEnd >= range) {
    viewStart -= viewEnd - range;
    viewEnd = range - 1;
  } // no room right, shift zoom left
  if (viewEnd - viewStart > maxProviders) {
    ({ viewStart } = state);
    viewEnd = state.viewStart + maxProviders - 1;
  } // at maxProvider count, limit zoom

  return {
    viewStart,
    viewEnd,
    spotsLeft: viewStart,
    spotsRight: range - viewEnd - 1,
  };
}

function processPan(state, payload) {
  let visibleRange = state.viewEnd - state.viewStart;
  if (visibleRange < 1) visibleRange = 1;
  const panRange = state.chart.meta.length;
  const panOffset = Math.ceil(payload.percent * visibleRange) * payload.direction;
  let viewStart = state.viewStart + panOffset;
  let viewEnd = state.viewEnd + panOffset;
  let spotsLeft = state.spotsLeft + panOffset;
  let spotsRight = state.spotsRight - panOffset;

  if (payload.direction === -1 && state.viewStart < payload.places) {
    // pan left without enough space
    viewEnd = state.viewEnd - state.viewStart + 1;
    viewStart = 0;
    spotsLeft = 0;
    spotsRight = state.viewStart + state.spotsRight;
  } else if (payload.direction === 1 && panRange - state.viewEnd < payload.places) {
    // pan right without enough space
    viewStart = state.viewStart + panRange - state.viewEnd;
    viewEnd = panRange - 1;
    spotsRight = 0;
    spotsLeft = state.spotsLeft + panRange - state.viewEnd - 1;
  }
  return {
    viewStart,
    viewEnd,
    spotsLeft,
    spotsRight,
    options: updateOptionsPosition(state.options, state.data.labels, viewStart, viewEnd),
  };
}

function getTitle({
  claimType,
  selectedProcedure,
  procedureSelections,
  providerSelections,
  providerData,
  outpatientCodeType = 'Diagnosis',
  professionalCodeType = 'Diagnosis',
}) {
  const procedureName = (claimType => {
    switch (claimType) {
      case 'Outpatient':
        return outpatientCodeType;
      case 'Professional':
        return professionalCodeType;
      default:
        return 'DRG';
    }
  })(claimType);
  const number = providerData && providerData.providers ? providerData.providers.length : 0;
  let facilityType = claimType === 'Professional' ? 'physician' : 'hospital';
  if (number === 0 || number > 1) facilityType += 's';
  const selectedCodes =
    procedureSelections.length > 0
      ? procedureSelections.map(i => i.code).join(', ')
      : selectedProcedure;
  let geos = providerSelections
    .filter(provider => provider.matches > 0)
    .map(i =>
      i.type === 'Provider' ? i.name.substring(i.name.indexOf('-') + 2) : i.name.replace(',', '')
    );
  geos =
    geos.length > 2
      ? geos
          .splice(0, 2)
          .concat([
            `and ${providerSelections.length - 2} other${
              providerSelections.length - 2 === 1 ? '' : 's'
            }`,
          ])
      : geos;
  const geosText =
    geos.length === 0
      ? ''
      : `in ${geos.length === 1 || geos.length === 3 ? geos.join(', ') : geos.join(' and ')}`;

  return `${claimType} ${procedureName} ${selectedCodes} across ${number} ${facilityType} ${geosText}`;
}

function getData({ bars, lines, meta }, hiddenData) {
  return {
    labels: meta.map(meta => generateProviderLabel(meta)),
    datasets: [
      ...bars.map((bar, i) => ({
        label: bar.label,
        data: bar.values,
        hidden: hiddenData.indexOf(bar.label) >= 0,
        yAxisID: 'left',
        backgroundColor: hexToRgba(chartColors.bar[i], 0.7),
        borderColor: chartColors.bar[i],
        pointStyle: 'rect',
      })),
      ...lines.map((line, i) => ({
        type: 'line',
        label: line.label,
        data: line.values,
        hidden: hiddenData.indexOf(line.label) >= 0,
        yAxisID: 'quality',
        fill: false,
        spanGaps: false,
        lineTension: 0,
        backgroundColor: hexToRgba(chartColors.line[i], 0.7),
        borderColor: chartColors.line[i],
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointHitRadius: 4,
        borderWidth: 3,
        showLine: true,
        pointHoverRadius: 3,
        pointRadius: 0,
      })),
    ],
  };
}
function updateChartInState(updatedChart, state) {
  const { claimType, selectedProcedure, procedureSelections, providerSelections, providerData } =
    state;
  const viewStart = 0;
  let viewEnd;
  if (updatedChart.meta.length < 0) viewEnd = 0;
  else if (updatedChart.meta.length > maxProviders) viewEnd = maxProviders - 1;
  else viewEnd = updatedChart.meta.length - 1;

  const spotsRight =
    updatedChart.meta.length > maxProviders ? updatedChart.meta.length - maxProviders : 0;
  const data = getData(updatedChart, state.hiddenData);
  return {
    ...state,
    chart: updatedChart,
    data,
    providerSelections: updateSelections(
      providerSelections,
      providerData.providerAutocompleteSelections
    ),
    viewStart,
    viewEnd,
    spotsLeft: 0,
    spotsRight,
    options: {
      ...state.options,
      scales: {
        ...state.options.scales,
        xAxes: [
          {
            ticks: {
              ...state.options.scales.xAxes[0].ticks,
              min: data.labels[0],
              max: data.labels[viewEnd],
            },
          },
        ],
      },
      title: {
        ...state.options.title,
        text: getTitle({
          claimType,
          selectedProcedure,
          procedureSelections,
          providerSelections,
          providerData,
          professionalCodeType: state.professionalCodeType,
          outpatientCodeType: state.outpatientCodeType,
        }),
      },
    },
  };
}
function updateSelections(selections, updatedSelections) {
  if (!updatedSelections) return selections;
  const updates = [...selections];
  selections.forEach((el, i) => {
    updates[i].matches = updatedSelections[i].matches;
  });
  return updates;
}

function getMaxValue(state, minIndex, maxIndex) {
  let newMax = 0;
  if (state.data.datasets && state.data.datasets.length > 0) {
    const bars = [];
    for (let i = 0; i < state.data.datasets.length; i++) {
      if (state.data.datasets[i].type !== 'line') bars.push(i);
    }
    if (state.hiddenData && state.hiddenData.length > 0) {
      state.hiddenData.forEach(label => {
        const barIndex = bars.findIndex(
          b => b === state.chart.bars.findIndex(c => c.label === label)
        );
        if (barIndex >= 0) bars.splice(barIndex, 1);
      });
    }
    bars.forEach(i => {
      state.data.datasets[i].data.slice(minIndex, maxIndex + 1).forEach(n => {
        if (n > newMax) newMax = n;
      });
    });
  }
  return newMax;
}

function determineNewSortOrder(claimType, oldSortOrder) {
  return claimType === 'Professional' &&
    (oldSortOrder === 'quality' || oldSortOrder === 'costRatio' || oldSortOrder === 'costAvg')
    ? 'numClaims'
    : oldSortOrder;
}

function saveAndSwapChartElements(newClaimType, oldChartElements) {
  setChartElements(oldChartElements);
  return getChartElements(newClaimType);
}

function setChartElements(chartElements) {
  if (chartElements.claimType)
    window.localStorage.setItem(chartElements.claimType, JSON.stringify(chartElements));
}

function getChartElements(claimType) {
  const elements = window.localStorage.getItem(claimType);
  return elements
    ? JSON.parse(elements)
    : {
        claimType,
        providerSelections: [],
        procedureSelections: [],
      };
}

function getSortOrderAfterRatioChange(sortOrder, claimType, useRatios) {
  if (sortOrder === 'quality' || sortOrder === 'billedAvg') {
    if (claimType === 'Professional') return 'medicareRatio';
    return 'quality';
  }
  if (sortOrder === 'numClaims') return 'numClaims';
  if (useRatios === false) return `${sortOrder.slice(0, -3)}Ratio`;
  return `${sortOrder.slice(0, -5)}Avg`;
}

export default benchmarkReducer;
