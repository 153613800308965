import p, { SetPlanState, ResetPlanPage } from './types/planActions';
import { Plan } from './types/plan';

export function setPlanState(plan: Plan): SetPlanState {
  return {
    type: p.SET_PLAN_STATE,
    payload: plan,
  };
}

export function resetPlanPage(): ResetPlanPage {
  return {
    type: p.RESET_PLAN_PAGE,
  };
}
