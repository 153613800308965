import React from 'react';
import RedirectExternal from '@apps/shared/src/auth/Redirect';

export default function AppRedirectPage(): JSX.Element {
  const userAgent = navigator.userAgent || navigator.vendor;
  let redirectURL = 'https://www.6degreeshealth.com/app';

  // Android detection
  if (/android/i.test(userAgent))
    redirectURL = 'https://play.google.com/store/apps/details?id=com.sixdegreeshealth.medivi';

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent))
    redirectURL = 'https://apps.apple.com/us/app/medivi/id1537890628';

  return <RedirectExternal to={redirectURL} />;
}
