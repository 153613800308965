import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import classnames from 'classnames';
import { Provider } from '../../shared/types/mediviTypes';

type Props = {
  classes: ClassNameMap<'displayItem' | 'addressBlock' | 'address'>;
  provider: Provider;
};

export default function AddressDisplayBlock({ classes, provider }: Props): JSX.Element {
  return (
    <div className={classnames(classes.displayItem, classes.addressBlock)}>
      <div>
        <strong>Address</strong>
      </div>
      <div className={classes.address}>{provider.address}</div>
      <div className={classes.address}>{`${provider.city}, ${provider.state} ${provider.zip}`}</div>
    </div>
  );
}
