import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import UserMenu from './UserMenu';
import NavMenu from './NavMenu';
import NotificationBell from '../notifications/NotificationBell';
import NotificationDropdown from '../notifications/NotificationDropdown';
import WebSocketSubscription from '../notifications/webSocketSubscription';

const column = {
  display: 'flex',
  alignItems: 'center',
};

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 0 0 1.5em',
    width: '100%',
    overflowX: 'auto',
    ...theme.header.root,
  },
  container: {
    minWidth: 'max-content',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    verticalAlign: 'middle',
    marginRight: '1em',
  },
  pageTitle: {
    fontFamily: theme.header.pageTitle.fontFamily,
    color: theme.header.pageTitle.color,
    fontWeight: 'bold',
    marginRight: '0.5em',
  },
  left: { ...column, justifyContent: 'flex-start' },
  center: { ...column, justifyContent: 'center' },
  right: { ...column, justifyContent: 'flex-end', flex: 1 },
});

function Header({
  classes,
  title = '',
  currentUser,
  authBaseURL,
  isLoggedIn,
  isCaseMember,
  accessLevel,
}) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleNotificationClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setAnchorEl(null);
  };
  return (
    <header className={classes.root}>
      <div className={classes.container}>
        <div className={classes.left}>
          <Link to="/">
            <img src="/images/medivi_logo_on_dark.svg" className={classes.logo} alt="logo" />
          </Link>
          <Typography component="h1" variant="h6" className={classes.pageTitle}>
            {title}
          </Typography>
        </div>
        {isLoggedIn && (
          <div className={classes.right}>
            {isCaseMember && accessLevel !== 'Limited Access' && (
              <>
                <WebSocketSubscription />
                <NotificationBell onClick={handleNotificationClick} />
                <NotificationDropdown anchorEl={anchorEl} handleClose={handleNotificationClose} />
              </>
            )}
            <UserMenu currentUser={currentUser} authBaseURL={authBaseURL} />
          </div>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  accessLevel: PropTypes.string,
  isCaseMember: PropTypes.bool,
  authBaseURL: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
Header.defaultProps = {
  title: '',
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.user.isLoggedIn,
    currentUser: state.user.currentUser,
    authBaseURL: state.user.authBaseURL,
    accessLevel: state.user.accessLevel,
    isCaseMember: state.user.isCaseMember,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(Header);
