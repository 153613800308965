import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import NumberFormat from 'react-number-format';
import { colors } from '@apps/shared/src/style';

const styles = theme => ({
  formControl: {
    marginBottom: 15,
  },
  inputInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '14px',
  },
  inputUnderline: {
    '&$warning:after': {
      borderBottomColor: colors.yellow,
    },
  },
  warning: {},
});

function NumberFormatFixed(props) {
  // couldn't figure out how to pass in isMoneyFixed to the NumberFormatCustom. With that, this component wouldn't be needed
  const { inputRef, onChange, onKeyDown, onFocus, onPaste, onBlur, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onPaste={onPaste}
      onBlur={onBlur}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

NumberFormatFixed.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, onKeyDown, onFocus, onPaste, onBlur, isMoneyFixed, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onPaste={onPaste}
      onBlur={onBlur}
      thousandSeparator
      decimalScale={isMoneyFixed ? 2 : undefined}
      fixedDecimalScale={isMoneyFixed}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  isMoneyFixed: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
};
NumberFormatCustom.defaultProps = {
  isMoneyFixed: false,
  onBlur: () => undefined,
  onChange: () => undefined,
  onFocus: () => undefined,
  onKeyDown: () => undefined,
  onPaste: () => undefined,
};

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedValue: this.props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ formattedValue: nextProps.value });
  }

  handleChange = name => e => {
    this.setState({ [name]: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleFocus = e => {
    e.target.select();
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  render() {
    const {
      classes,
      label,
      validation = {},
      isNumber,
      isMoney,
      isMoneyFixed,
      isPercent,
      onKeyDown,
      onPaste,
      onBlur,
      disabled,
      margin,
      fullWidth,
      id,
    } = this.props;
    const { formattedValue } = this.state;
    const warningClassName = validation.warning && !validation.error ? classes.warning : null;
    const errorLabel = validation.showValidation && (validation.error || validation.warning);
    let inputComponent;
    if (isNumber || isMoney) inputComponent = NumberFormatCustom;
    else if (isMoneyFixed) inputComponent = NumberFormatFixed;

    return (
      <FormControl
        error={!!errorLabel}
        disabled={disabled}
        margin={margin}
        fullWidth={fullWidth}
        className={classes.formControl}
      >
        <InputLabel htmlFor={id}>{errorLabel || label}</InputLabel>
        <Input
          id={id}
          label={label}
          value={formattedValue}
          onFocus={this.handleFocus}
          onBlur={onBlur}
          onChange={this.handleChange('formattedValue')}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          className={warningClassName}
          type={isMoney || isMoneyFixed || isPercent || isNumber ? 'tel' : undefined}
          // eslint-disable-next-line no-nested-ternary
          // type={isMoney || isMoneyFixed ? 'currency' : isNumber || isPercent ? 'number' : 'text'}
          classes={{
            input: classes.inputInput,
            underline: classes.inputUnderline,
          }}
          inputComponent={inputComponent}
          startAdornment={
            isMoney || isMoneyFixed ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : undefined
          }
          endAdornment={isPercent ? <InputAdornment position="end">%</InputAdornment> : undefined}
        />
      </FormControl>
    );
  }
}

TextInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  isMoney: PropTypes.bool,
  isMoneyFixed: PropTypes.bool,
  isNumber: PropTypes.bool,
  isPercent: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  validation: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
TextInput.defaultProps = {
  id: undefined,
  label: '',
  margin: 'normal',
  isMoneyFixed: false,
  disabled: false,
  fullWidth: false,
  isMoney: false,
  isNumber: false,
  isPercent: false,
  onBlur: () => undefined,
  onChange: () => undefined,
  onFocus: () => undefined,
  onKeyDown: () => undefined,
  onPaste: () => undefined,
  validation: {},
  value: '',
};

export default withStyles(styles)(TextInput);
