import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';

import { formatMoneyNoDecimal, formatCCR, formatConditionalNumber } from '../../shared/formats';
import { providerDetailsType } from '../../shared/types/propTypes';
import DetailPaneDataSourceDates from './DetailPaneDataSourceDates';
import BilledToMedicareDisplayItem from './BilledToMedicareDisplayItem';
import ProfessionalDetailPane from './ProfessionalDetailPane';
import AddressDisplayBlock from './AddressDisplayBlock';

const styles = theme => ({
  root: {
    padding: '1em',
    width: 'calc(100% - 2em)',
    maxWidth: '70em',
  },
  header: {
    margin: '0 0 20px 16px',
    textAlign: 'center',
  },
  tabs: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tab: {
    width: '15em',
    paddingBottom: '1em',
  },
  professionalTab: {
    paddingBottom: '1em',
  },
  cardHeader: {
    backgroundColor: theme.detailPane.cardHeader.backgroundColor,
    fontSize: '1.1em',
    padding: '.5em .5em .5em 1em',
  },
  displayItem: {
    fontSize: '.9em',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '1em',
    marginRight: '.25em',
    justifyItems: 'center',
  },
  address: {
    marginLeft: '0.5em',
  },
  addressBlock: {
    flexDirection: 'column',
  },
  taxonomyBlock: {
    fontSize: '.9em',
  },
  taxonomy: {
    margin: 0,
    padding: '0 1em 0 .5em',
  },
  noData: {
    fontSize: 12,
    marginLeft: 16,
  },
  tabDesc: {
    marginTop: 0,
  },
  groupHeader: {
    margin: '8px 4px 0 16px',
    fontSize: '1em',
  },
  groupData: {
    margin: '8px 4px 0 24px',
  },
});

export function DisplayItem(props) {
  const { label, value, classes } = props;
  return (
    <div className={classes.displayItem}>
      <strong>{label}</strong> {value}
    </div>
  );
}

DisplayItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.string,
};
DisplayItem.defaultProps = {
  value: '',
};

function CostCenter({ label, value, classes }) {
  return (
    <>
      <h3 className={classes.groupHeader}>{label}</h3>
      {value && (value.charges || value.costs || value.days) ? (
        <section className={classes.groupData}>
          <DisplayItem
            label="Charges"
            value={formatMoneyNoDecimal(value.totalCharges)}
            classes={classes}
          />
          <DisplayItem
            label="Costs"
            value={formatMoneyNoDecimal(value.totalCosts)}
            classes={classes}
          />
          <DisplayItem
            label="Days"
            value={formatConditionalNumber(value.days, '0,0')}
            classes={classes}
          />
          <DisplayItem
            label="Charge/Day"
            value={formatMoneyNoDecimal(value.totalCharges / value.days)}
            classes={classes}
          />
          <DisplayItem
            label="Cost/Day"
            value={formatMoneyNoDecimal(value.totalCosts / value.days)}
            classes={classes}
          />
        </section>
      ) : (
        <div className={classes.noData}>No data available</div>
      )}
    </>
  );
}

CostCenter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({
    charges: PropTypes.number,
    costs: PropTypes.number,
    days: PropTypes.number,
    totalCharges: PropTypes.number,
    totalCosts: PropTypes.number,
  }),
};
CostCenter.defaultProps = {
  label: '',
  value: {},
};

function Transplant({ label, value, classes }) {
  const total = ((value && value.live) || 0) + ((value && value.cadaver) || 0);
  return (
    <>
      <h3 className={classes.groupHeader}>{label}</h3>
      {value && (value.cost || value.charge || value.living || value.cadaveric) ? (
        <section className={classes.groupData}>
          <DisplayItem
            label="Acquisition Cost"
            value={total !== 0 ? formatMoneyNoDecimal(value.cost / total) : 'not available'}
            classes={classes}
          />
          <DisplayItem
            label="Acquisition Charge"
            value={total !== 0 ? formatMoneyNoDecimal(value.charges / total) : 'not available'}
            classes={classes}
          />
          <DisplayItem
            label="Living Count"
            value={formatConditionalNumber(value.live, '0,0') || 0}
            classes={classes}
          />
          <DisplayItem
            label="Cadaveric Count"
            value={formatConditionalNumber(value.cadaver, '0,0') || 0}
            classes={classes}
          />
          <DisplayItem
            label="Total Cost"
            value={formatMoneyNoDecimal(value.cost)}
            classes={classes}
          />
          <DisplayItem
            label="Total Charges"
            value={formatMoneyNoDecimal(value.charges)}
            classes={classes}
          />
        </section>
      ) : (
        <div className={classes.noData}>No data available</div>
      )}
    </>
  );
}

Transplant.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.shape({
    cadaver: PropTypes.number,
    cadaveric: PropTypes.number,
    charge: PropTypes.number,
    charges: PropTypes.number,
    cost: PropTypes.number,
    live: PropTypes.number,
    living: PropTypes.number,
  }),
};
Transplant.defaultProps = {
  label: '',
  value: {},
};

function DetailPane({ classes, isOpen, handleClose, details, claimType }) {
  const { info = {}, costreport = {}, quality = {} } = details;
  const pctBilled =
    costreport.patientRevenue && costreport.patientRevenue !== 0
      ? (1 - costreport.patientDiscounts / costreport.patientRevenue) * 100
      : 0;

  if (claimType === 'Professional')
    return (
      <ProfessionalDetailPane
        isOpen={isOpen}
        onClose={handleClose}
        classes={classes}
        provider={info}
      />
    );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      maxWidth={false}
    >
      <aside className={classes.root}>
        <h2 className={classes.header}>{info.name}</h2>
        <section className={classes.tabs}>
          <Card className={classes.tab}>
            <div className={classes.cardHeader}>General Information</div>
            <h3 className={classes.groupHeader}>Overview</h3>
            <section className={classes.groupData}>
              <DisplayItem
                label="Provider ID"
                value={costreport.providerNumber}
                classes={classes}
              />
              <AddressDisplayBlock classes={classes} provider={info} />

              <DisplayItem
                label="Beds"
                value={formatConditionalNumber(info.numBeds, '0,0')}
                classes={classes}
              />
              <DisplayItem label="Type" value={info.type} classes={classes} />
            </section>

            <h3 className={classes.groupHeader}>Billed to Medicare Ratios</h3>
            <section className={classes.groupData}>
              <BilledToMedicareDisplayItem
                classes={classes}
                claimType="Inpatient"
                ratio={details.inpatientBilledToMedicare}
              />
              <BilledToMedicareDisplayItem
                classes={classes}
                claimType="Outpatient"
                ratio={details.outpatientBilledToMedicare}
              />
            </section>

            <DetailPaneDataSourceDates
              classes={classes}
              inpatientFYEndDate={info?.inpatientFYEndDate}
              outpatientFYEndDate={info?.outpatientFYEndDate}
              costReportFY={info?.costReportFY}
            />

            <h3 className={classes.groupHeader}>Financials</h3>
            <section className={classes.groupData}>
              <DisplayItem
                label="Total charges"
                value={formatMoneyNoDecimal(costreport.totalCharge)}
                classes={classes}
              />
              <DisplayItem
                label="Total costs"
                value={formatMoneyNoDecimal(costreport.totalCost)}
                classes={classes}
              />
              <DisplayItem
                label="Net income"
                value={formatMoneyNoDecimal(costreport.netIncome)}
                classes={classes}
              />
              <div>&nbsp;</div>
              <DisplayItem
                label="Patient revenue"
                value={formatMoneyNoDecimal(costreport.patientRevenue)}
                classes={classes}
              />
              <DisplayItem
                label="Patient discounts"
                value={formatMoneyNoDecimal(costreport.patientDiscounts)}
                classes={classes}
              />
              <DisplayItem
                label="Operating expenses"
                value={formatMoneyNoDecimal(costreport.operatingExpenses)}
                classes={classes}
              />
              <DisplayItem
                label="Percent of billed after discounts"
                value={formatConditionalNumber(pctBilled / 100, '(0.00%)')}
                classes={classes}
              />
            </section>

            <h3 className={classes.groupHeader}>Quality</h3>
            <section className={classes.groupData}>
              <DisplayItem
                label="Total quality"
                value={formatConditionalNumber(quality.totalPerformanceScore)}
                classes={classes}
              />
              <DisplayItem
                label="Clinical"
                value={formatConditionalNumber(quality.clinicalCare)}
                classes={classes}
              />
              <DisplayItem
                label="Care coordination / experience"
                value={formatConditionalNumber(quality.careCoordination)}
                classes={classes}
              />
              <DisplayItem
                label="Safety"
                value={formatConditionalNumber(quality.safety)}
                classes={classes}
              />
              <DisplayItem
                label="Efficiency"
                value={formatConditionalNumber(quality.efficiency)}
                classes={classes}
              />
            </section>
          </Card>

          <Card className={classes.tab}>
            <div className={classes.cardHeader}>Cost to Charge Ratios</div>
            <section className={classes.groupData}>
              <DisplayItem
                label="Total (all departments)"
                value={formatCCR(costreport.total)}
                classes={classes}
              />
              <DisplayItem
                label="OR Room"
                value={formatCCR(costreport.operatingRoom)}
                classes={classes}
              />
              <DisplayItem
                label="Recovery Room"
                value={formatCCR(costreport.recoveryRoom)}
                classes={classes}
              />
              <DisplayItem
                label="Delivery Room"
                value={formatCCR(costreport.laborRoomAndDeliveryRoom)}
                classes={classes}
              />
              <DisplayItem
                label="Anesthesiology"
                value={formatCCR(costreport.anesthesiology)}
                classes={classes}
              />
              <DisplayItem
                label="Radiol. Diag."
                value={formatCCR(costreport.radiologyDiagnostic)}
                classes={classes}
              />
              <DisplayItem
                label="Radiol. Therap."
                value={formatCCR(costreport.radiologyTherapeutic)}
                classes={classes}
              />
              <DisplayItem
                label="Radioisotope"
                value={formatCCR(costreport.radioisotope)}
                classes={classes}
              />
              <DisplayItem
                label="CT Scan"
                value={formatCCR(costreport.computedTomographyScan)}
                classes={classes}
              />
              <DisplayItem
                label="MRI"
                value={formatCCR(costreport.magneticResonanceImaging)}
                classes={classes}
              />
              <DisplayItem
                label="Cardiac Cath"
                value={formatCCR(costreport.cardiacCatheterizaion)}
                classes={classes}
              />
              <DisplayItem
                label="Laboratory"
                value={formatCCR(costreport.laboratory)}
                classes={classes}
              />
              <DisplayItem
                label="PBP Clinical Lab"
                value={formatCCR(costreport.pbpClinicalLaboratoryServicesProgramOnly)}
                classes={classes}
              />
              <DisplayItem
                label="Blood/Packed Blood"
                value={formatCCR(costreport.wholeBloodAndPackedRedBloodCells)}
                classes={classes}
              />
              <DisplayItem
                label="Blood Store"
                value={formatCCR(costreport.bloodStoringProcessingAndTrans)}
                classes={classes}
              />
              <DisplayItem
                label="IV Therapy"
                value={formatCCR(costreport.intravenousTherapy)}
                classes={classes}
              />
              <DisplayItem
                label="Resp. Thera"
                value={formatCCR(costreport.respiratoryTherapy)}
                classes={classes}
              />
              <DisplayItem
                label="Phys. Thera"
                value={formatCCR(costreport.physicalTherapy)}
                classes={classes}
              />
              <DisplayItem
                label="Occ. Thera"
                value={formatCCR(costreport.occupationalTherapy)}
                classes={classes}
              />
              <DisplayItem
                label="Speech Path"
                value={formatCCR(costreport.speechPathology)}
                classes={classes}
              />
              <DisplayItem
                label="EKG"
                value={formatCCR(costreport.electrocardiology)}
                classes={classes}
              />
              <DisplayItem
                label="Electroencephalography"
                value={formatCCR(costreport.electroencephalography)}
                classes={classes}
              />
              <DisplayItem
                label="Medical Supplies"
                value={formatCCR(costreport.medicalSuppliesChargedToPatients)}
                classes={classes}
              />
              <DisplayItem
                label="Implant"
                value={formatCCR(costreport.implantableDevicesChargedToPatients)}
                classes={classes}
              />
              <DisplayItem
                label="Drugs"
                value={formatCCR(costreport.drugsChargedToPatients)}
                classes={classes}
              />
              <DisplayItem
                label="Renal"
                value={formatCCR(costreport.renalDialysis)}
                classes={classes}
              />
              <DisplayItem
                label="Home Dialysis"
                value={formatCCR(costreport.homeProgramDialysis)}
                classes={classes}
              />
              <DisplayItem
                label="Ambulance"
                value={formatCCR(costreport.ambulanceServices)}
                classes={classes}
              />
              <DisplayItem
                label="DME Rent"
                value={formatCCR(costreport.durableMedicalEquipmentRented)}
                classes={classes}
              />
              <DisplayItem
                label="DME Sold"
                value={formatCCR(costreport.durableMedicalEquipmentSold)}
                classes={classes}
              />
            </section>
          </Card>

          <Card className={classes.tab}>
            <div className={classes.cardHeader}>Cost centers</div>
            <CostCenter
              label="Routine Inpatient"
              value={costreport.routineInpatient}
              classes={classes}
            />
            <CostCenter label="Intensive Care" value={costreport.intensiveCare} classes={classes} />
            <CostCenter label="Coronary Care" value={costreport.coronaryCare} classes={classes} />
            <CostCenter label="Burn Care" value={costreport.burnCare} classes={classes} />
            <CostCenter label="Surgical Care" value={costreport.surgicalCare} classes={classes} />
            <CostCenter label="Nursery Care" value={costreport.nurseryCare} classes={classes} />
            <CostCenter
              label="Skilled Nursing"
              value={costreport.skilledNursingFacility}
              classes={classes}
            />
            <CostCenter
              label="Nursing Facility"
              value={costreport.nursingFacility}
              classes={classes}
            />
          </Card>

          <Card className={classes.tab}>
            <div className={classes.cardHeader}>Transplant information</div>
            <Transplant label="Kidney" value={costreport.kidney} classes={classes} />
            <Transplant label="Heart" value={costreport.heart} classes={classes} />
            <Transplant label="Liver" value={costreport.liver} classes={classes} />
            <Transplant label="Pancreas" value={costreport.pancreas} classes={classes} />
            <Transplant label="Lung" value={costreport.lung} classes={classes} />
            <Transplant label="Intestine" value={costreport.intestine} classes={classes} />
          </Card>
        </section>
      </aside>
    </Dialog>
  );
}

DetailPane.propTypes = {
  claimType: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  details: providerDetailsType.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DetailPane);
