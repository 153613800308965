import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CellMeasurer, ColumnSizer, CellMeasurerCache, List, AutoSizer } from 'react-virtualized';
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Plan } from '../../planSelect/types/plan';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  Provider,
  SearchRadius,
  SortCriteria,
  SortDirection,
  TaxonomyDetailsMap,
  RootState,
  Accessibility,
} from '../types/providerSearch';
import ProviderCard from './ProviderCard';

const useStyles = makeStyles({
  listWrapper: {
    marginLeft: '1.5rem',
    width: '100%',
    height: '100%',
  },
  list: {
    outline: 'none',
  },
  providerCard: {
    width: 'calc(50% - 10px)',
    marginBottom: '20px',
  },
  row: {
    display: 'flex',
  },
  noProviders: {
    width: '100%',
    margin: '1rem 2rem',
    textAlign: 'center',
  },
  noLivePlans: {
    maxWidth: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
  },
  loadingSpinner: {
    margin: '3rem auto 0 auto',
  },
  illustrationWrapper: {
    width: '100%',
    display: 'grid',
    placeContent: 'center',
  },
});

const cache = new CellMeasurerCache({
  defaultHeight: 200,
  fixedWidth: true,
});

function detectTestSizing(height: number, width: number): ActualSizing {
  let actualWidth = width;
  let actualHeight = height;
  // fallback height and width for test environments so that List will render
  if (process.env.NODE_ENV === 'test') {
    actualWidth = window.innerWidth;
    actualHeight = window.innerHeight;
  }
  return { actualHeight, actualWidth };
}
type ActualSizing = {
  actualHeight: number;
  actualWidth: number;
};

const mapStateToProps = ({ providerSearch, planSelect }: RootState): StateProps => ({
  selectedZipCode: providerSearch.selectedZipCode,
  searchInput: providerSearch.searchInput,
  radius: providerSearch.radius,
  isLoading: providerSearch.isLoadingProviderList,
  providerList: providerSearch.filteredList,
  sortDirection: providerSearch.sortDirection,
  sortCriteria: providerSearch.sortCriteria,
  selectedAccessibilities: providerSearch.selectedAccessibilities,
  taxonomyDetailsMap: providerSearch.taxonomyDetailsMap,
  selectedProviderType: providerSearch.selectedProviderType,
  selectedPlanID: providerSearch.selectedPlanID,
  plans: planSelect.plans,
});

type StateProps = {
  selectedZipCode: string;
  searchInput: string;
  radius: SearchRadius;
  isLoading: boolean;
  providerList: Provider[];
  sortDirection: SortDirection;
  sortCriteria: SortCriteria;
  selectedAccessibilities: Accessibility[];
  taxonomyDetailsMap: TaxonomyDetailsMap;
  selectedProviderType: string;
  selectedPlanID: string;
  plans: Plan[];
};

type Props = StateProps;

export function ProviderList({
  providerList,
  searchInput,
  selectedZipCode,
  isLoading,
  radius,
  sortDirection,
  sortCriteria,
  selectedAccessibilities,
  taxonomyDetailsMap,
  selectedProviderType,
  selectedPlanID,
  plans,
}: Props): JSX.Element {
  const classes = useStyles();
  const listRef = useRef<List>(null);

  useEffect(() => {
    // clear cached row heights
    cache.clearAll();
    const list = listRef.current;
    if (list) {
      // recalculate row heights for updated ProviderCards
      list.forceUpdateGrid();
      list.scrollToPosition(0);
    }
  }, [
    selectedZipCode,
    searchInput,
    radius,
    sortDirection,
    sortCriteria,
    selectedAccessibilities,
    selectedProviderType,
    selectedPlanID,
  ]);

  if (isLoading) return <LoadingSpinner containerClassName={classes.loadingSpinner} />;
  // if (plans.length === 0 && selectedZipCode !== '') {
  //   return (
  //     <Typography className={classes.noLivePlans}>
  //       We can’t find any active plans for you - if you believe this is an error, please contact
  //       your health plan administrator.
  //     </Typography>
  //   );
  // }
  if (providerList.length === 0 && selectedZipCode !== '') {
    return (
      <Typography className={classes.noProviders}>
        No providers found with given filters
        {searchInput ? ` and search text of "${searchInput}"` : ''}
      </Typography>
    );
  }

  if (providerList.length === 0 && selectedZipCode === '') {
    return (
      <div className={classes.illustrationWrapper}>
        <img src="./images/ProviderSearchIllustration.svg" alt="ProviderSearchIllustration" />
      </div>
    );
  }

  const rowRenderer = ({ index, key, style, parent }: RowRendererProps): JSX.Element => {
    const provider1 = providerList[index * 2];
    const provider2 = providerList[index * 2 + 1];

    // Check if providers exist for this index and index + 1
    const hasProvider1 = !!provider1;
    const hasProvider2 = !!provider2;

    // If no providers for both columns, render empty div
    if (!hasProvider1 && !hasProvider2) {
      return <div key={key} style={style} />;
    }

    return (
      <CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
        {({ measure }) => (
          <div style={style} className={classes.row}>
            {/* Render first provider */}
            {hasProvider1 && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div className={classes.providerCard} onClick={measure}>
                <ProviderCard
                  provider={provider1}
                  isFirstCard={index === 0 && !provider2}
                  isLastCard={!provider2 && index === Math.floor((providerList.length - 1) / 2)}
                  category={taxonomyDetailsMap[provider1.primaryTaxonomy]?.category}
                  title={taxonomyDetailsMap[provider1.primaryTaxonomy]?.title}
                  selectedAccessibilities={selectedAccessibilities}
                />
              </div>
            )}
            {/* Render second provider */}
            {hasProvider2 && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div className={classes.providerCard} onClick={measure}>
                <ProviderCard
                  provider={provider2}
                  isFirstCard={index === 0}
                  isLastCard={index === Math.floor((providerList.length - 1) / 2)}
                  category={taxonomyDetailsMap[provider2.primaryTaxonomy]?.category}
                  title={taxonomyDetailsMap[provider2.primaryTaxonomy]?.title}
                  selectedAccessibilities={selectedAccessibilities}
                />
              </div>
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  type RowRendererProps = {
    index: number;
    key: string;
    style: React.CSSProperties;
    parent: MeasuredCellParent;
  };

  return (
    <div className={classes.listWrapper}>
      <AutoSizer>
        {({ height, width }): JSX.Element => {
          const { actualHeight, actualWidth } = detectTestSizing(height, width);
          return (
            <List
              id="provider-list"
              ref={listRef}
              className={classes.list}
              width={actualWidth}
              height={actualHeight}
              rowCount={providerList.length}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={rowRenderer}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
}

export default connect(mapStateToProps)(ProviderList);
