import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '@apps/shared/src/style';

const useAboveTheFoldStyles = makeStyles(theme => {
  // eslint-disable-next-line global-require
  const background = require('./assets/LandingBG.svg') as string;

  return {
    heroImg: {
      width: '100vw',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      objectPosition: '0 -550px',
      backgroundColor: 'white',
    },
    fullHero: {
      minHeight: '100vh',
    },
    allContent: {
      margin: '0 5rem',
      minHeight: 'calc(100vh - 15rem)',
      paddingTop: '5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('xl')]: {
        margin: '0 10vw',
      },
    },
    typeOnDark: {
      color: colors.white,
    },
    allText: {
      [theme.breakpoints.down('sm')]: {
        width: '75vw',
      },
      [theme.breakpoints.up('xl')]: {
        width: '50vw',
        maxWidth: '900px',
      },
      width: '40vw',
      maxWidth: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    heading: {
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        fontSize: '4.75rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '4.5rem',
      },
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
      textAlign: 'right',
    },
    subHeading: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: '1.5em',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5em',
      },
      marginTop: '1em',
      marginBottom: '5.5em',
      fontSize: '1.5rem',
      textAlign: 'right',
    },
    subContent: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
    },
    laptop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      width: '50vw',
      maxWidth: '900px',
    },
    registerButton: {
      textTransform: 'uppercase',
      padding: '.8rem 2rem',
      backgroundColor: 'white',
      color: '#F97140',
      marginBottom: '2rem',
      '&.MuiButton-containedSecondary:hover': {
        backgroundColor: 'white',
      },
    },
  };
});

export default useAboveTheFoldStyles;
