import { models, Report, Embed } from 'powerbi-client';
import {
  MonthNumToDisplayName,
  Page,
  PageDisplayNameMap,
  ValidMonthlyRepricingMonth,
  ValidMonthlyRepricingYear,
} from './types';

export function isReport(embed: Embed): embed is Report {
  return embed.embedtype === 'report';
}

export const caseStatusPages = {
  accessIssueActive: 'Access Issue Active',
  accessIssueClosed: 'Access Issue Closed',
  balanceBillActive: 'Balance Bill Active',
  balanceBillClosed: 'Balance Bill Closed',
};

const firstPublishedYear = 2019;

export function determineReportYears(
  lastPublishedYear?: ValidMonthlyRepricingYear
): ValidMonthlyRepricingYear[] {
  if (!lastPublishedYear) {
    return [];
  }

  const reportYears: ValidMonthlyRepricingYear[] = [];
  for (let y = lastPublishedYear; y >= firstPublishedYear; y--) {
    reportYears.push(y);
  }
  return reportYears;
}

export function monthsToInclude(limit?: ValidMonthlyRepricingMonth) {
  return (month: MonthNumToDisplayName): boolean => {
    return limit ? month[0] <= limit : true;
  };
}

export const months: MonthNumToDisplayName[] = [
  [1, 'January'],
  [2, 'February'],
  [3, 'March'],
  [4, 'April'],
  [5, 'May'],
  [6, 'June'],
  [7, 'July'],
  [8, 'August'],
  [9, 'September'],
  [10, 'October'],
  [11, 'November'],
  [12, 'December'],
];

export function setMonthlyRepricingFilters(
  year?: ValidMonthlyRepricingYear,
  month?: ValidMonthlyRepricingMonth
): models.IBasicFilter[] {
  const filters = [];
  if (year !== undefined) {
    filters.push(createYearFilter(year));
  }
  if (month !== undefined) {
    filters.push(createMonthFilter(month));
  }
  return filters;
}

export function createYearFilter(year: ValidMonthlyRepricingYear): models.IBasicFilter {
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'public reportmonthlyrepricingfinal',
      column: 'reportyear',
    },
    operator: 'In',
    values: [year],
    filterType: models.FilterType.Basic,
    requireSingleSelection: true,
    displaySettings: {
      isLockedInViewMode: true,
      displayName: 'Report Year',
    },
  };
}

export function createMonthFilter(month: ValidMonthlyRepricingMonth): models.IBasicFilter {
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'public reportmonthlyrepricingfinal',
      column: 'reportmonth',
    },
    operator: 'In',
    values: [month],
    filterType: models.FilterType.Basic,
    requireSingleSelection: true,
    displaySettings: {
      isLockedInViewMode: true,
      displayName: 'Report Month',
    },
  };
}

export function makePageMap(pages: { value: Page[] }): PageDisplayNameMap {
  return pages.value.reduce(displayNameToName, {});
}

export function displayNameToName(m: PageDisplayNameMap, p: Page): PageDisplayNameMap {
  return { ...m, [p.displayName]: p.name };
}
