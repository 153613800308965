import React, { useState } from 'react';
import { MenuItem, Typography, IconButton, Box, Menu, makeStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import { Notification } from '@apps/shared/src/notification/types/notification';
import { markAsRead, deleteNotification } from '@apps/shared/src/notification/actions';
import { RootState } from '../store';

const useStyles = makeStyles(theme => ({
  menuItem: {
    backgroundColor: (props: { isUnread: boolean }) => (props.isUnread ? '#E3F2FD' : 'transparent'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '1.2rem 1rem',
    '&:hover': {
      backgroundColor: (props: { isUnread: boolean }) =>
        props.isUnread ? '#E3F2FD' : 'transparent',
    },
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  unreadDot: {
    backgroundColor: '#1976D2',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    visibility: (props: { isUnread: boolean }) => (props.isUnread ? 'visible' : 'hidden'),
  },
  ellipsesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  elapsedTime: {
    fontSize: '0.75rem',
    color: '#757575',
    marginTop: '.5rem',
  },
  messageContent: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
  AIHead: {
    color: '#05C9C9',
  },
  BBHead: {
    color: '#FFBC16',
  },
  profileArea: {},
  bold: {
    fontWeight: 600,
  },
  AIProfile: {
    width: '4rem',
    height: '4rem',
    backgroundColor: '#05C9C9',
    borderRadius: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '.5rem',
  },
  BBProfile: {
    width: '4rem',
    height: '4rem',
    backgroundColor: '#FFBC16',
    borderRadius: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '.5rem',
  },
}));

type StateProps = {
  isUnread: boolean;
};

type DispatchProps = {
  markAsRead: (id: string) => void;
  deleteNotification: (id: string) => void;
};

type Props = StateProps &
  DispatchProps & {
    notification: Notification;
  };

const mapStateToProps = (
  state: RootState,
  ownProps: { notification: Notification }
): StateProps => ({
  isUnread: !ownProps.notification.isRead,
});

const mapDispatchToProps: DispatchProps = {
  markAsRead,
  deleteNotification,
};

const NotificationItem: React.FC<Props> = ({
  notification,
  isUnread,
  markAsRead,
  deleteNotification,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles({ isUnread });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to calculate the elapsed time
  const getElapsedTime = (timestamp: Date) => {
    const now = new Date();
    const notificationDate = new Date(timestamp);
    const diff = Math.floor((now.getTime() - notificationDate.getTime()) / 1000); // Difference in seconds

    if (diff < 60) {
      return `${diff}s`;
    }
    if (diff < 3600) {
      return `${Math.floor(diff / 60)}m`;
    }
    if (diff < 86400) {
      return `${Math.floor(diff / 3600)}h`;
    }
    return `${Math.floor(diff / 86400)}d`;
  };

  return (
    <MenuItem className={classes.menuItem} disableRipple>
      <Box className={classes.messageContainer}>
        <div className={classes.unreadDot} />
        <div className={classes.profileArea}>
          {notification.recordType === 'Access Issue' && (
            <div className={classes.AIProfile}>
              <Typography>AI</Typography>
            </div>
          )}
          {notification.recordType === 'Balance Bill' && (
            <div className={classes.BBProfile}>
              <Typography>BB</Typography>
            </div>
          )}
        </div>
        <div className={classes.messageContent}>
          <Typography
            className={notification.recordType === 'Access Issue' ? classes.AIHead : classes.BBHead}
          >
            {notification.recordType}
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Case {notification.caseId}</span> status changed to{' '}
            <span className={classes.bold}>{notification.status}</span>
          </Typography>
        </div>
      </Box>
      <Box className={classes.ellipsesContainer}>
        <Typography className={classes.elapsedTime}>
          {getElapsedTime(notification.createdDate)}
        </Typography>
        <IconButton size="small" onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {!notification.isRead && (
            <MenuItem
              onClick={() => {
                markAsRead(notification.id);
                handleClose();
              }}
            >
              Mark as read
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              deleteNotification(notification.id);
              handleClose();
            }}
            style={{ color: 'red' }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </MenuItem>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
