import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ResetZoomIcon from '@material-ui/icons/ZoomOutMap';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Tooltip from '@material-ui/core/Tooltip';

import { formatBigNumber } from './formats';

const zoomStyle = theme => ({
  ZoomControl: {
    display: 'block',
    backgroundColor: theme.zoomControl.backgroundColor,
    textAlign: 'right',
    paddingRight: '1em',
    top: '0',
    width: '100%',
    height: '2.5em',
  },
  subtitle: {
    color: theme.zoomControl.subtitleColor,
    fontSize: '0.75em',
    letterSpacing: '1px',
    fontStyle: 'italic',
  },
  providerCount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.9em',
  },
  maxProviders: {
    color: theme.zoomControl.maxProvidersColor,
    margin: '0 0.5em',
  },
});

class ZoomControls extends React.Component {
  zoomIn = () => {
    this.props.zoomChart(1, 0.1);
  };

  zoomOut = () => {
    this.props.zoomChart(-1, 0.1);
  };

  render() {
    const { resetZoom, showControl, providerCount, viewCount, classes, atMax } = this.props;
    return showControl ? (
      <>
        {atMax && <span className={classes.maxProviders}>Maximum Providers Displayed</span>}
        {!atMax && viewCount < providerCount && (
          <>
            <Tooltip title="Reset Zoom to Max" placement="top">
              <IconButton onClick={resetZoom}>
                <ResetZoomIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zoom Out" placement="top">
              <IconButton onClick={this.zoomOut}>
                <ZoomOutIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <div className={classes.providerCount}>
          <div>
            {viewCount < providerCount && (
              <>
                {formatBigNumber(viewCount)}
                <span className={classes.subtitle}> of </span>{' '}
              </>
            )}
            {formatBigNumber(providerCount)}
          </div>
          <div className={classes.subtitle}>Providers</div>
        </div>
        <Tooltip title="Zoom In" placement="top">
          <IconButton onClick={this.zoomIn}>
            <ZoomInIcon />
          </IconButton>
        </Tooltip>
      </>
    ) : null;
  }
}

ZoomControls.propTypes = {
  zoomChart: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  resetZoom: PropTypes.func.isRequired,
  viewCount: PropTypes.number.isRequired,
  providerCount: PropTypes.number.isRequired,
  showControl: PropTypes.bool,
  atMax: PropTypes.bool,
};
ZoomControls.defaultProps = {
  showControl: false,
  atMax: false,
};

export default withStyles(zoomStyle)(ZoomControls);
