import { AxiosPromise } from 'axios';
import { AddDependentMemberInfo, DependentMemberInfo } from './dependentMemberTypes';

enum m {
  ADD_DEPENDENT_MEMBER = 'ADD_DEPENDENT_MEMBER',
  ADD_DEPENDENT_MEMBER_PENDING = 'ADD_DEPENDENT_MEMBER_PENDING',
  ADD_DEPENDENT_MEMBER_REJECTED = 'ADD_DEPENDENT_MEMBER_REJECTED',
  ADD_DEPENDENT_MEMBER_FULFILLED = 'ADD_DEPENDENT_MEMBER_FULFILLED',
  GET_DEPENDENT_MEMBERS = 'GET_DEPENDENT_MEMBERS',
  GET_DEPENDENT_MEMBERS_PENDING = 'GET_DEPENDENT_MEMBERS_PENDING',
  GET_DEPENDENT_MEMBERS_REJECTED = 'GET_DEPENDENT_MEMBERS_REJECTED',
  GET_DEPENDENT_MEMBERS_FULFILLED = 'GET_DEPENDENT_MEMBERS_FULFILLED',
  DELETE_DEPENDENT_MEMBER = 'DELETE_DEPENDENT_MEMBER',
  DELETE_DEPENDENT_MEMBER_PENDING = 'DELETE_DEPENDENT_MEMBER_PENDING',
  DELETE_DEPENDENT_MEMBER_REJECTED = 'DELETE_DEPENDENT_MEMBER_REJECTED',
  DELETE_DEPENDENT_MEMBER_FULFILLED = 'DELETE_DEPENDENT_MEMBER_FULFILLED',

  SET_DEPENDENT_MEMBER_INFO = 'SET_DEPENDENT_MEMBER_INFO',
  RESET_ADD_DEPENDENT_MEMBER = 'RESET_ADD_DEPENDENT_MEMBER',
}
export default m;

export type GetDepdendentMembers = {
  type: typeof m.GET_DEPENDENT_MEMBERS;
  payload: AxiosPromise;
};

export type GetDepdendentMembersPending = {
  type: typeof m.GET_DEPENDENT_MEMBERS_PENDING;
};

export type GetDependentMembersFulfilled = {
  type: typeof m.GET_DEPENDENT_MEMBERS_FULFILLED;
  payload: {
    data: {
      dependentMembers: DependentMemberInfo[];
    };
  };
};

export type GetDepdendentMembersRejected = {
  type: typeof m.GET_DEPENDENT_MEMBERS_REJECTED;
};

export type DeleteDepdendentMember = {
  type: typeof m.DELETE_DEPENDENT_MEMBER;
  payload: AxiosPromise;
};

export type DeleteDepdendentMemberFulfilled = {
  type: typeof m.DELETE_DEPENDENT_MEMBER_FULFILLED;
  payload: AxiosPromise;
};

export type DependentMembersActionTypes =
  | GetDepdendentMembers
  | GetDepdendentMembersPending
  | GetDepdendentMembersRejected
  | GetDependentMembersFulfilled;

export type SetDependentMemberInfo = {
  type: typeof m.SET_DEPENDENT_MEMBER_INFO;
  meta: {
    key: keyof AddDependentMemberInfo;
    value: AddDependentMemberInfo[keyof AddDependentMemberInfo];
  };
};

export type ResetDependentMember = {
  type: typeof m.RESET_ADD_DEPENDENT_MEMBER;
};

export type AddDependentMember = {
  type: typeof m.ADD_DEPENDENT_MEMBER;
  meta: { destinationUrl?: string };
  payload: AxiosPromise<AddDependentMemberInfo>;
};

export type AddDependentMemberPending = {
  type: typeof m.ADD_DEPENDENT_MEMBER_PENDING;
  meta: { redirectTo?: string };
};

export type AddDependentMemberFulfilled = {
  type: typeof m.ADD_DEPENDENT_MEMBER_FULFILLED;
  meta: { redirectTo?: string };
  payload: { data: AddDependentMemberInfo };
};

export type AddDependentMemberRejected = {
  type: typeof m.ADD_DEPENDENT_MEMBER_REJECTED;
  meta: { redirectTo?: string };
};

export type AddDependentMemberActions =
  | AddDependentMember
  | AddDependentMemberPending
  | AddDependentMemberRejected
  | ResetDependentMember
  | AddDependentMemberFulfilled;

export type DependentMemberActionsTypes =
  | SetDependentMemberInfo
  | ResetDependentMember
  | AddDependentMemberActions
  | DeleteDepdendentMember
  | DeleteDepdendentMemberFulfilled;
