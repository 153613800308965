import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import { parseDate } from '@apps/shared/src/utilities/date';
import InfoTooltip from '../../shared/InfoTooltip';
import { DisplayItem } from './DetailPane';

function formatDisplayDate(dateStr: string): string {
  const date = parseDate(dateStr);
  if (date === null) return '-';
  return date.toLocaleDateString('default', { year: 'numeric', month: 'short' });
}

function formatTooltipDate(dateStr: string, claimType: string): string {
  const date = parseDate(dateStr);
  if (date === null) return '';

  const reportingYearType = claimType === 'Professional' ? 'Calendar' : 'Fiscal';

  return `${reportingYearType} Year Ending ${date.toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`;
}

type Props = {
  classes: ClassNameMap<'groupHeader' | 'groupData'>;
  claimType: string;
  date?: string;
};

export default function DataSourceDatesDisplayItem({
  claimType,
  date = '',
  classes,
}: Props): JSX.Element {
  const toolTipDate = formatTooltipDate(date, claimType);

  return (
    <DisplayItem
      label={
        toolTipDate === '' ? (
          claimType
        ) : (
          <InfoTooltip tooltip={toolTipDate}>{claimType}</InfoTooltip>
        )
      }
      value={formatDisplayDate(date)}
      classes={classes}
    />
  );
}
