import { useSelector } from 'react-redux';

import { RootState } from '../types/root';
import {
  Provider,
  ProviderTypeCounts,
  TaxonomyDetailsMap,
  ProviderTypesMap,
} from '../types/providerSearch';
import { filterProviderList } from './providers';

export function countProvidersByCategoryAndTitle(
  providerTypeNeutralFilteredList: Provider[],
  taxonomyDetailsMap: TaxonomyDetailsMap,
  providerTypesMap: ProviderTypesMap
): ProviderTypeCounts {
  const counts: ProviderTypeCounts = { categories: {}, titles: {} };
  for (const category of Object.keys(providerTypesMap)) {
    counts.categories[category] = 0;

    for (const title of providerTypesMap[category]) {
      counts.titles[title] = 0;
    }
  }

  for (const p of providerTypeNeutralFilteredList) {
    const taxonomies = [p.primaryTaxonomy];
    if (p.secondaryTaxonomies) taxonomies.push(...p.secondaryTaxonomies);
    for (const taxonomy of taxonomies) {
      const details = taxonomyDetailsMap[taxonomy];
      if (details) {
        counts.categories[details.category]++;
        counts.titles[details.title]++;
      }
    }
  }

  return counts;
}

export default function useProviderTypeCounts(): ProviderTypeCounts {
  const values = useSelector((state: RootState) => state.providerSearch);

  const defaultProviderType = '';

  const providerTypeNeutralFilteredList = filterProviderList({
    fullList: values.fullList,
    radius: values.radius,
    searchInput: values.searchInput,
    selectedAccessibilities: values.selectedAccessibilities,
    taxonomyDetailsMap: values.taxonomyDetailsMap,
    selectedProviderType: defaultProviderType,
    selectedPlanID: values.selectedPlanID,
  });

  return countProvidersByCategoryAndTitle(
    providerTypeNeutralFilteredList,
    values.taxonomyDetailsMap,
    values.providerTypesMap
  );
}
