import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from '@material-ui/icons/ArrowUpward';

import { SortCriteria, SortDirection } from '@apps/shared/src/providerSearch/types/providerSearch';
import { setSortCriteria, toggleSortDirection } from '@apps/shared/src/providerSearch/actions';
import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  ascending: {
    transform: 'scaleY(-1)',
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
  header: theme.sidebar.headerText,
  menuItem: theme.selectMenuItem,
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  sortCriteria: providerSearch.sortCriteria,
  sortDirection: providerSearch.sortDirection,
  isLoading: providerSearch.isLoadingProviderList,
});
type StateProps = {
  sortCriteria: SortCriteria;
  sortDirection: SortDirection;
  isLoading: boolean;
};

const mapDispatchToProps = {
  setSortCriteria,
  toggleSortDirection,
};

type Props = StateProps & typeof mapDispatchToProps;

export function SortControl({
  sortCriteria,
  sortDirection,
  setSortCriteria,
  toggleSortDirection,
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleChange: SelectProps['onChange'] = e => {
    setSortCriteria(e.target.value as SortCriteria);
  };

  const handleDirectionClick: VoidFunction = () => {
    toggleSortDirection();
  };

  return (
    <div>
      <Typography className={classes.header}>Sort By</Typography>
      <div className={classes.sortContainer}>
        <FormControl>
          <Select
            value={sortCriteria}
            id="sort-search"
            onChange={handleChange}
            labelId="sort-criteria"
            className={classes.Select}
            disableUnderline
            disabled={isLoading}
          >
            <MenuItem className={classes.menuItem} value="distance">
              Distance
            </MenuItem>
            <MenuItem className={classes.menuItem} value="accessibility">
              Accessibility
            </MenuItem>
            <MenuItem className={classes.menuItem} value="name">
              Provider Name
            </MenuItem>
          </Select>
        </FormControl>
        <IconButton
          color="primary"
          edge="end"
          id={`sort-${sortDirection}`}
          value={sortDirection}
          onClick={handleDirectionClick}
          className={sortDirection === 'asc' ? classes.ascending : undefined}
          aria-label="toggle sort direction"
          disabled={isLoading}
        >
          <SortIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SortControl);
