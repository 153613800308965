import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const styles = () => ({
  textBlock: {
    paddingTop: '14px',
    fontSize: '0.75rem',
  },
});

const TermsOfService = props => {
  const { classes } = props;
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Terms of Service</Typography>
        <IconButton aria-label="Close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div
        style={{
          height: '50vh',
          maxHeight: '500px',
          overflowY: 'scroll',
          overflowX: 'hidden',
          padding: '10px',
          textAlign: 'left',
        }}
      >
        <Typography variant="subtitle1">
          6 Degrees Health DX, LLC. <br />
          Master Subscription Agreement
        </Typography>
        <Typography variant="body1" className={classes.textBlock}>
          THIS AGREEMENT GOVERNS YOUR ACQUISITION AND USE OF OUR SERVICES. BY ACCEPTING THIS
          AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE OR BY EXECUTING AN ORDER
          FORM THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE
          ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT
          THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND
          CONDITIONS, IN WHICH CASE THE TERMS &quot;YOU&quot; OR &quot;YOUR&quot; SHALL REFER TO
          SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE
          WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE
          SERVICES.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          You may not access the Services if You are Our direct competitor, including medical
          cost-containment, medical claim review or medical network development, except with Our
          prior written consent. In addition, You may not access the Services for purposes of
          monitoring their availability, performance or functionality, or for any other benchmarking
          or competitive purposes.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          This Agreement was last updated on April 12, 2017. It is effective between You and Us as
          of the date of You accepting this Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>1. DEFINITIONS</b>{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>&quot;Affiliate&quot;</b> means any entity that directly or indirectly controls, is
          controlled by, or is under common control with the subject entity. &quot;Control&quot;,
          for purposes of this definition, means direct or indirect ownership or control of more
          than 50% of the voting interests of the subject entity.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Agreement”</b> means this Master Subscription Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Beta Services”</b> means 6 DEGREES services or functionality that may be made
          available to Customer to try at its option at no additional charge which is clearly
          designated as beta, pilot, limited release, developer preview, non-production, evaluation,
          or by a similar description.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Content”</b> means information obtained by 6 DEGREES from publicly available sources
          or third party content providers and made available to Customer through the Services, Beta
          Services or pursuant to an Order Form.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Malicious Code”</b> means code, files, scripts, agents or programs intended to do
          harm, including, for example, viruses, worms, time bombs and Trojan horses.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Modifications”</b> means any work based on or incorporating all or any portion of the
          Services or Content, including, without limitation, modifications, enhancements and
          customizations to the Services and Content developed by Us.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Order Form”</b> means an ordering document or online order specifying the Services to
          be provided hereunder that is entered into between You and Us or any of Our Affiliates,
          including any addenda and supplements thereto. By entering into an Order Form hereunder,
          an Affiliate agrees to be bound by the terms of this Agreement as if it were an original
          party hereto.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Purchased Services”</b> means Services that You or Your Affiliate purchase under an
          Order Form.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Services”</b> means the products and services that are ordered by You under an Order
          Form or provided to You under a free trial, and made available online by Us.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“User”</b> means an individual who is authorized by You to use a Service, for whom You
          have purchased a subscription (or in the case of any Services provided by Us without
          charge, for whom a Service has been provisioned), and to whom You (or, when applicable, Us
          at Your request) have supplied a user identification and password (for Services utilizing
          authentication). Users may include, for example, Your employees, consultants, contractors
          and agents, and third parties with which You transact business.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“We,” “Us” or “Our”</b> means 6 DEGREES company described in Section 13 (Who You Are
          Contracting With, Notices, Governing Law and Jurisdiction).{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“You” or “Your”</b> means the company or other legal entity for which you are accepting
          this Agreement, and Affiliates of that company or entity which have signed Order Forms.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>“Your Data”</b> means electronic data and information submitted by or for Customer to
          the Services.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2. OUR RESPONSIBILITIES</b>{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2.1. Provision of Purchased Services.</b> We will (a) make the Services and Content
          available to You pursuant to this Agreement and the applicable Order Forms, (b) provide
          applicable 6 DEGREES standard support for the Services to You at no additional charge,
          and/or upgraded support if purchased, (c) use commercially reasonable efforts to make the
          online Services available 24 hours a day, 7 days a week, except for: (i) planned downtime
          (of which We shall give advance electronic notice), and (ii) any unavailability caused by
          circumstances beyond Our reasonable control, including, for example, an act of God, act of
          government, flood, fire, earthquake, civil unrest, act of terror, strike or other labor
          problem (other than one involving Our employees), Internet service provider failure or
          delay, Non-6 DEGREES Application, or denial of service attack.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2.2. Your Data</b>. We will not monitor or store Your Data, but we reserve the right to
          monitor and log your activity and access to Our Services.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2.3. Our Personnel</b>. We will be responsible for the performance of Our personnel
          (including Our employees and contractors) and their compliance with Our obligations under
          this Agreement, except as otherwise specified herein.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2.4. Beta Services.</b> From time to time, We may make Beta Services available to You
          at no charge. You may choose to try such Beta Services or not in Your sole discretion.
          Beta Services are intended for evaluation purposes and not for production use, are not
          supported, and may be subject to additional terms. Beta Services are not considered
          “Services” under this Agreement, however, all restrictions, Our reservation of rights and
          Your obligations concerning the Services, and use of any Content, shall apply equally to
          Your use of Beta Services. Unless otherwise stated, any Beta Services trial period will
          expire upon the earlier of one year from the trial start date or the date that a version
          of the Beta Services becomes generally available without the applicable Beta Services
          designation. We may discontinue Beta Services at any time in Our sole discretion and may
          never make them generally available. We will have no liability for any harm or damage
          arising out of or in connection with a Beta Service.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3. USE OF SERVICES AND CONTENT </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3.1 Subscriptions</b>. Unless otherwise provided in the applicable Order Form, (a)
          Services and access to Content are purchased as subscriptions, (b) subscriptions may be
          added during a subscription term at the same pricing as the underlying subscription
          pricing, prorated for the portion of that subscription term remaining at the time the
          subscriptions are added, and (c) any added subscriptions will terminate on the same date
          as the underlying subscriptions.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3.2 Usage Limits.</b> Services and Content are subject to usage limits, including, for
          example, the quantities specified in Order Forms. Unless otherwise specified, (a) a
          quantity in an Order Form refers to Users, and the Service or Content may not be accessed
          by more than that number of Users, (b) a User’s password may not be shared with any other
          individual, and (c) except as set forth in an Order Form, a User identification may only
          be reassigned to a new individual replacing one who will no longer use the Service or
          Content. If You exceed a contractual usage limit, We may work with You to seek to reduce
          Your usage so that it conforms to that limit. If, notwithstanding Our efforts, You are
          unable or unwilling to abide by a contractual usage limit, You will execute an Order Form
          for additional quantities of the applicable Services or Content promptly upon Our request,
          and/or pay any invoice for excess usage in accordance with Section 4.2 (Invoicing and
          Payment).{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3.3 Your Responsibilities</b>. You will (a) be responsible for Users’ compliance with
          this Agreement and Order Forms, (b) be responsible for the accuracy, quality and legality
          of Your Data and the means by which You acquired Your Data, (c) use commercially
          reasonable efforts to prevent unauthorized access to or use of Services and Content, and
          notify Us promptly of any such unauthorized access or use, (d) use Services and Content
          only in accordance with this Agreement, Order Forms and applicable laws and government
          regulations.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>2.4 Usage Restrictions</b>. You will not (a) make any Service or Content available to,
          or use any Service or Content for the benefit of, anyone other than You or Users, unless
          expressly stated otherwise in an Order Form, (b) sell, resell, license, sublicense,
          distribute, make available, rent or lease any Service or Content, or include any Service
          or Content in a service bureau or outsourcing offering, (c) use a Service to store or
          transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or
          transmit material in violation of third-party privacy rights, (d) use a Service to store
          or transmit Malicious Code, (e) interfere with or disrupt the integrity or performance of
          any Service or third-party data contained therein, (f) attempt to gain unauthorized access
          to any Service or Content or its related systems or networks, (g) permit direct or
          indirect access to or use of any Service or Content in a way that circumvents a
          contractual usage limit, or use any of Our Services to access or use any of Our
          intellectual property except as permitted under this Agreement, an Order Form, (h) copy a
          Service or any part, feature, function or user interface thereof, (i) copy Content except
          as permitted herein or in an Order Form, (j) frame or mirror any part of any Service or
          Content, other than framing on Your own intranets or otherwise for Your own internal
          business purposes, (k) access any Service or Content in order to build a competitive
          product or service or to benchmark with a Non-6 DEGREES product or service, or (l) reverse
          engineer any Service (to the extent such restriction is permitted by law). Any use of the
          Services in breach of this Agreement Order Forms, by You or Users that in Our judgment
          threatens the security, integrity or availability of Our services, may result in Our
          immediate suspension of the Services; however, We will use commercially reasonable efforts
          under the circumstances to provide You with notice and an opportunity to remedy such
          violation or threat prior to such suspension.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3.5 External-Facing Services.</b> You agree not to post Content on external-facing
          websites.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>3.6</b> <b>Removal of Content</b>. If We receive information that a Non-6 DEGREES
          Application hosted on a Service by You may violate Our External-Facing Services or
          applicable law or third-party rights, We may so notify You and in such event You will
          promptly disable such Non-6 DEGREES Application or modify the Non-6 DEGREES Application to
          resolve the potential violation.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4. FEES AND PAYMENT FOR PURCHASED SERVICES </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4.1. Fees</b>. You will pay all fees specified in Order Forms. Except as otherwise
          specified herein or in an Order Form,(i) fees are based on Services and Content
          subscriptions purchased and not actual usage, (ii) payment obligations are non-cancelable
          and fees paid are non-refundable, and (iii) quantities purchased cannot be decreased
          during the relevant subscription term.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4.2. Invoicing and Payment</b>. You will provide Us with valid and updated credit card
          information, or with a valid purchase order or alternative document reasonably acceptable
          to Us. If You provide credit card information to Us, You authorize Us to charge such
          credit card for all Purchased Services listed in the Order Form for the initial
          subscription term and any renewal subscription term(s) as set forth in Section 9.2 (Term
          of Purchased Subscriptions). Such charges shall be made in advance, either annually or in
          accordance with any different billing frequency stated in the applicable Order Form. If
          the Order Form specifies that payment will be by a method other than a credit card, We
          will invoice You in advance and otherwise in accordance with the relevant Order Form.
          Unless otherwise stated in the Order Form, invoiced charges are due net 30 days from the
          invoice date. You are responsible for providing complete and accurate billing and contact
          information to Us and notifying Us of any changes to such information.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4.3. Overdue Charges.</b> If any invoiced amount is not received by Us by the due date,
          then without limiting Our rights or remedies, (a) those charges may accrue late interest
          at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by
          law, whichever is lower, and/or (b) We may condition future subscription renewals and
          Order Forms on payment terms shorter than those specified in Section 4.2 (Invoicing and
          Payment).{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4.4. Suspension of Service and Acceleration</b>. If any amount owing by You under this
          or any other agreement for Our services is 30 or more days overdue (or 10 or more days
          overdue in the case of amounts You have authorized Us to charge to Your credit card), We
          may, without limiting Our other rights and remedies, accelerate Your unpaid fee
          obligations under such agreements so that all such obligations become immediately due and
          payable, and suspend Our services to You until such amounts are paid in full. Other than
          for customers paying by credit card or direct debit whose payment has been declined, We
          will give You at least 10 days’ prior notice that Your account is overdue, in accordance
          with Section 10.2 (Manner of Giving Notice) for billing notices, before suspending
          services to You.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>4.5. Payment Disputes</b>. We will not exercise Our rights under Section 4.3 (Overdue
          Charges) or 4.4 (Suspension of Service and Acceleration) above if You are disputing the
          applicable charges reasonably and in good faith and are cooperating diligently to resolve
          the dispute. 4.6. Taxes. Our fees do not include any taxes, levies, duties or similar
          governmental assessments of any nature, including, for example, value-added, sales, use or
          withholding taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”). You
          are responsible for paying all Taxes associated with Your purchases hereunder. If We have
          the legal obligation to pay or collect Taxes for which You are responsible under this
          Section 4.6, We will invoice You and You will pay that amount unless You provide Us with a
          valid tax exemption certificate authorized by the appropriate taxing authority. For
          clarity, We are solely responsible for taxes assessable against Us based on Our income,
          property andemployees. 4.7. Future Functionality. You agree that Your purchases are not
          contingent on the delivery of any future functionality or features, or dependent on any
          oral or written public comments made by Us regarding future functionality or features.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>5. PROPRIETARY RIGHTS AND LICENSES </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>5.1. Reservation of Rights</b>. Subject to the limited rights expressly granted
          hereunder, We reserve all of Our right, title and interest in and to the Services and
          Content, including all of Our related intellectual property rights. No rights are granted
          to You hereunder other than as expressly set forth herein. You hereby assign and will
          assign to Us all right, title and interest worldwide in the intellectual property rights
          embodied in any and all Modifications. To the extent any of the rights, title and interest
          are not assignable by You to Us, You grant and agree to grant to Us exclusive,
          royalty-free, transferable, irrevocable, worldwide, fully paid-up license (with rights to
          sublicense through multiple tiers of sublicensees) under Your intellectual property rights
          to use, disclose, reproduce, license, sell, offer for sale, distribute, import and
          otherwise exploit the Modifications in Our discretion, without restriction or obligation
          of any kind or nature.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>5.2. Access to and Use of Content</b>. You have the right to access and use applicable
          Content subject to the terms of applicable Order Forms and this Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>5.3. Attribution</b>. Any Medivi generated-charts or information may be used in
          presentations, journals, websites, newsletters, social media, or other forms of
          publication or dissemination, so long as it is accompanied by proper attribution to 6
          Degrees. This attribution may take the form of the use of the 6 Degrees company logo, or
          may be a written statement accompanying the Medivi-generated materials, according to
          standard practices of attribution for that medium.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>5.4. License to Use Feedback</b>. You grant to Us and Our Affiliates a worldwide,
          perpetual, irrevocable, royalty-free license to use and incorporate into Our and/or Our
          Affiliates’ services any suggestion, enhancement request, recommendation, correction or
          other feedback provided by You or Users relating to the operation of Our or Our
          Affiliates’ services.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>6. CONFIDENTIALITY </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>6.1. Definition of Confidential Information</b>. “Confidential Information” means all
          information disclosed by a party (“Disclosing Party”) to the other party (“Receiving
          Party”), whether orally or in writing, that is designated as confidential or that
          reasonably should be understood to be confidential given the nature of the information and
          the circumstances of disclosure. Your Confidential Information includes Your Data; Our
          Confidential Information includes the Services and Content; and Confidential Information
          of each party includes the terms and conditions of this Agreement and all Order Forms
          (including pricing), as well as business and marketing plans, technology and technical
          information, product plans and designs, and business processes disclosed by such party.
          However, Confidential Information does not include any information that (i) is or becomes
          generally known to the public without breach of any obligation owed to the Disclosing
          Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing
          Party without breach of any obligation owed to the Disclosing Party, (iii) is received
          from a third party without breach of any obligation owed to the Disclosing Party, or (iv)
          was independently developed by the Receiving Party.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <br />
          <b>6.2</b>. <b>Degree of Care</b>. The Receiving Party will use the same degree of care
          that it uses to protect the confidentiality of its own confidential information of like
          kind (but not less than reasonable care) to (i) not use any Confidential Information of
          the Disclosing Party for any purpose outside the scope of this Agreement and (ii) except
          as otherwise authorized by the Disclosing Party in writing, limit access to Confidential
          Information of the Disclosing Party to those of its and its Affiliates’ employees and
          contractors who need that access for purposes consistent with this Agreement and who have
          signed confidentiality agreements with the Receiving Party containing protections not
          materially less protective of the Confidential Information than those herein. Neither
          party will disclose the terms of this Agreement or any Order Form to any third party other
          than its Affiliates, legal counsel and accountants without the other party’s prior written
          consent, provided that a party that makes any such disclosure to its Affiliate, legal
          counsel or accountants will remain responsible for such Affiliate’s, legal counsel’s or
          accountant’s compliance with this “Confidentiality” section. Notwithstanding the
          foregoing, We may disclose the terms of this Agreement and any applicable Order Form to a
          subcontractor or Non-6 DEGREES Application Provider to the extent necessary to perform Our
          obligations to You under this Agreement, under terms of confidentiality materially as
          protective as set forth herein.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>6.3. Compelled Disclosure</b>. The Receiving Party may disclose Confidential
          Information of the Disclosing Party to the extent compelled by law to do so, provided the
          Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to
          the extent legally permitted) and reasonable assistance, at the Disclosing Party&apos;s
          cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party is
          compelled by law to disclose the Disclosing Party’s Confidential Information as part of a
          civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not
          contesting the disclosure, the Disclosing Party will reimburse the Receiving Party for its
          reasonable cost of compiling and providing secure access to that Confidential Information.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>7. REPRESENTATIONS, WARRANTIES, EXCLUSIVE REMEDIES ANDDISCLAIMERS </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>7.1. Representations</b>. Each party represents that it has validly entered into this
          Agreement and has the legal power to do so.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>7.2. Disclaimers</b>. EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY
          WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH PARTY
          SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW. CONTENT AND BETA SERVICES ARE PROVIDED “AS IS,”
          EXCLUSIVE OF ANY WARRANTY WHATSOEVER. EACH PARTY DISCLAIMS ALL LIABILITY AND
          INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD-PARTY HOSTING
          PROVIDERS.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>8. LIMITATION OF LIABILITY </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>8.1. Limitation of Liability</b>. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EACH
          PARTY TOGETHER WITH ALL OF ITS AFFILIATES ARISING OUT OF OR RELATED TO THIS AGREEMENT
          EXCEED THE LESSER OF THE TOTAL AMOUNT PAID BY YOU HEREUNDER, OR THE TOTAL PRICE STATED ON
          THE MEDIVI ORDER FORM, FOR THE SERVICES GIVING RISE TO THE LIABILITY IN THE TWELVE MONTHS
          PRECEDING THE FIRST INCIDENT OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION
          WILL APPLY WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF
          LIABILITY, BUT WILL NOT LIMIT YOUR AND YOUR AFFILIATES’ PAYMENT OBLIGATIONS UNDER THE
          “FEES AND PAYMENT” SECTION ABOVE.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>8.2. Exclusion of Consequential and Related Damages</b>. IN NO EVENT WILL EITHER PARTY
          OR ITS AFFILIATES HAVE ANY LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY
          LOST PROFITS, REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER,
          BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS IN CONTRACT OR TORT AND
          REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF A PARTY OR ITS AFFILIATES HAVE BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES OR IF A PARTY’S OR ITS AFFILIATES’ REMEDY OTHERWISE
          FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT
          PROHIBITED BY LAW.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9. TERM AND TERMINATION </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9.1 Term of Agreement</b>. This Agreement commences on the date You first accept it and
          continues until all subscriptions hereunder have expired, have been terminated, or upon
          the expiration of 6 months after Your last medical benefits plan terminates its
          relationship with Us.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9.2. Term of Purchased Subscriptions</b>. The term of each subscription shall be as
          specified in the applicable Order Form. Except as otherwise specified in an Order Form,
          subscriptions will automatically renew for additional periods equal to the expiring
          subscription term or one year (whichever is shorter), unless either party gives the other
          notice of non-renewal at least 30 days before the end of the relevant subscription term.
          The per-unit pricing during any renewal term will increase by up to 7% above the
          applicable pricing in the prior term, unless We provide You notice of different pricing at
          least 60 days prior to the applicable renewal term. Notwithstanding anything to the
          contrary, any renewal in which subscription volume for any Services has decreased from the
          prior term will result in re-pricing at renewal without regard to the prior term’s
          per-unit pricing.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9.3. Termination</b>. A party may terminate this Agreement for cause (i) upon 30 days
          written notice to the other party of a material breach if such breach remains uncured at
          the expiration of such period, or (ii) if the other party becomes the subject of a
          petition in bankruptcy or any other proceeding relating to insolvency, receivership,
          liquidation or assignment for the benefit of creditors.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9.4. Refund or Payment upon Termination</b>. If this Agreement is terminated by You in
          accordance with Section 9.3 (Termination), We will refund You any prepaid fees covering
          the remainder of the term of all Order Forms after the effective date of termination. If
          this Agreement is terminated by Us in accordance with Section 9.3, You will pay any unpaid
          fees covering the remainder of the term of all Order Forms. In no event will termination
          relieve You of Your obligation to pay any fees payable to Us for the period prior to the
          effective date of termination.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>9.5. Surviving Provisions.</b> The sections titled “Fees and Payment,” “Proprietary
          Rights and Licenses,” “Confidentiality,” “Disclaimers,” “Limitation of Liability,” “Refund
          or Payment upon Termination,” “Surviving Provisions” and “General Provisions” will survive
          any termination or expiration of this Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>10. WHO YOU ARE CONTRACTING WITH, NOTICES, GOVERNING LAW AND JURISDICTION</b>{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>10.1. General.</b> You are contracting with 6 Degrees Health DX, LLC (a Delaware
          Limited Liability Company). Notices should be addressed to 6 Degrees Health DX, LLC, ATTN:
          General Counsel, 1600 NW Compton Dr., Suite 208, Beaverton, OR 97006. The governing law is
          Oregon and controlling United States federal law.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>10.2 Manner of Giving Notice.</b> Except as otherwise specified in this Agreement, all
          notices related to this Agreement will be in writing and will be effective upon (a)
          personal delivery, (b) the second business day after mailing, or (c), except for notices
          of termination or an indemnifiable claim (“Legal Notices”), which shall clearly be
          identifiable as Legal Notices, the day of sending by email. Billing-related notices to You
          will be addressed to the relevant billing contact designated by You. All other notices to
          You will be addressed to the relevant Services system administrator designated by You.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>10.3. Agreement to Governing Law and Jurisdiction.</b> Each party agrees to the
          applicable governing law above without regard to choice or conflicts of law rules, and to
          the exclusive jurisdiction of the applicable courts above.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11. GENERAL PROVISIONS </b>
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.1. Export Compliance</b>. The Services, Content, other technology We make available,
          and derivatives thereof may be subject to export laws and regulations of the United States
          and other jurisdictions. Each party represents that it is not named on any U.S. government
          denied-party list. You shall not permit Users to access or use any Service or Content in a
          U.S. embargoed country (currently Cuba, Iran, North Korea, Sudan, Syria or Crimea) or in
          violation of any U.S. export law or regulation.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.2. Anti-Corruption</b>. You agree that You have not received or been offered any
          illegal or improper bribe, kickback, payment, gift, or thing of value from any of Our
          employees or agents in connection with this Agreement. Reasonable gifts and entertainment
          provided in the ordinary course of business do not violate the above restriction. If You
          learn of any violation of the above restriction, You will use reasonable efforts to
          promptly notify Our Legal Department.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.3 Entire Agreement and Order of Precedence</b>. This Agreement is the entire
          agreement between You and Us regarding Your use of Services and Content and supersedes all
          prior and contemporaneous agreements, proposals or representations, written or oral,
          concerning its subject matter. Except as otherwise provided herein, no modification,
          amendment, or waiver of any provision of this Agreement will be effective unless in
          writing and signed by the party against whom the modification, amendment or waiver is to
          be asserted. The parties agree that any term or condition stated in Your purchase order or
          in any other of Your order documentation (excluding Order Forms) is void. In the event of
          any conflict or inconsistency among the following documents, the order of precedence shall
          be: (1) the applicable Order Form, and (2) this Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.4. Assignment</b>. Neither party may assign any of its rights or obligations
          hereunder, whether by operation of law or otherwise, without the other party’s prior
          written consent (not to be unreasonably withheld); provided, however, either party may
          assign this Agreement in its entirety (together with all Order Forms), without the other
          party’s consent to its Affiliate or in connection with a merger, acquisition, corporate
          reorganization, or sale of all or substantially all of its assets. Notwithstanding the
          foregoing, if a party is acquired by, sells substantially all of its assets to, or
          undergoes a change of control in favor of, a direct competitor of the other party, then
          such other party may terminate this Agreement upon written notice. In the event of such a
          termination, We will refund to You any prepaid fees allocable to the remainder of the term
          of all subscriptions for the period after the effective date of such termination. Subject
          to the foregoing, this Agreement will bind and inure to the benefit of the parties, their
          respective successors and permitted assigns.
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.5. Relationship of the Parties</b>. The parties are independent contractors. This
          Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or
          employment relationship between the parties.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.6. Third-Party Beneficiaries</b>. There are no third-party beneficiaries under this
          Agreement.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.7. Waiver</b>. No failure or delay by either party in exercising any right under
          this Agreement will constitute a waiver of that right.{' '}
        </Typography>

        <Typography variant="body2" className={classes.textBlock}>
          <b>11.8. Severability</b>. If any provision of this Agreement is held by a court of
          competent jurisdiction to be contrary to law, the provision will be deemed null and void,
          and the remaining provisions of this Agreement will remain in effect.{' '}
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '30px',
        }}
      >
        <Button
          onClick={props.onAgree(true)}
          variant="contained"
          color="primary"
          fullWidth
          style={{ margin: '0 8px' }}
        >
          AGREE
        </Button>
        <Button
          onClick={props.onAgree(false)}
          variant="outlined"
          fullWidth
          style={{ margin: '0 8px' }}
        >
          DECLINE
        </Button>
      </div>
    </>
  );
};

TermsOfService.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(TermsOfService);
