import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ProviderSearchFAQ, BalanceBillFAQ, AccessIssueFAQ } from './faqQuestions';
import AppLayout from '../shared/AppLayout';
import Footer from '../shared/Footer';
import PageSpecificHeader from '../shared/PageSpecificHeader';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 120px - 2rem)',
    padding: '0 2rem 0 0',
    overflowY: 'scroll',
  },
  subHeading: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#36383D',
    marginBottom: '1rem',
    paddin: '0 0 0 2rem',
    marginLeft: '1.2rem',
    marginTop: '0.7rem',
  },
  question: {
    fontSize: '1.125rem',
    fontWeight: 550,
    lineHeight: '1.5625rem',
    letterSpacing: '0em',
    color: '#36383D',
    opacity: '80%',
    padding: '.5rem 0 0 1rem',
    marginBottom: '0.5rem',
  },
  answer: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.03125rem',
    color: '#888787',
    padding: '0 0 0.5rem 1rem',
  },
}));

const FAQ = () => {
  const classes = useStyles();
  return (
    <AppLayout>
      <div className={classes.container}>
        <PageSpecificHeader title="Frequently Asked Questions" />

        <Typography className={classes.subHeading}>Provider Search FAQ</Typography>
        {ProviderSearchFAQ.map(FAQ => (
          <>
            <Typography className={classes.question}>{FAQ.question}</Typography>
            <Typography className={classes.answer}>{FAQ.answer}</Typography>
          </>
        ))}
        <Typography className={classes.subHeading}>Balance Bill FAQ</Typography>
        {BalanceBillFAQ.map(FAQ => (
          <>
            <Typography className={classes.question}>{FAQ.question}</Typography>
            <Typography className={classes.answer}>{FAQ.answer}</Typography>
          </>
        ))}
        <Typography className={classes.subHeading}>Access Issue FAQ</Typography>
        {AccessIssueFAQ.map(FAQ => (
          <>
            <Typography className={classes.question}>{FAQ.question}</Typography>
            <Typography className={classes.answer}>{FAQ.answer}</Typography>
          </>
        ))}
      </div>
    </AppLayout>
  );
};
export default FAQ;
