import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import Tabs from '@apps/shared/src/components/Tabs';

import CaseStatusNavStage from './CaseStatusNavStage';
import { RootState } from '../../store';
import { selectPage } from '../reportActions';
import { caseStatusPages } from '../utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  resetBtn: {
    color: '#ff0000',
    textDecoration: 'underline',
    padding: '0',
    border: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  statusTitle: {
    fontWeight: 400,
    lineHeight: '23px',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    color: '#797C80',
    '& .MuiTypography-body1': {
      fontSize: '1rem',
    },
  },
  subHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    color: '#797C80',
    '& .MuiTypography-body1': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '20px',
      marginTop: '1rem',
    },
  },
  caseStatus: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
  },
});

type StateProps = {
  selectedReportPage: string;
};
const mapStateToProps = ({ reports }: RootState): StateProps => {
  return {
    selectedReportPage: reports.selectedReportPage,
  };
};

const mapDispatchToProps = {
  selectPage,
};

type Props = StateProps & typeof mapDispatchToProps;

export function CaseStatusNav({ selectedReportPage, selectPage }: Props): JSX.Element {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState('');
  const [caseValue, setCaseValue] = useState('');

  const handleCaseTypeChange = (newCaseType: string) => {
    setCaseValue(newCaseType);
  };

  const handleTabChange = (value: React.SetStateAction<string>) => {
    setTabValue(value);

    if (caseValue === 'AccessIssue') {
      if (value === 'Active') selectPage(caseStatusPages.accessIssueActive);
      else if (value === 'Closed') selectPage(caseStatusPages.accessIssueClosed);
    }

    if (caseValue === 'BalanceBill') {
      if (value === 'Active') selectPage(caseStatusPages.balanceBillActive);
      else if (value === 'Closed') selectPage(caseStatusPages.balanceBillClosed);
    }
  };

  const resetFilter = () => {
    setTabValue('');
    setCaseValue('');
    selectPage(caseStatusPages.accessIssueActive);
  };

  useEffect(() => {
    if (selectedReportPage === '') {
      selectPage(caseStatusPages.accessIssueActive);
    }
  }, [selectedReportPage, selectPage]);

  return (
    <>
      <div style={{ textAlign: 'right', margin: '1rem' }}>
        <button onClick={resetFilter} className={classes.resetBtn} type="button">
          Clear
        </button>
      </div>
      <div className={classes.statusTitle}>
        <Typography>Case Status Report</Typography>
      </div>
      <div className={classes.subHeading}>
        <Typography>Refine</Typography>
      </div>
      <div className={classes.caseStatus}>
        <Tabs
          onChange={handleTabChange}
          selectedValue={tabValue}
          options={[
            {
              content: 'Active Cases',
              value: 'Active',
              title: 'Active Cases',
            },
          ]}
        />
        <Tabs
          onChange={handleTabChange}
          selectedValue={tabValue}
          options={[
            {
              content: 'Closed Cases',
              value: 'Closed',
              title: 'Closed Cases',
            },
          ]}
        />
      </div>
      <CaseStatusNavStage
        stage={tabValue}
        caseValue={caseValue}
        accessIssuePage={
          tabValue === 'Active'
            ? caseStatusPages.accessIssueActive
            : caseStatusPages.accessIssueClosed
        }
        balanceBillPage={
          tabValue === 'Active'
            ? caseStatusPages.balanceBillActive
            : caseStatusPages.balanceBillClosed
        }
        selectedReportPage={selectedReportPage}
        selectPage={selectPage}
        onCaseTypeChange={handleCaseTypeChange}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseStatusNav);
