import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { debounce } from '@apps/shared/src/utilities';
import { setSearchInput } from '@apps/shared/src/providerSearch/actions';
import { RootState } from '../store';
import { IncompleteTheme } from '../shared/types/theme';
// import InfoTooltip from '../shared/InfoTooltip';

const useStyles = makeStyles({
  textBox: {
    '& .MuiOutlinedInput-input': {
      padding: '.6rem',
    },
  },
});

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  isLoading: providerSearch.isLoadingProviderList,
});

type StateProps = {
  isLoading: boolean;
};

type ParentProps = {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
};

const mapDispatchToProps = { setSearchInput };

type Props = StateProps & ParentProps & typeof mapDispatchToProps;

export function SearchBar({ setSearchInput, isLoading, input, setInput }: Props): JSX.Element {
  const classes = useStyles();

  const debouncedSearch = useCallback(debounce(setSearchInput, 400), [setSearchInput]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setInput(e.currentTarget.value);
    debouncedSearch(e.currentTarget.value);
  };

  const clearInput = (): void => {
    setSearchInput('');
    setInput('');
  };

  return (
    <div>
      {/* <InfoTooltip tooltip="Search providers by name, address, or phone">
        <Typography className={classes.header}>Search Providers</Typography>
      </InfoTooltip> */}
      <TextField
        id="search-bar"
        value={input}
        variant="outlined"
        fullWidth
        placeholder="Search..."
        aria-label="Search Providers"
        className={classes.textBox}
        onChange={handleChange}
        disabled={isLoading}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: input && (
            <InputAdornment position="end">
              <IconButton id="search-clear" onClick={clearInput}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
