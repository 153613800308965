import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import { colors } from '@apps/shared/src/style';
import { IncompleteTheme } from './types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  button: theme.header.navButton,
  active: theme.header.navButtonActive,
  activeSub: {
    ...theme.header.navButtonActive,
    ...theme.header.navSubButtonActive,
  },
  downButton: {
    height: '0.75em',
    marginRight: '-0.25em',
  },
  popover: {
    marginTop: '1em',
  },
  ahref: theme.cleanLink,
  indented: {
    paddingLeft: '2rem',
  },
  feature: {
    color: colors.grey76,
    fontStyle: 'italic',
  },
}));

type Props = {
  hasProviderSearchAccess: boolean;
  hasBenchmarksAccess: boolean;
};

export default function DocumentationNav({
  hasProviderSearchAccess,
  hasBenchmarksAccess,
}: Props): JSX.Element {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const match = useRouteMatch();

  const documentationNavClass = classNames(
    classes.button,
    match.path.includes('documentation') && classes.active
  );

  const buttonClassName = (route: string): string =>
    classNames(match.path.includes(route) && classes.activeSub, classes.indented);

  const handleMenuToggle = (e: React.SyntheticEvent<HTMLButtonElement>): void =>
    setMenuAnchor(menuAnchor ? null : e.currentTarget);

  return (
    <>
      <Button className={documentationNavClass} onClick={handleMenuToggle}>
        Documentation
        <DownIcon className={classes.downButton} />
      </Button>
      <Popover
        className={classes.popover}
        open={Boolean(menuAnchor)}
        onClose={handleMenuToggle}
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List component="nav">
          {hasBenchmarksAccess && (
            <>
              <ListItem className={classes.feature}>Benchmarks</ListItem>
              <Link to="/documentation/benchmarks-training" className={classes.ahref}>
                <ListItem button className={buttonClassName('documentation/benchmarks-training')}>
                  Training Videos
                </ListItem>
              </Link>
            </>
          )}
          {hasProviderSearchAccess && (
            <>
              <ListItem className={classes.feature}>Provider Search</ListItem>
              <Link to="/documentation/provider-search-training" className={classes.ahref}>
                <ListItem
                  button
                  className={buttonClassName('documentation/provider-search-training')}
                >
                  Training Video
                </ListItem>
              </Link>
              <Link to="/documentation/provider-search-faq" className={classes.ahref}>
                <ListItem button className={buttonClassName('documentation/provider-search-faq')}>
                  F.A.Q.
                </ListItem>
              </Link>
            </>
          )}
        </List>
      </Popover>
    </>
  );
}
