import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import { providerDataType, providerDetailsType } from '../shared/types/propTypes';

import AppLayout from '../shared/AppLayout';
import PageSpecificHeader from '../shared/PageSpecificHeader';
import ChartPane from './ChartPane';
import SelectionPane from './SelectionPane';
import DetailPane from './detailPane/DetailPane';
import {
  addProcedureFilter,
  addProviderFilter,
  removeProcedureFilter,
  removeProviderFilter,
  getAutocomplete,
  getChart,
  closeAutocomplete,
  openAutocomplete,
  updateSelectedProcedure,
  toggleUseRatios,
  getProviderDetail,
  zoomChart,
  resetChartZoom,
  panChart,
  updateChartPosition,
  updateCanShowLabels,
  showLabels,
  closeDetails,
  updateClaimType,
  changeSortOrder,
  toggleOptionsOpen,
  toggleDataSelectionOpen,
  outpatientCodeTypeChange,
  professionalCodeTypeChange,
  toggleHiddenData,
} from './benchmarkActions';
import { maxProviders } from '../shared/theme';
import { roles } from '../shared/globals';

const styles = {
  content: {
    width: '100vw',
    display: 'flex',
    position: 'relative',
  },
};

export class Benchmarks extends React.Component {
  componentDidMount() {
    this.dispatchAndGetChart(updateClaimType('Inpatient')); // Load saved chart
  }

  componentWillUnmount() {
    this.props.dispatch(updateClaimType('')); // Save Current Chart on Exit
  }

  handleProcedureSelect = filter => {
    this.closeAutocompleteAndBlur('procedure');
    if (filter) this.dispatchAndGetChart(addProcedureFilter(filter));
  };

  handleProviderSelect = filter => {
    this.closeAutocompleteAndBlur('provider');
    if (filter) this.dispatchAndGetChart(addProviderFilter(filter));
  };

  handleProcedureRemove = index => this.dispatchAndGetChart(removeProcedureFilter(index));
  handleProviderRemove = index => this.dispatchAndGetChart(removeProviderFilter(index));
  handleProcedureQueryChange = e =>
    this.props.dispatch(getAutocomplete(e.target.value, 'procedure', this.props.claimType));

  handleProviderQueryChange = e =>
    this.props.dispatch(getAutocomplete(e.target.value, 'provider', this.props.claimType));

  handleBlur = name => () => {
    this.props.dispatch(closeAutocomplete(name));
  };

  handleClick = name => () => {
    this.props.dispatch(openAutocomplete(name));
  };

  handleToggleOptionsOpen = () => {
    this.props.dispatch(toggleOptionsOpen());
  };

  handleToggleDataSelectionOpen = () => {
    this.props.dispatch(toggleDataSelectionOpen());
  };

  handleSelectedProcedureChange = value => this.props.dispatch(updateSelectedProcedure(value));

  handleUseRatiosToggle = () => this.props.dispatch(toggleUseRatios());
  handleSetUseRatios = value =>
    value !== this.props.useRatios && this.props.dispatch(toggleUseRatios());

  handleChartClick = e =>
    this.props.dispatch(
      getProviderDetail(this.props.providerData.providers[e._index].id, this.props.claimType) // eslint-disable-line no-underscore-dangle
    );

  handleZoomChart = (polarity, percent) => this.props.dispatch(zoomChart(polarity, percent));
  reportChartPosition = pos => this.props.dispatch(updateChartPosition(pos));
  handleResetZoom = () => this.props.dispatch(resetChartZoom());
  updateShowLabels = props => this.props.dispatch(updateCanShowLabels(props));
  handleShowLabels = value => this.props.dispatch(showLabels(value));
  handleDetailsClose = () => {
    this.props.dispatch(closeDetails());
  };

  handleChangeSortOrder = e => {
    this.props.dispatch(changeSortOrder(e.target.value));
  };

  closeAutocompleteAndBlur = type => this.props.dispatch(closeAutocomplete(type));
  handlePan = (direction, percent) => this.props.dispatch(panChart(direction, percent));
  handleClaimTypeChange = value => {
    if (value !== this.props.claimType) {
      this.dispatchAndGetChart(updateClaimType(value));
    }
  };

  handleToggleHiddenData = label => this.props.dispatch(toggleHiddenData(label));
  handleOutpatientCodeTypeChange = value => {
    this.dispatchAndGetChart(outpatientCodeTypeChange(value));
  };

  handleProfessionalCodeTypeChange = value => {
    this.dispatchAndGetChart(professionalCodeTypeChange(value));
  };

  dispatchAndGetChart(action) {
    this.props.dispatch(action);
    this.props.dispatch(getChart());
  }

  render() {
    const { classes, isUserAdmin, ...other } = this.props;
    return (
      <AppLayout>
        <PageSpecificHeader
          title="Benchmarks"
          description="View filtered reports by selecting the case type"
        />
        <section className={classes.content} id="benchmarksPane">
          <SelectionPane
            {...other}
            handleBlur={this.handleBlur}
            handleClick={this.handleClick}
            handleProviderSelect={this.handleProviderSelect}
            handleProviderQueryChange={this.handleProviderQueryChange}
            handleProviderRemove={this.handleProviderRemove}
            handleProcedureSelect={this.handleProcedureSelect}
            handleProcedureQueryChange={this.handleProcedureQueryChange}
            handleProcedureRemove={this.handleProcedureRemove}
            handleClaimTypeChange={this.handleClaimTypeChange}
            handleSetUseRatios={this.handleSetUseRatios}
            handleSelectedProcedureChange={this.handleSelectedProcedureChange}
            handleToggleHiddenData={this.handleToggleHiddenData}
            handleChangeSortOrder={this.handleChangeSortOrder}
            handleShowLabels={this.handleShowLabels}
            handleToggleOptionsOpen={this.handleToggleOptionsOpen}
            handleToggleDataSelectionOpen={this.handleToggleDataSelectionOpen}
            handleOutpatientCodeTypeChange={this.handleOutpatientCodeTypeChange}
            handleProfessionalCodeTypeChange={this.handleProfessionalCodeTypeChange}
            isUserAdmin={isUserAdmin}
          />
          <ChartPane
            {...other}
            handlePan={this.handlePan}
            updateShowLabels={this.updateShowLabels}
            reportChartPosition={this.reportChartPosition}
            handleChartClick={this.handleChartClick}
            handleResetZoom={this.handleResetZoom}
            handleZoomChart={this.handleZoomChart}
            handleUseRatiosToggle={this.handleUseRatiosToggle}
            handleSelectedProcedureChange={this.handleSelectedProcedureChange}
            handleToggleHiddenData={this.handleToggleHiddenData}
            updateYScale={this.handleUpdateYScale}
            options={this.props.options}
            atMax={this.props.atMax}
          />
        </section>
        <DetailPane
          details={this.props.details}
          isOpen={this.props.detailsIsOpen}
          handleClose={this.handleDetailsClose}
          claimType={this.props.claimType}
        />
      </AppLayout>
    );
  }
}

Benchmarks.propTypes = {
  atMax: PropTypes.bool,
  claimType: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  details: providerDetailsType,
  detailsIsOpen: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  drawerIsOpen: PropTypes.bool,
  options: PropTypes.shape({}).isRequired,
  providerData: providerDataType,
  useRatios: PropTypes.bool,
  isUserAdmin: PropTypes.bool.isRequired,
};
Benchmarks.defaultProps = {
  atMax: false,
  claimType: '',
  details: {},
  providerData: {},
  detailsIsOpen: false,
  drawerIsOpen: false,
  useRatios: false,
};

function mapStateToProps({ benchmarks: state, user: userState }) {
  return {
    procedureSelectIsOpen: state.procedureSelectIsOpen,
    providerSelectIsOpen: state.providerSelectIsOpen,
    procedureQuery: state.procedureQuery,
    providerQuery: state.providerQuery,
    claimType: state.claimType,
    procedureSelections: state.procedureSelections,
    providerSelections: state.providerSelections,
    procedureOptions: state.procedureOptions,
    providerOptions: state.providerOptions,
    bars: state.chart.bars,
    lines: state.chart.lines,
    meta: state.chart.meta,
    providerData: state.providerData,
    selectedProcedure: state.selectedProcedure,
    useRatios: state.useRatios,
    atMax: state.viewEnd - state.viewStart + 1 >= maxProviders,
    viewStart: state.viewStart,
    viewEnd: state.viewEnd,
    chartZoomPercent: state.chartZoomPercent,
    labelViewStart: state.labelViewStart,
    labelViewEnd: state.labelViewEnd,
    spotsLeft: state.spotsLeft,
    spotsRight: state.spotsRight,
    options: state.options,
    data: state.data,
    details: state.details,
    detailsIsOpen: state.detailsIsOpen,
    sortOrder: state.sortOrder,
    hiddenData: state.hiddenData,
    showLabels: state.showLabels,
    canShowLabels: state.canShowLabels,
    optionsOpen: state.optionsOpen,
    dataSelectionOpen: state.dataSelectionOpen,
    outpatientCodeType: state.outpatientCodeType,
    professionalCodeType: state.professionalCodeType,
    providerSelectPending: state.providerSelectPending,
    procedureSelectPending: state.procedureSelectPending,
    isChartLoading: state.chartLoading > 0,
    isUserAdmin: userState?.currentUser?.roles?.includes(roles.admin) || false,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(Benchmarks);
