import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { colors } from '@apps/shared/src/style';

const styles = theme => ({
  container: {
    width: '100%',
    minWidth: '250px',
    maxWidth: '550px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    backgroundColor: '#f47d12cf',
    padding: '10px',
    width: '60%',
    borderRadius: '4px',
    border: `1px solid white`,
    color: 'white',
    '&::placeholder': {
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
    fontSize: '1rem',
  },
  buttonInTextField: {
    backgroundColor: 'white',
    color: '#F97140',
    boxShadow: 'none',
    width: '35%',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    textTransform: 'uppercase',
    [theme.breakpoints.only('xs')]: {
      fontSize: '.75em',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '.7em',
      width: '45%',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '.85em',
      width: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
    },
  },
});

function SignUpInput({ classes, activeButton, updateEmail, requestAccess, email }) {
  return (
    <Paper elevation={20} className={classes.container}>
      <input
        value={email}
        className={classes.textField}
        placeholder="Email"
        type="email"
        onChange={updateEmail}
      />
      <Button
        variant="contained"
        className={classes.buttonInTextField}
        onClick={requestAccess}
        disabled={!activeButton}
      >
        Request Access
      </Button>
    </Paper>
  );
}

SignUpInput.propTypes = {
  activeButton: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  requestAccess: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpInput);
