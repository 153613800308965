import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

function RadioButton({ selection, value, onChange, label, disabled }) {
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      control={
        <Radio
          checked={selection === value}
          onChange={onChange}
          value={value}
          name={label}
          inputProps={{ 'aria-label': value }}
          color="primary"
        />
      }
    />
  );
}

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selection: PropTypes.string,
  value: PropTypes.string,
};
RadioButton.defaultProps = {
  disabled: false,
  selection: '',
  value: '',
};

export default RadioButton;
