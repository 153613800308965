import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import ProviderList from '@apps/shared/src/providerSearch/providerList/ProviderList';
import AppLayout from '../shared/AppLayout';
import PageSpecificHeader from '../shared/PageSpecificHeader';
import { contentHeight } from '../shared/theme';
import { getPlans } from './actions';
import ProviderSearchFilterPane from './ProviderSearchFilterPane';
import { RootState } from '../store';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    height: `calc(${contentHeight} - 6vh - 0.5rem)`,
  },
});

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  selectedZipCode: providerSearch.selectedZipCode,
  filteredCount: providerSearch.filteredList.length || 0,
  fullCount: providerSearch.fullList.length || 0,
});

const mapDispatchToProps = {
  getPlans,
};

type StateProps = {
  selectedZipCode: string;
  filteredCount: number;
  fullCount: number;
};

// type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & typeof mapDispatchToProps;

export function ProviderSearch({
  filteredCount,
  fullCount,
  selectedZipCode,
  getPlans,
}: Props): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  return (
    <AppLayout>
      {selectedZipCode === '' && (
        <PageSpecificHeader
          title="Provider Search"
          description="Enter Zip code to search for providers nearby you"
        />
      )}
      {selectedZipCode !== '' && (
        <PageSpecificHeader
          title="Provider Search"
          description="Search for Providers by zip code"
        />
      )}
      <div className={classes.container}>
        <ProviderSearchFilterPane />

        {/* for selenium testing */}
        <p id="provider-filtered-count" style={{ visibility: 'hidden', width: '0' }}>
          {`filtered: ${filteredCount}`}
        </p>
        <p id="provider-full-count" style={{ visibility: 'hidden', width: '0' }}>
          {`full: ${fullCount}`}
        </p>
        {/* end test section */}

        <ProviderList />
      </div>
    </AppLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearch);
