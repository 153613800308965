import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Question } from './faqQuestions';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    lineHeight: '1.3',
    padding: '20px 0px 5px 0px',
    color: theme.palette.text.primary,
  },
  text: {
    fontSize: '.85rem',
    paddingBottom: '10px',
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  faq: Question;
};

export default function FAQ({ faq }: Props): JSX.Element {
  const classes = useStyles();
  const { question, answer } = faq;

  return (
    <div data-testid="faq">
      <Typography className={classes.title} variant="body1">
        {question}
      </Typography>
      <Typography className={classes.text} variant="body2">
        {answer}
      </Typography>
    </div>
  );
}
