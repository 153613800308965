import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from '@material-ui/core/Slider';
import { Typography } from '@material-ui/core';

import { setSearchRadius } from '@apps/shared/src/providerSearch/actions';
import { SearchRadius } from '@apps/shared/src/providerSearch/types/providerSearch';
import { getProviders } from '../actions';

import { RootState } from '../../store';

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  searchRadius: providerSearch.radius,
  isLoading: providerSearch.isLoadingProviderList,
  selectedZipCode: providerSearch.selectedZipCode,
});

type StateProps = {
  searchRadius: SearchRadius;
  isLoading: boolean;
  selectedZipCode: string;
};

type ParentProps = {
  sliderValue: number;
  setSliderValue: React.Dispatch<React.SetStateAction<number>>;
};

const mapDispatchToProps = {
  setSearchRadius,
  getProviders,
};

type DispatchProps = {
  getProviders: (selectedZipCode: string, selecetedRadius: string) => void;
};

type Props = StateProps & ParentProps & DispatchProps & typeof mapDispatchToProps;

const SliderSelector = ({
  sliderValue,
  isLoading,
  setSliderValue,
  setSearchRadius,
  getProviders,
  selectedZipCode,
}: Props) => {
  const PrettoSlider = useMemo(
    () =>
      withStyles({
        root: {
          color: '#366BED',
          position: 'relative',
        },
        thumb: {
          height: 20,
          width: 20,
          backgroundColor: '#366BED',
          border: '2px solid #366BED',
          marginTop: -8,
          marginLeft: -12,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
          position: 'relative',
          zIndex: 1000,
        },
        active: {},
        track: {
          height: 3,
          background:
            'repeating-linear-gradient(90deg, #366BED, #366BED 5px, transparent 5px, transparent 10px)',
        },
        rail: {
          height: 3,
          background:
            'repeating-linear-gradient(90deg, #888787, #888787 5px, transparent 5px, transparent 10px)',
        },
        valueLabel: {
          left: 'calc(-50% + 12px)',
          top: 40,
          '& span': {
            transform: 'none',
          },
        },
      })(Slider),
    []
  );

  const useStyles = makeStyles({
    root: {
      paddingTop: '.5rem',
      width: '100%',
      position: 'relative',
    },
    title: {
      fontSize: '0.875rem',
      fontWeight: 600,
      marginBottom: '0.5rem',
    },
    valueStyle: {
      position: 'absolute',
      top: 'calc(38%)',
      left: '50%',
      color: '#366BED',
      fontSize: '15px',
    },
    sideStyleLeft: {
      position: 'relative',
      left: '-7px',
      marginTop: 20,
      color: '#797C80',
      fontSize: '17px',
    },
    sideStyleRight: {
      position: 'relative',
      right: '-2px',
      marginTop: 20,
      color: '#797C80',
      fontSize: '17px',
    },
    leftLineStyle: {
      position: 'absolute',
      top: '83%',
      transform: 'translateY(-83%)',
      left: '-2px',
      width: '15px',
      height: '15px',
      background: 'transparent',
      borderLeft: '4px solid #888787',
      color: '#888787',
    },
    rightLineStyle: {
      position: 'absolute',
      top: '83%',
      transform: 'translateY(-83%)',
      right: '-2px',
      width: '15px',
      height: '15px',
      background: 'transparent',
      borderRight: '4px solid #888787',
      color: '#888787',
    },
  });

  const classes = useStyles();

  const handleChange = useCallback(
    (_, newValue) => {
      setSliderValue(newValue);
      const radius = Math.round(newValue).toString();
      setSearchRadius(radius as SearchRadius);
      getProviders(selectedZipCode, radius);
    },
    [selectedZipCode]
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Radius</Typography>
      <PrettoSlider
        value={sliderValue}
        onChange={handleChange}
        step={1}
        min={0}
        max={100}
        disabled={isLoading}
      />
      <div className={classes.valueStyle} style={{ left: `calc(${sliderValue}% - 10px)` }}>
        {Math.round(sliderValue)}
      </div>
      <div className={classes.leftLineStyle}>
        <Typography className={classes.sideStyleLeft}>0</Typography>
      </div>
      <div className={classes.rightLineStyle}>
        <Typography className={classes.sideStyleRight}>100</Typography>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderSelector);
