import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { getMemberDetail } from '@apps/shared/src/getMember/actions';
import {
  createCaseInfo,
  resetAddCase,
  setAddCaseResponseInfo,
  updateCaseMemberDetail,
  setCaseMemberInfo,
  setIsDependentMember,
} from '@apps/shared/src/addCase/actions';
import { MemberInfo } from '@apps/shared/src/getMember/types/memberTypes';
import { AddCaseState, ProviderInfo } from '@apps/shared/src/addCase/types/addCaseTypes';
import { getDependentMembersDetail } from '@apps/shared/src/dependentMember/actions';
import { DependentMemberInfo } from '@apps/shared/src/dependentMember/types/dependentMemberTypes';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import { defaultMemberState } from '@apps/shared/src/getMember/reducer';
import { RootState } from '../store';
import TextInput from '../shared/textInput';
import SelectInput from '../shared/muiSelect';
import formatDate from '../shared/date';

import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles(() =>
  createStyles({
    appContainer: {
      padding: '1rem',
      color: '#797C80',
      position: 'relative',
      height: 'calc(100vh - 120px - 2.5rem)',
    },
    arrowIcon: {
      height: '2rem',
      width: '2rem',
      cursor: 'pointer',
    },
    formContainer: {
      color: '#797C80',
      height: 'calc(100vh - 20rem)',
      padding: '1.5rem 0',
      display: 'grid',
      gap: '1.5rem',
      gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '1px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '1px',
      },
    },
    loadingSpinner: {
      margin: '3rem auto 0 auto',
    },
    dependentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    radioGroup: {
      '&.MuiFormGroup-root': {
        flexWrap: 'nowrap !important',
        justifyContent: 'space-between',
        padding: '0 8rem 0 0',
      },
    },
    caution: {
      gridColumn: '1 / -1',
    },
    buttonStyle: {
      width: '32%',
      position: 'absolute',
      bottom: 0,
      right: '1.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.5rem',
    },
    backBtn: {
      gridColumn: '1 / -1',
      width: '50%',
      borderRadius: '.2rem',
      background: '#fff',
      color: '#888787',
      border: '1px solid #888787',
      padding: '.5rem 3rem',
      cursor: 'pointer',
      boxShadow: 'None',
    },
    successBtn: {
      gridColumn: '1 / -1',
      width: '50%',
      borderRadius: '.2rem',
      background: 'primary',
      color: 'white',
      fontWeight: 600,
      outline: 'None',
      padding: '.5rem 3rem',
      cursor: 'pointer',
      boxShadow: 'None',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0)',
      zIndex: -1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      transition: 'background 0.3s ease, opacity 0.3s ease',
    },
    overlayVisible: {
      background: 'rgba(255, 255, 255, 0.8)',
      opacity: 1,
      zIndex: 999,
    },
  })
);

type StateProps = {
  dependentMembers: DependentMemberInfo[];
  memberInfo: MemberInfo;
  memberDetailsLoaded: boolean;
  caseType: string;
  providerOption: boolean;
  addingCase: boolean;
  isDependentMember: boolean;
  isCaseCreated: boolean;
  newCaseInfo: MemberInfo;
};

type DispatchProps = {
  updateCaseMemberDetail: (memberInfo: MemberInfo) => void;
  getMemberDetail: () => void;
  createCaseInfo: (
    redirectTo: string,
    caseInfo: MemberInfo & ProviderInfo,
    caseType: string
  ) => void;
  getDependentMembersDetail: () => void;
  setAddCaseResponseInfo: (
    key: keyof AddCaseState,
    value: AddCaseState[keyof AddCaseState]
  ) => void;
  resetAddCase: () => void;
  setCaseMemberInfo: (key: keyof MemberInfo, value: MemberInfo[keyof MemberInfo]) => void;
  setIsDependentMember: (isDependentMember: boolean) => void;
};

const mapDispatchToProps = {
  updateCaseMemberDetail,
  getMemberDetail,
  setAddCaseResponseInfo,
  resetAddCase,
  createCaseInfo,
  getDependentMembersDetail,
  setCaseMemberInfo,
  setIsDependentMember,
};

const mapStateToProps = (state: RootState) => ({
  dependentMembers: state.getDependentMember,
  memberInfo: state.getMember.memberInfo,
  memberDetailsLoaded: state.getMember.memberDetailsLoaded,
  caseType: state.addCase.caseType,
  providerOption: state.addCase.providerOption,
  isCaseCreated: state.addCase.isCreated,
  addingCase: state.addCase.addingCase,
  isDependentMember: state.addCase.isDependentMember,
  newCaseInfo: state.addCase.memberInfo,
});

type Props = StateProps & DispatchProps;

const MemberDetail = ({
  memberInfo,
  memberDetailsLoaded,
  dependentMembers,
  caseType,
  updateCaseMemberDetail,
  getMemberDetail,
  getDependentMembersDetail,
  addingCase,
  isDependentMember,
  setCaseMemberInfo,
  newCaseInfo,
  setIsDependentMember,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isAppointmentForDependentMember, setIsAppointmentForDependentMember] =
    useState<boolean>(false);
  const [dependentMemberId, setDependentMemberId] = useState<string>('');
  const isNewPatient = newCaseInfo?.newPatient ? 'new' : 'existing';

  const [patientType, setPatientType] = useState(isNewPatient);

  const handleNext = () => {
    const memberData: any = {
      ...newCaseInfo,
      recordType: caseType === 'Balance Bill' ? 'BalanceBill' : 'AccessIssue',
      // planname: memberInfo?.policyNumber,
    };
    updateCaseMemberDetail(memberData);
    history.push(caseType === 'Access Issue' ? `/provider-detail` : `/upload-file`);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleAppointmentFor = (value = 'Myself'): void => {
    if (value === 'Dependent') {
      getDependentMembersDetail();
      updateCaseMemberDetail(defaultMemberState.memberInfo);
      setIsAppointmentForDependentMember(true);
    } else {
      setDependentMemberId('');
      updateCaseMemberDetail(memberInfo);
      setIsAppointmentForDependentMember(false);
      setIsDependentMember(false);
    }
    getMemberDetail();
  };

  const handlePatientType = (field: keyof MemberInfo, value: string): void => {
    setCaseMemberInfo(field, value === 'new');
  };

  const handleOnChange = (value: string, field: keyof MemberInfo) => {
    setCaseMemberInfo(field, value);
  };

  const handleDependentMemberSelect = (selectedMemberId: string): void => {
    setDependentMemberId(selectedMemberId);
    const dependentMemberInfo = dependentMembers.find((member: DependentMemberInfo) => {
      return `${member.firstName} ${member.lastName}` === selectedMemberId;
    });
    if (dependentMemberInfo) {
      setIsDependentMember(true);
      updateCaseMemberDetail(dependentMemberInfo);
    }
  };

  const validEmail = (email: string): boolean => {
    if (email !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const invalidCharactersRegex = /[&!#^$%*()''""~,`]/;
      return emailRegex.test(email) && !invalidCharactersRegex.test(email);
    }
    return true;
  };

  const validPhoneNumber = (phoneNumber: string): boolean => {
    if (phoneNumber !== '') {
      return phoneNumber.length === 10;
    }
    return true;
  };

  const validZipCode = (zipCode: string): boolean => {
    if (zipCode !== '') {
      return zipCode.length === 5;
    }
    return true;
  };

  const handleNumericInput = (e: React.ChangeEvent<HTMLInputElement>, length: number): void => {
    let inputValue = e.target.value.replace(/[^0-9]/g, '');
    inputValue = inputValue.slice(0, length);
    e.target.value = inputValue;
  };

  useEffect(() => {
    if (caseType === '') {
      history.push(`/my-cases`);
    }
    if (isDependentMember) {
      setDependentMemberId(`${newCaseInfo.firstName} ${newCaseInfo.lastName}`);
    }
    if (memberInfo && newCaseInfo.preferredMethodOfContact === '') {
      updateCaseMemberDetail(memberInfo);
      handleAppointmentFor();
    }
  }, [memberDetailsLoaded]);

  const disableNextAndSubmit: boolean =
    !newCaseInfo ||
    !newCaseInfo.preferredMethodOfContact ||
    (newCaseInfo.preferredMethodOfContact === 'Email' &&
      (!newCaseInfo.email || !validEmail(newCaseInfo.email))) ||
    (newCaseInfo.preferredMethodOfContact === 'Phone' && !newCaseInfo.phone) ||
    !validPhoneNumber(newCaseInfo.phone) ||
    !validEmail(newCaseInfo.email) ||
    !validZipCode(newCaseInfo.zip);

  return (
    <AppLayout>
      <div className={`${classes.overlay} ${addingCase ? classes.overlayVisible : ''}`}>
        <LoadingSpinner />
      </div>
      <div className={classes.appContainer}>
        <ArrowBackIcon className={classes.arrowIcon} onClick={handleBack} />
        <Typography>
          {`** Please do not submit ${caseType.toLowerCase()}s from multiple providers on the same submission.`}
        </Typography>

        <div className={classes.formContainer}>
          <div className={classes.dependentWrapper}>
            <FormControl>
              {caseType === 'Access Issue' && <Typography>Who is this appointment for?</Typography>}
              <RadioGroup
                row
                onChange={e => handleAppointmentFor(e.target.value)}
                defaultValue={isDependentMember ? 'Dependent' : 'Myself'}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="Myself"
                  label="Myself"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="Dependent"
                  label="Dependent"
                />
              </RadioGroup>
            </FormControl>

            {(isAppointmentForDependentMember || isDependentMember) && (
              <SelectInput
                label="Select Dependent Member"
                options={dependentMembers.map(
                  (dependentMember: DependentMemberInfo) =>
                    `${dependentMember.firstName} ${dependentMember.lastName}`
                )}
                value={dependentMemberId}
                onSelectChange={handleDependentMemberSelect}
              />
            )}
          </div>
          {caseType === 'Access Issue' && (
            <FormControl>
              <Typography>Patient Type(with provider)?*</Typography>
              <RadioGroup
                row
                onChange={e => {
                  setPatientType(e.target.value);
                  handlePatientType('newPatient', e.target.value);
                }}
                value={patientType}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="new"
                  label="New Patient"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="existing"
                  label="Existing Patient"
                />
              </RadioGroup>
            </FormControl>
          )}
          <TextInput
            label="Member ID"
            value={newCaseInfo?.memberId || newCaseInfo?.patientid || ''}
            inputProps={{
              readOnly: true,
            }}
            onChange={val => handleOnChange(val, 'memberId')}
          />
          <TextInput
            label="Member Name"
            value={`${newCaseInfo?.firstName || ''} ${newCaseInfo?.lastName || ''}`}
            inputProps={{
              readOnly: true,
            }}
            onChange={val => handleOnChange(val, 'firstName')}
          />
          <TextInput
            type="text"
            name="dob"
            label="Date of Birth"
            value={newCaseInfo?.dateOfBirth ? formatDate(newCaseInfo?.dateOfBirth) : ''}
            inputProps={{
              readOnly: true,
            }}
            onChange={val => handleOnChange(val, 'dateOfBirth')}
          />
          <SelectInput
            label="Preferred Method of Contact*"
            options={['Phone', 'Email']}
            value={newCaseInfo.preferredMethodOfContact}
            onSelectChange={val => handleOnChange(val, 'preferredMethodOfContact')}
          />
          <TextInput
            label={newCaseInfo?.preferredMethodOfContact === 'Phone' ? 'Phone*' : 'Phone'}
            value={newCaseInfo.phone}
            onChange={val => handleOnChange(val, 'phone')}
            validators={['validatePhoneNumberLength']}
            errorMessages={['Must be a valid phone number']}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              onInput: e => handleNumericInput(e, 10),
            }}
          />
          <TextInput
            label={newCaseInfo?.preferredMethodOfContact === 'Email' ? 'Email*' : 'Email'}
            value={newCaseInfo?.email}
            onChange={val => handleOnChange(val, 'email')}
            validators={['isEmail']}
            errorMessages={['Must be a valid email']}
          />
          <TextInput
            label="Address"
            value={newCaseInfo?.address || ''}
            inputProps={{
              readOnly: true,
            }}
            onChange={val => handleOnChange(val, 'address')}
          />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextInput
                label="State"
                value={newCaseInfo?.state || ''}
                inputProps={{
                  readOnly: true,
                }}
                onChange={val => handleOnChange(val, 'state')}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextInput
                label="City"
                value={newCaseInfo?.city || ''}
                inputProps={{
                  inputMode: 'numeric',
                  readOnly: true,
                  pattern: '[0-9]*',
                }}
                onChange={val => handleOnChange(val, 'city')}
              />
            </Grid>
          </Grid>
          <TextInput
            label="Zip"
            value={newCaseInfo?.zip || ''}
            onChange={val => handleOnChange(val, 'zip')}
            validators={['validateZipCode']}
            errorMessages={['Must be a valid Zip Code']}
            inputProps={{
              inputMode: 'numeric',
              readOnly: true,
              pattern: '[0-9]*',
            }}
          />
          <TextInput
            label="Health Plan Name"
            value={newCaseInfo?.planname}
            inputProps={{
              readOnly: true,
            }}
            // options={[memberInfo?.planName]}
            onChange={val => handleOnChange(val, 'planname')}
          />
          <TextInput
            label="Group#"
            value={newCaseInfo?.groupid}
            inputProps={{
              readOnly: newCaseInfo?.groupid,
            }}
            onChange={val => handleOnChange(val, 'groupid')}
          />
        </div>
        <div className={classes.buttonStyle}>
          <Button
            variant="outlined"
            color="default"
            onClick={handleBack}
            className={classes.backBtn}
          >
            BACK
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={disableNextAndSubmit}
            className={classes.successBtn}
          >
            NEXT
          </Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetail);
