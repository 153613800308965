import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';

import { Modal } from '@material-ui/core';

import { colors } from '@apps/shared/src/style';

import LoginForm from '@apps/shared/src/auth/LoginForm';

import Button from '@material-ui/core/Button';

import UserCard from '@apps/shared/src/auth/UserCard';
import { IncompleteTheme } from '../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  appBarButtonCTA: {
    textTransform: 'uppercase',
    margin: '0 0.5rem',
    // backgroundColor: colors.yellow,
    backgroundColor: '#F97140',
    color: 'white',
  },
  login: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: theme.props.contentHeight,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  card: {
    maxWidth: '30rem',
    margin: 'auto',
  },
}));

export default function LoginModal(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const [loginOpen, setLoginOpen] = useState(location.pathname === '/login');

  const classes = useStyles();

  const handleLoginClick = (): void => {
    // setLoginOpen(!loginOpen);
    history.push('/login');
  };

  return (
    <>
      <Button
        onClick={handleLoginClick}
        className={classes.appBarButtonCTA}
        variant="contained"
        disableElevation
      >
        Log In
      </Button>
      <Modal open={loginOpen} onBackdropClick={handleLoginClick} className={classes.login}>
        <UserCard showLogo classes={classes}>
          <LoginForm application="medivi" />
        </UserCard>
      </Modal>
    </>
  );
}
