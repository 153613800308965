import axios from 'axios';
import { getAxiosRequestConfig } from '../utilities/http';
import M, { GetNotification } from './types/notificationActions';

export const markAllAsRead =
  () => async (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    try {
      const payload = {
        markallasread: true,
      };

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL || ''}/api/notification/markasread`,
        payload,
        getAxiosRequestConfig()
      );

      dispatch({
        type: M.MARK_ALL_AS_READ,
      });
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };

export const markAsRead =
  (id: string) => async (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL || ''}/api/updateNotification/${id}`,
        { markasread: true },
        getAxiosRequestConfig()
      );

      dispatch({
        type: M.MARK_AS_READ,
        payload: id,
      });
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

export const deleteNotification =
  (id: string) => async (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL || ''}/api/deleteNotification/${id}`,
        getAxiosRequestConfig()
      );

      dispatch({
        type: M.DELETE_NOTIFICATION,
        payload: id,
      });
    } catch (error) {
      console.error('Failed to delete notification:', error);
    }
  };

export const updateCaseMessage = (message: any) => ({
  type: M.UPDATE_NEW_NOTIFICATION,
  payload: message,
});

export const setNotificationFilter = (filter: string) => ({
  type: M.SET_NOTIFICATION_FILTER,
  payload: filter,
});

export function getAllNotifications(): GetNotification {
  return {
    type: M.GET_NOTIFICATION,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/notifications`,
      getAxiosRequestConfig()
    ),
  };
}
