import { AxiosPromise } from 'axios';
import { Plan } from './plan';

enum p {
  SET_PLAN_STATE = 'SET_PLAN_STATE',
  GET_PLANS = 'GET_PLANS',
  GET_PLANS_PENDING = 'GET_PLANS_PENDING',
  GET_PLANS_REJECTED = 'GET_PLANS_REJECTED',
  GET_PLANS_FULFILLED = 'GET_PLANS_FULFILLED',
  GET_PLANS_RTK_PENDING = 'plan/getPlans/pending',
  GET_PLANS_RTK_REJECTED = 'plan/getPlans/rejected',
  GET_PLANS_RTK_FULFILLED = 'plan/getPlans/fulfilled',
  RESET_PLAN_PAGE = 'RESET_PLAN_PAGE',
}
export default p;

export type SetPlanState = {
  type: typeof p.SET_PLAN_STATE;
  payload: Plan;
};

export type GetPlans = {
  type: typeof p.GET_PLANS;
  payload: AxiosPromise<{
    data: Plan[];
  }>;
};

type GetPlansPending = {
  type: typeof p.GET_PLANS_PENDING | p.GET_PLANS_RTK_PENDING;
};

type GetPlansRejected = {
  type: typeof p.GET_PLANS_REJECTED | p.GET_PLANS_RTK_REJECTED;
};

export type GetPlansFulfilled = {
  type: typeof p.GET_PLANS_FULFILLED | p.GET_PLANS_RTK_FULFILLED;
  payload: {
    data: Plan[];
  };
};

type GetPlanActions = GetPlans | GetPlansPending | GetPlansRejected | GetPlansFulfilled;

export type ResetPlanPage = {
  type: typeof p.RESET_PLAN_PAGE;
};

export type PlanActionTypes = GetPlanActions | SetPlanState | ResetPlanPage;

export function isGetPlansResponse(data: unknown): data is Plan[] {
  return Array.isArray(data as Plan[]);
}
