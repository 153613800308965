import React, { useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  Provider,
  Accessibility,
  ProviderTitle,
} from '@apps/shared/src/providerSearch/types/providerSearch';
import { colors } from '../../style/colors';
import { radiusDescriptions } from '../api/providers';

import ProviderAccessibilityChip from './ProviderAccessibilityChip';
import CategoryAndTitle from './CategoryAndTitle';
import PhoneNumber from './PhoneNumber';
import ProviderAddress from './ProviderAddress';

type StyleProps = Pick<Props, 'isFirstCard' | 'isLastCard'>;

type Props = {
  provider: Provider;
  isFirstCard: boolean;
  isLastCard: boolean;
  category: string;
  title: string;
  selectedAccessibilities: Accessibility[];
};

const useStyles = makeStyles({
  card: (props: { isFirstCard: boolean; isLastCard: boolean; borderColor: string }) => ({
    borderRadius: '.5rem',
    margin: '1rem 1rem 0 1rem',
    marginBottom: determineCardMargin(props.isLastCard),
    borderLeftWidth: '5px',
    borderLeftStyle: 'solid',
    borderLeftColor: props.borderColor,
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  organizationName: {
    fontWeight: 'bold',
    color: '#36383D',
    margin: '0.5rem 0 0.8rem 0rem',
    lineHeight: 1.1,
  },
  footer: {
    color: colors.greyDark,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '8px',
    color: '#888787',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#888787',
  },
});

export function determineCardMargin(isFirstOrLastCard: boolean): string {
  return isFirstOrLastCard ? '2rem' : '0.25rem';
}

export function formatDistance(distance: number): string {
  if (distance === 0) return radiusDescriptions[0];
  if (distance <= 5) return radiusDescriptions[5];
  const rounded = Math.round(distance / 5) * 5;
  return `~ ${rounded.toFixed(0)} miles away`;
}

function determineBorderColor(label1: string, label2: string | undefined): string {
  if (label2 === 'Contracted') {
    return '#4caf50'; // Green
  }
  if (label1 === 'Unknown') {
    return '#9e9e9e'; // Grey
  }
  if (label1 === 'Caution') {
    return '#ffeb3b'; // Yellow
  }
  if (label1 === 'Not Recommended') {
    return '#f44336'; // Red
  }
  return '#000'; // Default
}

export default function ProviderCard({
  provider,
  isFirstCard,
  isLastCard,
  category,
  title,
  selectedAccessibilities,
}: Props): JSX.Element {
  const { name, address1, address2, city, state, zip, phone, label1, label2, distance } = provider;

  let borderColor = determineBorderColor(provider.label1, provider.label2);

  const classes = useStyles({ isFirstCard, isLastCard, borderColor });

  let tooltipText = '';
  if (label2 === 'Contracted') {
    tooltipText = ProviderTitle.Green;
  } else if (label1 === 'Unknown') {
    tooltipText = ProviderTitle.Grey;
  } else if (label1 === 'Caution') {
    tooltipText = ProviderTitle.Yellow;
  } else if (label1 === 'Not Recommended') {
    tooltipText = ProviderTitle.Red;
  }

  const formattedDistance = formatDistance(distance);

  useEffect(() => {
    borderColor = determineBorderColor(provider.label1, provider.label2);
  }, [selectedAccessibilities]);

  return (
    <Card data-testid="ProviderCard" variant="outlined" className={classes.card}>
      <CardContent>
        <div className={classes.header}>
          <Typography className={classes.organizationName}>{name}</Typography>
          <Tooltip title={tooltipText} placement="top" arrow classes={{ tooltip: classes.tooltip }}>
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </div>
        <ProviderAddress
          name={name}
          address1={address1 || ''}
          address2={address2}
          city={city}
          state={state}
          zip={zip}
        />
        <CategoryAndTitle category={category} title={title} />
        <div className={classes.footer}>
          <PhoneNumber number={phone} />
          <Typography variant="body2">{formattedDistance}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}
