import { AxiosPromise } from 'axios';
import { MemberInfo, MemberInfoState } from './memberTypes';

enum m {
  GET_MEMBER = 'GET_MEMBER',
  GET_MEMBER_PENDING = 'GET_MEMBER_PENDING',
  GET_MEMBER_REJECTED = 'GET_MEMBER_REJECTED',
  GET_MEMBER_FULFILLED = 'GET_MEMBER_FULFILLED',
  RESET_MEMBER_INFO = 'RESET_MEMBER_INFO',
}
export default m;

export type GetMember = {
  type: typeof m.GET_MEMBER;
  payload: AxiosPromise;
};

export type GetMemberPending = {
  type: typeof m.GET_MEMBER_PENDING;
};

export type GetMemberFulfilled = {
  type: typeof m.GET_MEMBER_FULFILLED;
  payload: { data: MemberInfo };
};

export type GetMemberRejected = {
  type: typeof m.GET_MEMBER_REJECTED;
};

export type ResetMemberInfo = {
  type: typeof m.RESET_MEMBER_INFO;
};

export type GetMemberActionTypes =
  | GetMember
  | GetMemberPending
  | GetMemberRejected
  | GetMemberFulfilled
  | ResetMemberInfo;
