import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Typography } from '@material-ui/core';
import { colors } from '@apps/shared/src/style';

const styles = theme => ({
  testimonial: {
    // minWidth: '250px',
    // maxWidth: '600px',
    fontFamily: theme.typography.landingPage.fontFamily,
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      // width: '38vw',
    },
    [theme.breakpoints.only('lg')]: {
      // width: '30vw',
    },
    [theme.breakpoints.up('lg')]: {
      // width: '18vw',
    },
  },
  quote: {
    color: `${colors.greyDark}cc`,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  companyLabel: {
    padding: '24px 24px 0 24px',
  },
  avatar: {
    [theme.breakpoints.up('lg')]: {
      width: '55px',
      height: '55px',
    },
  },
  photo: {
    width: '100%',
  },
  logo: {
    width: '50px',
  },
  companyName: {
    fontWeight: '600',
  },
});

function Testimonial({ classes, t }) {
  const headshot = t.photo ? (
    <img src={t.photo} alt={t.name} className={classes.photo} />
  ) : (
    t.initials
  );
  const company = t.logo ? (
    <img src={t.logo} alt={`${t.company} logo`} className={classes.logo} />
  ) : (
    <Typography className={classes.companyName}>{t.company}</Typography>
  );

  return (
    <div className={classes.testimonial}>
      <Card raised>
        <div className={classes.companyLabel}>{company}</div>
        <CardContent className={classes.quote}>{`"${t.quote}"`}</CardContent>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>{headshot}</Avatar>}
          title={t.name}
          subheader={t.jobTitle}
        />
      </Card>
    </div>
  );
}

Testimonial.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  t: PropTypes.shape({
    company: PropTypes.string,
    initials: PropTypes.string,
    jobTitle: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string,
    quote: PropTypes.string,
  }),
};
Testimonial.defaultProps = {
  t: {},
};

export default withStyles(styles)(Testimonial);
