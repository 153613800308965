import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { Accessibility } from '@apps/shared/src/providerSearch/types/providerSearch';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../store';

const useStyles = makeStyles(theme => ({
  inputContainer: {
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
    color: '#797C80',
  },
  input: {
    background: '#FFFFFF',
    minHeight: '52px',
    borderRadius: '.75rem',
    boxShadow: '0px 16px 32px 0px #090E1D0D',
    boxSizing: 'border-box',
    // padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
    //   1
    // )}px`,
    padding: '0.5rem 1rem',
    width: '100%',
    display: 'flex', // Center the text vertically
    alignItems: 'center', // Center the text vertically
    '&:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitBoxShadow: '0 0 0 30px white inset !important',
    },
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  selectedAccessibilities: providerSearch.selectedAccessibilities,
});

type StateProps = {
  selectedAccessibilities: Accessibility[];
};

type SelectInputProps = {
  label: string;
  value: string;
  selectBoxStyle?: string;
  options: string[];
  onSelectChange: (selectedValue: string) => void;
  radiusOption?: boolean;
  accessibility?: Accessibility[];
  setAccessibility?: (value: Accessibility[]) => void;
};

type Props = StateProps & SelectInputProps;

const SelectInput: React.FC<Props> = ({
  label,
  value,
  options,
  selectBoxStyle,
  onSelectChange,
  accessibility = undefined,
  setAccessibility = undefined,
  radiusOption = false,
}) => {
  const classes = useStyles();

  const handleInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    onSelectChange(newValue);
  };

  const handleCheckboxClick =
    (option: string) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      // Stop the propagation of the click event to avoid triggering handleInputChange
      if (radiusOption) {
        event.stopPropagation();
        if (accessibility !== undefined && setAccessibility !== undefined) {
          const updatedAccessibilities = accessibility.includes(option as Accessibility)
            ? accessibility.filter(a => a !== option)
            : [...accessibility, option];

          setAccessibility(updatedAccessibilities as Accessibility[]);
        }
      }
    };

  return (
    <div className={classes.inputContainer}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={handleInputChange}
        error={false} // Update this based on your validation logic
        classes={{ root: classes.input }}
        className={selectBoxStyle}
        fullWidth
        displayEmpty
        disableUnderline
      >
        <MenuItem value="" disabled>
          Select
        </MenuItem>
        {options &&
          options.map(option => (
            <MenuItem key={option} value={option} onClick={handleCheckboxClick(option)}>
              {radiusOption && accessibility !== undefined ? (
                <Checkbox
                  color="primary"
                  checked={accessibility.includes(option as Accessibility)}
                  disableRipple
                />
              ) : (
                option
              )}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default connect(mapStateToProps)(SelectInput);
