import { AxiosPromise } from 'axios';
import { Case } from './myCase';

enum c {
  GET_CASES = 'GET_CASES',
  GET_CASES_PENDING = 'GET_CASES_PENDING',
  GET_CASES_REJECTED = 'GET_CASES_REJECTED',
  GET_CASES_FULFILLED = 'GET_CASES_FULFILLED',
  GET_CASES_RTK_PENDING = 'case/getCases/pending',
  GET_CASES_RTK_REJECTED = 'case/getCases/rejected',
  GET_CASES_RTK_FULFILLED = 'case/getCases/fulfilled',
  RESET_CASES = 'RESET_CASES',
}
export default c;

export type GetCases = {
  type: typeof c.GET_CASES;
  payload: AxiosPromise<{
    data: Case[];
  }>;
};

type GetCasesPending = {
  type: typeof c.GET_CASES_PENDING | c.GET_CASES_RTK_PENDING;
};

type GetCasesRejected = {
  type: typeof c.GET_CASES_REJECTED | c.GET_CASES_RTK_REJECTED;
};

export type GetCasesFulfilled = {
  type: typeof c.GET_CASES_FULFILLED | c.GET_CASES_RTK_FULFILLED;
  payload: {
    data: Case[];
  };
};

export type ResetCaseState = {
  type: typeof c.RESET_CASES;
};

type GetCaseActions =
  | GetCases
  | GetCasesPending
  | GetCasesRejected
  | GetCasesFulfilled
  | ResetCaseState;

export type CaseActionTypes = GetCaseActions;

export function isGetCasesResponse(data: unknown): data is Case[] {
  return Array.isArray(data as Case[]);
}
