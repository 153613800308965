import { NotificationState } from './types/notification';
import M, { NotificationActionTypes } from './types/notificationActions';

export const defaultNotificationState: NotificationState = {
  notifications: [],
  isNotificationLoading: false,
  hasLoadedNotification: true,
  appliedFilter: 'All',
};

const mapApiResponseToNotification = (apiResponse: any) => {
  return {
    id: Number(apiResponse.NotificationUID),
    description: apiResponse.NotificationMessage,
    subject: apiResponse.Subject,
    createdDate: new Date(apiResponse.PublishedDateTime),
    caseId: apiResponse.CaseNumber,
    status: apiResponse.CaseStatus,
    memberID: apiResponse.SixDegPatientID,
    isRead: apiResponse.MarkAsRead,
    isDeleted: apiResponse.MarkAsDeleted,
    statusInfo: apiResponse.statusInfo,
    recordType: apiResponse.TypeOfCase,
  };
};

const notificationReducer = (
  state: NotificationState = defaultNotificationState,
  action: NotificationActionTypes
): NotificationState => {
  switch (action.type) {
    case M.GET_NOTIFICATION:
    case M.GET_NOTIFICATION_PENDING:
    case M.GET_NOTIFICATION_RTK_PENDING:
      return {
        ...state,
        isNotificationLoading: true,
        hasLoadedNotification: false,
      };
    case M.GET_NOTIFICATION_FULFILLED:
    case M.GET_NOTIFICATION_RTK_FULFILLED: {
      const apiNotifications = action.payload.data || [];
      const currentNotifications = state.notifications;

      // Map API response to notifications and filter out duplicates
      const mappedNotifications = apiNotifications
        .map(mapApiResponseToNotification)
        .filter(
          newNotification =>
            !currentNotifications.some(
              existingNotification => existingNotification.id === newNotification.id
            )
        );

      return {
        ...state,
        notifications: [...mappedNotifications, ...currentNotifications],
        isNotificationLoading: false,
        hasLoadedNotification: true,
      };
    }
    case M.GET_NOTIFICATION_REJECTED:
    case M.GET_NOTIFICATION_RTK_REJECTED:
      return {
        ...state,
        isNotificationLoading: false,
        hasLoadedNotification: false,
      };
    case M.UPDATE_NEW_NOTIFICATION: {
      const newNotification = action.payload;
      return {
        ...state,
        notifications: [newNotification, ...state.notifications],
      };
    }
    case M.SET_NOTIFICATION_FILTER: {
      const appliedFilter = action.payload;
      return {
        ...state,
        appliedFilter,
      };
    }
    case M.MARK_ALL_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map(notification => ({
          ...notification,
          isRead: true,
        })),
      };
    case M.MARK_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.id === action.payload ? { ...notification, isRead: true } : notification
        ),
      };
    case M.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default notificationReducer;
