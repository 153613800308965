import React, { useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Description as DescriptionIcon,
  AccountCircle as AccountCircleIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  Help as HelpIcon,
  KeyboardArrowLeft as ArrowLeft,
  KeyboardArrowRight as ArrowRight,
  ExitToApp as Logout,
} from '@material-ui/icons';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import SmsIcon from '@material-ui/icons/Sms';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Modal } from '@material-ui/core';

import { resetProviderSearch } from '@apps/shared/src/providerSearch/actions';
import { resetAddCase } from '@apps/shared/src/addCase/actions';
import { resetMemberInfo } from '@apps/shared/src/getMember/actions';

import { roles as mediviRoles } from './globals';
import { RootState } from '../store';
import ModalLayout from './ModalLayout';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: 'calc(100vh - 120px - 2.5rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '1rem 1rem 4rem 1rem',
  },
  navMenus: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7rem',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1px',
    },
  },
  navMenu: {
    width: '95%',
  },
  navText: {
    textWrap: 'nowrap',
    fontSize: 'clamp(0.7rem, 0.2013rem + 1.039vw, 1rem)',
  },
  shrinkedNavButton: {
    justifyContent: 'center !important',
    padding: '0.5rem 0.5rem 0.5rem 0.2rem !important',
  },
  navButton: {
    width: '100%',
    justifyContent: 'start',
    padding: '.5rem',
  },
  buttonContent: {
    display: 'flex',
    gap: '1rem',
  },
  activeMenuIndicator: {
    position: 'absolute',
    left: 0,
    height: '2.5rem',
    width: '0.2rem',
    background: '#EF7125',
    borderRadius: '0 0.5rem 0.5rem 0',
  },
  active: {
    width: '95%',
    backgroundColor: '#EF7125',
    color: '#ffffff',
    borderRadius: '.2rem',
    '&.MuiButton-root:hover': {
      backgroundColor: '#EF7125',
    },
    '&.MuiButton-root': {
      color: '#ffffff',
    },
  },
  activeButtonContent: {
    color: '#ffffff',
  },
  activeNavText: {
    fontWeight: 700,
  },
  btn: {
    marginTop: 'auto',
    width: '95%',
    textWrap: 'no-wrap',
    backgroundColor: '#EF7125',
    color: '#FFFFFF',
    padding: '.6rem',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    '&.MuiButton-root': {
      minWidth: '0px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'chocolate',
    },
  },
  drawerIcon: {
    background: 'white',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    position: 'absolute',
    top: '15px',
    right: '-15px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 9,
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '24px',
    boxShadow: '0px 4px 4px 0px #0000001A',
  },
}));

const mapStateToProps = ({ user }: RootState) => ({
  currentUser: user.currentUser,
  isCaseMember: user.isCaseMember,
});

type SideNavBarProps = {
  updateParentState: (newState: boolean) => void;
};

// MODAL styling starts from here
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Modal ends here

const mapDispatchToProps = {
  resetProviderSearch,
  resetAddCase,
  resetMemberInfo,
};

const SideNavBar = ({
  currentUser,
  resetProviderSearch,
  resetAddCase,
  resetMemberInfo,
  isCaseMember,
  updateParentState,
}: any & SideNavBarProps) => {
  const classes = useStyles();
  const history = useHistory();

  const roles = (currentUser && currentUser.roles) || [];

  const hasAdminRole = roles.indexOf(mediviRoles.admin) > -1;
  const hasAnalysisRole = roles.indexOf(mediviRoles.analysis) > -1;
  // const hasResearchRole = roles.indexOf(mediviRoles.code) > -1;
  const hasReadRole = roles.indexOf(mediviRoles.read) > -1;
  const hasBenchmarkRole = roles.indexOf(mediviRoles.benchmarks) > -1;
  const hasMobileReadRole = roles.indexOf(mediviRoles.mobileRead) > -1;
  const hasReportRole = roles.indexOf(mediviRoles.reports) > -1;
  const hasInviteRole = roles.indexOf(mediviRoles.invite) > -1;
  // const hasMediviTesterRole = roles.indexOf(mediviRoles.tester) > -1;

  const [showSideNav, setShowSideNav] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    history.push('/logout');
    resetProviderSearch();
    resetAddCase();
    resetMemberInfo();
    setShowLogoutModal(false);
  };

  const handleOpenLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleDrawer = () => {
    setShowSideNav(!showSideNav);
    updateParentState(!showSideNav);
  };

  const handleClose = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.navMenus}>
        {isCaseMember && (hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/my-cases"
            navText={showSideNav ? 'My Cases' : ''}
            Icon={DescriptionIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/provider-search"
            navText={showSideNav ? 'Provider Search' : ''}
            Icon={PersonIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/instructional-video"
            navText={showSideNav ? 'Instructional Video' : ''}
            Icon={PlayCircleFilledIcon}
            showSideNav={showSideNav}
          />
        )}
        {hasBenchmarkRole && (
          <NavItem
            navigateTo="/benchmarks"
            navText={showSideNav ? 'Benchmarks' : ''}
            Icon={TrendingUpIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasAdminRole || hasInviteRole) && (
          <NavItem
            navigateTo="/users"
            navText={showSideNav ? 'Users' : ''}
            Icon={SupervisorAccountIcon}
            showSideNav={showSideNav}
          />
        )}
        {hasAnalysisRole && (
          <NavItem
            navigateTo="/analysis"
            navText={showSideNav ? 'Market Analysis' : ''}
            Icon={GraphicEqIcon}
            showSideNav={showSideNav}
          />
        )}
        {hasReportRole && (
          <NavItem
            navigateTo="/reports"
            navText={showSideNav ? 'Reports' : ''}
            Icon={AssessmentIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/contact-us"
            navText={showSideNav ? 'Contact Us' : ''}
            Icon={SmsIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/FAQ"
            navText={showSideNav ? 'FAQs' : ''}
            Icon={HelpIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/privacy-policy"
            navText={showSideNav ? 'Privacy Policy' : ''}
            Icon={InsertDriveFileIcon}
            showSideNav={showSideNav}
          />
        )}
        {(hasMobileReadRole || hasReadRole || hasMobileReadRole) && (
          <NavItem
            navigateTo="/my-account"
            navText={showSideNav ? 'Account Settings' : ''}
            Icon={AccountCircleIcon}
            showSideNav={showSideNav}
          />
        )}
      </div>
      <Button className={classes.btn} onClick={handleOpenLogoutModal} role="button">
        {showSideNav ? 'LOG OUT' : <Logout />}
      </Button>
      <div
        className={classes.drawerIcon}
        onClick={handleDrawer}
        role="button"
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleDrawer();
          }
        }}
        tabIndex={0}
      >
        {showSideNav ? <ArrowLeft /> : <ArrowRight />}
      </div>

      <div>
        <ModalLayout
          showModal={showLogoutModal}
          title="Are You Sure?"
          message={
            <>
              <div>Do you really want to log out from your account?</div>
            </>
          }
          handleModalClose={handleClose}
          handleSuccess={handleLogout}
          closeTitle="CANCEL"
          actionTitle="LOG OUT"
        />
      </div>
    </div>
  );
};

interface NavItemProps {
  navigateTo: string;
  navText: string;
  showSideNav: boolean;
  Icon: React.ElementType;
}

const NavItem = ({ navigateTo, navText, Icon, showSideNav }: NavItemProps) => {
  const classes = useStyles();
  const location = useLocation();
  let isActive = false;
  if (navigateTo === '/my-cases') {
    isActive =
      location.pathname === navigateTo ||
      location.pathname === `/member-detail` ||
      location.pathname === `/provider-detail` ||
      location.pathname === `/upload-file` ||
      location.pathname === `/notifications`;
  } else if (navigateTo === '/my-account') {
    isActive =
      location.pathname === navigateTo ||
      location.pathname === `/dependent-information` ||
      location.pathname === `/add-plan`;
  } else {
    isActive = location.pathname === navigateTo;
  }
  return (
    <div className={`${classes.navMenu} ${isActive ? classes.active : ''}`}>
      {/* eslint-disable react/self-closing-comp */}
      {isActive && <div className={classes.activeMenuIndicator}></div>}
      <Button
        className={`${classes.navButton} ${showSideNav ? '' : classes.shrinkedNavButton}`}
        component={NavLink}
        to={navigateTo}
      >
        <div className={`${classes.buttonContent} ${isActive ? classes.activeButtonContent : ''}`}>
          <Icon />
          <Typography className={`${classes.navText} ${isActive ? classes.activeNavText : ''}`}>
            {navText}
          </Typography>
        </div>
      </Button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
