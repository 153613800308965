import axios from 'axios';

export const SET_CLAIM_TYPE = 'SET_CLAIM_TYPE';
export const ADD_PROVIDER_FILTER = 'ADD_PROVIDER_FILTER';
export const ADD_PROCEDURE_FILTER = 'ADD_PROCEDURE_FILTER';
export const REMOVE_PROVIDER_FILTER = 'REMOVE_PROVIDER_FILTER';
export const REMOVE_PROCEDURE_FILTER = 'REMOVE_PROCEDURE_FILTER';
export const UPDATE_SELECTED_PROCEDURE = 'UPDATE_SELECTED_PROCEDURE';
export const TOGGLE_USE_RATIOS = 'TOGGLE_USE_RATIOS';
export const SET_USE_RATIOS = 'SET_USE_RATIOS';
export const SEARCH_PROVIDER_CANCEL = `SEARCH_PROVIDER_CANCEL`;
export const SEARCH_PROCEDURE_CANCEL = `SEARCH_PROCEDURE_CANCEL`;
export const CLOSE_PROVIDER_AUTOCOMPLETE = `CLOSE_PROVIDER_AUTOCOMPLETE`;
export const CLOSE_PROCEDURE_AUTOCOMPLETE = `CLOSE_PROCEDURE_AUTOCOMPLETE`;
export const OPEN_PROVIDER_AUTOCOMPLETE = `OPEN_PROVIDER_AUTOCOMPLETE`;
export const OPEN_PROCEDURE_AUTOCOMPLETE = `OPEN_PROCEDURE_AUTOCOMPLETE`;
export const CLOSE_DETAILS_DIALOG = `CLOSE_DETAILS_DIALOG`;
export const UPDATE_CHART_ZOOM_PERCENT = 'UPDATE_CHART_ZOOM_PERCENT';
export const SAVE_CHART = 'SAVE_CHART';
export const SHOW_LABELS = 'SHOW_LABELS';
export const CAN_SHOW_LABELS = 'CAN_SHOW_LABELS';
export const ZOOM_CHART = 'ZOOM_CHART';
export const RESET_CHART_ZOOM = 'RESET_CHART_ZOOM';
export const PAN_CHART = 'PAN_CHART';
export const UPDATE_CHART_POSITION = 'UPDATE_CHART_POSITION';
export const TOGGLE_SELECTION_DRAWER = 'TOGGLE_SELECTION_DRAWER';
export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER';
export const TOGGLE_HIDDEN_DATA = 'TOGGLE_HIDDEN_DATA';
export const TOGGLE_OPTIONS_OPEN = 'TOGGLE_OPTIONS_OPEN';
export const TOGGLE_DATA_SELECTION_OPEN = 'TOGGLE_DATA_SELECTION_OPEN';
export const OUTPATIENT_CODE_TYPE = 'OUTPATIENT_CODE_TYPE';
export const PROFESSIONAL_CODE_TYPE = 'PROFESSIONAL_CODE_TYPE';
export const SCRUB_CHART = 'SCRUB_CHART';

const SEARCH_PROVIDER = 'SEARCH_PROVIDER';
export const SEARCH_PROVIDER_PENDING = `${SEARCH_PROVIDER}_PENDING`;
export const SEARCH_PROVIDER_FULFILLED = `${SEARCH_PROVIDER}_FULFILLED`;
export const SEARCH_PROVIDER_REJECTED = `${SEARCH_PROVIDER}_REJECTED`;
const SEARCH_PROCEDURE = 'SEARCH_PROCEDURE';
export const SEARCH_PROCEDURE_PENDING = `${SEARCH_PROCEDURE}_PENDING`;
export const SEARCH_PROCEDURE_FULFILLED = `${SEARCH_PROCEDURE}_FULFILLED`;
export const SEARCH_PROCEDURE_REJECTED = `${SEARCH_PROCEDURE}_REJECTED`;

const GET_CHART_INPATIENT = 'GET_CHART_INPATIENT';
export const GET_CHART_INPATIENT_PENDING = `${GET_CHART_INPATIENT}_PENDING`;
export const GET_CHART_INPATIENT_FULFILLED = `${GET_CHART_INPATIENT}_FULFILLED`;
export const GET_CHART_INPATIENT_REJECTED = `${GET_CHART_INPATIENT}_REJECTED`;
const GET_CHART_OUTPATIENT = 'GET_CHART_OUTPATIENT';
export const GET_CHART_OUTPATIENT_PENDING = `${GET_CHART_OUTPATIENT}_PENDING`;
export const GET_CHART_OUTPATIENT_FULFILLED = `${GET_CHART_OUTPATIENT}_FULFILLED`;
export const GET_CHART_OUTPATIENT_REJECTED = `${GET_CHART_OUTPATIENT}_REJECTED`;
const GET_CHART_PROFESSIONAL = 'GET_CHART_PROFESSIONAL';
export const GET_CHART_PROFESSIONAL_PENDING = `${GET_CHART_PROFESSIONAL}_PENDING`;
export const GET_CHART_PROFESSIONAL_FULFILLED = `${GET_CHART_PROFESSIONAL}_FULFILLED`;
export const GET_CHART_PROFESSIONAL_REJECTED = `${GET_CHART_PROFESSIONAL}_REJECTED`;
const GET_PROVIDER_DETAIL = 'GET_PROVIDER_DETAIL';
export const GET_PROVIDER_DETAIL_PENDING = `${GET_PROVIDER_DETAIL}_PENDING`;
export const GET_PROVIDER_DETAIL_FULFILLED = `${GET_PROVIDER_DETAIL}_FULFILLED`;
export const GET_PROVIDER_DETAIL_REJECTED = `${GET_PROVIDER_DETAIL}_REJECTED`;

export function updateClaimType(type) {
  return { type: SET_CLAIM_TYPE, meta: { type } };
}
export function addProviderFilter(filter) {
  return { type: ADD_PROVIDER_FILTER, meta: { filter } };
}

export function addProcedureFilter(filter) {
  return { type: ADD_PROCEDURE_FILTER, meta: { filter } };
}

export function removeProcedureFilter(index) {
  return { type: REMOVE_PROCEDURE_FILTER, meta: { index } };
}

export function removeProviderFilter(index) {
  return { type: REMOVE_PROVIDER_FILTER, meta: { index } };
}
export function updateSelectedProcedure(selectedProcedure) {
  return { type: UPDATE_SELECTED_PROCEDURE, meta: { selectedProcedure } };
}
export function toggleUseRatios() {
  return { type: TOGGLE_USE_RATIOS };
}
export function setUseRatios(useRatios) {
  return { type: SET_USE_RATIOS, payload: useRatios };
}
export function updateCanShowLabels(props) {
  return { type: CAN_SHOW_LABELS, payload: props };
}
export function showLabels(bool) {
  return { type: SHOW_LABELS, payload: bool };
}
export function getAutocomplete(query, autocompleteType, claimType) {
  const actionType = `SEARCH_${autocompleteType.toUpperCase()}`;
  if (!query || query.length < 2) return { type: `${actionType}_CANCEL`, meta: { query } };
  return (dispatch, getState) => {
    const { providerData, procedureSelections, professionalCodeType, outpatientCodeType } =
      getState().benchmarks;
    const selections = transformAutocompleteQuery(
      providerData,
      procedureSelections,
      autocompleteType,
      claimType,
      outpatientCodeType,
      professionalCodeType
    );
    dispatch({
      type: actionType,
      payload: axios.post(`/api/autocomplete/${query}`, selections, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
      meta: { query },
    });
  };
}

export function getProviderDetail(providerID, claimType) {
  return {
    type: GET_PROVIDER_DETAIL,
    payload: axios.post(
      `/api/providerDetail`,
      { providerID, claimType },
      { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
    ),
  };
}

export function getChart() {
  return (dispatch, getState) => {
    const {
      claimType,
      providerSelections,
      procedureSelections,
      outpatientCodeType,
      professionalCodeType,
    } = getState().benchmarks;
    if (providerSelections.length === 0 && procedureSelections.length === 0) {
      dispatch({ type: SCRUB_CHART });
    } else {
      const actionType = `GET_CHART_${claimType.toUpperCase()}`;
      dispatch({
        type: actionType,
        payload: axios.post(
          '/api/chart',
          transformChartQuery(
            providerSelections,
            procedureSelections,
            claimType,
            outpatientCodeType,
            professionalCodeType
          ),
          { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
        ),
      });
    }
  };
}

export function closeAutocomplete(name) {
  return {
    type: name === 'provider' ? CLOSE_PROVIDER_AUTOCOMPLETE : CLOSE_PROCEDURE_AUTOCOMPLETE,
  };
}
export function openAutocomplete(name) {
  return {
    type: name === 'provider' ? OPEN_PROVIDER_AUTOCOMPLETE : OPEN_PROCEDURE_AUTOCOMPLETE,
  };
}

export function closeDetails() {
  return {
    type: CLOSE_DETAILS_DIALOG,
  };
}

function getCodeTypeFromClaimType(claimType, outpatientCodeType, professionalCodeType) {
  if (claimType === 'Inpatient') return 'DRG';
  if (claimType === 'Outpatient') return outpatientCodeType;
  return professionalCodeType;
}

function transformChartQuery(
  providers = [],
  procedures = [],
  claimType,
  outpatientCodeType,
  professionalCodeType
) {
  return {
    providers: providers.map(provider => provider.filter),
    codes: procedures.map(procedure => procedure.code),
    codeType: getCodeTypeFromClaimType(claimType, outpatientCodeType, professionalCodeType),
    claimType,
  };
}

function transformAutocompleteQuery(
  chartData,
  procedures = [],
  autocompleteType,
  claimType,
  outpatientCodeType,
  professionalCodeType
) {
  return {
    providerIDs: getProviderIDsFromChart(chartData),
    codes: procedures.map(procedure => procedure.code),
    codeType: getCodeTypeFromClaimType(claimType, outpatientCodeType, professionalCodeType),
    claimType,
    autocompleteType,
  };
}

function getProviderIDsFromChart(chartData = { providers: [], excludedProviders: [] }) {
  const { providers, excludedProviders } = chartData;
  const chartIDs = providers.map(p => p.id);
  const excludedIDs = excludedProviders.map(e => e.split(' - ')[1]);
  return [...chartIDs, ...excludedIDs];
}

export function saveChart(chart) {
  return {
    type: SAVE_CHART,
    payload: { chart },
  };
}

export function zoomChart(polarity, percent = 0.1) {
  return {
    type: ZOOM_CHART,
    payload: { polarity, percent },
  };
}

export function resetChartZoom() {
  return {
    type: RESET_CHART_ZOOM,
  };
}

export function panChart(direction, percent) {
  return {
    type: PAN_CHART,
    payload: { direction, percent },
  };
}

export function updateChartPosition(pos) {
  return {
    type: UPDATE_CHART_POSITION,
    payload: pos,
  };
}

export function changeSortOrder(sort) {
  return { type: CHANGE_SORT_ORDER, payload: sort };
}

export function toggleHiddenData(label) {
  return { type: TOGGLE_HIDDEN_DATA, payload: label };
}

export function toggleOptionsOpen() {
  return { type: TOGGLE_OPTIONS_OPEN };
}

export function toggleDataSelectionOpen() {
  return { type: TOGGLE_DATA_SELECTION_OPEN };
}

export function outpatientCodeTypeChange(selection) {
  return { type: OUTPATIENT_CODE_TYPE, payload: selection };
}

export function professionalCodeTypeChange(selection) {
  return { type: PROFESSIONAL_CODE_TYPE, payload: selection };
}
