import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateInput from '../shared/DateInput';
import AppLayout from '../shared/AppLayout';
import TextInput from './TextInput';
import AnalysisLocations from './AnalysisLocations';
import RadioButton from './RadioButton';
import { dataSource, pricingSelection } from './analysisReducer';
import {
  updateValue,
  updatePricingSelection,
  updateDependentValue,
  updateTotalingNumber,
  updateMedicareRatios,
  downloadResult,
} from './analysisActions';

const styles = theme => ({
  card: {
    width: 220,
    margin: 20,
  },
  wideCard: {
    width: 480,
    margin: 20,
    gridColumn: '5/6',
  },
  center: {
    textAlign: 'center',
    marginBottom: '1em',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  generatePDF: {
    margin: '0 0.5em',
    minWidth: '16em',
  },
});

export class Analysis extends React.Component {
  handleTextChange = name => e => {
    this.props.dispatch(updateValue(name, e.target.value));
  };

  handleNumberChange = name => e => {
    this.props.dispatch(updateValue(name, Number(e.target.value)));
  };

  handleTotalingNumberChange = name => e => {
    this.props.dispatch(updateTotalingNumber(name, Number(e.target.value)));
  };

  handleDependentNumberChange = name => e => {
    this.props.dispatch(updateDependentValue(name, Number(e.target.value)));
  };

  handleDataSourceChange = name => e => {
    this.props.dispatch(updateDependentValue(name, e.target.value));
  };

  handlePricingSelectionChange = e => {
    this.props.dispatch(updatePricingSelection(e.target.value));
  };

  handleCheckedChange = name => e => {
    this.props.dispatch(updateValue(name, e.target.checked));
  };

  handleRatioChange = e => {
    this.props.dispatch(updateMedicareRatios(e));
  };

  generateExecutiveSummary = () => {
    this.props.dispatch(downloadResult('executive-summary'));
  };

  generateSavingsAnalysis = () => {
    this.props.dispatch(downloadResult('savings-analysis'));
  };

  roundNumberToInt = name => e =>
    this.props.dispatch(
      updateValue(name, Math.round(parseFloat(e.target.value.replace(/,/g, ''))))
    );

  roundTotalToInt = name => e =>
    this.props.dispatch(
      updateTotalingNumber(name, Math.round(parseFloat(e.target.value.replace(/,/g, ''))))
    );

  render() {
    const { classes, generatingPDF } = this.props;
    return (
      <>
        <AppLayout>
          <div style={{ overflow: 'scroll', height: 'calc(100vh - 70px - 50px)' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <AnalysisLocations onValueChange={this.handleRatioChange} />
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Plan Details</strong>
                    </p>
                    <TextInput
                      label="Plan Name"
                      value={this.props.planName}
                      onChange={this.handleTextChange('planName')}
                    />
                    <TextInput
                      isNumber
                      label="Number of Employees"
                      value={this.props.numberOfEmployees}
                      onChange={this.handleNumberChange('numberOfEmployees')}
                      onBlur={this.roundNumberToInt('numberOfEmployees')}
                    />
                    <p>
                      <strong>Data time period</strong>
                    </p>
                    <DateInput
                      label="Start Date"
                      value={this.props.startDate}
                      onChange={this.handleTextChange('startDate')}
                    />
                    <DateInput
                      label="End Date"
                      value={this.props.endDate}
                      onChange={this.handleTextChange('endDate')}
                    />
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Medicare Ratio</strong>
                    </p>
                    <TextInput
                      isNumber
                      label="Inpatient Ratio"
                      value={this.props.inpatientMedicareRatio}
                      onChange={this.handleNumberChange('inpatientMedicareRatio')}
                    />
                    <TextInput
                      isNumber
                      label="Outpatient Ratio"
                      value={this.props.outpatientMedicareRatio}
                      onChange={this.handleNumberChange('outpatientMedicareRatio')}
                    />
                    <TextInput
                      isNumber
                      label="Professional Ratio"
                      value={this.props.professionalMedicareRatio}
                      onChange={this.handleNumberChange('professionalMedicareRatio')}
                    />
                    <p>
                      <strong>Utilization % from</strong>
                    </p>
                    <RadioGroup
                      className={classes.group}
                      value={this.props.utilizationLocationEstimate}
                      onChange={this.handleTextChange('utilizationLocationEstimate')}
                    >
                      <FormControlLabel
                        value="claims"
                        control={<Radio color="primary" />}
                        label="Historical claim actuals"
                      />
                      <FormControlLabel
                        value="employees"
                        control={<Radio color="primary" />}
                        label="Employee census locations"
                      />
                      <FormControlLabel
                        value="headquarters"
                        control={<Radio color="primary" />}
                        label="Headquarter location"
                      />
                    </RadioGroup>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Plan Responsibility</strong>
                    </p>
                    <RadioButton
                      selection={this.props.planResponsibilityDataSource}
                      onChange={this.handleDataSourceChange('planResponsibilityDataSource')}
                      value={dataSource.complete}
                      label="All plan actuals"
                    />
                    {this.props.planResponsibilityDataSource === dataSource.complete && [
                      <TextInput
                        isMoney
                        key="1"
                        label="Inpatient Responsibility"
                        value={this.props.currentInpatientPlanResponsibility}
                        onChange={this.handleTotalingNumberChange(
                          'currentInpatientPlanResponsibility'
                        )}
                        onBlur={this.roundTotalToInt('currentInpatientPlanResponsibility')}
                      />,
                      <TextInput
                        isMoney
                        key="2"
                        label="Outpat. Responsibility"
                        value={this.props.currentOutpatientPlanResponsibility}
                        onChange={this.handleTotalingNumberChange(
                          'currentOutpatientPlanResponsibility'
                        )}
                        onBlur={this.roundTotalToInt('currentOutpatientPlanResponsibility')}
                      />,
                      <TextInput
                        isMoney
                        key="3"
                        label="Prof. Responsibility"
                        value={this.props.currentProfessionalPlanResponsibility}
                        onChange={this.handleTotalingNumberChange(
                          'currentProfessionalPlanResponsibility'
                        )}
                        onBlur={this.roundTotalToInt('currentProfessionalPlanResponsibility')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.planResponsibilityDataSource}
                      onChange={this.handleDataSourceChange('planResponsibilityDataSource')}
                      value={dataSource.facilityAndProfessional}
                      label="Facility and prof"
                    />
                    {this.props.planResponsibilityDataSource ===
                      dataSource.facilityAndProfessional && [
                      <TextInput
                        isMoney
                        key="1"
                        label="Facility Responsibility"
                        value={this.props.currentFacilityPlanResponsibility}
                        onChange={this.handleTotalingNumberChange(
                          'currentFacilityPlanResponsibility'
                        )}
                        onBlur={this.roundTotalToInt('currentFacilityPlanResponsibility')}
                      />,
                      <TextInput
                        isMoney
                        key="2"
                        label="Prof. Responsibility"
                        value={this.props.currentProfessionalPlanResponsibility}
                        onChange={this.handleTotalingNumberChange(
                          'currentProfessionalPlanResponsibility'
                        )}
                        onBlur={this.roundTotalToInt('currentProfessionalPlanResponsibility')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.planResponsibilityDataSource}
                      onChange={this.handleDataSourceChange('planResponsibilityDataSource')}
                      value={dataSource.totalOnly}
                      label="Total responsibility"
                    />
                    {this.props.planResponsibilityDataSource === dataSource.totalOnly && (
                      <TextInput
                        isMoney
                        label="Total Responsibility"
                        value={this.props.currentTotalPlanResponsibility}
                        onChange={this.handleTotalingNumberChange('currentTotalPlanResponsibility')}
                        onBlur={this.roundTotalToInt('currentTotalPlanResponsibility')}
                      />
                    )}
                    <RadioButton
                      selection={this.props.planResponsibilityDataSource}
                      onChange={this.handleDataSourceChange('planResponsibilityDataSource')}
                      value={dataSource.estimateFromMedicalSpend}
                      label="Percent of medical"
                      disabled={
                        this.props.medicalSpendDataSource ===
                        dataSource.estimateFromPlanResponsibility
                      }
                    />
                    {this.props.planResponsibilityDataSource ===
                      dataSource.estimateFromMedicalSpend && (
                      <TextInput
                        isPercent
                        label="Percent of medical"
                        value={this.props.currentPercentOfMedical}
                        onChange={this.handleDependentNumberChange('currentPercentOfMedical')}
                      />
                    )}
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Medical Allowable</strong>
                    </p>
                    <RadioButton
                      selection={this.props.medicalSpendDataSource}
                      onChange={this.handleDataSourceChange('medicalSpendDataSource')}
                      value={dataSource.complete}
                      label="All medical actuals"
                    />
                    {this.props.medicalSpendDataSource === dataSource.complete && [
                      <TextInput
                        isMoney
                        key="1"
                        label="Inpatient Spend"
                        value={this.props.currentInpatientMedicalSpend}
                        onChange={this.handleTotalingNumberChange('currentInpatientMedicalSpend')}
                        onBlur={this.roundTotalToInt('currentInpatientMedicalSpend')}
                      />,
                      <TextInput
                        isMoney
                        key="2"
                        label="Outpatient Spend"
                        value={this.props.currentOutpatientMedicalSpend}
                        onChange={this.handleTotalingNumberChange('currentOutpatientMedicalSpend')}
                        onBlur={this.roundTotalToInt('currentOutpatientMedicalSpend')}
                      />,
                      <TextInput
                        isMoney
                        key="3"
                        label="Professional Spend"
                        value={this.props.currentProfessionalMedicalSpend}
                        onChange={this.handleTotalingNumberChange(
                          'currentProfessionalMedicalSpend'
                        )}
                        onBlur={this.roundTotalToInt('currentProfessionalMedicalSpend')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.medicalSpendDataSource}
                      onChange={this.handleDataSourceChange('medicalSpendDataSource')}
                      value={dataSource.facilityAndProfessional}
                      label="Facility and prof"
                    />
                    {this.props.medicalSpendDataSource === dataSource.facilityAndProfessional && [
                      <TextInput
                        key="1"
                        isMoney
                        label="Facility Spend"
                        value={this.props.currentFacilityMedicalSpend}
                        onChange={this.handleTotalingNumberChange('currentFacilityMedicalSpend')}
                        onBlur={this.roundTotalToInt('currentFacilityMedicalSpend')}
                      />,
                      <TextInput
                        key="2"
                        isMoney
                        label="Professional Spend"
                        value={this.props.currentProfessionalMedicalSpend}
                        onChange={this.handleTotalingNumberChange(
                          'currentProfessionalMedicalSpend'
                        )}
                        onBlur={this.roundTotalToInt('currentProfessionalMedicalSpend')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.medicalSpendDataSource}
                      onChange={this.handleDataSourceChange('medicalSpendDataSource')}
                      value={dataSource.totalOnly}
                      label="Total spend"
                    />
                    {this.props.medicalSpendDataSource === dataSource.totalOnly && (
                      <TextInput
                        isMoney
                        label="Total Spend"
                        value={this.props.currentTotalMedicalSpend}
                        onChange={this.handleTotalingNumberChange('currentTotalMedicalSpend')}
                        onBlur={this.roundTotalToInt('currentTotalMedicalSpend')}
                      />
                    )}
                    <RadioButton
                      selection={this.props.medicalSpendDataSource}
                      onChange={this.handleDataSourceChange('medicalSpendDataSource')}
                      value={dataSource.estimateFromPlanResponsibility}
                      label="From plan responsibility"
                      disabled={
                        this.props.planResponsibilityDataSource ===
                        dataSource.estimateFromMedicalSpend
                      }
                    />
                    {this.props.medicalSpendDataSource ===
                      dataSource.estimateFromPlanResponsibility && (
                      <TextInput
                        isPercent
                        label="Percent of medical"
                        value={this.props.currentPercentOfMedical}
                        onChange={this.handleDependentNumberChange('currentPercentOfMedical')}
                      />
                    )}
                    <RadioButton
                      selection={this.props.medicalSpendDataSource}
                      onChange={this.handleDataSourceChange('medicalSpendDataSource')}
                      value={dataSource.estimateFromBilled}
                      label="Percent of billed"
                      disabled={this.props.billedDataSource === dataSource.estimateFromMedicalSpend}
                    />
                    {this.props.medicalSpendDataSource === dataSource.estimateFromBilled && (
                      <TextInput
                        isPercent
                        label="Network Discount"
                        value={this.props.currentNetworkDiscount}
                        onChange={this.handleDependentNumberChange('currentNetworkDiscount')}
                      />
                    )}
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Medical Billed</strong>
                    </p>
                    <RadioButton
                      selection={this.props.billedDataSource}
                      onChange={this.handleDataSourceChange('billedDataSource')}
                      value={dataSource.complete}
                      label="All billed actuals"
                    />
                    {this.props.billedDataSource === dataSource.complete && [
                      <TextInput
                        isMoney
                        key="1"
                        label="Inpatient Billed"
                        value={this.props.inpatientBilled}
                        onChange={this.handleTotalingNumberChange('inpatientBilled')}
                        onBlur={this.roundTotalToInt('inpatientBilled')}
                      />,
                      <TextInput
                        isMoney
                        key="2"
                        label="Outpatient Billed"
                        value={this.props.outpatientBilled}
                        onChange={this.handleTotalingNumberChange('outpatientBilled')}
                        onBlur={this.roundTotalToInt('outpatientBilled')}
                      />,
                      <TextInput
                        isMoney
                        key="3"
                        label="Professional Billed"
                        value={this.props.professionalBilled}
                        onChange={this.handleTotalingNumberChange('professionalBilled')}
                        onBlur={this.roundTotalToInt('professionalBilled')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.billedDataSource}
                      onChange={this.handleDataSourceChange('billedDataSource')}
                      value={dataSource.facilityAndProfessional}
                      label="Facility and prof"
                    />
                    {this.props.billedDataSource === dataSource.facilityAndProfessional && [
                      <TextInput
                        isMoney
                        key="1"
                        label="Facility Billed"
                        value={this.props.facilityBilled}
                        onChange={this.handleTotalingNumberChange('facilityBilled')}
                        onBlur={this.roundTotalToInt('facilityBilled')}
                      />,
                      <TextInput
                        isMoney
                        key="2"
                        label="Professional Billed"
                        value={this.props.professionalBilled}
                        onChange={this.handleTotalingNumberChange('professionalBilled')}
                        onBlur={this.roundTotalToInt('professionalBilled')}
                      />,
                    ]}
                    <RadioButton
                      selection={this.props.billedDataSource}
                      onChange={this.handleDataSourceChange('billedDataSource')}
                      value={dataSource.totalOnly}
                      label="Total billed"
                    />
                    {this.props.billedDataSource === dataSource.totalOnly && (
                      <TextInput
                        isMoney
                        label="Total Billed"
                        value={this.props.totalBilled}
                        onChange={this.handleTotalingNumberChange('totalBilled')}
                        onBlur={this.roundTotalToInt('totalBilled')}
                      />
                    )}
                    <RadioButton
                      selection={this.props.billedDataSource}
                      onChange={this.handleDataSourceChange('billedDataSource')}
                      value={dataSource.estimateFromMedicalSpend}
                      label="Estimate from medical spend"
                      disabled={this.props.medicalSpendDataSource === dataSource.estimateFromBilled}
                    />
                    {this.props.billedDataSource === dataSource.estimateFromMedicalSpend && (
                      <TextInput
                        isPercent
                        label="Network Discount"
                        value={this.props.currentNetworkDiscount}
                        onChange={this.handleDependentNumberChange('currentNetworkDiscount')}
                      />
                    )}
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Vendor Fees</strong>
                    </p>
                    <TextInput
                      isMoney
                      label="Facility PEPM"
                      value={this.props.currentVendorFeeFacilityPEPM}
                      onChange={this.handleNumberChange('currentVendorFeeFacilityPEPM')}
                    />
                    <TextInput
                      isMoney
                      label="Professional PEPM"
                      value={this.props.currentVendorFeeProfessionalPEPM}
                      onChange={this.handleNumberChange('currentVendorFeeProfessionalPEPM')}
                    />
                    <TextInput
                      isPercent
                      label="Percent Billed"
                      value={this.props.currentVendorFeePercentBilled}
                      onChange={this.handleNumberChange('currentVendorFeePercentBilled')}
                      disabled={
                        this.props.vendorPercentDisabled ||
                        this.props.currentVendorFeePercentSavings !== 0
                      }
                    />
                    <TextInput
                      isPercent
                      label="Percent Savings"
                      value={this.props.currentVendorFeePercentSavings}
                      onChange={this.handleNumberChange('currentVendorFeePercentSavings')}
                      disabled={
                        this.props.vendorPercentDisabled ||
                        this.props.currentVendorFeePercentBilled !== 0
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.props.currentVendorFeeIsEstimate}
                          onChange={this.handleCheckedChange('currentVendorFeeIsEstimate')}
                        />
                      }
                      label="Vendor fee is estimate"
                    />
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>Desired Medicare Reimbursement</strong>
                    </p>
                    <TextInput
                      isNumber
                      label="Facility Multiple"
                      value={this.props.sixdFacilityMedicareMultiple}
                      onChange={this.handleNumberChange('sixdFacilityMedicareMultiple')}
                    />
                    <TextInput
                      isNumber
                      label="Professional Multiple"
                      value={this.props.sixdProfessionalMedicareMultiple}
                      onChange={this.handleNumberChange('sixdProfessionalMedicareMultiple')}
                    />
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <p>
                      <strong>6 Degrees prices</strong>
                    </p>
                    <RadioGroup
                      className={classes.group}
                      value={this.props.pricingSelection}
                      onChange={this.handlePricingSelectionChange}
                    >
                      <FormControlLabel
                        value={pricingSelection.current}
                        control={<Radio color="primary" />}
                        label="Current"
                      />
                      <FormControlLabel
                        value={pricingSelection.old}
                        control={<Radio color="primary" />}
                        label="Old"
                      />
                      <FormControlLabel
                        value={pricingSelection.custom}
                        control={<Radio color="primary" />}
                        label="Custom"
                      />
                    </RadioGroup>
                    {this.props.pricingSelection === pricingSelection.custom && [
                      <TextInput
                        key="1"
                        isMoney
                        label="Full Replacement PEPM"
                        value={this.props.fullReplacementPEPMPrice}
                        onChange={this.handleNumberChange('fullReplacementPEPMPrice')}
                        onBlur={this.roundNumberToInt('fullReplacementPEPMPrice')}
                      />,
                      <TextInput
                        key="2"
                        isMoney
                        label="Facility PEPM"
                        value={this.props.facilityPEPMPrice}
                        onChange={this.handleNumberChange('facilityPEPMPrice')}
                        onBlur={this.roundNumberToInt('facilityPEPMPrice')}
                      />,
                      <TextInput
                        key="3"
                        isMoney
                        label="Professional PEPM"
                        value={this.props.professionalPEPMPrice}
                        onChange={this.handleNumberChange('professionalPEPMPrice')}
                        onBlur={this.roundNumberToInt('professionalPEPMPrice')}
                      />,
                      <TextInput
                        key="4"
                        isMoney
                        label="Professional Only PEPM"
                        value={this.props.professionalOnlyPEPMPrice}
                        onChange={this.handleNumberChange('professionalOnlyPEPMPrice')}
                        onBlur={this.roundNumberToInt('professionalOnlyPEPMPrice')}
                      />,
                      <TextInput
                        key="5"
                        isPercent
                        label="Facility OON Pct Sav."
                        value={this.props.facilityOONPercentOfSavingsPrice}
                        onChange={this.handleNumberChange('facilityOONPercentOfSavingsPrice')}
                      />,
                      <TextInput
                        key="6"
                        label="Min Employees"
                        value={this.props.minEmployees}
                        onChange={this.handleNumberChange('minEmployees')}
                        onBlur={this.roundNumberToInt('minEmployees')}
                      />,
                      <TextInput
                        key="7"
                        isMoney
                        label="Full Replacement Min"
                        value={this.props.fullReplacementMinPrice}
                        onChange={this.handleNumberChange('fullReplacementMinPrice')}
                        onBlur={this.roundNumberToInt('fullReplacementMinPrice')}
                      />,
                      <TextInput
                        key="8"
                        isMoney
                        label="Facility Min"
                        value={this.props.facilityMinPrice}
                        onChange={this.handleNumberChange('facilityMinPrice')}
                        onBlur={this.roundNumberToInt('facilityMinPrice')}
                      />,
                      <TextInput
                        key="9"
                        isMoney
                        label="Professional Only Min"
                        value={this.props.professionalOnlyMinPrice}
                        onChange={this.handleNumberChange('professionalOnlyMinPrice')}
                        onBlur={this.roundNumberToInt('professionalOnlyMinPrice')}
                      />,
                    ]}
                  </CardContent>
                </Card>
                <Card className={classes.wideCard}>
                  <CardContent>
                    <p>
                      <strong>Notes</strong>
                    </p>
                    <TextInput fullWidth label="Additional information that should be added" />
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className={classes.center}>
              <Button
                variant="contained"
                onClick={this.generateExecutiveSummary}
                color="primary"
                className={classes.generatePDF}
                disabled={generatingPDF !== ''}
              >
                {generatingPDF && generatingPDF === 'executive-summary' ? (
                  <CircularProgress size="1.25em" />
                ) : (
                  'Generate Executive Summary'
                )}
              </Button>
              <Button
                variant="contained"
                onClick={this.generateSavingsAnalysis}
                color="primary"
                className={classes.generatePDF}
                disabled={generatingPDF !== ''}
              >
                {generatingPDF && generatingPDF === 'savings-analysis' ? (
                  <CircularProgress size="1.25em" />
                ) : (
                  'Generate Savings Analysis'
                )}
              </Button>
            </div>
          </div>
        </AppLayout>
      </>
    );
  }
}

Analysis.propTypes = {
  billedDataSource: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentFacilityMedicalSpend: PropTypes.number,
  currentFacilityPlanResponsibility: PropTypes.number,
  currentInpatientMedicalSpend: PropTypes.number,
  currentInpatientPlanResponsibility: PropTypes.number,
  currentNetworkDiscount: PropTypes.number,
  currentOutpatientMedicalSpend: PropTypes.number,
  currentOutpatientPlanResponsibility: PropTypes.number,
  currentPercentOfMedical: PropTypes.number,
  currentProfessionalMedicalSpend: PropTypes.number,
  currentProfessionalPlanResponsibility: PropTypes.number,
  currentTotalMedicalSpend: PropTypes.number,
  currentTotalPlanResponsibility: PropTypes.number,
  currentVendorFeeFacilityPEPM: PropTypes.number,
  currentVendorFeeIsEstimate: PropTypes.bool,
  currentVendorFeePercentBilled: PropTypes.number,
  currentVendorFeePercentSavings: PropTypes.number,
  currentVendorFeeProfessionalPEPM: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  facilityBilled: PropTypes.number,
  facilityMinPrice: PropTypes.number,
  facilityOONPercentOfSavingsPrice: PropTypes.number,
  facilityPEPMPrice: PropTypes.number,
  fullReplacementMinPrice: PropTypes.number,
  fullReplacementPEPMPrice: PropTypes.number,
  generatingPDF: PropTypes.string,
  inpatientBilled: PropTypes.number,
  inpatientMedicareRatio: PropTypes.number,
  medicalSpendDataSource: PropTypes.string,
  minEmployees: PropTypes.number,
  numberOfEmployees: PropTypes.number,
  outpatientBilled: PropTypes.number,
  outpatientMedicareRatio: PropTypes.number,
  planName: PropTypes.string,
  planResponsibilityDataSource: PropTypes.string,
  pricingSelection: PropTypes.string,
  professionalBilled: PropTypes.number,
  professionalMedicareRatio: PropTypes.number,
  professionalOnlyMinPrice: PropTypes.number,
  professionalOnlyPEPMPrice: PropTypes.number,
  professionalPEPMPrice: PropTypes.number,
  sixdFacilityMedicareMultiple: PropTypes.number,
  sixdProfessionalMedicareMultiple: PropTypes.number,
  startDate: PropTypes.string,
  totalBilled: PropTypes.number,
  utilizationLocationEstimate: PropTypes.string,
  vendorPercentDisabled: PropTypes.bool,
};

Analysis.defaultProps = {
  billedDataSource: '',
  currentFacilityMedicalSpend: 0,
  currentFacilityPlanResponsibility: 0,
  currentInpatientMedicalSpend: 0,
  currentInpatientPlanResponsibility: 0,
  currentNetworkDiscount: 0,
  currentOutpatientMedicalSpend: 0,
  currentOutpatientPlanResponsibility: 0,
  currentPercentOfMedical: 0,
  currentProfessionalMedicalSpend: 0,
  currentProfessionalPlanResponsibility: 0,
  currentTotalMedicalSpend: 0,
  currentTotalPlanResponsibility: 0,
  currentVendorFeeFacilityPEPM: 0,
  currentVendorFeeIsEstimate: false,
  currentVendorFeePercentBilled: 0,
  currentVendorFeePercentSavings: 0,
  currentVendorFeeProfessionalPEPM: 0,
  endDate: '',
  facilityBilled: 0,
  facilityMinPrice: 0,
  facilityOONPercentOfSavingsPrice: 0,
  facilityPEPMPrice: 0,
  fullReplacementMinPrice: 0,
  fullReplacementPEPMPrice: 0,
  generatingPDF: '',
  inpatientBilled: 0,
  inpatientMedicareRatio: 0,
  medicalSpendDataSource: '',
  minEmployees: 0,
  numberOfEmployees: 0,
  outpatientBilled: 0,
  outpatientMedicareRatio: 0,
  planName: '',
  planResponsibilityDataSource: '',
  pricingSelection: '',
  professionalBilled: 0,
  professionalMedicareRatio: 0,
  professionalOnlyMinPrice: 0,
  professionalOnlyPEPMPrice: 0,
  professionalPEPMPrice: 0,
  sixdFacilityMedicareMultiple: 0,
  sixdProfessionalMedicareMultiple: 0,
  startDate: '',
  totalBilled: 0,
  utilizationLocationEstimate: '',
  vendorPercentDisabled: false,
};

function mapStateToProps({ analysis: state }) {
  return {
    planName: state.planName,
    numberOfEmployees: state.numberOfEmployees,
    startDate: state.startDate,
    endDate: state.endDate,
    inpatientBilled: state.inpatientBilled,
    outpatientBilled: state.outpatientBilled,
    facilityBilled: state.facilityBilled,
    professionalBilled: state.professionalBilled,
    totalBilled: state.totalBilled,
    utilization: state.utilization,
    currentInpatientPlanResponsibility: state.currentInpatientPlanResponsibility,
    currentOutpatientPlanResponsibility: state.currentOutpatientPlanResponsibility,
    currentProfessionalPlanResponsibility: state.currentProfessionalPlanResponsibility,
    currentFacilityPlanResponsibility: state.currentFacilityPlanResponsibility,
    currentTotalPlanResponsibility: state.currentTotalPlanResponsibility,
    currentInpatientMedicalSpend: state.currentInpatientMedicalSpend,
    currentOutpatientMedicalSpend: state.currentOutpatientMedicalSpend,
    currentProfessionalMedicalSpend: state.currentProfessionalMedicalSpend,
    currentFacilityMedicalSpend: state.currentFacilityMedicalSpend,
    currentTotalMedicalSpend: state.currentTotalMedicalSpend,
    currentNetworkDiscount: state.currentNetworkDiscount,
    currentPercentOfMedical: state.currentPercentOfMedical,
    currentVendorFeeFacilityPEPM: state.currentVendorFeeFacilityPEPM,
    currentVendorFeeProfessionalPEPM: state.currentVendorFeeProfessionalPEPM,
    currentVendorFeePercentBilled: state.currentVendorFeePercentBilled,
    currentVendorFeePercentSavings: state.currentVendorFeePercentSavings,
    vendorPercentDisabled: state.vendorPercentDisabled,
    inpatientMedicareRatio: state.inpatientMedicareRatio,
    outpatientMedicareRatio: state.outpatientMedicareRatio,
    professionalMedicareRatio: state.professionalMedicareRatio,
    sixdPercentBilled: state.sixdPercentBilled,
    sixdPercentSavings: state.sixdPercentSavings,
    sixdFacilityMedicareMultiple: state.sixdFacilityMedicareMultiple,
    sixdProfessionalMedicareMultiple: state.sixdProfessionalMedicareMultiple,
    billedDataSource: state.billedDataSource,
    planResponsibilityDataSource: state.planResponsibilityDataSource,
    medicalSpendDataSource: state.medicalSpendDataSource,
    pricingSelection: state.pricingSelection,
    fullReplacementPEPMPrice: state.fullReplacementPEPMPrice,
    facilityPEPMPrice: state.facilityPEPMPrice,
    facilityOONPercentOfSavingsPrice: state.facilityOONPercentOfSavingsPrice,
    professionalPEPMPrice: state.professionalPEPMPrice,
    professionalOnlyPEPMPrice: state.professionalOnlyPEPMPrice,
    minEmployees: state.minEmployees,
    fullReplacementMinPrice: state.fullReplacementMinPrice,
    facilityMinPrice: state.facilityMinPrice,
    professionalOnlyMinPrice: state.professionalOnlyMinPrice,
    minFullNetworkRate: state.minFullNetworkRate,
    minFacilityRate: state.minFacilityRate,
    minProfessionalAddOnRate: state.minProfessionalAddOnRate,
    minProfessionalOONAddOnRate: state.minProfessionalOONAddOnRate,
    minProfessionalStandAloneRate: state.minProfessionalStandAloneRate,
    utilizationLocationEstimate: state.utilizationLocationEstimate,
    allowedNotAvailable: state.allowedNotAvailable,
    currentVendorFeeIsEstimate: state.currentVendorFeeIsEstimate,
    generatingPDF: state.generatingPDF,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Analysis));
