// used to format the date globally
export default function formatDate(inputDate) {
  if (inputDate !== '') {
    const dateObject = new Date(inputDate);
    const datePart = dateObject.toISOString().split('T')[0];
    const dateComponents = datePart.split('-');
    if (dateComponents.length === 3) {
      const year = dateComponents[0];
      const month = dateComponents[1];
      const day = dateComponents[2];
      return `${month}/${day}/${year}`;
    }
  }
  return '';
}
