import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { colors } from '@apps/shared/src/style';
import Testimonial from './Testimonial';

import { testimonials } from './content';

const styles = theme => ({
  allContent: {
    // minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: '20vh',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10vh',
    },
  },
  testimonialBackground: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: '45vh',
    width: '100vw',
    padding: '5vw 10vw 0 10vw',
    marginBottom: '10vh',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  usedByLogos: {
    width: '60vw',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  usedByType: {
    fontSize: '1rem',
    marginBottom: '25px',
  },
  logo: {
    width: '175px',
    height: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '25px',
    },
    filter: 'gray',
    '-webkitFilter': 'grayscale(100%)',
    '-mozFilter': 'grayscale(100%)',
    opacity: '.6',
  },
});

function TestimonialPage({ classes }) {
  const userTestimonials = testimonials.data.map(t => <Testimonial key={t.name} t={t} />);

  return (
    <div className={classes.allContent}>
      <div className={classes.testimonialBackground}>{userTestimonials}</div>
    </div>
  );
}

TestimonialPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(TestimonialPage);
