import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { colors } from '../../style/colors';

const useStyles = makeStyles({
  phone: {
    paddingTop: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    color: colors.greyDark,
  },
  icon: {
    marginRight: '0.25rem',
  },
  number: {
    color: colors.blueMuted,
  },
});

export function isValidPhone(number: string): boolean {
  const numRegex = /^[0-9]{10}$/; // 10-digit number
  return numRegex.test(number);
}

export function getDisplayPhone(number: string): string {
  return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
}

// Preferred phone format for href: https://developers.google.com/web/fundamentals/native-hardware/click-to-call/?hl=en#other-click-to-call-features
export function getPhoneLink(number: string): string {
  return `tel:+1-${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`;
}

type ParentProps = { number?: string };

export default function PhoneNumber({ number }: ParentProps): JSX.Element {
  const isSmallScreen = useMediaQuery('(max-width: 576px)');
  const classes = useStyles();
  if (!number) return <div />;
  if (!isValidPhone(number)) return <div />;

  return (
    <Typography variant="body2" className={classes.phone}>
      <PhoneIcon className={classes.icon} fontSize="inherit" />

      {isSmallScreen ? (
        <a
          href={getPhoneLink(number)}
          aria-label="phone number"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.number}
        >
          {getDisplayPhone(number)}
        </a>
      ) : (
        <span className={classes.number}>{getDisplayPhone(number)}</span>
      )}
    </Typography>
  );
}
