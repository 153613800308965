export type Question = {
  question: string;
  answer: string;
};

export const ProviderSearchFAQ: Question[] = [
  {
    question: `How do I find a provider in my area?`,
    answer: `Begin by entering a valid 5-digit zip code to serve as the starting location for your provider search. You may then narrow the list of providers by searching via text or utilizing any of the filters located above the list.`,
  },
  {
    question: `What does the green badge represent?`,
    answer: `We encourage using these providers as they are contracted.`,
  },
  {
    question: `What does the yellow badge represent?`,
    answer: `It is recommended to proceed with caution and if issues arise, contact 6 Degrees Health, or submit a case via the mobile app.`,
  },
  {
    question: `What does the red badge represent?`,
    answer: `The provider has a history of denying access. However, this does not mean that you cannot see this provider. It is recommended that you provide your new health plan information to the provider when scheduling. If issues arise, contact 6 Degrees Health, or submit a case via the mobile app.`,
  },
  {
    question: `What does the grey badge represent?`,
    answer: `There is not enough adequate data to categorize this Provider. However, this does not mean that you cannot see this provider. It is recommended that you provide your new health plan information to the provider when scheduling. If issues arise, contact 6 Degrees Health, or submit a case via the mobile app.`,
  },
  {
    question: `Do the color badges represent the best-quality providers?`,
    answer: `Badge color does not indicate the quality of care, only the provider’s willingness to work with your self-funded health plan.`,
  },
  {
    question: `What do I do if I cannot find my physician in the MediVI Provider Search?`,
    answer: `6 Degrees Health is continuously updating the MediVI Provider search. If you cannot find your Provider in MediVI, this does not mean you cannot seek care from them. Contact your Provider directly.`,
  },
  {
    question: `I’ve found a Provider, now what should I do? `,
    answer: `The MediVI app will provide the contact number for the Provider. Please reach out to the Provider to schedule an appointment. Please always confirm the Provider’s address as they may have multiple offices.`,
  },
];

export const BalanceBillFAQ: Question[] = [
  {
    question: `I’ve received a bill in the mail from my provider, what should I do?`,
    answer: `If you receive a bill, compare it to the Explanation of Benefits that you previously received from your Health Plan Administrator. If you are being asked to pay more than what is shown as patient responsibility, submit a balance bill case.`,
  },
  {
    question: `What if a provider continues to contact me?`,
    answer: `If you receive any additional bills or communication from your provider, you need to send the additional documentation to your contact at 6 Degrees Health.`,
  },
  {
    question: `How long will this process take?`,
    answer: `Each provider has different processes for resolving payment disputes. 6 Degrees Health will support and guide you through the entire process and work to resolve the matter as quickly as possible. Please note the average resolution time is between 60-90 days, with many being resolved faster.`,
  },
  {
    question: `What if my provider denies access due to a balance bill?`,
    answer: `If the provider turns you away because of an outstanding balance, call your 6 Degrees Health contact. We will work to resolve the issue on your behalf.`,
  },
];

export const AccessIssueFAQ: Question[] = [
  {
    question: `What if the medical provider does not recognize my health plan coverage?`,
    answer: `Inform your medical provider that your health coverage does NOT require that they participate in a network to provide care for you and get paid. Your medical provider should collect any applicable copay and submit a claim through your Health Plan Administrator using the information listed on your member ID card as they would for a traditional network plan.`,
  },
  {
    question: `What if my medical provider still has questions?`,
    answer: `Please call 6 Degrees Health and we are happy to work with the provider further.`,
  },
  {
    question: `What should I do if a provider requests payment upfront?`,
    answer: `Do not pay anything other than your copay upfront. The provider should call the Health Plan Administrator listed on your member ID card.`,
  },
  {
    question: `What should the provider classify my health plan coverage as?`,
    answer: `The best option would be non-commercial.`,
  },
];
