import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Footer from '../shared/Footer';
import Header from '../shared/Header';
import VideoCard from './VideoCard';
import { IncompleteTheme } from '../shared/types/theme';
import { Video } from '../shared/types/mediviTypes';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  content: {
    boxSizing: 'border-box',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...theme.documentation.root,
    padding: '2rem 1rem',
    alignItems: 'center',
    minHeight: theme.props.contentHeight,
  },
  pageTitle: {
    marginBottom: '0.5em',
    ...theme.documentation.title,
  },
  videosFlexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

type ParentProps = {
  title: string;
  heading: string;
  videos: Video[];
};

export default function VideoPage({ videos, title, heading }: ParentProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Header title={title} />
      <section className={classes.content}>
        <Typography component="h3" variant="h4" className={classes.pageTitle}>
          {heading}
        </Typography>
        <div className={classes.videosFlexContainer}>{videos.map(VideoCard)}</div>
      </section>
      <Footer />
    </>
  );
}
