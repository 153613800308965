import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import styled from './styled';

export function styledAvatar(size, backgroundColor, styles) {
  const AvatarStyled = styled(Avatar)({
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
    fontSize: `${size / 2}px`,
    backgroundColor,
    ...styles,
  });
  return props => <AvatarStyled {...props} />;
}

export const roles = {
  read: 'medivi-read',
  analysis: 'medivi-analysis',
  code: 'medivi-code',
  admin: 'medivi-admin',
  tester: 'medivi-tester',
  mobileRead: 'medivimobile-read',
  benchmarks: 'medivi-benchmarks',
  reports: 'medivi-reports',
  invite: 'medivi-inviteuser',
};
