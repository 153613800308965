import numeral from 'numeral';
import { formatCurrency } from '@apps/shared/src/utilities';
import { toSentenceCase } from '@apps/shared/src/utilities/stringUtilities';

export function formatNumber(value, format = '0.00') {
  return numeral(value).format(format.toString());
}
export function formatConditionalNumber(value, format = '0.00') {
  return value ? formatNumber(value, format) : '-';
}
function trimPointZero(numberString) {
  return numberString.replace('.0', '');
}
export function formatMoneyNoDecimal(value) {
  return formatNumber(value, '$0,0');
}
export function formatMoneyShort(value) {
  const sign = value < 0 ? '-' : '';
  const formattedValue = formatBigNumber(Math.abs(value));
  return `${sign}$${formattedValue}`;
}
export function formatRatios(value) {
  return trimPointZero(formatNumber(value, '0.0'));
}
export function formatCCR(costCenter) {
  return formatConditionalNumber(costCenter?.ccr, '%0');
}
export function formatBigNumber(value) {
  return Math.abs(value) > 1e6
    ? trimPointZero(formatNumber(value, '0.0a')) // 1.7m or 2m
    : formatNumber(value, '0a'); // 152k
}

const abbreviationGroups = [
  {
    HOSPITAL: 'HOSP', // 2763 occurances
    HOSPITALS: 'HOSPS', // 38
    CENTER: 'CTR', // 1603
    CENTERS: 'CTRS', // 8
    MEDICAL: 'MED', // 1480
    HEALTH: 'HLTH', // 479
    HEALTHCARE: 'HLTHCARE', // 85
    REGIONAL: 'REG', // 474
    COUNTY: 'CTY', // 363
    COMMUNITY: 'COMM', // 287]
  },
  {
    SAINT: 'ST', // 67
    MOUNT: 'MT', // 18
    AND: '&', // 86
  },
  {
    NORTH: 'N', // 54
    SOUTH: 'S', // 46
    EAST: 'E', // 37
    WEST: 'W', // 48
    NORTHWEST: 'NW', // 20
    NORTHEAST: 'NE', // 7
    SOUTHWEST: 'SW', // 13
    SOUTHEAST: 'SE', // 9
  },
  {
    MEMORIAL: 'MEM', // 589
    GENERAL: 'GEN', // 152
    UNIVERSITY: 'UNIV', // 120
    INSTITUTE: 'INST', // 13
    SURGICAL: 'SURG', // 55
    DISTRICT: 'DIST', // 48
    CLINIC: 'CLIN', // 42
    SERVICES: 'SVCS', // 36
    SPECIALTY: 'SPEC', // 22
  },
];

const acronyms = {
  Inc: 'INC', // 165 occurances
  Llc: 'LLC', // 44
  Llp: 'LLP', // 5
  Lp: 'LP', // 3
  Nw: 'NW', // 20
  Ne: 'NE', // 7
  Sw: 'SW', // 13
  Se: 'SE', // 9
};

export function shortenProviderName(name, maxLength) {
  let nameLength = name.length;
  const words = name.split(' ');
  for (let a = 0; a < abbreviationGroups.length; a++) {
    for (let w = words.length - 1; w >= 0; w--) {
      if (nameLength <= maxLength) break;
      const abbreviation = abbreviationGroups[a][words[w]];
      if (abbreviation) {
        nameLength -= words[w].length - abbreviation.length;
        words[w] = abbreviation;
      }
    }
  }
  return words.join(' ');
}

export const labelDelim = '~#~';

export function formatProviderName(initialName, maxLength = 0, truncate = false) {
  let name = initialName;
  if (maxLength) {
    name = shortenProviderName(name, maxLength);
  }
  name = caseProviderName(name);
  return truncate ? truncateString(name, maxLength) : name;
}

export function caseProviderName(name) {
  return toTitleCase(name)
    .split(' ')
    .map(w => uppercaseAcronym(w))
    .join(' ');
}

export function uppercaseAcronym(word) {
  const acronym = acronyms[word];
  return acronym || word;
}

export function generateProviderLabel(meta) {
  return formatProviderName(meta.name, 20, true) + labelDelim + meta.id;
}

export function getProviderName(str) {
  return str.split(labelDelim)[0];
}

function truncateString(name, maxLength) {
  return name.length <= maxLength
    ? name
    : `${name.slice(0, maxLength - 1).replace(/[\s,.]+$/, '')}…`;
}

export function toTitleCase(text) {
  return text.replace(/\b([A-Z']+)\b/gi, toSentenceCase);
}

export function filterQualityTicks(axis) {
  // Only show 0, 100, min, and max
  axis.ticks = axis.min < 0 ? [axis.min] : []; // eslint-disable-line no-param-reassign
  axis.ticks.push(0, 100);
  if (axis.max > 100) axis.ticks.push(axis.max);
}

export function filterLeftTicks(axis) {
  if (axis.max === 0) {
    axis.ticks = [0]; // eslint-disable-line no-param-reassign
  } else {
    const maxTickSpacing = axis.ticks[0] - axis.ticks[1];
    const comfortableSpacing = (axis.ticks[1] - axis.ticks[2]) * 0.5;
    if (maxTickSpacing <= comfortableSpacing) {
      axis.ticks.shift(); // remove max tick if too close to previous tick
    }
  }
}

export function makeTooltipTitleFormatter(meta) {
  return tooltipItems => {
    // Use full provider name and id instead of abbreviated name
    const metaItem = meta[tooltipItems[0].index];
    return `${metaItem.id} ${formatProviderName(metaItem.name)}`;
  };
}

export function makeCallbackLabelFormatter(useRatios) {
  return (tooltipItem, data) => {
    // Format money and round quality score
    const axis = data.datasets[tooltipItem.datasetIndex].yAxisID;
    const label = data.datasets[tooltipItem.datasetIndex].label || '';
    let field = '';
    if (axis === 'quality' || useRatios) {
      if (tooltipItem.yLabel !== 0.0) field = formatNumber(tooltipItem.yLabel);
      else field = 'not available';
    } else {
      field = formatCurrency(tooltipItem.yLabel);
    }
    return label ? `${label}: ${field}` : field;
  };
}

export function makeCallbackFooterFormatter(meta) {
  return tooltipItems => {
    // Show metadata
    return meta[tooltipItems[0].index].data.map(metaItem => `${metaItem.label}: ${metaItem.value}`);
  };
}
