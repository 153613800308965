export type Question = {
  id: number;
  question: string;
  answer: string;
  link?: string;
};

export const ContactUsInfo: Question[] = [
  {
    id: 1,
    question: `Questions about medical plan coverage?`,
    answer: `Please contact your Health Plan Administrator. They have a dedicated customer service team ready to assist you with any questions you might have. Their contact information can be found on the back of your insurance ID card.`,
  },
  {
    id: 2,
    question: `Technical questions about the app?`,
    answer: `Please contact 6 Degrees Health technical support.`,
    link: 'appsupport@6degreeshealth.com',
  },
  {
    id: 3,
    question: `About 6 Degrees health`,
    answer: `MediVI is powered by 6 Degrees Health, built to bring equity and fairness back into the healthcare reimbursement equation.
    Our cost containment efforts utilize MediVI technology, which supports our solutions with objective, transparent and defensible data.
    Solutions include everything from provider market analyses, reasonable value claim reports, claim negotiations, and referenced based repricing.`,
    link: 'www.6degreeshealth.com',
  },
];
