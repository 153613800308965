import React from 'react';
import { Video } from '../shared/types/mediviTypes';
import VideoPage from './VideoPage';

const videos: Video[] = [
  {
    src: 'https://player.vimeo.com/video/649611624',
    title: 'Desktop Tutorial',
    description: 'An introduction to the MediVI Provider Search Desktop Tool',
  },
];

export default function ProviderSearchTraining(): JSX.Element {
  return <VideoPage videos={videos} heading="Provider Search Training" title="Documentation" />;
}
