import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import SignUpInput from './SignUpInput';

import laptop from './assets/BenchamrksLaptop.png';
import { partnerIntro } from './content';
import useAboveTheFoldStyles from './useAboveTheFoldStyles';
import Features from './Features';

function AboveTheFold(props) {
  const classes = useAboveTheFoldStyles('partner');

  return (
    <div className={classnames(classes.heroImg, classes.fullHero)}>
      <section className={classes.allContent}>
        <img className={classes.laptop} src={laptop} alt="Laptop with screenshot of MediVi" />
        <div className={classes.allText}>
          <Typography variant="h4" className={classnames(classes.heading, classes.typeOnDark)}>
            {partnerIntro.headline}
          </Typography>
          <div className={classes.subContent}>
            <Typography className={classnames(classes.subHeading, classes.typeOnDark)}>
              {partnerIntro.subHeading}
            </Typography>
            <SignUpInput
              email={props.email}
              updateEmail={props.updateEmail}
              requestAccess={props.requestAccess}
              activeButton={props.activeButton}
            />
          </div>
        </div>
      </section>
      <Features />
    </div>
  );
}

AboveTheFold.propTypes = {
  activeButton: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  requestAccess: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
};

export default AboveTheFold;
