import React, { FormEventHandler, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { User } from '@apps/shared/src/auth/types';
import {
  clearInviteStatus,
  getUsers,
  invite,
} from '@apps/shared/src/components/AdminUserList/adminActions';
import LoadingIndicator from '@apps/shared/src/components/LoadingIndicator';
import { colors } from '@apps/shared/src/style/colors';

import { Button, Dialog, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import VirtualizedUserList from './VirtualizedUserList';
import { InputChangeHandler } from '../shared/types/mediviTypes';
import { RootState } from '../store';
import AppLayout from '../shared/AppLayout';
import { PrimaryButton } from '../shared/button';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '93vh',
    overflowY: 'hidden',
  },
  modalC: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0 0 1rem',
  },
  title: {
    color: '#36383D',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '.5rem',
  },
  inviteNewUserBtn: {
    backgroundColor: '#F07037',
    color: '#EFECE7',
    height: '40px',
    fontSize: '16px',
    fontWeight: 700,
    marginRight: '20px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#F07037',
    },
  },

  Modal: {
    height: 'calc(100vh - 220px)',
    width: '35%',
    backgroundColor: '#fff',
    marginTop: '5.2rem',
    marginLeft: '35%',
    borderRadius: '.5rem',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '2rem 3rem',
    borderRadius: '.5rem',
    position: 'relative',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
  },
  box: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  header: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  imgWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundImage: 'url("./images/typeOfIssue.svg")',
    backgroundSize: 'auto calc(100% - 15px)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  closeBtn: {
    color: '#36383D',
    fontWeight: 600,
    cursor: 'pointer',
  },
  headingTxt: {
    color: '#36383D',
    fontWeight: 600,
    fontSize: 'clamp(1rem, 0.1rem + 1.875vw, 1.3rem)',
    margin: '0.5rem 0',
  },
  headingTxtSmall: {
    fontSize: '1rem',
    color: '#36383D',
    fontWeight: 600,
    margin: '0.5rem 0',
  },
  inputLabel: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
  },
  textBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '0.65rem',
    },
    '& .MuiOutlinedInput-root': {
      border: '1px solid #888787',
      borderRadius: '0.75rem',
      backgroundColor: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    marginBottom: '1rem',
  },
  transparentDropdown: {
    '& .MuiOutlinedInput-root': {
      height: '3rem',
      border: '1px solid #888787',
      borderRadius: '0.75rem',
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-root': {
      backgroundColor: 'transparent',
      width: '100%',
      border: 'None',
    },
    marginBottom: '1.5rem',
  },
  footer: {
    marginTop: '.5rem',
    marginBottom: '1rem',
    backgroundColor: '#fff',
  },
  successInvite: {
    width: 'wrap-content',
    padding: '0.75rem',
    fontSize: '1rem',
    minHeight: '2.5rem !important',
    border: '1px solid #84D65A',
    borderRadius: '0.5rem',
    backgroundColor: '#EDFBD8',
    color: '#2B641E',
    textAlign: 'center',
    position: 'fixed',
    bottom: '50px',
    right: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '2.75rem',
    '& div': {
      display: 'flex',
      gap: '0.65rem',
    },
  },
  successTick: {
    color: '#84D65A',
  },
  inviteBtn: {
    borderRadius: '0.125rem',
    minHeight: '2.5rem',
    fontSize: '1rem',
  },
  cancelBtn: {
    backgroundColor: '#fff',
    border: '1px solid #888787',
    color: '#888787',
    borderRadius: '0.125rem',
    minHeight: '2.5rem',
    fontSize: '1rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#888787',
    },
  },
}));

// Component

type StateProps = {
  superAdmin: boolean;
  userList: User[];
  app: string;
  appRoles: string[];
  fetching: boolean;
  userInvited?: boolean;
  inviteUserRole: boolean;
  isAdmin: boolean;
};

const Users: React.FC<Props> = (props): JSX.Element => {
  const {
    superAdmin,
    userList,
    app,
    appRoles,
    fetching,
    userInvited,
    getUsers,
    invite,
    clearInviteStatus,
    inviteUserRole,
    isAdmin,
  } = props;
  const classes = useStyles();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [userRolesValue, setUserRolesValue] = React.useState('');
  const [showSuccessInvite, setShowSuccessInvite] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const isSmallScreen = useMediaQuery('(max-height:650px)');

  useEffect(() => {
    if (!userList || userList.length < 1) getUsers(app || '');
  }, [app, getUsers, userList]);

  const handleSuccessInviteClose = () => {
    setShowSuccessInvite(false);
  };

  const handleSubmit: FormEventHandler<Element> = e => {
    e.preventDefault();
    invite(email, userRolesValue, app)
      .then((result: any) => {
        // Handle the resolved data here
        if (result != null) {
          setOpenEmailModal(false);
          setShowSuccessInvite(true);
        }
      })
      .catch((error: any) => {
        // Handle any errors that occurred during the promise execution
        console.error('Error:', error);
        setShowError(true);
      });
    setEmail('');
    setUserRolesValue('');
  };

  const handleChange: InputChangeHandler = e => {
    setEmail(e.currentTarget.value);
    setShowError(false);
  };

  const handleUserRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserRolesValue(event.target.value);
  };

  const userRolesOptions = [
    { value: '6dhemployee', label: '6DH Employee' },
    { value: 'partnerwithphi', label: 'Partner (w/ PHI)' },
    { value: 'partnerwithout', label: 'Partner (w/o PHI)' },
    { value: 'partnerprovidersearch', label: 'Partner (Provider Search)' },
  ];

  return (
    <>
      <AppLayout>
        {fetching && (!userList || userList.length < 1) ? (
          <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <LoadingIndicator isLoading={fetching} size={75} color={colors.orange} />
          </div>
        ) : (
          <>
            <section className={classes.content}>
              <div className={classes.modalC}>
                <Typography className={classes.title}>Users</Typography>
                {inviteUserRole && (
                  <Button
                    variant="contained"
                    className={classes.inviteNewUserBtn}
                    onClick={(): void => setOpenEmailModal(true)}
                    title="Invite user to MediVI"
                    aria-label="Invite user to MediVI"
                  >
                    INVITE NEW USER
                  </Button>
                )}
              </div>
              <VirtualizedUserList
                users={userList}
                app={app}
                appRoles={appRoles}
                superAdmin={superAdmin}
              />
            </section>
          </>
        )}
      </AppLayout>

      <Modal
        className={classes.Modal}
        open={openEmailModal}
        onClose={(): void => setOpenEmailModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalContainer}>
          <div className={classes.box}>
            <div className={classes.imgWrapper} />
          </div>
          <div className={classes.box}>
            <div>
              <Typography
                className={`${isSmallScreen ? classes.headingTxtSmall : classes.headingTxt}`}
              >
                NEW USER
              </Typography>
              <Typography className={classes.inputLabel}>
                Email <sup>*</sup>
              </Typography>
              <TextField
                variant="outlined"
                placeholder="abc@6degreeshealth.com"
                value={email}
                onChange={handleChange}
                required
                fullWidth
                className={classes.textBox}
              />
              <Typography className={classes.inputLabel}>
                User Role <sup>*</sup>
              </Typography>

              <TextField
                select
                variant="outlined"
                value={userRolesValue}
                onChange={handleUserRoleChange}
                required
                fullWidth
                className={classes.transparentDropdown}
                InputLabelProps={{ shrink: !!userRolesValue }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Select user role
                </MenuItem>
                {isAdmin
                  ? userRolesOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : userRolesOptions
                      .filter(option => option.value !== '6dhemployee')
                      .map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
              </TextField>
            </div>
            <div className={classes.footer}>
              {!fetching && userInvited === false && showError && (
                <span style={{ color: '#ff0000' }}>
                  Something went wrong, email may be invalid.
                </span>
              )}
              <PrimaryButton
                type="button"
                onClick={handleSubmit}
                disabled={email === '' || userRolesValue === ''}
                className={classes.inviteBtn}
              >
                {fetching ? (
                  <LoadingIndicator isLoading={fetching} size={15} color={colors.orange} />
                ) : (
                  'INVITE'
                )}
              </PrimaryButton>
              <PrimaryButton
                className={classes.cancelBtn}
                type="button"
                onClick={(): void => {
                  setEmail('');
                  clearInviteStatus();
                  setOpenEmailModal(false);
                  setUserRolesValue('');
                }}
              >
                CANCEL
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      {showSuccessInvite && (
        <div className={classes.successInvite}>
          <div>
            <CheckCircleIcon className={classes.successTick} />
            <Typography> User added successfully</Typography>
          </div>
          <CloseIcon
            onClick={handleSuccessInviteClose}
            style={{ cursor: 'pointer', height: '1rem' }}
          />
        </div>
      )}
    </>
  );
};

// modalC

type DispatchProps = {
  getUsers: typeof getUsers;
  invite: typeof invite;
  clearInviteStatus: typeof clearInviteStatus;
};

const mapStateToProps = ({ admin, user }: RootState): StateProps => ({
  superAdmin:
    (user.currentUser &&
      user.currentUser?.roles?.filter(role => role === 'users-admin').length > 0) ||
    false,
  userList: admin.users || [],
  appRoles: admin.appRoles || [],
  app: admin.app || '',
  fetching: admin.fetching,
  userInvited: admin.userInvited,
  inviteUserRole:
    (user.currentUser &&
      user.currentUser?.roles?.filter(
        role => role === 'medivi-inviteuser' || role === 'medivi-admin'
      ).length > 0) ||
    false,
  isAdmin:
    (user.currentUser &&
      user.currentUser?.roles?.filter(role => role === 'medivi-admin').length > 0) ||
    false,
});

const mapDispatchToProps = {
  getUsers,
  invite,
  clearInviteStatus,
};

type Props = StateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Users);
