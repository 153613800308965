import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { IncompleteTheme } from './types/theme';

const styles = makeStyles((theme: IncompleteTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  logos: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  sixDegreeLogo: {
    width: '30%',
    height: '2.5rem',
    marginLeft: '1rem',
  },
  mediviLogo: {
    width: '15%',
    height: '4rem',
  },
  illustratorBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  illustratorImg: {
    width: '70%',
    // height: '25rem',
  },
  illustratorContent: {
    color: '#888787',
    fontSize: '1rem',
    lineHeight: '1.875rem',
    fontWeight: 500,
    textAlign: 'center',
    width: '58%',
  },
}));

const Illustrator = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.logos}>
        <img
          alt="6 Degrees Health Logo"
          src="./images/6_degrees_expand_logo.svg"
          className={classes.sixDegreeLogo}
        />

        <img
          alt="6 Degrees Health Logo"
          src="./images/medivi_logo.svg"
          className={classes.mediviLogo}
        />
      </div>
      <div className={classes.illustratorBox}>
        <img alt="illustrator" src="./images/illustrator.png" className={classes.illustratorImg} />
        <p className={classes.illustratorContent}>
          Introducing a streamlined hub for submitting, tracking, and viewing all your bill/provider
          cases effortlessly
        </p>
      </div>
    </div>
  );
};

export default Illustrator;
