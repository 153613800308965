import doug from './assets/healtH2Business/DougHetherington.jpg';
import ebms from './assets/ebms/logo.png';
import aimm from './assets/aimm/logo.png';
import deb from './assets/aimm/nurseDeb.jpg';
import barry from './assets/mitigate/barryMurphy.jpg';

import facilityDetail from './assets/featureScreenshots/facilityDetail.png';
import averages from './assets/featureScreenshots/averages.png';
import ratiosSimple from './assets/featureScreenshots/ratiosSimple.png';

const partnerIntro = {
  headline: 'Data Driven Cost Insights',
  subHeading: `6 Degrees Health's MediVI software platform is an 
    industry leading source of objective and transparent healthcare data`,
};

const memberIntro = {
  headline: 'Navigating Your Healthcare Plan',
  subHeading: `Powered by 6 Degrees Health, MediVI Provider Search will help you find the healthcare providers you and your family need.`,
};

const features = [
  {
    title: 'Evidence Based Metrics',
    description: `Our data driven approach utilizing multiple benchmarks and industry
        recognized data helps provide transparent solutions for your healthcare decisions.`,
    screenshot: averages,
  },
  {
    title: `Transparent Analytics`,
    description: `MediVI medical reimbursement analytics help support your audits,
      contracting, claim negotiations, and reference based pricing with defensible and understandable metrics.`,
    screenshot: facilityDetail,
  },
  {
    title: 'Connections Made Simple',
    description: `Intelligent search functionality finds physicians and hospitals that provide selected procedures, showing only the data that matters to you.`,
    screenshot: ratiosSimple,
  },
];

const testimonials = {
  usedByLogos: [ebms, aimm],
  data: [
    {
      name: 'Doug Hetherington',
      photo: doug,
      jobTitle: 'CEO & Program Architect',
      company: 'HealtH2Business',
      initials: 'DH',
      quote: `The MediVI platform has been a game changer for
        my clients and our organization. Utilizing the facilities
        own claim and cost information allows us to better understand
        the potential costs of services from one facility to the next,
        but more importantly allows us to engage in a meaningful way
        with healthcare providers and systems. This is the only tool
        I have seen that allows all stakeholders to better understand
        and improve the currently antiquated fee-for-service
        reimbursement model.`,
      logo: '',
    },
    {
      name: 'Barry Murphy',
      photo: barry,
      jobTitle: 'Benefit Advisor',
      company: 'Mitigate Partners',
      initials: 'BM',
      quote: `I have been a benefit advisor for over 40 years and have
        waited all this time for a user-friendly database that will
        illustrate the problem of pricing transparency and the fallacy of
        networks.  Now, when explaining to our prospective clients the need
        for ACTIVE MANAGEMENT of their health spending combined with
        weaponized plan designs to encourage smarter decisions, a picture
        (data) is worth a lot more than 1,000 words.  MediVI is the greatest
        front facing database on the market.  I look forward to partnering
        with 6 Degrees in negotiating direct Hospital contracts and as a
        re-pricing partner in all of my self-funded plans. Keep up the
        innovation.`,
      logo: '',
    },
    {
      name: 'Nurse Deb (Deborah Ault)',
      photo: deb,
      jobTitle: 'President',
      company: 'Ault International Medical Management',
      initials: 'DA',
      quote: `MediVI is one of the most operationally friendly quality & 
        cost transparency tools we have found.  It is very quick and easy for
        my nurses to use the MediVI tool to steer patients to the highest 
        quality and most cost-effective providers for their care.  And given 
        the volume our medical management call center handles, quick and easy 
        is super important!`,
      logo: aimm,
    },
  ],
};

const registerPage = {
  headline: 'Request Access to MediVI',
  subHeading: `Enter the world of data driven insights and be informed about 
    healthcare costs for numerous different providers and care facilities.`,
};

export { partnerIntro, memberIntro, features, testimonials, registerPage };
