import axios from 'axios';
import { getAxiosRequestConfig } from '../utilities/http';

import m, { GetMember, ResetMemberInfo } from './types/memberActions';

export function getMemberDetail(): GetMember {
  return {
    type: m.GET_MEMBER,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/memberDetail`,
      getAxiosRequestConfig()
    ),
  };
}

export function resetMemberInfo(): ResetMemberInfo {
  return {
    type: m.RESET_MEMBER_INFO,
  };
}
