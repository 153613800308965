import React, { ReactChild } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { colors } from '@apps/shared/src/style';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  helpIcon: {
    width: '.75em',
    margin: '-0.2em 0 0 0.2em',
    color: colors.blue,
  },
});

type ParentProps = {
  tooltip: TooltipProps['title'];
  children?: ReactChild;
};

type Props = ParentProps;

export default function InfoTooltip({ tooltip, children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip} placement="top" enterTouchDelay={0} leaveTouchDelay={3000}>
      <div className={classes.wrapper}>
        {children}
        <HelpIcon className={classes.helpIcon} />
      </div>
    </Tooltip>
  );
}
