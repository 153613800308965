import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import FAQ from '@apps/shared/src/providerSearch/faq/FAQ';
import { colors } from '@apps/shared/src/style';
import Footer from '../shared/Footer';
import Header from '../shared/Header';
import { IncompleteTheme } from '../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  content: {
    minHeight: theme.props.contentHeight,
    padding: '1rem calc(50vw - 25rem)', // centered with a max content width of 50rem
    backgroundColor: colors.beige,
  },
}));

export default function ProviderSearchFAQ(): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Header title="Provider Search F.A.Q." />
      <section className={classes.content}>
        <FAQ />
      </section>
      <Footer />
    </>
  );
}
