import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Chart from 'chart.js';
import zoom from 'chartjs-plugin-zoom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import theme from './theme';

const styles = theme => ({
  canvasContainer: {
    // https://www.chartjs.org/docs/latest/general/responsive.html#important-note
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  loadingBar: {
    margin: '-3px 4.5em 0',
    height: '3px',
    top: '38px',
    ...theme.loadingBar,
  },
});

export class ChartCanvas extends React.Component {
  canvas = React.createRef();

  componentDidMount() {
    const {
      type,
      data,
      options = {},
      reportChartPosition,
      updateShowLabels,
      handleToggleHiddenData,
      hiddenData,
      viewStart: start,
      viewEnd: end,
    } = this.props;
    const ctx = this.canvas.current.getContext('2d');
    this.chart = new Chart(ctx, {
      type,
      data,
      options: {
        ...options,
        onClick: this.handleClick(options.onClick),
      },
      plugins: [
        zoom,
        {
          afterUpdate: c => {
            c.data.datasets.forEach((d, i) => {
              // check if datasets have been hidden/shown and record that state
              if (c.isDatasetVisible(i) === Boolean(hiddenData.indexOf(d.label) >= 0))
                handleToggleHiddenData(d.label);
            });
            const { minIndex } = c.scales['x-axis-0'];
            let { maxIndex } = c.scales['x-axis-0'];
            if (maxIndex < 0) maxIndex = c.data.labels.length - 1;
            const comfortableSpacing = 30;
            const canShowLabels =
              c.width / (maxIndex - minIndex) >= comfortableSpacing && c.data.labels.length > 0;
            const canShowLabelsSetting = c.config.options.scales.xAxes[0].ticks.canDisplay;

            if (canShowLabels !== canShowLabelsSetting)
              updateShowLabels({ canShowLabels, minIndex, maxIndex });
            else reportChartPosition({ minIndex, maxIndex });
          },
        },
        {
          beforeUpdate: c => {
            const { datasets } = c.data;
            let { labels } = c.data;
            const viewStart = start;
            let viewEnd = end;
            if (!viewEnd || viewEnd < 0) viewEnd = c.data.labels.length - 1;
            if (!c.data.fullDatasetsData) {
              c.data.fullDatasetsData = datasets.map(d => d.data); // eslint-disable-line no-param-reassign
              c.data.fullLabels = [...labels]; // eslint-disable-line no-param-reassign
            }
            const { fullDatasetsData } = c.data;
            labels = c.data.fullLabels.slice(viewStart, viewEnd + 1);
            for (let i = 0; i < fullDatasetsData.length; i++) {
              const fullData = fullDatasetsData[i];
              if (!fullData.length) continue;
              datasets[i].data = fullData.slice(viewStart, viewEnd + 1);
            }
          },
        },
      ],
    });
  }

  shouldComponentUpdate(nextProps) {
    const newData = nextProps.data.labels.length !== this.props.data.labels.length;
    const newOptions = nextProps.options !== this.props.options;
    const newRatio = nextProps.useRatios !== this.props.useRatios;
    const newProcedure = nextProps.selectedProcedure !== this.props.selectedProcedure;
    const loading = nextProps.isChartLoading !== this.props.isChartLoading;
    return newData || newOptions || newRatio || newProcedure || loading;
  }

  componentDidUpdate() {
    const {
      formatTooltipTitle,
      formatCallbackLabel,
      formatCallbackFooter,
      type,
      data,
      options = {},
    } = this.props;
    const tooltips = {
      footerFontStyle: 'normal',
      mode: 'index',
      position: 'nearest',
      backgroundColor: theme.chart.tooltips.backgroundColor,
      titleFontColor: theme.chart.tooltips.titleFontColor,
      bodyFontColor: theme.chart.tooltips.bodyFontColor,
      footerFontColor: theme.chart.tooltips.footerFontColor,
      borderColor: theme.chart.tooltips.borderColor,
      borderWidth: 1,
      multiKeyBackground: theme.chart.tooltips.multiKeyBackground,
      xPadding: 16,
      yPadding: 12,
      callbacks: {
        title: formatTooltipTitle,
        label: formatCallbackLabel,
        footer: formatCallbackFooter,
        beforeBody: () => [`(click chart for full details)`, ``],
        afterBody: () => ``,
      },
    };
    const onClick = this.handleClick(options.onClick);
    const animation = { duration: 1000, easing: 'easeOutQuart' };

    Object.assign(this.chart, {
      type,
      data,
      options: { ...options, onClick, tooltips, animation },
    });
    this.chart.update();
  }

  componentWillUnmount() {
    this.chart.destroy();
  }

  handleClick = outerClick => e => {
    if (outerClick) {
      // bubble the low-level click event
      outerClick(e);
    }
    if (this.props.onClick) {
      // send the selected element
      const element = this.chart.getElementAtEvent(e);
      if (element.length > 0) {
        this.props.onClick(element[0]);
      }
    }
  };

  render() {
    const { classes, isChartLoading } = this.props;
    return (
      <div className={classes.canvasContainer}>
        <Fade in={isChartLoading}>
          <LinearProgress className={classes.loadingBar} vaiant="query" />
        </Fade>
        <canvas ref={this.canvas} />
      </div>
    );
  }
}

ChartCanvas.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formatCallbackFooter: PropTypes.func.isRequired,
  formatCallbackLabel: PropTypes.func.isRequired,
  formatTooltipTitle: PropTypes.func.isRequired,
  handleToggleHiddenData: PropTypes.func.isRequired,
  hiddenData: PropTypes.arrayOf(PropTypes.shape({})),
  isChartLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  reportChartPosition: PropTypes.func.isRequired,
  selectedProcedure: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateShowLabels: PropTypes.func.isRequired,
  useRatios: PropTypes.bool.isRequired,
  viewEnd: PropTypes.number.isRequired,
  viewStart: PropTypes.number.isRequired,
};
ChartCanvas.defaultProps = {
  hiddenData: [],
  options: {},
};

export default withStyles(styles)(ChartCanvas);
