import React from 'react';
import { Video } from '../shared/types/mediviTypes';
import VideoPage from './VideoPage';

const videos: Video[] = [
  {
    src: 'https://player.vimeo.com/video/337786351',
    title: 'Benchmarks Highlights',
    description: 'An introduction to MediVI and the Benchmarks Tool',
  },
  {
    src: 'https://player.vimeo.com/video/336157586',
    title: 'Benchmarks Inpatient Module',
    description: 'A hands-on walk-through of the Inpatient Module of MediVI Benchmarks',
  },
  {
    src: 'https://player.vimeo.com/video/337786374',
    title: 'Benchmarks Outpatient Module',
    description:
      'A continuation of the Inpatient video covering the Outpatient and Professional Modules',
  },
];

export default function BenchmarksTraining(): JSX.Element {
  return <VideoPage videos={videos} heading="Benchmarks Training Videos" title="Documentation" />;
}
