import UserCard from '@apps/shared/src/auth/UserCard';
import { addUser } from '@apps/shared/src/register/actions';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../store';

const useStyles = makeStyles({
  container: {
    margin: '1rem auto',
    width: 'fit-content',
  },
  text: {
    marginTop: '10px',
    display: 'block',
  },
  buttonText: {
    minWidth: 'unset',
    textTransform: 'unset',
    fontSize: '0.75rem',
    margin: '0',
    padding: '0',
  },
  italics: {
    fontStyle: 'italic',
    marginTop: '0',
    fontSize: '0.6rem',
  },
});

type AddPlanState = RootState['addPlan'];
type RegisterState = RootState['register'];
type StateProps = {
  userInfo: RegisterState['userInfo'];
  currentPage: RegisterState['currentPage'];
  isAddingPlan: AddPlanState['isAddingPlan'];
  planInfo: AddPlanState['planInfo'];
};

const mapStateToProps = ({ addPlan, register, user }: RootState): StateProps => ({
  userInfo: register.userInfo,
  currentPage: register.currentPage,
  isAddingPlan: addPlan.isAddingPlan,
  planInfo: addPlan.planInfo,
});

type DispatchProps = {
  addUser: typeof addUser;
};

const mapDispatchToProps = { addUser };

type Props = StateProps & DispatchProps;

export const CheckEmail = ({ userInfo }: Props): JSX.Element => {
  const classes = useStyles();

  const { email } = userInfo;

  if (email === '') {
    return <Redirect to="/" />;
  }

  return (
    <UserCard showLogo>
      <div className={classes.container}>
        <Typography className={classes.text} variant="h5">
          Please verify your email.
        </Typography>
        <Typography className={classes.text} variant="body2">
          We sent a verification email to: {email}
        </Typography>
      </div>
    </UserCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmail);
