import React, { ChangeEventHandler, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { setPlanInfo } from '@apps/shared/src/addPlan/actions';
import { PlanInfo } from '@apps/shared/src/addPlan/types/addPlanTypes';
import { RootState } from '@apps/shared/src/rootStateType';
import { IncompleteTheme } from '../shared/types/theme';
import InputBox from '../shared/InputBox';

const styles = makeStyles((theme: IncompleteTheme) => ({
  signinBox: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  signinText: {
    fontSize: 'clamp(1.7rem, 1.2013rem + 1.039vw, 2.9rem)',
    fontWeight: 600,
    lineHeight: '2.375rem',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#37383D',
  },
  userDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-input': {
      // width: '20vw',
    },
    '& > div': {
      width: '47%',
    },
  },
  inputBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '0.55rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.75rem',
      boxShadow: 'rgba(33, 35, 38, 0.2) 0px 10px 10px -10px',
    },
  },
  label: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '0',
  },
  registerSubtext: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '2rem',
    margin: '0 0 1rem 0',
  },
}));

type AddPlanState = RootState['addPlan'];
type StateProps = {
  planInfo: AddPlanState['planInfo'];
};

const mapStateToProps = ({ addPlan }: RootState): StateProps => ({
  planInfo: addPlan.planInfo,
});

type DispatchProps = {
  setPlanInfo: typeof setPlanInfo;
};

const mapDispatchToProps = {
  setPlanInfo,
};

type parentProps = {
  onNext: () => void;
  onTextInputEmptyChange: (isEmpty: boolean) => void;
};

type Props = StateProps & DispatchProps & parentProps;

const MemberPage = ({
  planInfo,
  setPlanInfo,
  onNext,
  onTextInputEmptyChange,
}: Props): JSX.Element => {
  const classes = styles();

  const scroll = (e: React.MouseEvent<HTMLElement>): void => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleChange =
    (key: keyof PlanInfo): ChangeEventHandler<HTMLInputElement> =>
    (e): void => {
      setPlanInfo(key, e.currentTarget.value.toUpperCase());
    };

  const handleDateOfBirthChange = (date: string): void => {
    setPlanInfo('dateOfBirth', date);
  };

  const isAnyTextInputEmpty = Object.values(planInfo).some(value => value === '');

  useEffect(() => {
    onTextInputEmptyChange(isAnyTextInputEmpty);
  }, [planInfo, onTextInputEmptyChange, isAnyTextInputEmpty]);

  const handleSubmit = (e: React.FormEvent<Element>): void => {
    onNext();
  };

  return (
    <div className={classes.signinBox}>
      <Typography className={classes.signinText}>Register</Typography>
      <p className={classes.registerSubtext}>
        Enter your 6 degrees health account information to register.
      </p>
      <div className={classes.userDetails}>
        <div>
          <InputBox
            name="memberID"
            label="Member ID"
            onClick={scroll}
            onChangeEvent={handleChange('memberID')}
            value={planInfo.memberID}
            validators={['required']}
            errorMessages={['This field is required.']}
            inputProps={{ maxLength: 50 }}
            type="text"
            className={classes.inputBox}
          />
        </div>
        <div>
          <InputBox
            name="groupID"
            label="Group ID"
            onClick={scroll}
            onChangeEvent={handleChange('groupID')}
            value={planInfo.groupID}
            validators={['required']}
            errorMessages={['This field is required.']}
            inputProps={{ maxLength: 20 }}
            type="text"
            className={classes.inputBox}
          />
        </div>
      </div>
      <div className={classes.userDetails}>
        <div>
          <InputBox
            name="firstName"
            label="First Name"
            onClick={scroll}
            onChangeEvent={handleChange('firstName')}
            value={planInfo.firstName}
            validators={['required']}
            errorMessages={['This field is required.']}
            inputProps={{ maxLength: 35 }}
            type="text"
            className={classes.inputBox}
          />
        </div>
        <div>
          <InputBox
            name="lastName"
            label="Last Name"
            onClick={scroll}
            onChangeEvent={handleChange('lastName')}
            value={planInfo.lastName}
            validators={['required']}
            errorMessages={['This field is required.']}
            inputProps={{ maxLength: 60 }}
            type="text"
            className={classes.inputBox}
          />
        </div>
      </div>
      <div>
        <InputBox
          label="Date of Birth"
          type="date"
          name="dateOfBirth"
          value={planInfo.dateOfBirth}
          onClick={scroll}
          onChange={handleDateOfBirthChange}
          validators={['required']}
          errorMessages={['This field is required.']}
          className={classes.inputBox}
        />
      </div>

      <div>
        <InputBox
          name="zip"
          label="Zip Code"
          type="text"
          onClick={scroll}
          onChangeEvent={handleChange('zip')}
          inputProps={{
            maxLength: 5,
            inputMode: 'numeric',
          }}
          value={planInfo.zip}
          validators={['required', 'isNumber']}
          errorMessages={['This field is required.', 'Only numbers are allowed.']}
          className={classes.inputBox}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberPage);
