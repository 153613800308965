import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import PanLeftIcon from '@material-ui/icons/ChevronLeft';
import PanRightIcon from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';

const panStyle = {
  panGroup: {
    textAlign: 'center',
    minWidth: '2.5em',
    position: 'sticky',
    top: '0',
    margin: 'auto',
  },
  left: {
    marginLeft: '0',
  },
  right: {
    marginRight: '0',
  },
};

function PanControl({ classes, direction, spots, handlePan }) {
  const PanIcon = direction === 'left' ? PanLeftIcon : PanRightIcon;
  const Dir = direction.charAt(0).toUpperCase() + direction.substr(1);

  return spots <= 0 ? (
    <div className={classes.panGroup} />
  ) : (
    <div className={classnames(classes.panGroup, classes[direction])}>
      <Tooltip title={`Pan Chart ${Dir}. ${spots} Provider${spots !== 1 ? 's' : ''} Hidden`}>
        <IconButton onClick={handlePan}>
          <PanIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

PanControl.propTypes = {
  handlePan: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  direction: PropTypes.string.isRequired,
  spots: PropTypes.number.isRequired,
};

export default withStyles(panStyle)(PanControl);
