import lzString from 'lz-string';
import { Provider, TaxonomyDetailsMap, ProviderTypesMap } from '../types/providerSearch';

const defaultProviderStorage: ProviderStorage = {
  zip: '',
  dateLastFetched: '',
  fullList: [],
  taxonomyDetailsMap: {},
  providerTypesMap: {},
};

type ProviderStorage = {
  zip: string;
  dateLastFetched: string;
  fullList: Provider[];
  taxonomyDetailsMap: TaxonomyDetailsMap;
  providerTypesMap: ProviderTypesMap;
};

export const getProviderStorage = (): ProviderStorage => {
  const payload = window.localStorage.getItem('providerSearch');
  if (payload) {
    const stringPayload = lzString.decompress(payload);
    if (stringPayload) return JSON.parse(stringPayload);
  }
  return defaultProviderStorage;
};

export const setProviderStorage = (payload: ProviderStorage): void => {
  try {
    window.localStorage.setItem('providerSearch', lzString.compress(JSON.stringify(payload)));
  } catch (error) {
    console.error(`Error saving Providers: ${error}`); // eslint-disable-line no-console
  }
};
