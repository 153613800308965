import React, { ChangeEvent, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/Input';
import theme from './theme';
import { IncompleteTheme } from './types/theme';

const useStyles = makeStyles(theme => ({
  inputContainer: {
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
    color: '#797C80',
  },
  input: {
    background: '#FFFFFF',
    minHeight: '3.2em',
    borderRadius: '.75rem',
    boxShadow: '0px 16px 32px 0px #090E1D0D',
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px`,
    width: '100%',
    '&:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitBoxShadow: '0 0 0 30px white inset !important',
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '0.65rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.75rem',
      boxShadow: 'rgba(33, 35, 38, 0.2) 0px 10px 10px -10px',
      backgroundColor: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

type InputBoxProps = {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  onChangeEvent?(e: React.FormEvent<{}>): void;
  onChange?(value: string): void;
  onClick?(e: React.MouseEvent<{}>): void;
  validators?: string[];
  errorMessages?: string[];
  inputProps?: {
    [key: string]: string | number | ((e: React.FormEvent<{}>) => void) | React.ReactNode;
  };
  disabled?: boolean;
  currentPassValue?: string;
  required?: boolean;
  minRow?: number;
  maxRow?: number;
  multiline?: boolean;
};
const InputBox: React.FC<InputBoxProps> = ({
  id,
  name,
  label,
  value,
  placeholder,
  type = 'text',
  onChangeEvent,
  onChange,
  onClick,
  className,
  validators = [],
  errorMessages = [],
  inputProps = {},
  currentPassValue = '',
  disabled = false,
  required = false,
  minRow = 1,
  maxRow = 1,
  multiline = false,
}) => {
  //   const classes = styles();
  //   return (
  //     <TextField
  //       id="outlined-basic"
  //       variant="outlined"
  //       placeholder={props.placeholder}
  //       type={props.type}
  //       className={`${classes.inputBox} ${props.className ? props.className : ''}`}
  //     />
  //   );
  // };
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    if (onChangeEvent) {
      onChangeEvent(event);
    }

    if (onChange) {
      onChange(newValue);
    }

    let firstErrorIndex = -1;

    const hasError = validators.some((validator, index) => {
      if (validator === 'required') {
        if (!newValue.trim()) {
          firstErrorIndex = index;
          return true;
        }
      } else {
        const isValid = validate(validator, newValue);
        if (!isValid) {
          firstErrorIndex = index;
          return true;
        }
      }

      return false;
    });

    setError(hasError);
    setErrorMessage(hasError ? errorMessages[firstErrorIndex] : '');
  };

  const validate = (validator: string, value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidCharactersRegex = /[&!#^$%*()""~,`]/;
    switch (validator) {
      case 'required':
        return value.trim() !== '';
      case 'isEmail':
        if (value.length === 0) {
          return true;
        }
        return emailRegex.test(value) && !invalidCharactersRegex.test(value);
      case 'noWhitespacePadding':
        return !/^\s|\s$/.test(value);
      case 'maxStringLength':
        return value.length <= 128;
      case 'isPasswordMatch':
        return currentPassValue === value;
      case 'validatePhoneNumberLength':
        return value.length === 10;
      case 'validateZipCode':
        return value.length === 5;
      case 'checkAgeUnder18': {
        const currentDate = new Date();
        const eighteenYearsAgo = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const enteredDate = new Date(value);
        const isValid = enteredDate >= eighteenYearsAgo;
        return isValid;
      }
      default:
        return true;
    }
  };

  return (
    <div className={classes.inputContainer}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <TextField
        id={id}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        onClick={onClick}
        error={error}
        classes={{ root: classes.input }}
        fullWidth
        multiline={multiline}
        minRows={minRow}
        maxRows={maxRow}
        disableUnderline
        disabled={disabled}
        required
        {...inputProps}
      />
      {error && (
        <Typography variant="body2" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

InputBox.defaultProps = {
  id: '',
  type: 'text',
  name: '',
  value: '',
  label: '',
  placeholder: '',
  disabled: false,
  validators: [],
  errorMessages: [],
  inputProps: {},
};

export default InputBox;
