import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddCircle from '@material-ui/icons/AddCircle';
import { formatNumber, formatBigNumber } from '../shared/formats';
import { autocompleteOptionsType } from '../shared/types/propTypes';

const styles = theme => ({
  container: {
    // ...theme.inputList.container,
    color: '#EF7125',
  },
  containerDark: {
    // ...theme.inputList.containerDark,
    color: '#EF7125',
  },
  listItemText: {
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '0.8em',
  },
  type: {
    paddingLeft: '1em',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    ...theme.inputList.type,
    color: '#EF7125',
  },
  lightType: {
    paddingLeft: '1em',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    ...theme.inputList.lightType,
    color: '#EF7125',
  },
  title: {
    ...theme.inputList.title,
    color: '#EF7125',
  },
  lightTitle: {
    ...theme.inputList.lightTitle,
    color: '#EF7125',
  },
  button: {
    border: 'none',
    backgroundColor: 'initial',
    // color: theme.inputList.removeButton.color,
    cursor: 'pointer',
    transform: 'rotate(45deg)',
    marginRight: '-0.75em',
    marginBottom: '0.1em',
    '&:hover': {
      // color: theme.inputList.removeButton.hoverColor,
    },
  },
  lightButton: {
    border: 'none',
    backgroundColor: 'initial',
    // color: theme.inputList.removeButtonLight.color,
    cursor: 'pointer',
    transform: 'rotate(45deg)',
    marginRight: '-0.75em',
    marginBottom: '0.1em',
    '&:hover': {
      // color: theme.inputList.removeButtonLight.hoverColor,
    },
  },
  icon: {
    fontSize: 20,
  },
});

function InputList({ items, onRemove, showType, showCount, classes }) {
  return (
    <List dense>
      {items.map((item, i) => {
        const count = item.matches;
        const selectionTitle = `${item.name} (${item.type})${
          showCount ? ` - ${formatNumber(count, '0,0')} Match${count === 1 ? '' : 'es'}` : ''
        }`;
        const containerClass = count > 0 ? classes.container : classes.containerDark;
        const titleClass = count > 0 ? classes.title : classes.lightTitle;
        const typeClass = count > 0 ? classes.type : classes.lightType;
        const buttonClass = count > 0 ? classes.button : classes.lightButton;

        return (
          <ListItem key={selectionTitle} disableGutters className={containerClass}>
            <ListItemText className={classes.listItemText} disableTypography>
              <Tooltip title={selectionTitle} enterDelay={250} placement="bottom-start">
                <Typography noWrap className={titleClass}>
                  {item.name}
                </Typography>
              </Tooltip>
              {(showType || showCount) && (
                <Tooltip title={selectionTitle} enterDelay={250} placement="bottom-start">
                  <Typography className={typeClass}>
                    {showType && item.type}
                    {showCount && `${`(${formatBigNumber(count)})`}`}
                  </Typography>
                </Tooltip>
              )}
            </ListItemText>
            <ListItemSecondaryAction className={classes.removeButton}>
              <Tooltip
                title={`Remove ${item.name} from selections`}
                enterDelay={250}
                placement="bottom-start"
              >
                <ButtonBase
                  focusRipple
                  className={buttonClass}
                  alt={`Remove ${item.name} from selections`}
                  onClick={() => onRemove(i)}
                >
                  <AddCircle className={classes.icon} />
                </ButtonBase>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

InputList.propTypes = {
  items: autocompleteOptionsType.isRequired,
  onRemove: PropTypes.func.isRequired,
  showType: PropTypes.bool,
  showCount: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
InputList.defaultProps = {
  showType: false,
  showCount: false,
};

export default withStyles(styles)(InputList);
