import axios from 'axios';
import { toRFC3339 } from '../utilities/stringUtilities';

import { getAxiosRequestConfig } from '../utilities/http';

import m, {
  AddDependentMember,
  DeleteDepdendentMember,
  GetDepdendentMembers,
  ResetDependentMember,
  SetDependentMemberInfo,
} from './types/dependentMemberActions';
import { AddDependentMemberInfo, DependentMemberInfo } from './types/dependentMemberTypes';

export function getDependentMembersDetail(): GetDepdendentMembers {
  return {
    type: m.GET_DEPENDENT_MEMBERS,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/getDependentMember`,
      getAxiosRequestConfig()
    ),
  };
}

export function deleteDependentMember(id: string): DeleteDepdendentMember {
  return {
    type: m.DELETE_DEPENDENT_MEMBER,
    payload: axios.delete(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/dependentMember/${id}`,
      getAxiosRequestConfig()
    ),
  };
}

export function addDependentMember(member: AddDependentMemberInfo): AddDependentMember {
  return {
    type: m.ADD_DEPENDENT_MEMBER,
    meta: { destinationUrl: '/my-account' },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/add-dependent-member`,
      { ...member, dateOfBirth: toRFC3339(member.dateOfBirth) },
      getAxiosRequestConfig()
    ),
  };
}

export function setDependentMemberInfo(
  key: keyof AddDependentMemberInfo,
  value: AddDependentMemberInfo[keyof AddDependentMemberInfo]
): SetDependentMemberInfo {
  return {
    type: m.SET_DEPENDENT_MEMBER_INFO,
    meta: { key, value },
  };
}

export function resetDependentMember(): ResetDependentMember {
  return {
    type: m.RESET_ADD_DEPENDENT_MEMBER,
  };
}
