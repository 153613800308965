import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import VisibilityAdornment from '@apps/shared/src/components/VisibilityAdornment';
import { previousPage, setUserInfo } from '@apps/shared/src/register/actions';
import { UserInfo } from '@apps/shared/src/register/types/register';
import { RootState } from '@apps/shared/src/rootStateType';
import { checkCompromisedPassword } from '@apps/shared/src/auth/passwordUtils';
import { IncompleteTheme } from '../shared/types/theme';
import InputBox from '../shared/InputBox';

const styles = makeStyles((theme: IncompleteTheme) => ({
  signinBox: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  signinText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    letterSpacing: '0em',
    marginBottom: '1rem',
    textAlign: 'left',
    color: '#37383D',
  },
  registerSubtext: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '2rem',
    margin: 0,
  },
  inputBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      borderRadius: '0.75rem',
      height: '0.55rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.75rem',
      boxShadow: 'rgba(33, 35, 38, 0.2) 0px 10px 10px -10px',
    },
  },
  label: {
    color: '#797C80',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '0',
  },
  passwordValidation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '0.5rem',
  },
  statusIcon: {
    marginRight: '0.5rem',
  },
}));

type RegisterState = RootState['register'];
type StateProps = {
  hasAccount: RegisterState['hasAccount'];
  userInfo: RegisterState['userInfo'];
};

const mapStateToProps = ({ register }: RootState): StateProps => ({
  hasAccount: register.hasAccount,
  userInfo: register.userInfo,
});

type DispatchProps = {
  setUserInfo: typeof setUserInfo;
  previousPage: typeof previousPage;
};

const mapDispatchToProps = { setUserInfo, previousPage };

type parentProps = {
  onNext: () => void;
  onTextInputEmptyChange: (isEmpty: boolean) => void;
  setInputEmpty: (isEmpty: boolean) => void;
};
type Props = StateProps & DispatchProps & parentProps;

const AccountPage = ({
  hasAccount,
  userInfo,
  setUserInfo,
  onNext,
  previousPage,
  onTextInputEmptyChange,
  setInputEmpty,
}: Props): JSX.Element => {
  const classes = styles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState({ password: '', confirmPassword: '' });
  const [passwordValidation, setPasswordValidation] = useState({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasMinChar: false,
    isCompromised: false,
  });

  const scroll = (e: React.MouseEvent<HTMLElement>): void => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleChange =
    (key: keyof UserInfo): ChangeEventHandler<HTMLInputElement> =>
    async (e): Promise<void> => {
      setUserInfo(key, e.currentTarget.value);

      if (key === 'password') {
        const newPassword = e.currentTarget.value;
        setPassword(prevState => {
          const newPasswordState = { ...prevState, password: newPassword };
          checkPassword(newPassword).then(validation => setPasswordValidation(validation));
          return newPasswordState;
        });
      } else {
        setPassword(prevState => ({
          ...prevState,
          confirmPassword: e.currentTarget.value,
        }));
      }
    };

  const checkPassword = async (password: string) => {
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let hasMinChar = false;
    let isCompromised = false;

    if (password.length >= 12) {
      hasMinChar = true;
    }
    if (/[A-Z]/.test(password)) {
      hasUppercase = true;
    }
    if (/[a-z]/.test(password)) {
      hasLowercase = true;
    }
    if (/[0-9]/.test(password)) {
      hasNumber = true;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      hasSpecialChar = true;
    }

    // Check if the password is compromised
    try {
      isCompromised = await checkCompromisedPassword(password);
    } catch (error) {
      isCompromised = false;
    }

    return {
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      hasMinChar,
      isCompromised,
    };
  };

  return (
    <div className={classes.signinBox}>
      <Typography className={classes.signinText}>Register</Typography>
      <Typography className={classes.registerSubtext}>
        Enter your 6 degrees health account information to register.
      </Typography>
      <div>
        <InputBox name="email" type="email" label="Email" onClick={scroll} value={userInfo.email} />
      </div>

      <div>
        <InputBox
          name="password"
          label="Password"
          className={classes.inputBox}
          onClick={scroll}
          onChangeEvent={handleChange('password')}
          value={userInfo.password}
          validators={['required', 'noWhitespacePadding', 'maxStringLength:128']}
          errorMessages={[
            'This field is required',
            'No leading or trailing whitespace',
            'Password must be less than 128 characters',
          ]}
          inputProps={{
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <VisibilityAdornment showPassword={showPassword} setShowPassword={setShowPassword} />
            ),
          }}
        />
      </div>
      {userInfo.password && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '0.5rem',
          }}
        >
          {[
            { label: '12 Characters', state: passwordValidation.hasMinChar },
            { label: 'Uppercase', state: passwordValidation.hasUppercase },
            { label: 'Lowercase', state: passwordValidation.hasLowercase },
            { label: 'Number', state: passwordValidation.hasNumber },
            { label: 'Special Character', state: passwordValidation.hasSpecialChar },
          ].map(({ label, state }) => (
            <span key={label}>
              <span style={{ color: state ? 'green' : 'red' }}>
                {state ? '\u2714' : '\u2718'} <span style={{ color: 'black' }}>{label}</span>
              </span>
            </span>
          ))}
          <div>
            <span style={{ color: passwordValidation.isCompromised ? 'red' : 'green' }}>
              {passwordValidation.isCompromised ? '\u2718' : '\u2714'}
              <span style={{ color: 'black' }}>
                {' '}
                Password{' '}
                {passwordValidation.isCompromised ? 'is compromised' : 'is not compromised'}
              </span>
            </span>
          </div>
        </div>
      )}

      {!hasAccount && (
        <div>
          <InputBox
            name="password-confirm"
            label="Confirm Password"
            onClick={scroll}
            className={classes.inputBox}
            onChangeEvent={handleChange('passwordConfirm')}
            value={userInfo.passwordConfirm}
            currentPassValue={userInfo.password}
            validators={[
              'required',
              'noWhitespacePadding',
              'maxStringLength:128',
              'isPasswordMatch',
            ]}
            errorMessages={[
              'This field is required',
              'No leading or trailing whitespace',
              'Password must be less than 128 characters',
              'Passwords must match',
            ]}
            type={showConfirmPassword ? 'text' : 'password'}
            inputProps={{
              endAdornment: (
                <VisibilityAdornment
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                />
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
