import { CaseState } from './types/myCase';
import c, { CaseActionTypes } from './types/myCaseAction';

export function makeDefaultCaseState(): CaseState {
  return {
    Cases: [],
    isCasesLoading: false,
    hasLoadedCases: false,
  };
}

export const initializeCaseState = {
  Cases: [],
  isCasesLoading: false,
  hasLoadedCases: false,
};

const caseReducer = (
  state: CaseState = initializeCaseState,
  action: CaseActionTypes
): CaseState => {
  switch (action.type) {
    case c.GET_CASES_PENDING:
    case c.GET_CASES_RTK_PENDING: {
      return { ...state, isCasesLoading: true };
    }
    case c.GET_CASES_REJECTED:
    case c.GET_CASES_RTK_REJECTED: {
      return { ...state, isCasesLoading: false };
    }
    case c.GET_CASES_FULFILLED:
    case c.GET_CASES_RTK_FULFILLED: {
      let sortedCases;

      if (action.payload.data && action.payload.data.length > 0) {
        const cases = action.payload.data;
        sortedCases = cases.sort(
          (a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()
        );
      }
      return {
        ...state,
        Cases: sortedCases || [],
        isCasesLoading: false,
        hasLoadedCases: true,
      };
    }

    case c.RESET_CASES: {
      const state = initializeCaseState;
      return { ...state, isCasesLoading: false };
    }

    default:
      return state;
  }
};

export default caseReducer;
