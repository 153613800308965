import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import c, { ResetCaseState } from '@apps/shared/src/caseSelect/types/myCaseAction';
import { getAxiosRequestConfig } from '@apps/shared/src/utilities';

export interface CaseFilter {
  queryParam: string;
  type: string;
  createdDate: string;
  status: string;
}

export const getMyCases = createAsyncThunk(
  'case/getCases',
  async (payload: CaseFilter, thunkAPI) => {
    const { type, createdDate, status, queryParam } = payload;
    const token = localStorage.getItem('csrfToken') ?? '';
    if (token === '') return thunkAPI.rejectWithValue('');
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL || ''
        }/api/getCases?q=${queryParam}&type=${type}&createdDate=${createdDate}&status=${status}`,
        getAxiosRequestConfig()
      );
      return { data: res.data.records };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCaseById = async (id: string) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/case/${id}`,
      getAxiosRequestConfig()
    );
    return res.data.records;
  } catch (e) {
    return e;
  }
};

export const resetMyCasesState = (): ResetCaseState => {
  return {
    type: c.RESET_CASES,
  };
};
