import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Tabs from '@apps/shared/src/components/Tabs';
import InputList from './InputList';
import AutocompleteSelect from './AutocompleteSelect';
import InfoTooltip from '../shared/InfoTooltip';
import { autocompleteOptionsType } from '../shared/types/propTypes';
import Sidebar from '../shared/Sidebar';

function getSortOptions(claimType, useRatios) {
  if (claimType === 'Professional') {
    return useRatios
      ? [
          {
            text: 'Medicare',
            value: 'medicareRatio',
            tooltip: 'Sort by Billed as Medicare Multiple',
          },
          { text: 'Claims', value: 'numClaims', tooltip: 'Sort by number of Claims' },
        ]
      : [
          { text: 'Billed', value: 'billedAvg', tooltip: 'Sort by Billed Average' },
          { text: 'Medicare', value: 'medicareAvg', tooltip: 'Sort by Medicare Average' },
          { text: 'Claims', value: 'numClaims', tooltip: 'Sort by number of Claims' },
        ];
  }

  return useRatios
    ? [
        { text: 'Cost', value: 'costRatio', tooltip: 'Sort by Billed as Cost Multiple' },
        {
          text: 'Medicare',
          value: 'medicareRatio',
          tooltip: 'Sort by Billed as Medicare Multiple',
        },
        { text: 'Claims', value: 'numClaims', tooltip: 'Sort by number of Claims' },
        { text: 'Quality', value: 'quality', tooltip: 'Sort by Quality Score' },
      ]
    : [
        { text: 'Billed', value: 'billedAvg', tooltip: 'Sort by Billed Average' },
        { text: 'Cost', value: 'costAvg', tooltip: 'Sort by Cost Average' },
        { text: 'Medicare', value: 'medicareAvg', tooltip: 'Sort by Medicare Average' },
        { text: 'Claims', value: 'numClaims', tooltip: 'Sort by number of Claims' },
        { text: 'Quality', value: 'quality', tooltip: 'Sort by Quality Score' },
      ];
}

const styles = theme => ({
  root: {
    width: 383,
  },
  selectionGroup: {
    margin: '1.5rem 0',
  },
  selectionTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '23px',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 0 1rem',
    backgroundColor: '#F1F5FF',
  },
  collapseTab: {
    padding: '1rem 1rem 0 1rem',
  },
  header: {
    fontFamily: theme.selectionPane.root.fontFamily,
    fontSize: '14px',
    fontWeight: '500',
  },
  resetBtn: {
    color: '#ff0000',
    textDecoration: 'underline',
    padding: '0',
    border: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  buttonGroup: {
    marginBottom: '16px',
  },
  label: {
    fontFamily: theme.buttonGroup.fontFamily,
    color: theme.buttonGroup.color,
    fontSize: theme.buttonGroup.fontSize,
    fontWeight: '500',
    marginBottom: '7px',
  },
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
  sortMenuItem: {
    width: '275px',
    fontFamily: theme.selectionPane.root.fontFamily,
    fontSize: '14px',
  },
});

const outpatientCodeTypeOptions = [
  {
    content: 'Diagnosis',
    value: 'Diagnosis',
    title: 'Search by Primary Diagnosis Code',
  },
  {
    content: 'HCPCS',
    value: 'HCPCS',
    title: 'Search by Healthcare Common Procedure Coding System',
  },
  {
    content: 'APC',
    value: 'APC',
    title: 'Search by Ambulatory Payment Classification',
  },
];

const professionalCodeTypeOptions = [
  {
    content: 'Diagnosis',
    value: 'Diagnosis',
    title: 'Search by Primary Diagnosis Code',
  },
  {
    content: 'HCPCS',
    value: 'HCPCS',
    title: 'Search by Healthcare Common Procedure Coding System',
  },
];

export class SelectionPane extends React.Component {
  collapseToggle = props => {
    const { isOpen, title, onClick } = props;
    const Icon = isOpen ? <UpIcon /> : <DownIcon />;
    return (
      <Tooltip placement="right" title={isOpen ? `Close ${title}` : `Open ${title}`}>
        <IconButton onClick={onClick}>{Icon}</IconButton>
      </Tooltip>
    );
  };

  getProcedureText = (claimType, outpatientCodeType, professionalCodeType) => {
    const text = {
      drg: {
        title: 'DRG',
        placeholder: 'e.g. 470 or Joint Replacement',
        help: 'Search procedures by name or DRG code. Multiple selections allowed',
      },
      diagnosis: {
        title: 'Diagnosis',
        placeholder: 'e.g. Chest Pain, or R071',
        help: 'Search procedures by Primary Diagnosis Code description or code. Multiple selections allowed',
      },
      apc: {
        title: 'APC',
        placeholder: 'e.g. Radiation Therapy, or 5621',
        help: 'Search procedures by Ambulatory Payment Classification code. Multiple selections allowed',
      },
      hcpcs: {
        title: 'HCPCS',
        placeholder: 'e.g. Dialysis, or 90945',
        help: 'Search procedures by HCPCS (Healthcare Common Procedure Coding System code). Multiple selections allowed',
      },
      default: {
        title: 'Procedures',
        placeholder: 'e.g. Procedure, or CODE',
        help: 'Search procedures by name or code. Multiple selections allowed',
      },
    };
    switch (claimType) {
      case 'Inpatient':
        return text.drg;
      case 'Professional':
        switch (professionalCodeType) {
          case 'APC':
            return text.apc;
          case 'HCPCS':
            return text.hcpcs;
          case 'Diagnosis':
            return text.diagnosis;
          default:
            return text.default;
        }
      case 'Outpatient':
        switch (outpatientCodeType) {
          case 'Diagnosis':
            return text.diagnosis;
          case 'APC':
            return text.apc;
          case 'HCPCS':
            return text.hcpcs;
          default:
            return text.default;
        }
      default:
        return text.default;
    }
  };

  render() {
    const {
      handleBlur,
      handleClick,
      handleProviderSelect,
      handleProviderQueryChange,
      handleProviderRemove,
      handleProcedureSelect,
      handleProcedureQueryChange,
      handleProcedureRemove,
      selectedProcedure,
      handleClaimTypeChange,
      handleSetUseRatios,
      handleSelectedProcedureChange,
      handleChangeSortOrder,
      procedureQuery,
      providerQuery,
      procedureSelections,
      providerSelections,
      providerSelectIsOpen,
      procedureSelectIsOpen,
      procedureOptions,
      providerOptions,
      claimType,
      classes,
      useRatios,
      width,
      sortOrder,
      handleShowLabels,
      showLabels,
      canShowLabels,
      optionsOpen,
      handleToggleOptionsOpen,
      handleToggleDataSelectionOpen,
      dataSelectionOpen,
      handleOutpatientCodeTypeChange,
      handleProfessionalCodeTypeChange,
      outpatientCodeType,
      professionalCodeType,
      providerSelectPending,
      procedureSelectPending,
      isUserAdmin,
    } = this.props;
    const procText = this.getProcedureText(claimType, outpatientCodeType, professionalCodeType);
    const sortOptions = getSortOptions(claimType, useRatios);

    const resetFilter = () => {
      console.log('Cleared');
    };

    return (
      <Sidebar className={classes.root} toggleButtonTitle="Data Selection Drawer">
        <div style={{ textAlign: 'right', margin: '1rem' }}>
          <button onClick={resetFilter} className={classes.resetBtn} type="button">
            Clear
          </button>
        </div>
        <div className={classes.selectionTitle}>
          <Typography>Data Selection</Typography>
          <this.collapseToggle
            title="Data Selection"
            isOpen={dataSelectionOpen}
            onClick={handleToggleDataSelectionOpen}
          />
        </div>
        <Collapse in={dataSelectionOpen} className={classes.collapseTab}>
          <Tabs
            onChange={handleClaimTypeChange}
            selectedValue={claimType}
            options={[
              {
                content: 'Inpatient',
                value: 'Inpatient',
                title: 'Inpatient',
              },
              {
                content: 'Outpatient',
                value: 'Outpatient',
                title: 'Outpatient',
              },
              {
                content: 'Professional',
                value: 'Professional',
                title: 'Professional',
              },
            ]}
          />
          <section className={classes.selectionGroup}>
            <InfoTooltip tooltip="Search providers by geographic area, name, or code. Multiple selections allowed">
              <Typography className={classes.header}>Select Providers</Typography>
            </InfoTooltip>
            <AutocompleteSelect
              onBlur={handleBlur('provider')}
              onClick={handleClick('provider')}
              isOpen={providerSelectIsOpen}
              value={providerQuery}
              options={providerOptions}
              selections={providerSelections}
              onSelect={handleProviderSelect}
              onChange={handleProviderQueryChange}
              placeholder="e.g. Banner, 030002, or Maricopa"
              isLoading={providerSelectPending > 0}
            />
            <InputList
              items={providerSelections}
              onRemove={handleProviderRemove}
              showType
              showCount
            />
          </section>
          <section className={classes.selectionGroup}>
            <InfoTooltip tooltip={procText.help}>
              <Typography className={classes.header}>Select {procText.title}</Typography>
            </InfoTooltip>
            <AutocompleteSelect
              onBlur={handleBlur('procedure')}
              onClick={handleClick('procedure')}
              isOpen={procedureSelectIsOpen}
              value={procedureQuery}
              options={procedureOptions}
              selections={procedureSelections}
              onSelect={handleProcedureSelect}
              onChange={handleProcedureQueryChange}
              placeholder={procText.placeholder}
              isLoading={procedureSelectPending > 0}
            />
            <InputList items={procedureSelections} onRemove={handleProcedureRemove} />
          </section>
        </Collapse>
        <div className={classes.selectionTitle}>
          <Typography>Options</Typography>
          <this.collapseToggle
            title="Options"
            isOpen={optionsOpen}
            onClick={handleToggleOptionsOpen}
          />
        </div>
        <Collapse in={optionsOpen} className={classes.collapseTab}>
          <div className={classes.options}>
            {claimType === 'Outpatient' && (
              <section className={classes.buttonGroup}>
                <Typography className={classes.label}>Code Type</Typography>
                <Tabs
                  onChange={handleOutpatientCodeTypeChange}
                  selectedValue={outpatientCodeType}
                  options={outpatientCodeTypeOptions}
                />
              </section>
            )}
            {isUserAdmin && claimType === 'Professional' && (
              <section className={classes.buttonGroup}>
                <Typography className={classes.label}>Code Type</Typography>
                <Tabs
                  onChange={handleProfessionalCodeTypeChange}
                  selectedValue={professionalCodeType}
                  options={professionalCodeTypeOptions}
                />
              </section>
            )}
            <section className={classes.buttonGroup}>
              <Typography className={classes.label}>Averages</Typography>
              <Tabs
                onChange={handleSelectedProcedureChange}
                selectedValue={selectedProcedure}
                options={[
                  {
                    content: 'Weighted',
                    value: 'weighted average',
                    title: 'Weighted Averages Across Selected Procedures',
                  },
                  {
                    content: 'Simple',
                    value: 'average',
                    title: 'Simple Averages Across all Procedures',
                  },
                ]}
              />
            </section>
            <section className={classes.buttonGroup}>
              <Typography className={classes.label}>Display</Typography>
              <Tabs
                onChange={handleSetUseRatios}
                selectedValue={useRatios}
                options={[
                  {
                    content: 'Values',
                    value: false,
                    title: 'Display as Raw Values',
                  },
                  {
                    content: 'Ratios',
                    value: true,
                    title: 'Display as Ratios of Billed Charges',
                  },
                ]}
              />
            </section>
            <section className={classes.buttonGroup}>
              <InputLabel id="sort-label" className={classes.label}>
                Sort
              </InputLabel>
              <Select
                labelId="sort-label"
                autoWidth
                onChange={handleChangeSortOrder}
                value={sortOrder}
                disableUnderline
                className={classes.Select}
              >
                {sortOptions.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={classes.sortMenuItem}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </section>
            {canShowLabels && (width === 'xs' || width === 'sm' || showLabels === false) ? (
              <>
                <Typography>Hide Labels</Typography>
                <Tabs
                  onChange={handleShowLabels}
                  selectedValue={showLabels}
                  options={[
                    {
                      content: 'No',
                      value: true,
                      title: 'Show Labels',
                    },
                    {
                      content: 'Yes',
                      value: false,
                      title: 'Hide Labels',
                    },
                  ]}
                />
              </>
            ) : null}
          </div>
        </Collapse>
      </Sidebar>
    );
  }
}

SelectionPane.propTypes = {
  canShowLabels: PropTypes.bool.isRequired,
  claimType: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dataSelectionOpen: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChangeSortOrder: PropTypes.func.isRequired,
  handleClaimTypeChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleOutpatientCodeTypeChange: PropTypes.func.isRequired,
  handleProfessionalCodeTypeChange: PropTypes.func.isRequired,
  handleProcedureQueryChange: PropTypes.func.isRequired,
  handleProcedureRemove: PropTypes.func.isRequired,
  handleProcedureSelect: PropTypes.func.isRequired,
  handleProviderQueryChange: PropTypes.func.isRequired,
  handleProviderRemove: PropTypes.func.isRequired,
  handleProviderSelect: PropTypes.func.isRequired,
  handleSelectedProcedureChange: PropTypes.func.isRequired,
  handleSetUseRatios: PropTypes.func.isRequired,
  handleShowLabels: PropTypes.func.isRequired,
  handleToggleDataSelectionOpen: PropTypes.func.isRequired,
  handleToggleOptionsOpen: PropTypes.func.isRequired,
  optionsOpen: PropTypes.bool.isRequired,
  outpatientCodeType: PropTypes.string.isRequired,
  procedureOptions: autocompleteOptionsType,
  procedureQuery: PropTypes.string.isRequired,
  procedureSelectIsOpen: PropTypes.bool.isRequired,
  procedureSelectPending: PropTypes.number,
  procedureSelections: autocompleteOptionsType,
  professionalCodeType: PropTypes.string.isRequired,
  providerOptions: autocompleteOptionsType,
  providerQuery: PropTypes.string.isRequired,
  providerSelectIsOpen: PropTypes.bool.isRequired,
  providerSelectPending: PropTypes.number,
  providerSelections: autocompleteOptionsType,
  selectedProcedure: PropTypes.string.isRequired,
  showLabels: PropTypes.bool.isRequired,
  sortOrder: PropTypes.string.isRequired,
  useRatios: PropTypes.bool.isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
};
SelectionPane.defaultProps = {
  procedureSelectPending: 0,
  providerSelectPending: 0,
  providerOptions: [],
  procedureOptions: [],
  providerSelections: [],
  procedureSelections: [],
};

export default compose(withStyles(styles), withWidth())(SelectionPane);
