/* eslint-disable react/state-in-constructor */
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const styles = {
  root: {
    margin: '1em',
    maxWidth: '300px',
    minWidth: '300px',
    height: 'min-content',
    gridRow: '1 / 3',
    display: 'grid',
    gridTemplateColumns: '42% 58%',
  },
  title: {
    fontWeight: 'bold',
    gridColumn: '1/3',
  },
  total: {
    fontWeight: 'bold',
    gridColumn: '2/3',
  },
};

class AnalysisLocations extends React.Component {
  state = {
    AL: { name: 'Alabama', iphRatio: 6.5, ophRatio: 4.89, proRatio: 2.78, percent: 0 },
    AK: { name: 'Alaska', iphRatio: 4.5, ophRatio: 3.87, proRatio: 4.21, percent: 0 },
    AZ: { name: 'Arizona', iphRatio: 6.11, ophRatio: 4.75, proRatio: 2.74, percent: 0 },
    AR: { name: 'Arkansas', iphRatio: 4.35, ophRatio: 3.68, proRatio: 2.64, percent: 0 },
    CA: { name: 'California', iphRatio: 7.37, ophRatio: 5.46, proRatio: 2.9, percent: 0 },
    CO: { name: 'Colorado', iphRatio: 7.5, ophRatio: 5.71, proRatio: 2.95, percent: 0 },
    CT: { name: 'Connecticut', iphRatio: 4.1, ophRatio: 4.02, proRatio: 2.86, percent: 0 },
    DE: { name: 'Delaware', iphRatio: 3.18, ophRatio: 3.36, proRatio: 2.66, percent: 0 },
    DC: { name: 'Washington DC', iphRatio: 4.88, ophRatio: 4.05, proRatio: 2.64, percent: 0 },
    FL: { name: 'Florida', iphRatio: 8.85, ophRatio: 6.1, proRatio: 2.8, percent: 0 },
    GA: { name: 'Georgia', iphRatio: 5.55, ophRatio: 4.6, proRatio: 3.2, percent: 0 },
    HI: { name: 'Hawaii', iphRatio: 4.83, ophRatio: 3.06, proRatio: 2.54, percent: 0 },
    ID: { name: 'Idaho', iphRatio: 4.86, ophRatio: 2.93, proRatio: 2.6, percent: 0 },
    IL: { name: 'Illinois', iphRatio: 4.91, ophRatio: 4.83, proRatio: 3.08, percent: 0 },
    IN: { name: 'Indiana', iphRatio: 5.1, ophRatio: 4.44, proRatio: 3.01, percent: 0 },
    IA: { name: 'Iowa', iphRatio: 4.02, ophRatio: 3.57, proRatio: 2.75, percent: 0 },
    KS: { name: 'Kansas', iphRatio: 5.41, ophRatio: 4.84, proRatio: 2.86, percent: 0 },
    KY: { name: 'Kentucky', iphRatio: 5.17, ophRatio: 5.09, proRatio: 2.75, percent: 0 },
    LA: { name: 'Louisiana', iphRatio: 5.69, ophRatio: 4.45, proRatio: 3.17, percent: 0 },
    ME: { name: 'Maine', iphRatio: 3.4, ophRatio: 2.41, proRatio: 2.49, percent: 0 },
    MD: { name: 'Maryland', iphRatio: 1.13, ophRatio: 1.13, proRatio: 2.54, percent: 0 },
    MA: { name: 'Massachusetts', iphRatio: 2.88, ophRatio: 2.95, proRatio: 3.19, percent: 0 },
    MI: { name: 'Michigan', iphRatio: 4.11, ophRatio: 3.16, proRatio: 2.53, percent: 0 },
    MN: { name: 'Minnesota', iphRatio: 3.43, ophRatio: 2.72, proRatio: 2.94, percent: 0 },
    MS: { name: 'Mississippi', iphRatio: 4.88, ophRatio: 4.98, proRatio: 3.1, percent: 0 },
    MO: { name: 'Missouri', iphRatio: 5.07, ophRatio: 4.17, proRatio: 3.02, percent: 0 },
    MT: { name: 'Montana', iphRatio: 3.27, ophRatio: 3.98, proRatio: 2.43, percent: 0 },
    NE: { name: 'Nebraska', iphRatio: 4.07, ophRatio: 3.74, proRatio: 2.68, percent: 0 },
    NV: { name: 'Nevada', iphRatio: 8.48, ophRatio: 6, proRatio: 3.3, percent: 0 },
    NH: { name: 'New Hampshire', iphRatio: 3.41, ophRatio: 3.66, proRatio: 3.38, percent: 0 },
    NJ: { name: 'New Jersey', iphRatio: 7.61, ophRatio: 5.08, proRatio: 3.04, percent: 0 },
    NM: { name: 'New Mexico', iphRatio: 4.79, ophRatio: 4.13, proRatio: 2.89, percent: 0 },
    NY: { name: 'New York', iphRatio: 5.54, ophRatio: 4.09, proRatio: 2.88, percent: 0 },
    NC: { name: 'North Carolina', iphRatio: 4.48, ophRatio: 4.12, proRatio: 2.98, percent: 0 },
    ND: { name: 'North Dakota', iphRatio: 3.02, ophRatio: 2.37, proRatio: 2.84, percent: 0 },
    OH: { name: 'Ohio', iphRatio: 5.83, ophRatio: 4.7, proRatio: 2.81, percent: 0 },
    OK: { name: 'Oklahoma', iphRatio: 5.29, ophRatio: 4.54, proRatio: 2.84, percent: 0 },
    OR: { name: 'Oregon', iphRatio: 3.96, ophRatio: 2.99, proRatio: 2.95, percent: 0 },
    PA: { name: 'Pennsylvania', iphRatio: 7.96, ophRatio: 5.51, proRatio: 2.74, percent: 0 },
    RI: { name: 'Rhode Island', iphRatio: 4.4, ophRatio: 3.75, proRatio: 2.69, percent: 0 },
    SC: { name: 'South Carolina', iphRatio: 6.09, ophRatio: 4.92, proRatio: 2.94, percent: 0 },
    SD: { name: 'South Dakota', iphRatio: 3.95, ophRatio: 2.79, proRatio: 2.66, percent: 0 },
    TN: { name: 'Tennessee', iphRatio: 5.6, ophRatio: 4.89, proRatio: 3, percent: 0 },
    TX: { name: 'Texas', iphRatio: 7.3, ophRatio: 5.29, proRatio: 3.41, percent: 0 },
    UT: { name: 'Utah', iphRatio: 4.27, ophRatio: 2.97, proRatio: 2.6, percent: 0 },
    VT: { name: 'Vermont', iphRatio: 2.44, ophRatio: 3.28, proRatio: 2.75, percent: 0 },
    VA: { name: 'Virginia', iphRatio: 4.93, ophRatio: 4.55, proRatio: 2.71, percent: 0 },
    WA: { name: 'Washington', iphRatio: 5.08, ophRatio: 4.07, proRatio: 2.69, percent: 0 },
    WV: { name: 'West Virginia', iphRatio: 4.36, ophRatio: 3.02, proRatio: 2.81, percent: 0 },
    WI: { name: 'Wisconsin', iphRatio: 4.68, ophRatio: 3.57, proRatio: 4.52, percent: 0 },
    WY: { name: 'Wyoming', iphRatio: 2.85, ophRatio: 3.37, proRatio: 3.35, percent: 0 },
  };

  totalPercent = 0;
  totalIPH = 0;
  totalOPH = 0;
  totalPro = 0;
  states = this.getValues().states;

  getValues() {
    let total = 0;
    let totalIPH = 0;
    let totalOPH = 0;
    let totalPro = 0;
    const states = [];
    for (const abbrev in this.state) {
      if (this.state.hasOwnProperty(abbrev)) {
        const v = this.state[abbrev];
        const percent = Number(v.percent) / 100.0;
        total += percent * 100;
        totalIPH += percent * v.iphRatio;
        totalOPH += percent * v.ophRatio;
        totalPro += percent * v.proRatio;
        states.push({ ...v, abbrev });
      }
    }
    return {
      iphRatio: totalIPH,
      ophRatio: totalOPH,
      proRatio: totalPro,
      totalPercent: total,
      states,
    };
  }

  handlePercentChange = name => e => {
    const updating = this.state[name];
    const oldValue = updating.percent;
    let newValue = parseInt(e.target.value, 10);
    const newTotal = this.totalPercent + newValue - oldValue;
    if (newTotal > 100) {
      newValue -= newTotal - 100;
    }
    this.setState({ [name]: { ...updating, percent: newValue } }, this.sendOnValueChange);
  };

  sendOnValueChange = () => {
    if (!this.props.onValueChange) return;
    const v = this.getValues();
    this.totalIPH = v.iphRatio;
    this.totalOPH = v.ophRatio;
    this.totalPro = v.proRatio;
    this.totalPercent = v.totalPercent;
    this.states = v.states;
    this.props.onValueChange({
      utilization: this.states
        .filter(i => i.percent > 0)
        .map(i => {
          return { state: i.name, percent: i.percent };
        }),
      iphRatio: Number(v.iphRatio.toFixed(2)),
      ophRatio: Number(v.ophRatio.toFixed(2)),
      proRatio: Number(v.proRatio.toFixed(2)),
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <strong>Utilization Locations</strong>
        </div>
        {this.states.map(i => (
          <React.Fragment key={i.abbrev}>
            <input
              type="range"
              value={i.percent}
              onInput={this.handlePercentChange(i.abbrev)}
              onChange={this.handlePercentChange(i.abbrev)}
              step="1"
              max="100"
            />
            <div>
              {i.name}
              {i.percent ? ` - ${i.percent}%` : null}
            </div>
          </React.Fragment>
        ))}
        <div className={classes.total}>
          <strong>Total - {this.totalPercent}%</strong>
        </div>
      </div>
    );
  }
}

AnalysisLocations.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AnalysisLocations);
