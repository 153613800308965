import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import { inflateString } from '@apps/shared/src/inflators';
import { getInitials } from '@apps/shared/src/utilities';

import { styledAvatar } from './globals';

const Avatar = styledAvatar(30);

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0 1em',
    color: theme.header.navButton.color,
  },
  avatar: {
    backgroundColor: theme.header.avatar.backgroundColor,
    color: theme.header.avatar.color,
    fontFamily: theme.header.navButton.fontFamily,
  },
  downIcon: {
    height: '0.75em',
  },
  popover: {
    marginTop: '1em',
  },
  ahref: theme.cleanLink,
}));

export default function UserMenu({ currentUser }) {
  const classes = useStyles();
  const [userAnchor, setUserAnchor] = useState(null);

  const handleUserMenuToggle = e => setUserAnchor(userAnchor ? null : e.currentTarget);

  return (
    <>
      <Button onClick={handleUserMenuToggle} className={classes.button}>
        <Avatar className={classes.avatar}>
          {getInitials(inflateString(currentUser.fullName)) || 'Me'}
        </Avatar>
        <DownIcon className={classes.downIcon} />
      </Button>
      <Popover
        className={classes.popover}
        open={Boolean(userAnchor)}
        onClose={handleUserMenuToggle}
        anchorEl={userAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List component="nav">
          <ListItem>
            <ListItemText>{(currentUser && currentUser.fullName) || 'MediVI User'}</ListItemText>
          </ListItem>
          {/* <Divider />
          <Link to="/change-password">
            <ListItem button>
              <ListItemText>Change Password</ListItemText>
            </ListItem>
          </Link>
          <Link to="/logout">
            <ListItem button>
              <ListItemText>Log Out</ListItemText>
            </ListItem>
          </Link> */}
        </List>
      </Popover>
    </>
  );
}

UserMenu.propTypes = {
  currentUser: PropTypes.shape({
    fullName: PropTypes.string,
  }).isRequired,
};
