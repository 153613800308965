import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import { Accessibility } from '@apps/shared/src/providerSearch/types/providerSearch';
import { setAccessibilities } from '@apps/shared/src/providerSearch/actions';
import useProviderAccessibilityCounts from '@apps/shared/src/providerSearch/api/useProviderAccessibilityCounts';
import { accessibilityLabel } from '@apps/shared/src/providerSearch/api/utilities';
import { toSentenceCase } from '@apps/shared/src/utilities/stringUtilities';
import { allAccessibilities } from '@apps/shared/src/providerSearch/api/providers';
import { RootState } from '../../store';
import { IncompleteTheme } from '../../shared/types/theme';

const useStyles = makeStyles((theme: IncompleteTheme) => ({
  header: theme.sidebar.headerText,
  menuItem: theme.selectMenuItem,
  Select: {
    '& .MuiSelect-selectMenu': {
      background: '#ffffff',
      border: '1px solid #E5EBF5',
    },
  },
}));

const mapStateToProps = ({ providerSearch }: RootState): StateProps => ({
  selectedAccessibilities: providerSearch.selectedAccessibilities,
  isLoading: providerSearch.isLoadingProviderList,
});

type StateProps = {
  selectedAccessibilities: Accessibility[];
  isLoading: boolean;
};

const mapDispatchToProps = {
  setAccessibilities,
};

type Props = StateProps & typeof mapDispatchToProps;

export function AccessibilityFilter({
  selectedAccessibilities,
  setAccessibilities,
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();
  const counts = useProviderAccessibilityCounts();

  const handleProviderAccessibilityChange: SelectProps['onChange'] = e => {
    const levels = e.target.value as Accessibility[];
    setAccessibilities(levels);
  };

  const filterEmptyAccessibilities = (a: Accessibility): boolean => {
    return counts.hasOwnProperty(a) && counts[a] > 0;
  };

  const nonEmptyAccessibilities = allAccessibilities.filter(filterEmptyAccessibilities);

  const accessibilityValue =
    selectedAccessibilities === []
      ? nonEmptyAccessibilities.filter(filterEmptyAccessibilities)
      : selectedAccessibilities.filter(filterEmptyAccessibilities);

  const renderAccessibilitySelectValue: SelectProps['renderValue'] = () =>
    accessibilityValue.map(toSentenceCase).join(', ');

  return (
    <div>
      <Typography className={classes.header}>Accessibility</Typography>
      <Select
        labelId="provider-accessibility"
        id="accessibility-search"
        value={accessibilityValue}
        onChange={handleProviderAccessibilityChange}
        disableUnderline
        disabled={isLoading}
        multiple
        className={classes.Select}
        MenuProps={{ variant: 'menu' }} // This fixes an MUI v4 bug https://github.com/mui-org/material-ui/issues/19245
        renderValue={renderAccessibilitySelectValue}
      >
        {nonEmptyAccessibilities.map(a => (
          <MenuItem key={a} value={a} className={classes.menuItem}>
            <Checkbox color="primary" checked={selectedAccessibilities.includes(a)} />
            {accessibilityLabel(a, counts[a])}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessibilityFilter);
