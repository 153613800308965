import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { PowerBIEmbed } from 'powerbi-client-react';
import { IEmbedConfiguration, Report } from 'powerbi-client';
import { RootState } from '../store';

import { getPowerBIEmbed } from './reportActions';
import { ValidReportName } from './types';
import { IncompleteTheme } from '../shared/types/theme';
import { isReport } from './utils';

const useStyles = makeStyles<IncompleteTheme>(theme => ({
  pbiEmbed: {
    width: '100%',
    paddingLeft: '1.5rem',
    boxSizing: 'border-box',
    '& iframe': {
      border: 'none',
    },
  },
}));

type StateProps = {
  config: IEmbedConfiguration;
  isEmbedFetched: boolean;
  selectedReportName: ValidReportName | '';
  selectedReportPage: string;
};
const mapStateToProps = ({ reports }: RootState): StateProps => {
  return {
    config: reports.config,
    isEmbedFetched: reports.isEmbedFetched,
    selectedReportName: reports.selectedReportName,
    selectedReportPage: reports.selectedReportPage,
  };
};

const mapDispatchToProps = {
  getPowerBIEmbed,
};

type Props = StateProps & typeof mapDispatchToProps;

export function PowerBI({
  getPowerBIEmbed,
  config,
  selectedReportName,
  isEmbedFetched,
}: Props): JSX.Element {
  const classes = useStyles();
  const [embed, setEmbed] = useState<Report | null>(null);

  useEffect(() => {
    if (selectedReportName && !isEmbedFetched) {
      getPowerBIEmbed(selectedReportName);
    }
  }, [getPowerBIEmbed, selectedReportName, isEmbedFetched]);

  useEffect(() => {
    if (embed && config.pageName) {
      embed.setPage(config.pageName);
    }
  });

  useEffect(() => {
    if (embed && config.filters) {
      embed.setFilters(config.filters);
    }
  }, [embed, config]);

  return (
    <PowerBIEmbed
      embedConfig={config}
      cssClassName={classes.pbiEmbed}
      getEmbeddedComponent={(embed): void => {
        if (isEmbedFetched && isReport(embed)) {
          setEmbed(embed);
        }
      }}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerBI);
