import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Tabs, { Option } from '@apps/shared/src/components/Tabs';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Sidebar from '../../shared/Sidebar';
import { RootState } from '../../store';
// import ReportSelect from './ReportSelect';
import CaseStatusNav from './CaseStatusNav';
import MonthlyRepricingNav from './MonthlyRepricingNav';
import { IncompleteTheme } from '../../shared/types/theme';
import PageSpecificHeader from '../../shared/PageSpecificHeader';

const useStyles = makeStyles<IncompleteTheme>(theme => ({
  container: {
    backgroundColor: '#ffffff',
    height: 'calc(100vh - 70px - 50px - 6rem)',
    padding: '0 1rem',
    zIndex: 0,
  },
}));

type StateProps = {
  selectedReportName: string;
};
const mapStateToProps = ({ reports }: RootState): StateProps => {
  return {
    selectedReportName: reports.selectedReportName,
  };
};

export function NavPanel({ selectedReportName }: StateProps): JSX.Element {
  const classes = useStyles();

  return (
    <Sidebar className={classes.container} toggleButtonTitle="Data Selection Drawer">
      <CaseStatusNav />
    </Sidebar>
  );
}

export default connect(mapStateToProps)(NavPanel);
