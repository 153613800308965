import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Notification } from '@apps/shared/src/notification/types/notification';
import { markAllAsRead, getAllNotifications } from '@apps/shared/src/notification/actions';
import { toggleNotification } from '@apps/shared/src/auth/userActions';
import NotificationItem from './NotificationItem';
import AppLayout from '../shared/AppLayout';
import { RootState } from '../store';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  disableNotification: {
    fontSize: '1rem',
  },
  ellipsesButton: {
    marginLeft: 'auto',
  },
  bellIllustration: {
    width: '14rem',
    height: '14rem',
  },
  noNotifications: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '.5rem',
  },
}));

type StateProps = {
  notifications: Notification[];
  notificationsEnabled: boolean;
};

const mapStateToProps = ({ notification, user }: RootState): StateProps => ({
  notifications: notification.notifications,
  notificationsEnabled: user?.currentUser?.enableNotification || false,
});

const mapDispatchToProps = {
  markAllAsRead,
  toggleNotification,
  getAllNotifications,
};

type Props = StateProps & typeof mapDispatchToProps;

const NotificationsPage: React.FC<Props> = ({
  notifications,
  markAllAsRead,
  toggleNotification,
  notificationsEnabled,
  getAllNotifications,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [ellipsesAnchorEl, setEllipsesAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();

  const filteredNotifications = (type: string): Notification[] => {
    const sortedNotifications = notifications.sort(
      (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    switch (type) {
      // case 'Message Center':
      //   return sortedNotifications.filter(
      //     notification => notification.recordType === 'Message Center'
      //   );
      case 'Access Issue':
      case 'Balance Bill':
        return sortedNotifications.filter(notification => notification.recordType === 'Others');
      default:
        return sortedNotifications;
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number): void => {
    setTabIndex(newIndex);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setEllipsesAnchorEl(event.currentTarget);
  };

  const handleEllipsesClose = (): void => {
    setEllipsesAnchorEl(null);
  };

  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  const getCurrentTabNotifications = (): Notification[] => {
    // if (tabIndex === 1) {
    //   return filteredNotifications('Message Center');
    // }
    if (tabIndex === 1) {
      return filteredNotifications('Others');
    }
    return filteredNotifications('');
  };

  const renderContent = () => {
    if (notificationsEnabled) {
      if (getCurrentTabNotifications().length > 0) {
        return getCurrentTabNotifications().map(notification => (
          <NotificationItem key={notification.id} notification={notification} />
        ));
      }
      return (
        <div className={classes.noNotifications}>
          <img
            className={classes.bellIllustration}
            src="/images/NotificationBell.png"
            alt="Notification Bell"
          />
          <Typography variant="h6" color="textSecondary" align="center">
            No Notifications
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            You don’t have any notifications at this time.
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Please come back later.
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.noNotifications}>
        <img
          className={classes.bellIllustration}
          src="/images/NotificationBell.png"
          alt="Notification Bell"
        />
        <Typography variant="h6" color="textSecondary" align="center">
          Notifications Disabled
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          You have disabled your notifications.
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          Turn them on to see any updates.
        </Typography>
      </div>
    );
  };

  return (
    <AppLayout>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="h4" className={classes.heading}>
            Notifications
          </Typography>
          <IconButton size="small" className={classes.ellipsesButton} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={ellipsesAnchorEl}
            open={Boolean(ellipsesAnchorEl)}
            onClose={handleEllipsesClose}
          >
            {notifications.length !== 0 && (
              <MenuItem
                onClick={() => {
                  markAllAsRead();
                  handleEllipsesClose();
                }}
              >
                Mark all as read
              </MenuItem>
            )}
            <MenuItem onClick={() => toggleNotification(!notificationsEnabled)}>
              {notificationsEnabled ? 'Disable Notifications' : 'Enable Notifications'}
            </MenuItem>
          </Menu>
        </Box>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab key="All" label={`All (${filteredNotifications('').length})`} />
          {/* <Tab
            key="Message Center"
            label={`Message Center (${filteredNotifications('Message Center').length})`}
          /> */}
          <Tab key="Others" label={`Others (${filteredNotifications('Others').length})`} />
        </Tabs>
        <Box style={{ overflow: 'scroll', height: '70vh' }}>{renderContent()}</Box>
      </Box>
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
