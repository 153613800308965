import { createTheme } from '@material-ui/core';
import { hexToRgba, colors } from '@apps/shared/src/style';

export const maxProviders = 200;

export const shadows = {
  paper:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
};

export const footerHeight = 50;
export const headerHeight = 70;
export const contentHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`; // fill entire screen

const muliFontFamily = ['"Muli"', 'Helvetica', 'sans-serif'].join(',');
const avenirFontFamily = ['Avenir', 'Helvetica', 'sans-serif'].join(',');
const headFootBorder = `2px solid ${colors.orange}`;

export default createTheme({
  props: {
    headerHeight,
    footerHeight,
    contentHeight,
  },
  header: {
    root: {
      backgroundColor: colors.greyDarkest,
      borderBottom: headFootBorder,
      height: headerHeight,
    },
    pageTitle: {
      fontFamily: muliFontFamily,
      color: colors.white,
    },
    navButton: {
      fontFamily: avenirFontFamily,
      color: colors.white,
      margin: '0 2px',
      '&:hover': {
        backgroundColor: colors.greyDarker,
      },
    },
    navButtonActive: {
      backgroundColor: colors.primary,
      '&:hover': {
        backgroundColor: colors.orangeDark,
      },
    },
    navSubButtonActive: {
      color: colors.white,
    },
    avatar: {
      backgroundColor: colors.white,
      color: colors.primary,
    },
  },
  cleanLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  autocompleteSelect: {
    color: colors.greyDarkest,
    icon: {
      color: colors.grey37,
    },
  },
  chartSummary: {
    excludedIcon: {
      color: colors.greyDarkest,
    },
    approximate: {
      color: colors.greyDark,
    },
  },
  detailPane: {
    cardHeader: {
      backgroundColor: colors.grey05,
    },
  },
  excludedProviders: {
    excludedIcon: {
      color: colors.greyDarkest,
    },
    provider: {
      color: colors.greyDark,
    },
  },
  inputList: {
    container: {
      backgroundColor: colors.grey60,
      borderRadius: '1em',
      padding: '.4em 2em .4em 1em',
      marginBottom: '.25em',
      alignItems: 'center',
    },
    containerDark: {
      backgroundColor: colors.grey20,
      borderRadius: '1em',
      padding: '.4em 2em .4em 1em',
      marginBottom: '.25em',
      alignItems: 'center',
    },
    title: {
      color: colors.grey05,
      fontFamily: muliFontFamily,
      fontSize: '.9em',
    },
    lightTitle: {
      color: colors.grey05,
      fontFamily: muliFontFamily,
      fontSize: '.9em',
    },
    type: {
      color: colors.grey05,
      fontSize: '.85em',
    },
    lightType: {
      color: colors.grey05,
      fontSize: '.85em',
    },
    removeButton: {
      hoverColor: colors.grey05,
      color: colors.white,
    },
    removeButtonLight: {
      hoverColor: colors.greyDark,
      color: colors.grey05,
    },
  },
  selectionPane: {
    root: {
      backgroundColor: colors.beige,
      fontFamily: avenirFontFamily,
    },
    select: {
      backgroundColor: colors.grey12,
      borderColor: colors.orange,
    },
  },
  selectMenuItem: {
    minWidth: '275px',
    fontFamily: avenirFontFamily,
    fontSize: '14px',
  },
  sidebar: {
    headerText: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '1rem',
    },
    headerText2: {
      fontSize: '12px',
      fontWeight: 'normal',
      marginTop: '0.5rem',
    },
  },
  documentation: {
    root: {
      backgroundColor: colors.beige,
    },
    title: {
      color: colors.primary,
      fontFamily: muliFontFamily,
      fontWeight: 'bold',
    },
    trainingVideo: {
      iframe: {
        borderRadius: '4px',
        boxShadow: shadows.paper,
      },
      title: {
        fontFamily: muliFontFamily,
        fontWeight: 'bold',
        marginTop: '1rem',
      },
      description: {
        fontFamily: avenirFontFamily,
        margin: '1rem 0',
        textAlign: 'center',
      },
    },
  },
  loadingSpinner: {
    color: hexToRgba(colors.greyDark, 0.5),
  },
  loadingBar: {
    backgroundColor: hexToRgba(colors.greyDark, 0.25),
  },
  buttonGroup: {
    fontFamily: avenirFontFamily,
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
  },
  chart: {
    tooltips: {
      backgroundColor: colors.grey05,
      titleFontColor: colors.orange,
      bodyFontColor: colors.black,
      footerFontColor: colors.orange,
      borderColor: colors.grey,
      multiKeyBackground: colors.white,
    },
  },
  zoomControl: {
    backgroundColor: colors.white,
    subtitleColor: colors.grey,
    maxProvidersColor: colors.orange,
  },
  footer: {
    root: {
      border: headFootBorder,
      backgroundColor: colors.greyDarkest,
      color: colors.white,
    },
    link: {
      color: colors.yellow,
    },
  },
  popoverButton: {
    icon: {
      color: colors.greyDarkest,
    },
  },
  palette: {
    primary: {
      main: colors.orange,
      dark: colors.orangeDark,
      contrastText: colors.offwhite,
    },
    secondary: {
      main: colors.grey05,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    landingPage: {
      fontFamily: muliFontFamily,
    },
  },
  reports: {
    navPanel: {
      backgroundColor: colors.beige,
      fontFamily: avenirFontFamily,
      title: {
        fontFamily: muliFontFamily,
      },
    },
  },
  overrides: {
    MuiAvatar: {
      root: {
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: colors.black,
        backgroundColor: colors.grey05,
        border: `1px solid ${colors.grey}`,
        fontSize: '.75em',
      },
    },
    MuiSelect: {
      selectMenu: {
        backgroundColor: colors.grey12,
        width: '275px',
        padding: '10px',
        border: `1px solid transparent`,
        borderRadius: '5px',
        boxShadow: 'none',
        fontFamily: avenirFontFamily,
        fontSize: '14px',
        boxSizing: 'border-box',
        '&:focus': {
          border: `1px solid ${colors.orange}`,
          borderRadius: '5px',
          backgroundColor: colors.grey12,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.grey17,
        },
      },
    },
  },
});
